import { IntlProvider } from 'preact-i18n';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { MemoryRouter } from 'react-router-dom';

import storage from 'local-storage-fallback';
import { ThemeProvider } from 'styled-components';

import { affiliateLinkVar } from 'redesigncache/affiliateLink/cache';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import { WidgetFlowType } from 'redesigncache/bookingFlow/cache';
import useFlowSetup from 'redesigncache/bookingFlow/useFlowSetup';
import useHostConfiguration from 'redesigncache/hostConfiguration/useHostConfiguration';
import { PageVisitedProvider } from 'redesigncomponents/PageVisitedProvider';
import WidgetModal from 'redesigncomponents/WidgetModal';
import WidgetWrapper from 'redesigncomponents/WidgetWrapper';
import * as locales from 'redesigntranslations';
import Router from 'router/Router';
import {
	borderRadius,
	colors,
	fontSizes,
	fonts,
	spacing,
	thicknesses
} from 'styles';
import GlobalStyles from 'styles/globalStyles';
import { WidgetMessageType } from 'types/enums';
import { checkIfIframe } from 'utils/iframe/checkIfIframe';
import { onMsg } from 'utils/iframe/postMessage';

const App = () => {
	const { theme: providedColors, hostId } = window.DEST1_REDESIGN_CONFIG;
	const theme = {
		borderRadius,
		colors: { ...colors, ...providedColors },
		fonts,
		fontSizes,
		spacing,
		thicknesses
	};

	const isIframe = useMemo(checkIfIframe, []);
	const { flow, setFlow } = useFlowSetup();
	const [trustedOrigin, setTrustedOrigin] = useState('');
	const [messageOrigin, setMessageOrigin] = useState('');
	const [widgetConfig, setWidgetConfig] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [usedLocale, setUsedLocale] = useState(null);
	const { refreshAuth } = useUserAuth();
	const { loadHostConfiguration, hostConfiguration } =
		useHostConfiguration(messageOrigin);

	useEffect(() => {
		if (isIframe) {
			window.addEventListener('message', e => {
				// Todo: use host_id from e.data.config.user_id
				// to check if the message initiator has rights to communicate with the widget
				if (typeof e.data !== 'object' && e.data !== null) {
					let data;
					try {
						data = JSON.parse(e.data);
					} catch (err) {
						console.log(err.message);
					}
					if (data?.message === WidgetMessageType.WidgetConfig) {
						storage.setItem('DESTI1_REDESIGN:HOSTID', data.config.hostId);
						setWidgetConfig(data.config);
						setMessageOrigin(e.origin);
						loadHostConfiguration(data.config.hostId);
					} else if (data?.message === WidgetMessageType.StartDirectBooking) {
						affiliateLinkVar({
							id: data?.linkConfig.id,
							config: data?.linkConfig,
							startedAt: Date.now()
						});
						setFlow({
							type: WidgetFlowType.AffiliateLink,
							config: data?.linkConfig
						});
					} else if (data?.message === WidgetMessageType.StartMain) {
						setFlow({
							type: WidgetFlowType.Main
						});
					}
				}
			});
		} else {
			storage.setItem('DESTI1_REDESIGN:HOSTID', hostId);
			loadHostConfiguration(hostId);
		}
	}, []);

	useEffect(() => {
		refreshAuth();
	}, []);

	useEffect(() => {
		onMsg('dest1RedesignWidgetInit', '*');
	}, []);

	useEffect(() => {
		if (hostConfiguration) {
			if (isIframe) {
				const trustedOrigins = ['https://js.stripe.com'];

				const hostUrl = hostConfiguration?.hostUrl;

				if (hostUrl !== '') {
					trustedOrigins.push(hostUrl);
				}

				if (trustedOrigins.includes(messageOrigin)) {
					setTrustedOrigin(hostUrl);
					window.DEST1_REDESIGN_CONFIG = {
						...window.DEST1_REDESIGN_CONFIG,
						...widgetConfig
					};
					setShowModal(true);
				} else {
					setShowModal(false);
					onMsg('dest1RedesignHideIframe', messageOrigin);
				}
			} else {
				setShowModal(true);
			}
		}
	}, [hostConfiguration, messageOrigin, widgetConfig]);

	if (!showModal) return <></>;

	setInterval(() => {
		if (
			window.DEST1_REDESIGN_CONFIG?.lang &&
			window.DEST1_REDESIGN_CONFIG.lang !== usedLocale
		) {
			setUsedLocale(window.DEST1_REDESIGN_CONFIG.lang);
		}
	}, 500);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<IntlProvider definition={locales[usedLocale || 'en']}>
				<WidgetWrapper>
					<PageVisitedProvider>
						{showModal ? (
							<MemoryRouter>
								<WidgetModal
									onDismiss={() => {
										onMsg('dest1RedesignHideIframe', trustedOrigin);
									}}
								>
									<Router flow={flow} />
								</WidgetModal>
							</MemoryRouter>
						) : null}
					</PageVisitedProvider>
				</WidgetWrapper>
			</IntlProvider>
		</ThemeProvider>
	);
};

export default App;
