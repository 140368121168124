import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useParams } from 'react-router-dom';

import useAffiliateLink from 'redesigncache/affiliateLink/useAffiliateLink';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';

import { ContentWrapper } from './components/StyledComponents';
import useProductTypeRedirect from './useProductTypeRedirect';

interface IProps {}

const ProductTypeRedirect: FunctionalComponent<IProps> = () => {
	const { id } = useParams();

	const { clearWishlist } = useWishlist();
	const { clearCheckout } = useCheckout();

	const { affiliateLink, error: errorAffiliateLink } = useAffiliateLink(id);

	useProductTypeRedirect(affiliateLink?.config, affiliateLink?.details);

	useEffect(() => {
		clearWishlist();
		clearCheckout();
	}, []);

	return errorAffiliateLink ? (
		<AffiliateLinkWrapper>
			<ContentWrapper>{errorAffiliateLink}</ContentWrapper>
		</AffiliateLinkWrapper>
	) : null;
};

export default ProductTypeRedirect;
