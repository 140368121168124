import {
  borderRadius,
  colors,
  colorsSpec,
  fonts,
  fontSizes,
  shadows,
  spacing,
  thicknesses,
} from './defaultTheme';

export {
  colors,
  borderRadius,
  fontSizes,
  spacing,
  shadows,
  fonts,
  thicknesses,
  colorsSpec,
};
