import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import Accordion from 'redesigncomponents/Accordion';
import { AccordionParagraph } from 'redesigncomponents/Accordion/components/StyledComponents';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldSelect from 'redesigncomponents/FieldSelect';
import HeroHeader from 'redesigncomponents/HeroHeader';
import ImageGallery from 'redesigncomponents/ImageGallery';
import LocationMap from 'redesigncomponents/LocationMap';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import utcDateWithLocalTimePlugin from 'utils/date/utcDateWithLocalTimePlugin';

import DestinationIcon from 'assets/icons/transport/destination.svg';

import SessionTickets from './components/SessionTickets';
import {
	BoxTitle,
	BoxWrapper,
	HeaderTitle,
	ImageGalleryContainer,
	InputContainer,
	LeftSection,
	LoaderContainer,
	MapDivider,
	RightSection,
	SectionContainer,
	SectionHeader
} from './components/StyledComponents';
import useActivityDetails, {
	sanitizeActivityDescription
} from './useActivityDetails';
import useActivityOffer from './useActivityOffer';

dayjs.extend(utcDateWithLocalTimePlugin);

interface IProps {
	externalId: string;
	travelPeriod?: [Date, Date];
	people?: number;
}

const ActivityDetailsContainer: FunctionalComponent<IProps> = ({
	externalId = '',
	travelPeriod,
	people
}) => {
	const { t: activityDetailsTranslations } = useIntl('activityDetails');

	const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>();
	const [limitedTravelPeriod] = useState<[Date, Date]>(travelPeriod);
	const {
		activity,
		heroImage,
		geographicaLocation,
		loading: loadingActivityDetails,
		called: calledActivityDetails,
		lowestSessionTicketPrice,
		getActivity,
		clearActivityDetailsCache
	} = useActivityDetails(externalId);
	const {
		firstAvailableDate,
		sessionOptions,
		selectedDate,
		selectedSession,
		selectedTickets,
		setSelectedDateValue,
		setSelectedSessionValue,
		setSelectedTicketsValue,
		isDateAvailable,
		getSessionsForDate
	} = useActivityOffer();

	useEffect(() => {
		if (externalId) {
			clearActivityDetailsCache();
		}
	}, [externalId]);

	useEffect(() => {
		let initialStartTime = dayjs().startOf('day').toUTCDateWithLocalTime();
		let initialEndTime = dayjs()
			.endOf('month')
			.add(1, 'days')
			.endOf('day')
			.endOf('month')
			.toUTCDateWithLocalTime();

		if (limitedTravelPeriod?.length) {
			const [limitedPeriodStartTime, limitedPeriodInitialEndTime] =
				limitedTravelPeriod;

			initialStartTime = dayjs(limitedPeriodStartTime)
				.startOf('day')
				.toUTCDateWithLocalTime();
			initialEndTime = dayjs(limitedPeriodInitialEndTime)
				.endOf('day')
				.toUTCDateWithLocalTime();
		}

		if (externalId) {
			getActivity(externalId, initialStartTime, initialEndTime, people);
		}
	}, [externalId]);

	const onMonthOrYearChange = (date: Date) => {
		const startTime = dayjs(date)
			.startOf('day')
			.startOf('month')
			.toUTCDateWithLocalTime();
		const endTime = dayjs(date)
			.endOf('month')
			.add(1, 'days')
			.endOf('day')
			.endOf('month')
			.toUTCDateWithLocalTime();
		try {
			(async () => {
				await getActivity(externalId, startTime, endTime);
			})();
		} catch {
			// ignored
		}
	};

	useEffect(() => {
		if (firstAvailableDate && !selectedDate) {
			setSelectedDateValue(firstAvailableDate);
		}
	}, [firstAvailableDate]);

	const toggleImageGalleryOpen = () =>
		setImageGalleryOpen(imageGalleryOpen => !imageGalleryOpen);

	const minDate = limitedTravelPeriod?.[0] || new Date();
	const maxDate = limitedTravelPeriod?.[1] || undefined;

	return !activity || (!calledActivityDetails && loadingActivityDetails) ? (
		<LoaderContainer>
			<ThreeDotLoader style={{ zIndex: 101 }} color="#342245" size={80} />
		</LoaderContainer>
	) : imageGalleryOpen ? (
		<ImageGalleryContainer>
			<ImageGallery
				imageUrls={activity?.images.map(i => i.largeUrl)}
				onClose={toggleImageGalleryOpen}
			/>
		</ImageGalleryContainer>
	) : (
		<>
			<HeroHeader
				title={activity?.name}
				infos={[
					activity.supplierName,
					lowestSessionTicketPrice
						? activityDetailsTranslations('availableFrom', {
								price: lowestSessionTicketPrice,
								currency: activity?.displayPrice.currency
						  })
						: ''
				]}
				backgroundImage={heroImage?.largeUrl}
				onClick={activity?.images?.length ? toggleImageGalleryOpen : undefined}
			/>
			<SectionContainer>
				<LeftSection>
					<SectionHeader>
						<HeaderTitle>Booking Details</HeaderTitle>
					</SectionHeader>

					<BoxWrapper>
						<BoxTitle>Time of the tour</BoxTitle>
						<InputContainer>
							<FieldDatepicker
								smallPadding
								showMonthYearDropdown
								filterDate={isDateAvailable}
								label={activityDetailsTranslations('chooseTheDate')}
								minDate={minDate}
								maxDate={maxDate}
								dateFormatCalendarHeader="MMMM, YYYY."
								loading={loadingActivityDetails}
								onMonthChange={onMonthOrYearChange}
								onYearChange={onMonthOrYearChange}
								formatAsPlaceholder
								secondary
								input={{
									onChange: setSelectedDateValue,
									value: selectedDate,
									name: '',
									onBlur: () => null,
									onFocus: () => null
								}}
							/>

							<FieldSelect
								label={activityDetailsTranslations('chooseTheTime')}
								disabled={!selectedDate || sessionOptions?.length === 0}
								secondary
								input={{
									onChange: (value: { value: string }) => {
										const sessionsForDate = getSessionsForDate(selectedDate);
										const newSelectedSession = sessionsForDate.find(
											s => s.startTimeLocal === value.value
										);
										setSelectedSessionValue(newSelectedSession);
									},
									value: selectedSession?.startTimeLocal,
									name: ''
								}}
								options={sessionOptions}
								smallPadding
							/>
						</InputContainer>
					</BoxWrapper>
					{selectedSession && (
						<SessionTickets
							numberOfSeatsAvailable={selectedSession?.seatsAvailable}
							selectedTickets={selectedTickets}
							setSelectedTickets={setSelectedTicketsValue}
							ticketsAvailable={selectedSession?.prices}
						></SessionTickets>
					)}
				</LeftSection>
				<RightSection>
					<Accordion
						title={activityDetailsTranslations('descriptionTitle')}
						defaultValue={true}
						Icon={DestinationIcon}
					>
						<AccordionParagraph
							dangerouslySetInnerHTML={{
								__html: sanitizeActivityDescription(activity?.description)
							}}
						>
							{activity?.description}
						</AccordionParagraph>
					</Accordion>
				</RightSection>
			</SectionContainer>
			<MapDivider />
			<LocationMap markers={[geographicaLocation]} />
		</>
	);
};

export default ActivityDetailsContainer;
