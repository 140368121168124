import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

import { GET_TRAVEL_ITINERARY_BY_ID } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import StyledToast from 'redesigncomponents/Toast';

import { IComposedTravelPlanItem } from '../../../TravelPlan/useTravelPlan';
import useUpdateTravelPlanItem from '../../hooks/useUpdateTravelPlanItem';

enum FlightPaymentStatusEnum {
	Pending = 'PENDING',
	Received = 'RECEIVED',
	Confirmed = 'CONFIRMED'
}

export type TravelItineraryTransport = {
	travel_itinerary_transport: Array<{
		id: string;
		external_id?: string;
		transaction_id?: string;
	}>;
};

const useFlightsItineraryPaymentStatus = (
	travelPlanId,
	travelPlanItem: IComposedTravelPlanItem
) => {
	const { t } = useIntl('checkout.fligthCheckoutForm');
	const flightItineraryStatusCheckerTimerRef = useRef(null);
	const flightExpiryTimerRef = useRef(null);

	const [savedTravelItineraryId, setSavedTravelItineraryId] = useState();
	const [flightPaymentStatus, setFlightPaymentStatus] =
		useState<FlightPaymentStatusEnum>(undefined);
	const { setPaidTravelPlanItem } = useUpdateTravelPlanItem(
		travelPlanItem,
		travelPlanId
	);
	const [getItineraryById] = useMyPagesMutation(GET_TRAVEL_ITINERARY_BY_ID, {
		onCompleted: data => {
			const transportItinerary =
				data?.getItineraryById.travelItineraryTransports?.[0];
			if (transportItinerary.transactionId && transportItinerary.externalId) {
				setFlightPaymentStatus(FlightPaymentStatusEnum.Confirmed);
				setPaidTravelPlanItem();
				clearTimersForPaymentAttempt();
			} else if (transportItinerary.externalId) {
				setFlightPaymentStatus(FlightPaymentStatusEnum.Received);
			}
		}
	});

	useEffect(() => {
		return () => {
			// clean up of all used timers
			clearTimersForPaymentAttempt();
		};
	}, []);

	useEffect(() => {
		if (travelPlanItem.expired || travelPlanItem.paid) {
			clearTimersForPaymentAttempt();
		}
	}, [travelPlanItem.expired, travelPlanItem.paid]);

	const startFlightPayment = useCallback(
		travelItineraryId => {
			setSavedTravelItineraryId(travelItineraryId);
			// clear previous payment attempts for this travelPlanItem
			clearTimersForPaymentAttempt();

			flightExpiryTimerRef.current = setTimeout(() => {
				StyledToast.warning(t('flightStatusCouldNotBeDetermined'), {
					autoClose: false
				});
				setSavedTravelItineraryId(undefined);
				clearTimersForPaymentAttempt();
			}, 300000);

			getItineraryById({
				variables: {
					id: travelItineraryId
				}
			});
			flightItineraryStatusCheckerTimerRef.current = setInterval(() => {
				getItineraryById({
					variables: {
						id: travelItineraryId
					}
				});
			}, 5000);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[savedTravelItineraryId]
	);

	const clearTimersForPaymentAttempt = () => {
		if (flightExpiryTimerRef.current) {
			clearTimeout(flightExpiryTimerRef.current);
		}

		if (flightItineraryStatusCheckerTimerRef.current) {
			clearTimeout(flightItineraryStatusCheckerTimerRef.current);
		}
	};

	return {
		flightPaymentStatus,
		savedTravelItineraryId,
		startFlightPayment
	};
};

export default useFlightsItineraryPaymentStatus;
