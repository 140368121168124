import { CardElement } from '@stripe/react-stripe-js';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';

import FormSectionTitle from './FormSectionTitle';

const StyleWrapper = styled.div`
	.card-element-base {
		input,
		label {
			background: #fafbfb;
		}
		border: 1px solid #91889b;
		padding: 15px;
		border-radius: 4px;
		font-size: 16px;
		outline: none;
		color: #2c2c2c;
		width: 100%;
		font-family: Open Sans, Arial, Helvetica, sans-serif;
	}
`;
const PaymentDetailsSectionForm = ({ onCardChange }) => {
	const { t } = useIntl('checkout.paymentDetailsSectionForm');

	return (
		<StyleWrapper>
			<FormSectionTitle title={t('title')} />
			<CardElement
				id="card-element"
				options={{
					hidePostalCode: true,
					classes: {
						base: 'card-element-base'
					},
					style: {
						base: {
							fontSize: '16px',
							fontFamily: 'Open Sans'
						}
					}
				}}
				onChange={onCardChange}
			/>
		</StyleWrapper>
	);
};

export default PaymentDetailsSectionForm;
