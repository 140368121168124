import { useEffect } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';

import TransportAddedToWishlistRedirectModal from '../components/TransportAddedToWishlistRedirectModal';
import AirTransportListContainer from './AirTransportListContainer';
import useTransportSearchOptionsCache from './AirTransportListContainer/cache/useTransportSearchOptionsCache';
import { StyledBodyWrapper } from './AirTransportListContainer/components/StyledComponents';
import useAddTransportToWishlist from './AirTransportListContainer/hooks/useAddTransportToWishlist';
import useTransportationDetails from './AirTransportListContainer/hooks/useTransportationDetails';
import useLoadTransportationOffers from './AirTransportListContainer/hooks/useTransportationOffers';

const TransportSearch = () => {
	const { t } = useIntl('transportList');
	const [itemAddedToWishlistOpen, setItemAddedToWishlistOpen] = useToggle();
	const { travelPeriod: initialTravelPeriod } = useDestinationAndPeriod();
	const { travelPeriod, setTravelPeriod } = useTransportSearchOptionsCache();
	const { loadingTransportOffer } = useLoadTransportationOffers();
	const { selectedTransportOffer } = useTransportationDetails();

	const { addToWishlist, addedWishlistItem } = useAddTransportToWishlist(
		selectedTransportOffer
	);

	useEffect(() => {
		setTravelPeriod([initialTravelPeriod[0], initialTravelPeriod[1]]);
	}, [initialTravelPeriod]);

	const handleAddToWishlist = () => {
		addToWishlist();
		setItemAddedToWishlistOpen(true);
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<AirTransportListContainer
					travelPeriod={travelPeriod as [Date, Date]}
				/>
			</StyledBodyWrapper>
			{itemAddedToWishlistOpen && addedWishlistItem && (
				<TransportAddedToWishlistRedirectModal
					item={addedWishlistItem}
					open={itemAddedToWishlistOpen}
					toggleOpen={setItemAddedToWishlistOpen}
				/>
			)}
			<Footer>
				{selectedTransportOffer && !loadingTransportOffer && (
					<Button
						title={t('addToWishlistButtonTitle')}
						onClick={handleAddToWishlist}
					/>
				)}
			</Footer>
		</MainWrapper>
	);
};

export default TransportSearch;
