import { FunctionalComponent } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import DatePicker from 'react-datepicker';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import StyledTextInput from 'redesigncomponents/FieldTextInput/StyledTextInput';

import DateIcon from 'assets/date.svg';

import CustomDateHeader from './components/CustomDateHeader';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

Date.prototype.isBefore = function (date: Date) {
	return dayjs(this).isBefore(date);
};

Date.prototype.isAfter = function (date: Date) {
	return dayjs(this).isAfter(date);
};

enum DateFormatStyle {
	SHORT = 'SHORT'
}
const dateFormatMap = {
	[DateFormatStyle.SHORT]: {
		datepicker: 'dd/MM/yyyy',
		component: 'DD/MM/YYYY'
	}
};

const DatePickerWrapper = styled.div`
	flex: 1;
	.date_picker.full-width {
		width: 100%;
	}
	.react-datepicker__header {
		border: unset;
		background-color: unset;
	}
	.react-datepicker__day-names {
		display: flex;
		justify-content: space-between;
		padding: 0px 0.9rem;
		border-bottom: 1px solid #efedf0;
	}
	.react-datepicker__day {
		width: 2rem;
		height: 2rem;
		padding: 4px;
		border-radius: 99px;
		margin: 4px;
		color: #342245;
	}
	.react-datepicker__day--disabled {
		background-color: #f7f6f8;
		color: #ccc8d1;
		cursor: not-allowed;
	}
	.react-datepicker__day--selected {
		background-color: #342245;
		color: #ccc8d1;
	}
	.react-datepicker__day--in-range,
	.react-datepicker__day--in-selecting-range {
		background-color: #342245;
		color: #ccc8d1;
	}
	.react-datepicker__day--keyboard-selected {
		background-color: #ccc8d1;
		color: #342245;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	> div {
		flex: 1;
	}
`;

export type Props = {
	required?: boolean;
	id?: string;
	disabled?: boolean;
	secondary?: boolean;
	input?: FieldInputProps<any>;
	onMonthChange?: (date: Date) => void;
	onYearChange?: (date: Date) => void;
	meta?: FieldMetaState<any>;
	dateFormatStyle?: DateFormatStyle;
	freeEntry?: boolean;
	dateFormatCalendarHeader?: string;
	label?: string;
	placeholder?: string;
	formatAsPlaceholder?: boolean;
	fromPlaceholder?: string;
	toPlaceholder?: string;
	maxDate?: Date;
	minDate?: Date;
	isRange?: boolean;
	showMonthYearDropdown?: boolean;
	smallPadding?: boolean;
	filterDate?: (date) => boolean;
	loading?: boolean;
	showTimeSelect?: boolean;
	popperPlacement?: string;
	excludeDates?: Date[];
};

const FieldDatepicker: FunctionalComponent<Props> = ({
	input = null,
	meta = { visited: false },
	required = false,
	disabled = false,
	secondary = false,
	dateFormatStyle = DateFormatStyle.SHORT,
	dateFormatCalendarHeader = 'MMMM, YYYY.',
	freeEntry = true,
	label,
	placeholder,
	formatAsPlaceholder,
	fromPlaceholder,
	toPlaceholder,
	maxDate = null,
	minDate = null,
	id = null,
	isRange = false,
	showMonthYearDropdown = false,
	filterDate,
	smallPadding,
	loading,
	showTimeSelect,
	popperPlacement,
	excludeDates,
	onMonthChange,
	onYearChange
}) => {
	const error = meta?.touched && (meta.error || meta.submitError);

	const startDateValue = Array.isArray(input?.value)
		? input?.value[0]
		: input?.value;

	const endDateValue = Array.isArray(input?.value)
		? input?.value[1]
		: input?.value;

	const { t } = useIntl('common.Components.FieldDatepicker');
	const [startDate, setStartDate] = useState<Date | null>(startDateValue);
	const [endDate, setEndDate] = useState<Date | null>(endDateValue);

	const dateFormat = useMemo(
		() =>
			dateFormatMap[dateFormatStyle] || dateFormatMap[DateFormatStyle.SHORT],
		[dateFormatStyle]
	);

	useEffect(() => {
		setStartDate(startDateValue);
	}, [startDateValue]);

	useEffect(() => {
		setEndDate(endDateValue);
	}, [endDateValue]);

	const onChange = selected => {
		if (isRange) {
			const [start, end] = selected;
			setStartDate(start);
			setEndDate(end);
		} else {
			setStartDate(selected);
		}
		input.onChange(selected);
	};

	const onStartDateRawChange = rawText => {
		const parsedStartDate = parseDate(rawText);
		if (parsedStartDate) {
			if (
				(minDate && parsedStartDate.isBefore(minDate)) ||
				(maxDate && parsedStartDate.isAfter(maxDate))
			)
				return;

			setStartDate(parsedStartDate);
			setEndDate(undefined);
			input.onChange([parsedStartDate, null]);
		}
	};

	const onEndDateRawChange = rawText => {
		const parsedEndDate = parseDate(rawText);
		if (parsedEndDate) {
			if (
				(minDate && parsedEndDate.isBefore(minDate)) ||
				(maxDate && parsedEndDate.isAfter(maxDate))
			)
				return;
			setEndDate(parsedEndDate);
			input.onChange([startDate, parsedEndDate]);
		}
	};

	const parseDate = (rawText): Date => {
		const componentDateFormat = dateFormat.component;
		if (componentDateFormat && rawText?.length !== componentDateFormat.length) {
			return;
		}

		const parsedDate = dayjs(rawText, componentDateFormat);

		return parsedDate.isValid() ? parsedDate.toDate() : undefined;
	};

	const formatDate = (date: Date) => {
		return date ? dayjs(date).format(dateFormat.component) : undefined;
	};

	const inputRef = useRef(null);

	return (
		<DatePickerWrapper error={error}>
			<DatePicker
				id={id}
				selected={startDate}
				useWeekdaysShort={true}
				wrapperClassName="date_picker full-width"
				calendarStartDay={1}
				customInput={
					isRange ? (
						<InputWrapper>
							<StyledTextInput
								inputRef={inputRef}
								label={label ? label : t('chooseTheDates')}
								style={{
									borderTopRightRadius: '0px',
									borderBottomRightRadius: '0px'
								}}
								secondary={secondary}
								error={error}
								inputProps={{
									value: formatDate(startDate),
									onChange: e =>
										freeEntry
											? onStartDateRawChange(e.target.value)
											: undefined,
									placeholder: fromPlaceholder || t('from'),
									readonly: !freeEntry
								}}
								smallPadding={smallPadding}
								required={required}
							/>
							<StyledTextInput
								inputRef={inputRef}
								style={{
									borderTopLeftRadius: '0px',
									borderBottomLeftRadius: '0px'
								}}
								secondary={secondary}
								icon={<DateIcon />}
								error={error}
								inputProps={{
									value: formatDate(endDate),
									onChange: e =>
										freeEntry ? onEndDateRawChange(e.target.value) : undefined,
									placeholder: toPlaceholder || t('to'),
									readonly: !freeEntry
								}}
								smallPadding={smallPadding}
								required={required}
							/>
						</InputWrapper>
					) : (
						<StyledTextInput
							inputRef={inputRef}
							label={label ? label : t('chooseADate')}
							icon={<DateIcon />}
							error={error}
							inputProps={{
								value: formatDate(startDate),
								onChange: e =>
									freeEntry ? onStartDateRawChange(e.target.value) : undefined,
								placeholder: formatAsPlaceholder
									? dateFormat.component
									: placeholder
							}}
							secondary={secondary}
							loading={loading}
							smallPadding={smallPadding}
							required={required}
						/>
					)
				}
				{...input}
				disabled={disabled}
				required={required}
				showPopperArrow={false}
				onChange={onChange}
				onMonthChange={onMonthChange}
				onYearChange={onYearChange}
				startDate={startDate}
				endDate={endDate}
				dropdownMode="select"
				maxDate={maxDate}
				minDate={minDate}
				selectsRange={isRange}
				filterDate={filterDate}
				excludeDates={excludeDates}
				showTimeSelect={showTimeSelect}
				popperPlacement={popperPlacement}
				shouldCloseOnSelect={
					isRange ? !(!startDate || Boolean(startDate && endDate)) : true
				}
				renderCustomHeader={({
					date,
					changeYear,
					changeMonth,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => {
					return (
						<CustomDateHeader
							date={date}
							minDate={minDate}
							maxDate={maxDate}
							changeYear={changeYear}
							changeMonth={changeMonth}
							decreaseMonth={decreaseMonth}
							increaseMonth={increaseMonth}
							prevMonthButtonDisabled={prevMonthButtonDisabled}
							nextMonthButtonDisabled={nextMonthButtonDisabled}
							showMonthYearDropdown={showMonthYearDropdown}
							dateFormatCalendarHeader={dateFormatCalendarHeader}
						/>
					);
				}}
			/>
		</DatePickerWrapper>
	);
};

export default FieldDatepicker;
