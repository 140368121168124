import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useLocation } from 'react-router-dom';

import { PAGE_DEFAULT } from 'config/network';
import useAirplaneClassOptions from 'hooks/useAirplaneClassOptions';
import useAirplaneTransportSortCodebookOptions from 'hooks/useAirplaneTransportSortOptions';
import useTransportTripTypeCodebookOptions from 'hooks/useTransportTripTypeOptions';
import { Option } from 'redesigntypes/Option';
import { TransportTripType } from 'types/enums';

import useTransportSearchOptionsCache from './cache/useTransportSearchOptionsCache';
import AirplaneOfferResultList from './components/AirplaneOfferResultList';
import AirplaneSearchOptions from './components/AirplaneSearchOptions';
import { initialValuesOptionCategories } from './components/OptionCategories/useOptionCategories';
import useTransportationDetails from './hooks/useTransportationDetails';
import useLoadTransportationOffers from './hooks/useTransportationOffers';

interface IProps {
	travelPeriod?: [Date, Date];
}

const AirTransportListContainer: FunctionalComponent<IProps> = ({
	travelPeriod
}) => {
	const location = useLocation();
	const { airplaneClassOptions } = useAirplaneClassOptions();
	const { airplaneTransportSortOptions } =
		useAirplaneTransportSortCodebookOptions();
	const { transportTripTypeOptions } = useTransportTripTypeCodebookOptions();
	const {
		transportType,
		fromLocation,
		toLocation,
		tripType,
		transportSort,
		airClass,
		optionCategories,
		initializeTransportSearchCache
	} = useTransportSearchOptionsCache();

	const { selectedTransportOffer, setSelectedTransportOfferDetailsValue } =
		useTransportationDetails();

	const {
		loadTransportationOffer,
		loadingTransportOffer,
		calledLoadTransportOffer,
		transportOffer,
		page,
		hasMore,
		totalCount
	} = useLoadTransportationOffers();

	useEffect(() => {
		if (
			!location?.state?.breadcrumbNavigation &&
			transportTripTypeOptions?.length
		) {
			// breadcrumbNavigation added to state in Breadcrumbs component
			// initialize the activity list cache only if we got here from a regular link and if the screen has not been initialized before
			// if we got here by breadcrumb navigation, we expect to have the same state as we are going back in history
			initializeTransportSearchCache(
				transportType,
				transportTripTypeOptions[1],
				airplaneClassOptions[0],
				airplaneTransportSortOptions,
				initialValuesOptionCategories,
				toLocation,
				travelPeriod
			);
		}
	}, [transportTripTypeOptions, location?.state?.breadcrumbNavigation]);

	useEffect(() => {
		// clear returning date if the trip type switches to one way
		if (tripType?.value === TransportTripType.OneWay) {
			travelPeriod[1] = undefined;
		}
	}, [tripType]);

	const searchTransportOffers = () => {
		loadTransportationOfferPage(PAGE_DEFAULT, transportSort);
	};

	const loadTransportationOfferPage = (pageNumber: number, sort: Option) => {
		loadTransportationOffer(
			travelPeriod,
			fromLocation,
			toLocation,
			airClass,
			optionCategories,
			pageNumber,
			undefined,
			transportType,
			tripType,
			sort
		);
	};

	const selectTransportOffer = offer => {
		if (offer) {
			setSelectedTransportOfferDetailsValue({
				offer,
				fromLocation,
				toLocation,
				travelPeriod,
				optionCategories,
				transportType,
				tripType,
				airClass
			});
		} else {
			setSelectedTransportOfferDetailsValue(undefined);
		}
	};
	return (
		<>
			<AirplaneSearchOptions
				searchTransportOffers={searchTransportOffers}
				loadingTransportOffer={loadingTransportOffer}
			/>
			<AirplaneOfferResultList
				transportOffer={transportOffer}
				loadingTransportOffer={loadingTransportOffer}
				calledLoadTransportOffer={calledLoadTransportOffer}
				page={page}
				hasMore={hasMore}
				totalCount={totalCount}
				loadTransportationOfferPage={loadTransportationOfferPage}
				selectTransportOffer={selectTransportOffer}
				selectedTransportOfferDetails={selectedTransportOffer}
			/>
		</>
	);
};

export default AirTransportListContainer;
