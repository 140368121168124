import styled from 'styled-components';
import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 530px;
  margin: 0 auto;
	@media ${DeviceBreakpoints.small} {
		flex-direction: row;
    gap: 1rem;
	}
`;

export const MenuItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
	@media ${DeviceBreakpoints.small} {
    gap: 0.5rem;
	}
`;

export const IconWrapper = styled.div`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.active ? `${Colors.V900}` : `${Colors.V30}`)};
  svg {
    width: 1.75rem;
  }
  path {
    fill: ${props => (!props.active ? `${Colors.V900}` : `${Colors.V30}`)};
  }
  &:hover {
    background: ${props => (props.active ? `${Colors.V900}` : `${Colors.V400}`)};
    path {
      fill: ${Colors.V30};
    }
  }
`;

export const ItemText = styled.span`
  font-size: ${FontSize.smSize};
  color: ${Colors.V900};
  margin-top: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		margin-top: 0;
    font-weight: ${FontWeight.semibold};
	}
`;
