import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import config from 'config/config';
import useIntl from 'hooks/useIntl';
import { Modal, ModalBody } from 'redesigncomponents/Modal';

import DestiOneIcon from 'assets/images/DestiOneIcon.png';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 400px;
`;

const LogoImg = styled.img`
	width: 20%;
	min-width: 75px;
	margin-top: 20px;
	margin-bottom: 40px;
`;

const Link = styled.a`
	text-decoration: underline;
	cursor: pointer;
	color: #342245;
`;

type Props = {
	onClose: () => void;
	isOpen: boolean;
};

const PoweredByModal: FunctionalComponent<Props> = ({ isOpen, onClose }) => {
	const { t } = useIntl('common');

	return (
		<Modal onClose={onClose} isOpen={isOpen} animation="slideUp">
			<ModalBody>
				<ContentWrapper>
					<LogoImg src={DestiOneIcon} />
					<Link href={config.poweredByDestiOneLink} target="_blank">
						{t('poweredByDestiOne')}
					</Link>
				</ContentWrapper>
			</ModalBody>
		</Modal>
	);
};

export default PoweredByModal;
