import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

import ActivityImage from 'assets/images/activities-bckg.png';

export const HeroContainer = styled.div`
	position: relative;
	min-height: 12.5rem;
	border-radius: 0.25rem;
	background: url(${({ backgroundImage }) => backgroundImage || ActivityImage})
		no-repeat;

	background-size: cover;
	background-position: center;
	@media ${DeviceBreakpoints.small} {
		border-radius: 0;
	}
`;

export const GalleryButton = styled.div`
	display: inline-flex;
	align-items: center;
	border-radius: 0.25rem;
	background: linear-gradient(
		97.17deg,
		rgba(255, 255, 255, 0.69) 2.4%,
		rgba(255, 255, 255, 0) 150.47%
	);
	padding: 0.625rem;
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	cursor: pointer;
	@media ${DeviceBreakpoints.small} {
		bottom: 0.625rem;
		right: 0.5rem;
		top: auto;
	}
`;

export const ButtonText = styled.span`
	color: ${Colors.V900};
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.bold};
	text-transform: uppercase;
	margin-left: 0.625rem;
`;

export const PositionContainer = styled.div`
	position: absolute;
	left: 0.625rem;
	bottom: 0.625rem;
	@media ${DeviceBreakpoints.small} {
		left: 0;
	}
`;

export const BoxContainer = styled.div`
	border-radius: 0.25rem;
	background: linear-gradient(
		95.09deg,
		#ffffff 4.17%,
		rgba(255, 255, 255, 0) 101.17%
	);
	width: 15rem;
	padding: 0.625rem;
	color: ${Colors.V900};
	@media ${DeviceBreakpoints.small} {
		border-radius: 0 0.75rem 0.75rem 0;
    	background: linear-gradient(114.07deg, #FFFFFF 4.73%, rgba(255, 255, 255, 0.5) 100%);
    	padding: 0.75rem 1.25rem 0.75rem 0.75rem;
   	 	border: 1px solid #ececec;
    	border-left: none;
	}
`;

export const BoxTitle = styled.h4`
	font-size: ${FontSize.base};
	font-weight: ${FontWeight.bold};
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const BoxSubtitle = styled.p`
	font-size: ${FontSize.xsSize};
	margin-bottom: 0.125rem;
`;

export const BoldText = styled.span`
	font-weight: ${FontWeight.bold};
`;

export const ReviewInfo = styled.div`
	font-weight: ${FontWeight.bold};
	display: flex;
	align-items: center;
`;

export const StarContainer = styled.div`
	margin-right: 0.325rem;
`;

export const ReviewText = styled.span`
	font-size: ${FontSize.xsSize};
`;
