import { FunctionalComponent } from 'preact';
import { Field } from 'react-final-form';

import dayjs from 'dayjs';

import useCountryCodebookOptions from 'hooks/useCountryCodebookOptions';
import useGenderCodebookOptions from 'hooks/useGenderCodebookOptions';
import useIntl from 'hooks/useIntl';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldSelect from 'redesigncomponents/FieldSelect';
import FieldTextInput from 'redesigncomponents/FieldTextInput';

import { LeadTravelSection, TwoInputRow } from '../../StyledComponents';
import FormSectionTitle from './FormSectionTitle';

interface IProps {
	isMerchandise?: boolean;
	hiddenFields?: string[];
}

const LeadTravelerFormSection: FunctionalComponent<IProps> = ({
	isMerchandise,
	hiddenFields
}) => {
	const { t } = useIntl('checkout.leadTravelerFormSection');
	const { countryOptions } = useCountryCodebookOptions();
	const { genderOptions } = useGenderCodebookOptions();

	return (
		<LeadTravelSection>
			<FormSectionTitle
				title={isMerchandise ? t('merchandiseTitle') : t('title')}
			/>
			<TwoInputRow rowLayout>
				<Field
					label={t('firstNameLabel')}
					required
					name="firstName"
					id="firstName"
					component={FieldTextInput}
				/>
				<Field
					label={t('lastNameLabel')}
					required
					name="lastName"
					id="lastName"
					component={FieldTextInput}
				/>
			</TwoInputRow>
			<TwoInputRow>
				<Field
					label={t('emailLabel')}
					required
					name="email"
					id="email"
					component={FieldTextInput}
				/>
				{hiddenFields.findIndex(hf => hf === 'country') !== -1 && (
					<Field
						label={t('countryLabel')}
						required
						component={FieldSelect}
						options={countryOptions}
						name="country"
						id="country"
						tertiary
						searchable
					/>
				)}
			</TwoInputRow>
			<TwoInputRow rowLayout>
				{hiddenFields.findIndex(hf => hf === 'dateOfBirth') !== -1 && (
					<Field
						label={t('dateOfBirthLabel')}
						required
						name="dateOfBirth"
						id="dateOfBirth"
						component={FieldDatepicker}
						tertiary
						maxDate={
							new Date(dayjs().subtract(18, 'year').subtract(1, 'day').toDate())
						}
						showMonthYearDropdown
						formatAsPlaceholder
					/>
				)}
				{hiddenFields.findIndex(hf => hf === 'gender') !== -1 && (
					<Field
						label={t('genderLabel')}
						required
						component={FieldSelect}
						options={genderOptions}
						name="gender"
						id="gender"
						tertiary
					/>
				)}
			</TwoInputRow>
		</LeadTravelSection>
	);
};

export default LeadTravelerFormSection;
