import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { Field, Form, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useSocialSignIn from 'redesigncache/auth/useSocialSignIn';
import Button from 'redesigncomponents/Button';
import FieldCheckbox from 'redesigncomponents/FieldCheckbox';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Footer from 'redesigncomponents/Footer';
import GoogleButton from 'redesigncomponents/GoogleButton';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { Paths } from 'router';

import {
	ContentWrapper,
	FormHeader,
	SignupContent,
	SignupLink,
	SignupText
} from '../components/StyledComponents';
import { SignUpFormValuesType, signUpFormVar } from './cache';
import {
	CheckboxLabelText,
	CheckboxWrapper,
	DividerLine,
	DividerText,
	DividerWrapper,
	InputRow,
	StyledBodyWrapper,
	TermsLink
} from './components/StyledComponents';
import useSignUp from './useSignUp';

interface IProps {}

const SignUp: FunctionalComponent<IProps> = () => {
	const history = useHistory();
	const { t } = useIntl('signUp');
	const { initialValues, validate, signUp, loading } = useSignUp();
	const { startGoogleSignIn } = useSocialSignIn();
	const [passwordInfoLabel, setPasswordInfoLabel] = useState<string>(undefined);

	const goToSignIn = () => history.push(Paths.SignIn);

	return (
		<MainWrapper>
			<Form
				onSubmit={signUp}
				validate={validate}
				initialValues={initialValues}
				validateOnBlur
				render={({
					handleSubmit,
					submitting,
					pristine,
					hasValidationErrors
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<StyledBodyWrapper>
								<ContentWrapper>
									<FormHeader>{t('signUpHeader')}</FormHeader>
									<InputRow>
										<Field
											name="firstName"
											component={FieldTextInput}
											label={t('firstNameLabel')}
											type="text"
										/>
										<Field
											name="lastName"
											component={FieldTextInput}
											label={t('lastNameLabel')}
											type="text"
										/>
									</InputRow>

									<Field
										name="email"
										component={FieldTextInput}
										label={t('emailLabel')}
										type="email"
									/>

									<Field id="password" name="password">
										{({ input, meta }) => {
											return (
												<FieldTextInput
													meta={meta}
													{...input}
													onFocus={event => {
														setPasswordInfoLabel(t('passwordInfoLabel'));
														input.onFocus(event);
													}}
													onBlur={event => {
														setPasswordInfoLabel(undefined);
														input.onBlur(event);
													}}
													info={passwordInfoLabel}
													label={t('passwordLabel')}
													type="password"
												/>
											);
										}}
									</Field>
									<Field
										name="confirmPassword"
										component={FieldTextInput}
										label={t('confirmPasswordLabel')}
										type="password"
									/>

									<CheckboxWrapper>
										<Field
											name="olderThan18AndTermsOfUse"
											component={FieldCheckbox}
											label={
												<CheckboxLabelText>
													{t(
														'olderThan18andTermsOfUse.olderThan18andIAcceptTermsOfUse'
													)}
													<TermsLink
														onClick={() =>
															history.replace({
																pathname: Paths.TermsOfUse
															})
														}
													>
														{t('olderThan18andTermsOfUse.termsOfUse')}
													</TermsLink>
												</CheckboxLabelText>
											}
											type="checkbox"
										/>
									</CheckboxWrapper>
									<Button
										loading={loading}
										title={t('signUpHereButtonTitle')}
										type="submit"
										disabled={
											submitting || pristine || loading || hasValidationErrors
										}
									/>
									<DividerWrapper>
										<DividerLine></DividerLine>
										<DividerText>{t('dividerOr')}</DividerText>
									</DividerWrapper>
									<GoogleButton
										title={t('continueWithGoogle')}
										onClick={startGoogleSignIn}
									></GoogleButton>

									<SignupContent>
										<SignupText>{t('haveAnAccount')}</SignupText>
										<SignupLink onClick={goToSignIn}>
											{t('signInHereLink')}
										</SignupLink>
									</SignupContent>
								</ContentWrapper>
							</StyledBodyWrapper>
							<Footer />
							<FormSpy
								onChange={props => {
									signUpFormVar({
										...props.values,
										password: undefined,
										confirmPassword: undefined
									} as SignUpFormValuesType);
								}}
							/>
						</form>
					);
				}}
			/>
		</MainWrapper>
	);
};

export default SignUp;
