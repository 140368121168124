import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const ContentWrapper = styled.div`
	flex: 1;
`;

export const MainHeading = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
	margin-bottom: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const TwoInputWrapper = styled.div`
	display: grid;
	gap: 0.9375rem;
	margin-bottom: 0.75rem;
	grid-template-columns: 1fr 1fr;
	> div {
		margin-bottom: 0;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
	@media ${DeviceBreakpoints.small} {
		gap: ${({ mobileLayout }) => (mobileLayout ? '0.75rem' : '0.5rem')};
		grid-template-columns: ${({ mobileLayout }) => (mobileLayout ? '1fr' : '1fr 1fr')};
	}
`;

export const InputWrapper = styled.div`
	input {
		height: unset;
	}
`;

export const FormWrapper = styled.div`
	margin-bottom: 0.75rem;
`;

export const Divider = styled.div`
	width: calc(100% + 3rem);
	height: 1px;
	background: ${Colors.V50};
	margin: 1.25rem 0 1.25rem -1.875rem;
	@media ${DeviceBreakpoints.small} {
		margin: 0.5rem 0 0.5rem -0.75rem;
		width: calc(100% + 1.5rem);
		visibility: hidden;
	}
`;
