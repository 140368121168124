import styled from 'styled-components';

import config from 'config/config';
import useIntl from 'hooks/useIntl';

export const FooterLink = styled.div`
	display: flex;
	background-color: #fff;
	justify-content: center;
	padding: 0.75rem 0;
`;

export const PoweredByLink = styled.a`
	font-size: 0.625rem;
	text-decoration: underline;
	color: #091e42;
`;

const GetGestFooter = () => {
	const { t } = useIntl('common');
	return (
		<FooterLink>
			<PoweredByLink href={config.poweredByGetGestLink} target="_blank">
				{t('poweredByGetGest')}
			</PoweredByLink>
		</FooterLink>
	);
};

export default GetGestFooter;
