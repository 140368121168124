import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const TabsNavigation = styled.ul`
	list-style: none;
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

export const SingleTab = styled.li`
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	padding: 0.5rem 0.75rem;
	background-color: ${props =>
		props.active ? `${Colors.V30}` : `${Colors.V20}`};
	border-radius: 0.25rem 0.25rem 0 0;
	color: ${props => (props.active ? `${Colors.V900}` : `${Colors.V600}`)};
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.semibold};
	@media ${DeviceBreakpoints.small} {
		justify-content: center;
		flex: 1;
	}
`;

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const TabTitle = styled.span`
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const DateTitle = styled.span``;

export const TabContentContainer = styled.div`
	background-color: ${Colors.V30};
	padding: 0.5rem;
	border-radius: 0 0.25rem 0.25rem 0.25rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 0.75rem;
`;
