import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import { hostConfigurationVar } from 'redesigncache/hostConfiguration/cache';
import {
	Breadcrumb,
	BreadcrumbItem,
	Link,
	NavIconWrapper
} from 'redesigncomponents/Breadcrumbs/components/StyledComponents';
import IconButton from 'redesigncomponents/IconButton';
import { ModalContext } from 'redesigncomponents/WidgetModal';

import CloseIcon from 'assets/close.svg';
import GetGestLogo from 'assets/get-gest-logo.svg';
import NavigationArrowIcon from 'assets/navigation-arrow.svg';

import {
	HeaderWrapper,
	LeftWrapper,
	LogoWrapper,
	RightWrapper
} from './components/StyledComponents';

interface IProps {
	onBack?: () => void;
}

const CheckoutHeader: FunctionalComponent<IProps> = ({ onBack }) => {
	const { onModalClose } = useContext(ModalContext);
	const { t } = useIntl('common');
	const { logoUrl } = useReactiveVar(hostConfigurationVar);

	return (
		<HeaderWrapper>
			<LeftWrapper>
				<Breadcrumb>
					<BreadcrumbItem getGest onClick={onBack}>
						<NavIconWrapper back>
							<NavigationArrowIcon />
						</NavIconWrapper>
						<Link>{t('back')}</Link>
					</BreadcrumbItem>
				</Breadcrumb>
			</LeftWrapper>
			<RightWrapper>
				<LogoWrapper>
					{logoUrl ? <img src={logoUrl} /> : <GetGestLogo />}
				</LogoWrapper>
				<IconButton Icon={CloseIcon} onClick={onModalClose} />
			</RightWrapper>
		</HeaderWrapper>
	);
};

export default CheckoutHeader;
