export enum WidgetMessageType {
	WidgetConfig = 'dest1RedesignWidgetConfig',
	StartDirectBooking = 'dest1RedesignStartDirectBooking',
	StartMain = 'dest1RedesignStartMain'
}

export enum Gender {
	Female = 'FEMALE',
	Male = 'MALE'
}

export enum TransportType {
	Airplane = 'airplane',
	TrainBus = 'trainbus'
}

export enum TransportTripType {
	OneWay = 'OneWay',
	RoundTrip = 'RoundTrip'
}

export enum AirplaneClass {
	Economy = 'M',
	EconomyPremium = 'W',
	Business = 'C',
	First = 'F'
}

export enum DetailTransportTypes {
	Airplane = 'airplane',
	TrainBus = 'trainbus',
	Air = 'Air',
	Bus = 'Bus',
	Train = 'Train'
}

export enum UserConnectionTypes {
	GoogleSignIn = 'google-oauth2',
	EmailAndPassword = 'Username-Password-Authentication'
}

export enum ActivityOrderBy {
	PriceAsc = 'priceAsc',
	PriceDesc = 'priceDesc'
}

export enum MerchandiseOrderBy {
	PriceAsc = 'priceAsc',
	PriceDesc = 'priceDesc'
}
