import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	FontSize,
	FontWeight,
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	display: flex;
	flex-direction: column;
	background: ${Colors.V0};
	padding: 0.75rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	border-top: 1px solid #efedf0;
	border-bottom: 1px solid #efedf0;
	@media ${DeviceBreakpoints.small} {
		height: calc(100% - 144px);
		padding: 0;
	}
`;

export const InputOptionsContainer = styled.div`
	@media ${DeviceBreakpoints.small} {
		> div:first-of-type > div:last-of-type {
			order: 1;
			> div:first-of-type {
				order: 2;
			}
			> div:last-of-type {
				order: 1;
			}
		}
		> div:first-of-type > div:first-of-type {
			order: 2;
			flex-direction: row;
		}
		> div:first-of-type {
			margin-bottom: 2.5rem;
		}
		padding: 0.75rem;
	}
`;

export const InputOptionsRow = styled.div`
	display: flex;
	gap: 0.75rem;
	margin-bottom: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		flex-direction: column;
	}
`;

export const SortingContainer = styled.div`
	position: absolute;
	min-width: 14.5rem;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.15rem;
	@media ${DeviceBreakpoints.small} {
		width: 100%;
		top: 34px;
    	justify-content: space-between;
    	padding: 0.75rem 0.75rem;
    	background: linear-gradient(180deg, #fff 0%, #efedf0 100%);
		border-top: 1px solid #ccc8d1;
	}
`;

export const TransportListContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
	> ul {
		@media ${DeviceBreakpoints.small} {
			margin-bottom: 52px;
		}
	}
`;

export const SelectedItemsContainer = styled.div`
	background-color: ${Colors.V50};
	border: 1px solid ${Colors.V100};
	border-radius: 0.25rem;
	padding: 0.5rem;
`;

export const SelectedTitleContainer = styled.div`
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.semibold};
	padding-left: 0.25rem;
	margin-bottom: 0.625rem;
`;

export const SelectedTitle = styled.span``;

export const SelectedNumber = styled.span`
	margin-left: 0.25rem;
`;

export const HalfRowWidth = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 50%;
	gap: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		width: 100%;
		flex-direction: column;
		> button {
			width: 100%!important;
			padding: 0.6rem 1.5rem;
		}
	}
`;

export const ResultsContainer = styled.div`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
`;

export const ResultsTitle = styled.span``;
export const ResultsValue = styled.span`
	padding: 0.25rem;
	border: 1px solid ${Colors.V100};
	border-radius: 0.25rem;
	margin-left: 0.25rem;
	background: ${Colors.V0};
`;
