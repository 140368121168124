import { FunctionalComponent } from 'preact';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import StyledTextInput from './StyledTextInput';

type Props = {
	autoFocus?: boolean;
	placeholder?: string;
	id?: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	input?: FieldInputProps<any>;
	meta?: FieldMetaState<any>;
	name?: string;
	type?: string;
	info?: string;
	mask?: string;
	onFocus?: (event) => void;
	onBlur?: (event) => void;
};

const FieldTextInput: FunctionalComponent<Props> = ({
	input,
	meta = {},
	label = '',
	required = false,
	disabled = false,
	type = 'text',
	id = null,
	autoFocus,
	info,
	mask,
	...rest
}) => {
	return (
		<StyledTextInput
			id={id}
			type={type}
			required={required}
			disabled={disabled}
			label={label}
			error={
				(meta?.touched || meta?.submitFailed) &&
				(meta.error || meta.submitError)
			}
			inputProps={{ ...input, ...rest }}
			active={meta.active}
			autoFocus={autoFocus}
			info={info}
			mask={mask}
		/>
	);
};

export default FieldTextInput;
