import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	FontSize,
	FontWeight,
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background-color: ${Colors.V0};
	color: ${Colors.V900};
	border-top: 1px solid ${Colors.V30};
	border-bottom: 1px solid ${Colors.V30};
	height: ${BodyWrapperHeight.base};
	display: block;
	padding: 1.5rem;
	overflow-y: auto;
	position: relative;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.mobile};
		padding: 0.5rem;
	}
`;

export const HeaderTitle = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
	margin-bottom: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.lgSize};
	}
`;

export const HeaderSubTitle = styled.p`
	color: ${Colors.V500};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const WishlistContent = styled.div`
	margin-top: 2.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		margin-top: 1.25rem;
	}
`;

export const InputContainer = styled.div``;

export const SelectedCards = styled.div`
	border-radius: 0.25rem;
	background-color: ${Colors.V20};
	border: 1px solid ${Colors.V500};
	padding: 0.75rem 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;

export const FooterButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.15rem;
`;

export const TravelPlannerLink = styled.a`
	font-weight: ${FontWeight.bold};
	text-decoration: underline;
	cursor: pointer;
`;
