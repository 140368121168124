import { useState } from 'preact/hooks';

import {
	ITransportWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import { ISegment } from 'redesigntypes/Itinerary';
import { sumPrices } from 'utils/price/priceOperations';

import {
	getPassengerAdultCount,
	getPassengerYouthCount,
	getPassengersForSearch
} from '../../../TrainTransportList/TrainTransportListContainer/hooks/useTransportationOffers';
import useTrainTransportDetailsCache from '../cache/useTrainTransportDetailsCache';

export const TransportMappings = {
	J: 'Train',
	B: 'Bus'
};

const useAddTrainTransportToWishlist = () => {
	const [addedWishlistItem, setAddedWishlistItem] = useState<WishlistItem>();
	const { createWishlistItem, addItemToWishlist } = useWishlist();
	const { departureDetails, returnDetails } = useTrainTransportDetailsCache();

	const addToWishlist = (): WishlistItem => {
		const newWishlistItem = createTrainTransportWishlistItem();
		addItemToWishlist(newWishlistItem);

		setAddedWishlistItem(newWishlistItem);
		return newWishlistItem;
	};

	const createTrainTransportWishlistItem = () => {
		const optionCategories = departureDetails.offerDetails.optionCategories;

		const numberOfPeople = getPassengerAdultCount(optionCategories);
		const numberOfChildren = getPassengerYouthCount(optionCategories);
		const price = sumPrices(departureDetails.price, returnDetails?.price);

		const tripType = returnDetails ? 'roundTrip' : 'oneWayTrip';

		const wishlistItem: Partial<WishlistItem> = {
			type: WishlistItemTypes.TRANSPORT,
			item: {
				title: `${departureDetails.offerDetails.fromLocation?.label} - ${departureDetails.offerDetails.toLocation?.label}`,
				people: numberOfPeople,
				children: numberOfChildren > 0 ? numberOfChildren : null,
				price: price,
				book_transportation: formatBookTransportationOBJ(
					departureDetails.transportDetails,
					departureDetails.selectedOptions,
					returnDetails?.transportDetails,
					returnDetails?.selectedOptions,
					optionCategories
				),
				operatorNames: departureDetails.offerDetails.offer.operator_names,
				city_from: departureDetails.offerDetails.fromLocation?.label,
				city_to: departureDetails.offerDetails.toLocation?.label,
				date: departureDetails.offerDetails.offer?.departure_time,
				return_date: returnDetails?.offerDetails.offer?.departure_time,
				departure_time: departureDetails.offerDetails.offer?.departure_time,
				return_departure_time:
					returnDetails?.offerDetails.offer?.departure_time,
				passengers: getPassengersForSearch(optionCategories),
				product_owner:
					departureDetails.offerDetails.offer.transport_item.productOwner,
				transport_type: formatTransportTypeTitles(
					departureDetails.transportDetails
				),
				trip_type: tripType,
				product_location: departureDetails.transportDetails.productLocation,
				duration: departureDetails.transportDetails.duration,
				integration: departureDetails.transportDetails.integration
			} as ITransportWishlistItem
		};
		return createWishlistItem(wishlistItem);
	};

	return { addToWishlist, createTrainTransportWishlistItem, addedWishlistItem };
};

export default useAddTrainTransportToWishlist;

const formatTransportTypeTitles = transportDetails => {
	const set = new Set<string>(
		transportDetails.itineraries.flatMap(x =>
			x.segments.map(
				y =>
					TransportMappings[y.travelMethodCode.value] ??
					y.travelMethodCode.title
			)
		)
	);

	return Array.from(set).join(', ');
};

const formatSegments = (segments: ISegment[], selectedItineraryOptions) => {
	return segments.map((segment, segmentIndex) => ({
		id: segment.id,
		departureDateTime: segment.departureDateTime,
		arrivalDateTime: segment.arrivalDateTime,
		departureLocation: {
			id: segment.departureLocation?.id,
			name: segment.departureLocation?.name,
			producerId: segment.departureLocation?.producerId
		},
		arrivalLocation: {
			id: segment.arrivalLocation?.id,
			name: segment.arrivalLocation?.name,
			producerId: segment.arrivalLocation?.producerId
		},
		travelMethod: segment.travelMethodCode?.value,
		transportId: segment.transportId,
		productCode: segment.productCode?.value,
		segmentProducerCode: segment.segmentProducerCode?.value,
		bookingClass: segment.bookingClass,
		bookingRule: segment.bookingRule,
		placementOption:
			selectedItineraryOptions.segments[segmentIndex]?.characteristicValue
				?.value,
		placementOrientation:
			selectedItineraryOptions.segments[segmentIndex]?.orientationValue?.value,
		placementCompartment:
			selectedItineraryOptions.segments[segmentIndex]?.compartmentValue?.value,
		transportInformation: segment.transportInformation.map(x => x.value)
	}));
};

const formatBookTransportationOBJ = (
	departureTransportDetails,
	selectedDepartureOptions,
	returnTransportDetails,
	selectedReturnOptions,
	optionCategories
) => {
	const journeys = [
		{
			id: departureTransportDetails.id.toString(),
			hasPets: optionCategories.addons.pets.count > 0,
			itineraries: departureTransportDetails.itineraries.map(
				(item, itineraryIndex) => ({
					id: item.id,
					priceGroup: selectedDepartureOptions[itineraryIndex].priceGroupValue,
					variantNumber:
						selectedDepartureOptions[itineraryIndex].flexibilityVariantNumber,
					segments: formatSegments(
						item.segments,
						selectedDepartureOptions[itineraryIndex]
					)
				})
			)
		}
	];
	if (returnTransportDetails && selectedReturnOptions) {
		journeys.push({
			id: returnTransportDetails.id.toString(),
			hasPets: optionCategories.addons.pets.count > 0,
			itineraries: returnTransportDetails.itineraries.map(
				(item, itineraryIndex) => ({
					id: item.id,
					priceGroup: selectedReturnOptions[itineraryIndex].priceGroupValue,
					variantNumber:
						selectedReturnOptions[itineraryIndex].flexibilityVariantNumber,
					segments: formatSegments(
						item.segments,
						selectedReturnOptions[itineraryIndex]
					)
				})
			)
		});
	}
	return {
		passengers: getPassengersForSearch(optionCategories),
		journeys
	};
};
