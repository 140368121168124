import { useMemo } from 'preact/hooks';

import useCodebook from 'redesigncache/codebook/useCodebook';
import { IGender } from 'redesigntypes/Gender';
import capitalizeWords from 'utils/string/capitalizeWords';

const useGenderCodebookOptions = () => {
	const { genders } = useCodebook(['genders']);

	const genderOptions = useMemo(() => {
		return genders.map((g: IGender) => ({
			value: g.value,
			label: capitalizeWords(g.value)
		}));
	}, [genders]);

	return { genderOptions };
};

export default useGenderCodebookOptions;
