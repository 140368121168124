import { useReactiveVar } from '@apollo/client';

import { ActivityModel, Image, Session } from 'redesigntypes/ActivityModel';

import { activityDetailsVar, initialActivityDetailsValue } from '.';

const useActivityDetailsCache = () => {
	const {
		activity,
		heroImage,
		geographicaLocation,
		lowestSessionTicketPrice,
		availableDates,
		sessionsByDate,
		selectedDate,
		selectedSession,
		selectedTickets
	} = useReactiveVar(activityDetailsVar);

	const setActivityDetails = (
		activity: ActivityModel,
		heroImage: Image,
		geographicaLocation,
		lowestSessionTicketPrice = undefined,
		availableDates = undefined,
		sessionsByDate = undefined
	) => {
		activityDetailsVar({
			...initialActivityDetailsValue,
			activity,
			heroImage,
			geographicaLocation,
			lowestSessionTicketPrice,
			availableDates,
			sessionsByDate
		});
	};

	const setActivityAvailabilityDetails = (
		lowestSessionTicketPrice,
		availableDates,
		sessionsByDate
	) => {
		activityDetailsVar({
			...activityDetailsVar(),
			lowestSessionTicketPrice,
			availableDates,
			sessionsByDate
		});
	};

	const setSelectedDate = (selectedDate: Date) => {
		activityDetailsVar({
			...activityDetailsVar(),
			selectedDate
		});
	};

	const setSelectedSession = (selectedSession: Session) => {
		activityDetailsVar({
			...activityDetailsVar(),
			selectedSession
		});
	};

	const setSelectedTickets = (selectedTickets: {
		[priceOptionKey: string]: number;
	}) => {
		activityDetailsVar({
			...activityDetailsVar(),
			selectedTickets
		});
	};

	const clearActivityDetailsCache = () => {
		activityDetailsVar({
			...initialActivityDetailsValue
		});
	};

	return {
		activity,
		heroImage,
		geographicaLocation,
		lowestSessionTicketPrice,
		availableDates,
		sessionsByDate,
		selectedDate,
		selectedSession,
		selectedTickets,
		setActivityDetails,
		setActivityAvailabilityDetails,
		setSelectedDate,
		setSelectedSession,
		setSelectedTickets,
		clearActivityDetailsCache
	};
};

export default useActivityDetailsCache;
