import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import { trainTransportSearchOptionsVar } from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer/cache';
import { Option } from 'redesigntypes/Option';

import { TrainTransportDetailsStep } from '../../cache';
import useTrainTransportDetailsCache from '../../cache/useTrainTransportDetailsCache';

const useTransportDetailsWizard = () => {
	const {
		tripType,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	} = useReactiveVar(trainTransportSearchOptionsVar);

	const { currentStep, setCurrentStep } = useTrainTransportDetailsCache();

	const steps: Array<Option> = useMemo(() => {
		const steps = [];
		if (selectedDepartureTransportOfferDetails) {
			steps.push({
				value: TrainTransportDetailsStep.Departure,
				label: 'Departure ticket'
			});
		}
		if (selectedReturnTransportOfferDetails) {
			steps.push({
				value: TrainTransportDetailsStep.Return,
				label: 'Return ticket'
			});
		}

		steps.push({ value: TrainTransportDetailsStep.Summary, label: 'Summary' });

		return steps;
	}, [
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails,
		tripType
	]);

	const goToNextStep = () => {
		const currentStepIndex = steps.findIndex(
			step => step.value === currentStep
		);
		if (currentStepIndex < steps.length - 1) {
			setCurrentStep(
				steps[currentStepIndex + 1].value as TrainTransportDetailsStep
			);
		}
	};

	const goToFirstStep = () => {
		setCurrentStep(steps[0].value as TrainTransportDetailsStep);
	};

	const currentStepIndex = useMemo(
		() => steps.findIndex(step => step.value === currentStep),
		[steps, currentStep]
	);

	const goToStep = (chosenStep: TrainTransportDetailsStep) => {
		const chosenStepIndex = steps.findIndex(step => step.value === chosenStep);

		if (chosenStepIndex <= currentStepIndex) {
			setCurrentStep(chosenStep);
		}
	};
	return {
		goToNextStep,
		goToFirstStep,
		goToStep,
		currentStepIndex,
		currentStep,
		steps
	};
};

export default useTransportDetailsWizard;
