import { useReactiveVar } from '@apollo/client';

import { AccommodationModel } from 'redesigntypes/AccommodationModel';
import { AccommodationRoom } from 'redesigntypes/AccommodationRoom';
import { Option } from 'redesigntypes/Option';

import {
	accommodationDetailsCacheVar,
	initialAccommodationDetailsValue
} from '.';

const useAccommodationDetailsCache = () => {
	const {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		selectedRoomOption,
		headerImage,
		galleryImages,
		geographicaLocation,
		accommodation,
		availableRoomsById
	} = useReactiveVar(accommodationDetailsCacheVar);

	const setNumberOfRooms = (numberOfRooms: number) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			numberOfRooms
		});
	};

	const setNumberOfAdults = (numberOfAdults: number) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			numberOfAdults
		});
	};

	const setNumberOfChildren = (numberOfChildren: number) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			numberOfChildren
		});
	};

	const setChildrenAges = (childrenAges: number[]) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			childrenAges
		});
	};

	const setTravelPeriod = (travelPeriod: [Date, Date]) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			travelPeriod
		});
	};

	const setSelectedRoomOption = (selectedRoomOption: Option) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			selectedRoomOption
		});
	};

	const setAvailableRoomsById = (availableRoomsById: AccommodationRoom) => {
		accommodationDetailsCacheVar({
			...accommodationDetailsCacheVar(),
			availableRoomsById
		});
	};

	const setAccommodationDetails = (
		accommodation: AccommodationModel,
		headerImage,
		galleryImages,
		geographicaLocation
	) => {
		accommodationDetailsCacheVar({
			...initialAccommodationDetailsValue,
			numberOfRooms,
			numberOfAdults,
			numberOfChildren,
			childrenAges,
			travelPeriod,
			selectedRoomOption,
			headerImage,
			galleryImages,
			geographicaLocation,
			accommodation
		});
	};

	const clearAccommodationDetailsCache = () => {
		accommodationDetailsCacheVar({
			...initialAccommodationDetailsValue
		});
	};

	return {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		selectedRoomOption,
		headerImage,
		galleryImages,
		geographicaLocation,
		accommodation,
		availableRoomsById,
		setNumberOfRooms,
		setNumberOfAdults,
		setNumberOfChildren,
		setChildrenAges,
		setTravelPeriod,
		setSelectedRoomOption,
		setAccommodationDetails,
		setAvailableRoomsById,
		clearAccommodationDetailsCache
	};
};

export default useAccommodationDetailsCache;
