import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import useToggle from 'hooks/useToggle';
import { ITravelPlan } from 'redesigncache/checkout/types';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import GetGestFooter from 'redesigncomponents/Footer/GetGestFooter';
import LoadingModal from 'redesigncomponents/LoadingModal';
import AffiliateCheckoutWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateCheckoutWrapper';
import { isPaymentProcessingVar } from 'redesignscreens/Checkout/cache';
import TravelPlan from 'redesignscreens/Checkout/components/TravelPlan';
import GetGestPaymentConfirmationModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestPaymentConfirmationModal';
import useAutofill from 'redesignscreens/Checkout/useAutofill';

interface IProps {}

const Checkout: FunctionalComponent<IProps> = () => {
	const {
		checkout: { travelPlans },
		allItemsFullyPaid,
		merchandiseOnlyCheckout
	} = useCheckout();
	const isLoadingModalOpen = useReactiveVar(isPaymentProcessingVar);

	const { AutofillContainer } = useAutofill();

	const [paymentConfirmationDialogOpen, togglePaymentConfirmationDialogOpen] =
		useToggle();

	useEffect(() => {
		if (allItemsFullyPaid) {
			togglePaymentConfirmationDialogOpen();
		}
	}, [allItemsFullyPaid]);

	return (
		<AffiliateCheckoutWrapper>
			<BodyWrapper block>
				{travelPlans.map((travelPlan: ITravelPlan) => (
					<TravelPlan travelPlan={travelPlan} hideHeader />
				))}
				{paymentConfirmationDialogOpen && (
					<GetGestPaymentConfirmationModal
						affiliateLink
						showExploreMoreTravel={!merchandiseOnlyCheckout}
						open={paymentConfirmationDialogOpen}
					/>
				)}
			</BodyWrapper>
			<AutofillContainer />
			<GetGestFooter />
			<LoadingModal isOpen={isLoadingModalOpen} />
		</AffiliateCheckoutWrapper>
	);
};

export default Checkout;
