import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { CHANGE_EMAIL } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Toast from 'redesigncomponents/Toast';
import { Paths } from 'router';
import { useChangeEmailConstraints } from 'shared/constraints';
import { UserConnectionTypes } from 'types/enums';
import validateForm from 'utils/form/validateForm';

import CloseIcon from 'assets/close.svg';
import EditIcon from 'assets/edit.svg';
import SaveIcon from 'assets/save.svg';

import FormSectionHeader from '../../FormSectionHeader';
import { FormWrapper, TwoInputWrapper } from '../../StyledComponents';

const RESET_FORM_TIMEOUT = 100;

interface IProps {
	mobileLayout?: boolean;
}

const EmailForm: FunctionalComponent<IProps> = (mobileLayout) => {
	const history = useHistory();
	const { t } = useIntl('account.accountDetails');
	const { user, logout } = useUserAuth();
	const logOut = () => {
		logout();
		history.replace({
			pathname: Paths.SignIn
		});
	};
	const [isEmailEdit, setIsEmailEdit] = useState<boolean>(false);
	const { constraints: changeEmailConstraints } = useChangeEmailConstraints();

	const emailInitialValue = {
		email: user?.email,
		oldEmail: user?.email
	};

	const [changeEmail, { loading: isEmailChangeLoading }] =
		useMyPagesMutation(CHANGE_EMAIL);

	const onChangeEmailFormSubmit = async (values, form) => {
		const response = await changeEmail({
			variables: values
		})
			.then(() => {
				setTimeout(() => {
					setIsEmailEdit(false);
					form.reset();
				}, RESET_FORM_TIMEOUT);
				Toast.success(t('successfulEmailEdit'), { autoClose: false });
				logOut();
			})
			.catch(error => ({ message: error.message }));

		if (response && response.message) {
			return { oldEmail: response.message };
		}
	};

	const renderEmailChangeForm = ({
		handleSubmit,
		pristine,
		submitting,
		hasValidationErrors,
		form
	}) => (
		<form onSubmit={handleSubmit}>
			<FormWrapper>
				<FormSectionHeader title={t('email')}>
					{isEmailEdit ? (
						<>
							<Button
								title={t('cancel')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={CloseIcon}
								small
								tightPadding
								onClick={() => {
									form.reset();
									setIsEmailEdit(false);
								}}
							/>
							<Button
								title={t('saveChanges')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={SaveIcon}
								small
								tightPadding
								disabled={
									pristine ||
									submitting ||
									hasValidationErrors ||
									isEmailChangeLoading
								}
								loading={isEmailChangeLoading}
								onClick={handleSubmit}
							/>
						</>
					) : (
						user?.connection === UserConnectionTypes.EmailAndPassword && (
							<Button
								title={t('edit')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={EditIcon}
								small
								tightPadding
								onClick={() => {
									setIsEmailEdit(true);
								}}
							/>
						)
					)}
				</FormSectionHeader>
				{isEmailEdit ? (
					<>
						<TwoInputWrapper mobileLayout>
							<Field
								label={t('insertOldEmail')}
								name="oldEmail"
								component={FieldTextInput}
								disabled={true}
								required
								smallPadding
							/>
							<Field
								label={t('insertNewEmail')}
								name="newEmail"
								component={FieldTextInput}
								disabled={!isEmailEdit}
								required
								smallPadding
							/>
						</TwoInputWrapper>
						<TwoInputWrapper mobileLayout>
							<Field
								type="password"
								label={t('password')}
								name="password"
								id="password"
								component={FieldTextInput}
								disabled={!isEmailEdit}
								required
								smallPadding
							/>
						</TwoInputWrapper>
					</>
				) : (
					<TwoInputWrapper mobileLayout>
						<Field
							label={t('email')}
							name="email"
							id="email"
							component={FieldTextInput}
							disabled={!isEmailEdit}
							required
							smallPadding
						/>
					</TwoInputWrapper>
				)}
			</FormWrapper>
		</form>
	);

	return (
		<Form
			onSubmit={(values, form) => onChangeEmailFormSubmit(values, form)}
			initialValues={emailInitialValue}
			validate={values => validateForm(values, changeEmailConstraints)}
			render={renderEmailChangeForm}
		/>
	);
};

export default EmailForm;
