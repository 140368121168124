import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';

import { wishlistVar } from './cache';
import { Traveler, TravelerTypes, Wishlist, WishlistItem } from './types';

export enum WishlistActionTypes {
	Add,
	Remove,

	TermsAndPrivacyChecked,
	TermsAndPrivacyUnchecked,
	CleanCart,
	AddTraveler,
	UpdateTravelers,
	UpdateLeadTraveler
}

export default function useWishlist() {
	const wishlist = useReactiveVar<Wishlist>(wishlistVar);

	const clearWishlist = () => {
		wishlistVar({
			wishlistItems: [],
			travelItineraryId: null
		});
	};

	const removeItemFromWishlist = (
		wishlistItems: WishlistItem[],
		id: string
	): WishlistItem[] => {
		const items = [...wishlistItems];
		const wishlistItemIndex = items.findIndex(i => i.id === id);
		items.splice(wishlistItemIndex, 1);
		return items;
	};

	const removeWishlistItem = (id: string) => {
		const wishlistItems = removeItemFromWishlist(wishlist.wishlistItems, id);
		wishlistVar({ ...wishlist, wishlistItems });
	};

	const removeWishlistItems = (ids: string[]) => {
		let wishlistItems = wishlist.wishlistItems;
		for (const id of ids) {
			wishlistItems = removeItemFromWishlist(wishlistItems, id);
		}
		wishlistVar({ ...wishlist, wishlistItems });
	};

	const setTravelItineraryId = (travelItineraryId: string) => {
		const updated = {
			...wishlist,
			travelItineraryId
		};
		wishlistVar(updated);
	};

	const addItemToWishlist = (item: WishlistItem) => {
		const wishlistItems = cloneDeep(wishlist.wishlistItems);
		wishlistItems.push(item);
		const updated = {
			...wishlist,
			wishlistItems
		};

		wishlistVar(updated);
	};

	const createWishlistItem = payload => {
		return {
			...payload,
			id: uuidv4(),
			travelerList: [
				{
					firstName: '',
					lastName: '',
					dateOfBirth: null,
					nationality: '',
					country: '',
					gender: '',
					type: TravelerTypes.Lead
				}
			]
		};
	};
	const wishlistItemsCount = useMemo(() => {
		return wishlist.wishlistItems.length;
	}, [wishlist]);

	return {
		wishlistItemsCount,
		wishlist: wishlist,
		clearWishlist,
		createWishlistItem,
		addItemToWishlist,
		removeWishlistItem,
		removeWishlistItems,
		setTravelItineraryId
	};
}
