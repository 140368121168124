import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background-color: ${Colors.V0};
	color: ${Colors.V900};
	border-top: 1px solid ${Colors.V30};
	border-bottom: 1px solid ${Colors.V30};
	height: ${BodyWrapperHeight.base};
	display: block;
	padding: 1.25rem;
	overflow-y: auto;
	position: relative;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.getgestmobile};
		padding: 0.5rem;
	}
`;

export const AutofillDialogWrapper = styled.div`
	position: relative;
	bottom: 0.7rem;
	left: 2rem;
`;

export const AutofillDialogPortal = styled.div`
	position: absolute;
	bottom: 0;
`;
