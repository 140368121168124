import styled from 'styled-components';
import { FontWeight, FontSize, DeviceBreakpoints } from 'styles/defaultTheme';

export const ContentWrapper = styled.div`
	max-width: 435px;
	width: 100%;
	margin: auto;
	> *,
	input {
		width: 100%;
	}
	> div {
		margin-bottom: 15px;
	}
`;

export const FormHeader = styled.h4`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.xlSize};
	text-align: center;
	margin-bottom: 3.375rem;
	@media ${DeviceBreakpoints.small} {
		margin-bottom: 2.5rem;
		font-size: 1.375rem;
	}
`;

export const SignupContent = styled.div`
	text-align: center;
	margin-top: 25px;
`;

export const SignupText = styled.span`
	margin-right: 5px;
`;

export const SignupLink = styled.a`
	font-weight: ${FontWeight.bold};
	text-decoration: underline;
	cursor: pointer;
`;
