import { useEffect, useMemo } from 'preact/hooks';

import { PAGE_DEFAULT } from 'config/network';
import useAirplaneTransportSortCodebookOptions from 'hooks/useAirplaneTransportSortOptions';
import Loading from 'redesigncomponents/Loading';
import SortBy from 'redesigncomponents/SortBy';
import TransportTab from 'redesigncomponents/TransportTabs/components/SingleTab';
import TabContent from 'redesigncomponents/TransportTabs/components/TabContent';
import TabsContainer from 'redesigncomponents/TransportTabs/components/TabsContainer';
import { Option } from 'redesigntypes/Option';
import { InfiniteScrollWithRef } from 'shared/components';
import { TransportTripType } from 'types/enums';

import useTransportSearchOptionsCache from '../cache/useTransportSearchOptionsCache';
import generateTravelPeriodString from '../hooks/generateTravelPeriodString';
import useItemCanBeAddedToWishlist from '../hooks/useItemCanBeAddedToWishlist';
import DefaultStateTransportList from './DefaultStateTransportList';
import NoResultsTransportList from './NoResultsTransportList';
import SelectedItems from './SelectedItems';
import {
	ResultsContainer,
	ResultsTitle,
	ResultsValue,
	SortingContainer,
	TransportListContainer
} from './StyledComponents';
import TransportOfferItem from './TransportOfferItem';

const AirplaneOfferResultList = ({
	transportOffer,
	page,
	hasMore,
	totalCount,
	selectTransportOffer,
	selectedTransportOfferDetails,
	loadTransportationOfferPage,
	loadingTransportOffer,
	calledLoadTransportOffer
}) => {
	const { airplaneTransportSortOptions } =
		useAirplaneTransportSortCodebookOptions();
	const { tripType, transportSort, travelPeriod, setTransportSort } =
		useTransportSearchOptionsCache();
	const { itemCanBeAddedToWishlist } = useItemCanBeAddedToWishlist(
		selectedTransportOfferDetails
	);

	const filteredTransportOffer = useMemo(
		() =>
			transportOffer?.filter(to =>
				itemCanBeAddedToWishlist(to.transport_item.bookingToken)
			),
		[transportOffer, itemCanBeAddedToWishlist]
	);

	const loadNextTransportationOfferPage = () => {
		loadTransportationOfferPage(page + 1, transportSort);
	};

	const updateSortOption = (sort: Option) => {
		setTransportSort(sort);
		loadTransportationOfferPage(PAGE_DEFAULT, sort);
	};

	useEffect(() => {
		// initial sort
		if (airplaneTransportSortOptions?.length && !transportSort) {
			setTransportSort(airplaneTransportSortOptions[0]);
		}
	}, [airplaneTransportSortOptions]);

	return (
		<TransportListContainer>
			{!calledLoadTransportOffer ? (
				<DefaultStateTransportList />
			) : (
				<>
					<TabsContainer>
						<TransportTab
							active
							tabTitle={tripType?.label}
							dateTitle={generateTravelPeriodString(travelPeriod)}
						></TransportTab>
					</TabsContainer>
					{selectedTransportOfferDetails && (
						<SelectedItems selectedValue={1}>
							<TransportOfferItem
								tripType={tripType.value as TransportTripType}
								offer={selectedTransportOfferDetails?.offer}
								clearSelectedTransportOffer={() =>
									selectTransportOffer(undefined)
								}
							></TransportOfferItem>
						</SelectedItems>
					)}
					{loadingTransportOffer && typeof transportOffer === 'undefined' ? (
						<Loading />
					) : (
						<>
							{transportOffer.length ? (
								<InfiniteScrollWithRef
									height={450}
									dataLength={transportOffer.length}
									next={loadNextTransportationOfferPage}
									hasMore={hasMore}
									loader={<div />}
								>
									<TabContent>
										{filteredTransportOffer.map(offer => {
											return (
												<TransportOfferItem
													key={offer.timetable_id}
													tripType={tripType.value as TransportTripType}
													offer={offer}
													selectTransportOffer={() =>
														selectTransportOffer(offer)
													}
												/>
											);
										})}
									</TabContent>
								</InfiniteScrollWithRef>
							) : (
								<NoResultsTransportList />
							)}
							<SortingContainer>
								<ResultsContainer>
									<ResultsTitle>Results</ResultsTitle>
									<ResultsValue>{totalCount}</ResultsValue>
								</ResultsContainer>
								<SortBy
									value={transportSort}
									onSortChange={updateSortOption}
									options={airplaneTransportSortOptions}
								></SortBy>
							</SortingContainer>
						</>
					)}
				</>
			)}
		</TransportListContainer>
	);
};

export default AirplaneOfferResultList;
