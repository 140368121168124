import useIntl from 'hooks/useIntl';

export default function () {
	const { t: messages } = useIntl('common.validationMessages');

	const constraints = {
		firstName: {
			presence: {
				message: messages('firstNameRequired')
			}
		},
		lastName: {
			presence: {
				message: messages('lastNameRequired')
			}
		},
		email: {
			presence: {
				message: messages('emailRequired')
			},
			email: {
				message: messages('emailInvalid')
			}
		},
		dateOfBirth: {
			presence: {
				message: messages('dateOfBirthRequired')
			}
		},
		country: {
			presence: {
				message: messages('countryRequired')
			}
		},
		gender: {
			presence: {
				message: messages('genderRequired')
			}
		}
	};

	return { constraints };
}
