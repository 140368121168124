import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Modal, ModalBody, ModalHeader } from 'redesigncomponents/Modal';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	min-width: 100%;
	padding: 1rem;
`;

const Text = styled.span`
	color: #342245;
	text-align: justify;
`;

type Props = {
	onClose: () => void;
	isOpen: boolean;
	title: string;
	content: string;
};

const TermsAndConditionsPreview: FunctionalComponent<Props> = ({
	isOpen,
	onClose,
	title,
	content
}) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} animation="slideUp" big>
			<ModalBody>
				<ModalHeader>{title}</ModalHeader>
				<ContentWrapper>
					<Text>{content}</Text>
				</ContentWrapper>
			</ModalBody>
		</Modal>
	);
};

export default TermsAndConditionsPreview;
