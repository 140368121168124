import styled from 'styled-components';
import { Colors, FontSize, DeviceBreakpoints } from 'styles/defaultTheme';


export const OfferWrapper = styled.div`
	cursor: pointer;
	${props =>
		props.image &&
		`
    background-image: url(${props.image});
  `}
  height: 18.125rem;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.V10};
  background-size: cover;
  position: relative;
  color: ${Colors.V900};
	@media ${DeviceBreakpoints.small} {
    	height: 7.5rem;
	}
`;

export const TitleWrapper = styled.div`
    position: absolute;
    border-radius: 0 0.75rem 0.75rem 0;
    background: linear-gradient(114.07deg, #FFFFFF 4.73%, rgba(255, 255, 255, 0.5) 100%);
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
    bottom: 0.5rem;
    border: 1px solid #ececec;
    border-left: none;
    @media ${DeviceBreakpoints.small} {
      > p:nth-child(2) {
          display: none;
      }
	  }
`;

export const Title = styled.h6`
    font-size: ${FontSize.base};
`;

export const SubTitle = styled.p`
    font-size: ${FontSize.xsSize};
`;

















