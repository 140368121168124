import styled from 'styled-components';

import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';

export const LoadingWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

const Loading = () => {
	return (
		<LoadingWrapper>
			<ThreeDotLoader style={{ zIndex: 101 }} size={80} color="#342245" />
		</LoadingWrapper>
	);
};

export default Loading;
