import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontSize } from 'styles/defaultTheme';

import InfoIcon from 'assets/info.svg';

export const TravelPlanItemNotificationBox = styled.div`
	display: flex;

	background: ${({ infoLevel }) => Colors[infoLevel]};
	padding: 0.3rem 0.5rem 0.3rem 0rem;
	border-radius: 0.25rem;
	flex-direction: row;
	align-items: center;
	color: ${Colors.V0};
	font-size: ${FontSize.xsSize};
	margin: 0.2rem 0rem;
`;

const IconWrapper = styled.div`
	width: 1.1rem;
	height: 1.1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.5rem;
	> svg path {
		fill: #fff;
	}
`;

type InfoLevel = 'info' | 'warning' | 'error' | 'success';
type Props = {
	message: string;
	infoLevel?: InfoLevel;
};

const NotificationBox: FunctionalComponent<Props> = ({
	message,
	infoLevel = 'info'
}) => {
	return (
		<TravelPlanItemNotificationBox infoLevel={infoLevel}>
			<IconWrapper>
				<InfoIcon />
			</IconWrapper>
			{message}
		</TravelPlanItemNotificationBox>
	);
};

export default NotificationBox;
