import { useMemo } from 'preact/hooks';

import { Option } from 'redesigntypes/Option';
import { AirplaneClass } from 'types/enums';

import useIntl from './useIntl';

const useAirplaneClassOptions = () => {
	const { t } = useIntl('common.airplaneClasses');

	const airplaneClassOptions = useMemo(() => {
		return Object.entries(AirplaneClass).map(
			([key, value]) =>
				({
					value: value,
					label: t(key)
				} as Option)
		);
	}, []);

	return { airplaneClassOptions };
};

export default useAirplaneClassOptions;
