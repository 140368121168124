import { ComponentChildren, FunctionalComponent, toChildArray } from 'preact';
import { useMemo } from 'preact/hooks';

import useIntl from 'hooks/useIntl';

import {
	SelectedItemsContainer,
	SelectedNumber,
	SelectedTitle,
	SelectedTitleContainer
} from './StyledComponents';

interface IProps {
	children: ComponentChildren;
	selectedValue?: number;
}

const SelectedItems: FunctionalComponent<IProps> = ({ children }) => {
	const { t } = useIntl('transportList.results');

	const childrenCount = useMemo(() => {
		return toChildArray(children).length;
	}, [children]);

	return (
		<SelectedItemsContainer>
			<SelectedTitleContainer>
				<SelectedTitle>{t('selectedSectionTitle')}</SelectedTitle>
				<SelectedNumber>({childrenCount})</SelectedNumber>
			</SelectedTitleContainer>
			{children}
		</SelectedItemsContainer>
	);
};

export default SelectedItems;
