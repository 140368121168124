import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import { TransportTripType } from 'types/enums';
import { formatListsPrice } from 'utils/price/priceFormatting';
import dateDiffInDMH from 'utils/time/dateDiffInDMH';

const useTransportOfferItem = (offerItem, tripType: TransportTripType) => {
	const headerDetails = useMemo(() => {
		if (offerItem) {
			const itineraries = offerItem.transport_item.itineraries;
			const firstItinerary = itineraries[0];
			const lastItinerary = itineraries[itineraries.length - 1];
			const outgoingItineraries = itineraries.slice(
				0,
				itineraries.findIndex(
					i => i.arrivalLocation.airportName === offerItem.arrival_station_code
				) + 1
			);
			return {
				fromDate: dayjs.utc(firstItinerary.departureTime).format('DD.MM'),
				toDate:
					tripType === TransportTripType.RoundTrip
						? dayjs.utc(lastItinerary.arrivalTime).format('DD.MM')
						: undefined,
				startingAirline: firstItinerary.airline,
				numberOfStops:
					outgoingItineraries.length === 1
						? 'Direct'
						: `${outgoingItineraries.length} stops`,
				price: `${formatListsPrice(offerItem.price.amount)} ${
					offerItem.price.currency
				}`
			};
		}
	}, [offerItem]);

	const expandedDetails = useMemo(() => {
		if (offerItem) {
			const itineraries = offerItem.transport_item.itineraries;

			const lastOutgoingItineraryIndex =
				itineraries.findIndex(
					i => i.arrivalLocation.airportName === offerItem.arrival_station_code
				) + 1;

			// outgoing itineraries
			const outgoingItineraries = itineraries.slice(
				0,
				lastOutgoingItineraryIndex
			);
			const lastOutgoingItinerary =
				outgoingItineraries[outgoingItineraries.length - 1];

			// returning itineraries
			const returningItineraries = itineraries.slice(
				lastOutgoingItineraryIndex
			);
			const firstReturningItinerary = returningItineraries[0];
			const lastReturningItinerary =
				returningItineraries[returningItineraries.length - 1];

			return {
				stayLengthNights:
					tripType === TransportTripType.RoundTrip
						? dayjs(firstReturningItinerary.departureTime).diff(
								dayjs(lastOutgoingItinerary.arrivalTime),
								'd'
						  )
						: undefined,
				outgoing: {
					outgoingTimeString: dayjs
						.utc(outgoingItineraries[0].departureTime)
						.format('ddd, DD MMM'),
					outgoingTime: outgoingItineraries[0].departureTime,
					arrivalTime: lastOutgoingItinerary.arrivalTime,
					outgoingAirport: outgoingItineraries[0].departureLocation.airportName,
					arrivalAirport: lastOutgoingItinerary.arrivalLocation.airportName,
					itineraries: outgoingItineraries.map(it => ({
						outgoingTime: dayjs.utc(it.departureTime).format('HH:mm'),
						outgoingAirport: it.departureLocation.airportName,
						flight: `${it.airline} ${it.flightNumber}`,
						flightDuration: dateDiffInDMH(
							it.departureTimeUTC,
							it.arrivalTimeUTC
						)
					}))
				},
				returning:
					tripType === TransportTripType.RoundTrip
						? {
								outgoingTimeString: dayjs(
									firstReturningItinerary.departureTime
								).format('ddd, DD MMM'),
								outgoingTime: firstReturningItinerary.departureTime,
								arrivalTime: lastReturningItinerary.arrivalTime,
								outgoingAirport:
									firstReturningItinerary.departureLocation.airportName,
								arrivalAirport:
									returningItineraries[returningItineraries.length - 1]
										.arrivalLocation.airportName,
								itineraries: returningItineraries.map(it => ({
									outgoingTime: dayjs.utc(it.departureTime).format('HH:mm'),
									outgoingAirport: it.departureLocation.airportName,
									flight: `${it.airline} ${it.flightNumber}`,
									flightDuration: dateDiffInDMH(
										it.departureTimeUTC,
										it.arrivalTimeUTC
									)
								}))
						  }
						: undefined
			};
		}
	}, [offerItem]);

	return { headerDetails, expandedDetails };
};

export default useTransportOfferItem;
