import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useTrainTransportOfferItem from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer/components/TrainTransportOfferItem/useTrainTransportOfferItem';
import { OfferDetails } from 'redesignscreens/Main/Transport/TrainTransportList/types';

const useTrainTransportItemPreview = (offerDetails: OfferDetails) => {
	const { headerDetails, expandedDetails } = useTrainTransportOfferItem(
		offerDetails.offer
	);

	const previewDetails = useMemo(() => {
		return {
			fromLocation: offerDetails.fromLocation.label,
			toLocation: offerDetails.toLocation.label,
			offerTravelPeriodSummaryString: `${dayjs(headerDetails.fromDate).format(
				'dddd DD.MM.YYYY'
			)} ${dayjs(headerDetails.fromDate).format('HH:mm')} - ${dayjs(
				headerDetails.toDate
			).format('HH:mm')}`,
			outgoingItineraries: expandedDetails.outgoingItineraries
		};
	}, [expandedDetails]);

	return { previewDetails };
};

export default useTrainTransportItemPreview;
