import { useQuery } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import { myPagesClient } from 'apollo/client';
import { TRAVEL_ITINERARY_BY_ITEM_ID } from 'apollo/myPages/queries';
import useUserAuth from 'redesigncache/auth/useUserAuth';

import { IComposedTravelPlanItem } from '../../TravelPlan/useTravelPlan';

const useCheckPaidStatus = (travelPlanItem: IComposedTravelPlanItem) => {
	const { isLoggedIn } = useUserAuth();

	const { data: { travel_itinerary = [] } = {} } = useQuery(
		TRAVEL_ITINERARY_BY_ITEM_ID,
		{
			client: myPagesClient,
			fetchPolicy: 'no-cache',
			variables: { id: travelPlanItem.id },
			skip: !isLoggedIn
		}
	);

	const isTravelPlanItemPaid = useMemo(
		() => travel_itinerary.length > 0 || travelPlanItem.paid,
		[travel_itinerary, travelPlanItem.paid]
	);

	return { isTravelPlanItemPaid };
};

export default useCheckPaidStatus;
