import { FunctionalComponent } from 'preact';
import { Text } from 'preact-i18n';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import {
	Colors,
	DeviceBreakpoints,
	FontSize,
	FontWeight
} from 'styles/defaultTheme';

export const CardWrapper = styled.div`
	cursor: pointer;
	background-size: cover;
	${props =>
		props.image &&
		`
    background-image: url(${props.image});
  `}
	@media ${DeviceBreakpoints.small} {
		min-height: 10.5rem;
	}
`;

export const TitleBar = styled.span`
	font-size: ${FontSize.base};
	font-weight: ${FontWeight.bold};
	margin-right: 0.5rem;
	color: ${Colors.V900};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const OptionsTitle = styled.span`
	height: 37px;
	font-size: ${FontSize.smSize};
	color: ${Colors.V400};
	@media ${DeviceBreakpoints.small} {
		font-weight: ${FontWeight.semibold};
		text-transform: lowercase;
		font-size: ${FontSize.xsSize};
	}
`;

export const Count = styled(OptionsTitle)`
	display: flex;
	align-items: center;
`;

export const TitleWrapper = styled.div`
	background: ${Colors.V0};
	padding: 0.5rem;
	display: flex;
	align-items: center;
	position: absolute;
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	bottom: 6px;
	border-radius: 0px 20px 20px 0px;
	height: 46px;
`;

const MAX_COUNT = 1000;

export type IProps = {
	count?: number;
	label?: string;
	image?: any;
	isLoading?: boolean;
	showOfferValue?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const OfferCard: FunctionalComponent<IProps> = ({
	count = 0,
	label,
	image,
	isLoading = true,
	showOfferValue,
	onClick
}) => {
	const { t } = useIntl('main');
	return (
		<CardWrapper image={image} onClick={onClick}>
			<TitleWrapper>
				<TitleBar>{label}</TitleBar>
				<OptionsTitle>
					{showOfferValue ? (
						isLoading ? (
							<ThreeDotLoader color="#342245" size={40} />
						) : (
							<Count>
								{count >= MAX_COUNT ? (
									`${t('optionsLargeCount')}`
								) : (
									<Text
										id="main.options"
										plural={count}
										fields={{ count: count }}
									/>
								)}
							</Count>
						)
					) : null}
				</OptionsTitle>
			</TitleWrapper>
		</CardWrapper>
	);
};

export default OfferCard;
