export const colorsSpec = {
	primary: 'primary',
	primaryDark: 'primaryDark',
	primaryLight: 'primaryLight',

	secondary: 'secondary',
	secondaryDark: 'secondaryDark',
	secondaryLight: 'secondaryLight',

	disabled: 'disabled',

	accent: 'accent',
	accentDark: 'accentDark',
	accentLight: 'accentLight',

	black: 'black',
	white: 'white',

	info: 'info',
	warning: 'warning',
	danger: 'danger',
	success: 'success',

	backgroundPrimary: 'backgroundPrimary'
};

export const colors = {
	[colorsSpec.primary]: '#372046',
	[colorsSpec.primaryDark]: '#000',
	[colorsSpec.primaryLight]: '#03C6FB',

	[colorsSpec.secondary]: '#FECB00',
	[colorsSpec.secondaryDark]: '#C19A00',
	[colorsSpec.secondaryLight]: '#E2B400',

	[colorsSpec.accent]: '#C4C4C4',
	[colorsSpec.accentDark]: '#F96842',
	[colorsSpec.accentLight]: '#00BA19',

	[colorsSpec.disabled]: '#ccc',

	[colorsSpec.black]: '#000',
	[colorsSpec.white]: '#FFF',

	[colorsSpec.info]: '#2472AB',
	[colorsSpec.warning]: '#AB7D24',
	[colorsSpec.danger]: '#B3261E',
	[colorsSpec.success]: '#528B18',

	[colorsSpec.backgroundPrimary]: '#FFF'
};

export const fonts = {
	default: '"Open Sans", arial, sans-serif',
	monospace: '"Source Code Pro", monospace'
};

export const shadows = {
	short: '0 2px 4px rgba(0, 0, 0, 0.14)',
	long: `
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0 ,0, 0, 0.2)
      `
};

export const transparency = {
	white70: 'rgba(255, 255, 255, 0.7)',
	white80: 'rgba(255, 255, 255, 0.8)',
	black50: 'rgba(0, 0, 0, 0.5)',
	black80: 'rgba(0, 0, 0, 0.80)'
};

export const borderRadius = {
	small: '2.5px',
	medium: '3px',
	large: '4px',
	extraLarge: '5px',
	round: '50%'
};

export const spacing = {
	none: 0,
	extraSmall: '1px',
	small1: '5px',
	small2: '7px',
	small3: '8px',
	small4: '9px',
	medium1: '10px',
	medium2: '11px',
	medium3: '12px',
	large1: '14px',
	large2: '15px',
	large3: '16px',
	large4: '18px',
	extraLarge: '29px'
};

export const thicknesses = {
	normal: '1px',
	default: '1px',
	wide: '2px',
	thick: '2px',
	large: '2px',
	extraWide: '3px',
	extraLarge: '3px'
};

export const fontSizes = {
	extraSmall: '8px',
	smaller: '10px',
	small1: '11px',
	small2: '12px',
	default: '14px',
	large: '16px',
	extraLarge: '24px',

	heading1: '42px',
	heading2: '38px',
	heading3: '21px',
	heading4: '18px',
	heading5: '16px'
};

// copied from stories until the merge is done
export const FontSize = {
	xxsSize: '0.5625rem', //9px
	xsSize: '0.6875rem', //11px
	smSize: '0.8125rem', //13px
	base: '1rem', //16px
	lgSize: '1.1875rem', //19px
	xlSize: '1.4375rem' //23px
};

export const FontWeight = {
	light: '300',
	normal: '400',
	semibold: '600',
	bold: '700'
};

export const Colors = {
	V0: '#fff',
	V10: '#fbfbfb',
	V20: '#f7f6f8',
	V30: '#efedf0',
	V40: '#e5e2e7',
	V50: '#ccc8d1',
	V60: '#c0bac5',
	V70: '#b6afbc',
	V80: '#aaa2b1',
	V90: '#9e95a6',
	V100: '#91889b',
	V200: '#857a8f',
	V300: '#796d84',
	V400: '#6f627b',
	V500: '#635570',
	V600: '#594a66',
	V700: '#4a3a59',
	V800: '#3e2d4e',
	V900: '#342245',
	N700: '#2c2c2c',
	GetGest: '#091e42',
	error: '#b3261e',
	success: '#528b18',
	info: '#2472ab',
	warning: '#ab7d24'
};

export const GetGestColors = {
	V0: '#fff',
	V10: '#FAFBFB',
	V30: '#EBEDF0'
};

export const BodyWrapperHeight = {
	base: '74vh',
	mobile: 'calc(100vh - 159px)',
	getgestmobile: 'calc(100vh - 107px)',
};

export const DeviceBreakpoints = {
	small: '(max-width: 37.5rem)', //600px
	medium: '(max-width: 50rem)' //800px
};
