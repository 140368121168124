import styled from 'styled-components';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { Colors, BodyWrapperHeight, FontSize, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	border-top: 1px solid ${Colors.V30};
    border-bottom: 1px solid ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: grid;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.mobile};
	}
`;

export const PasswordUpdate = styled.div`
	text-align: right;
`;

export const PasswordUpdateLink = styled.a`
	font-size: ${FontSize.smSize};
	cursor: pointer;
`;
