import { useCallback, useMemo } from 'preact/hooks';

import styled from 'styled-components';

import MapComponent from 'redesigncomponents/Map';

const MapContainer = styled.div`
	width: 100%;
`;
const containerStyle = {
	width: '100%',
	height: '250px',
	border: '1px solid #857A8F',
	borderRadius: '4px'
};

const MAP_ZOOM = 1;

const LocationMap = ({ markers }) => {
	const center = useMemo(() => {
		return {
			lat:
				markers.reduce(
					(accummulator, currentValue) => currentValue.lat + accummulator,
					0
				) / markers.length,
			lng:
				markers.reduce(
					(accummulator, currentValue) => currentValue.lng + accummulator,
					0
				) / markers.length
		};
	}, [markers]);

	const onMapLoad = useCallback(
		map => {
			const bounds = new window.google.maps.LatLngBounds(center);
			markers.forEach(m =>
				bounds.extend(new window.google.maps.LatLng(m.lat, m.lng))
			);
			map.fitBounds(bounds);
			window.google.maps.event.addListenerOnce(
				map,
				'bounds_changed',
				function () {
					if (this.getZoom() > 15) {
						this.setZoom(15);
					}
				}
			);
		},
		[center, markers]
	);

	return (
		<MapContainer>
			<MapComponent
				center={center}
				containerStyle={containerStyle}
				onMapLoad={onMapLoad}
				zoom={MAP_ZOOM}
				markers={markers}
			/>
		</MapContainer>
	);
};

export default LocationMap;
