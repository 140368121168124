import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { CHANGE_PASSWORD } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Toast from 'redesigncomponents/Toast';
import { Paths } from 'router';
import { usePasswordConstraints } from 'shared/constraints';
import { UserConnectionTypes } from 'types/enums';
import validateForm from 'utils/form/validateForm';

import CloseIcon from 'assets/close.svg';
import EditIcon from 'assets/edit.svg';
import SaveIcon from 'assets/save.svg';

import FormSectionHeader from '../../FormSectionHeader';
import { FormWrapper, TwoInputWrapper } from '../../StyledComponents';

const RESET_FORM_TIMEOUT = 100;

interface IProps {
	mobileLayout?: boolean;
}

const PasswordForm: FunctionalComponent<IProps> = (mobileLayout) => {
	const history = useHistory();
	const { t } = useIntl('account.accountDetails');
	const { user, logout } = useUserAuth();
	const logOut = () => {
		logout();
		history.replace({
			pathname: Paths.SignIn
		});
	};
	const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
	const { constraints: passwordConstraints } = usePasswordConstraints();

	const resetFormInitialValues = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: ''
	};

	const passwordInitialValue = {
		password: '******'
	};

	const [changePassword, { loading: isUpdatingPassword }] =
		useMyPagesMutation(CHANGE_PASSWORD);

	const onPasswordFormSubmit = async values => {
		const response = await changePassword({
			variables: {
				username: user.email,
				...values
			}
		})
			.then(() => {
				setTimeout(() => setIsPasswordEdit(false), RESET_FORM_TIMEOUT);
				Toast.success(t('successfulPasswordEdit'), { autoClose: false });
				logOut();
			})
			.catch(error => ({ message: error.message }));

		if (response && response.message) {
			return { oldPassword: response.message };
		}
	};

	const renderPasswordForm = ({
		handleSubmit,
		submitting,
		pristine,
		hasValidationErrors,
		form
	}) => {
		return (
			<form onSubmit={handleSubmit} noValidate>
				<FormWrapper>
					<FormSectionHeader title={t('password')}>
						<>
							<Button
								title={t('cancel')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={CloseIcon}
								small
								tightPadding
								onClick={() => {
									form.reset();
									setIsPasswordEdit(false);
								}}
							/>
							<Button
								title={t('saveChanges')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={SaveIcon}
								small
								tightPadding
								disabled={
									pristine ||
									submitting ||
									hasValidationErrors ||
									isUpdatingPassword
								}
								loading={isUpdatingPassword}
								onClick={handleSubmit}
							/>
						</>
					</FormSectionHeader>
					<TwoInputWrapper mobileLayout>
						<Field
							type="password"
							label={t('oldPassword')}
							name="oldPassword"
							id="oldPassword"
							component={FieldTextInput}
							disabled={!isPasswordEdit}
							required
							smallPadding
						/>
					</TwoInputWrapper>
					<TwoInputWrapper mobileLayout>
						<Field
							type="password"
							label={t('newPassword')}
							name="newPassword"
							id="newPassword"
							component={FieldTextInput}
							disabled={!isPasswordEdit}
							required
							smallPadding
						/>
						<Field
							type="password"
							label={t('confirmPassword')}
							name="confirmPassword"
							id="confirmPassword"
							component={FieldTextInput}
							disabled={!isPasswordEdit}
							required
							smallPadding
						/>
					</TwoInputWrapper>
				</FormWrapper>
			</form>
		);
	};

	const renderPasswordField = ({ handleSubmit }) => (
		<form onSubmit={handleSubmit} noValidate>
			<FormWrapper>
				<FormSectionHeader title={t('password')}>
					{user?.connection === UserConnectionTypes.EmailAndPassword && (
						<Button
							title={t('edit')}
							Icon={EditIcon}
							buttonStyleType={ButtonStyleTypes.tertiary}
							small
							tightPadding
							onClick={() => {
								setIsPasswordEdit(true);
							}}
						/>
					)}
				</FormSectionHeader>
				<TwoInputWrapper mobileLayout>
					<Field
						type="password"
						label={t('password')}
						name="password"
						id="password"
						component={FieldTextInput}
						disabled={!isPasswordEdit}
						required
						smallPadding
					/>
				</TwoInputWrapper>
			</FormWrapper>
		</form>
	);

	return (
		<>
			{isPasswordEdit ? (
				<Form
					onSubmit={values => onPasswordFormSubmit(values)}
					initialValues={resetFormInitialValues}
					validate={values => validateForm(values, passwordConstraints)}
					render={renderPasswordForm}
				/>
			) : (
				<Form
					onSubmit={() => null}
					initialValues={passwordInitialValue}
					render={renderPasswordField}
				/>
			)}
		</>
	);
};

export default PasswordForm;
