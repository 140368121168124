import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontWeight } from 'styles/defaultTheme';

import CalendarIcon from 'assets/icons/transport/calendar.svg';
import SwapIcon from 'assets/icons/transport/swap.svg';

const DateChipContainer = styled.div`
	color: ${Colors.V900};
	background-color: ${Colors.V30};
	padding: 0.5rem;
	display: inline-flex;
	align-items: center;
	border-radius: 0.25rem;
	gap: 0.5rem;
	font-weight: ${FontWeight.bold};
	height: 2.25rem;
`;

const DateFromTitle = styled.span``;

const DateToTitle = styled.span``;

interface IProps {
	fromTitle?: string;
	toTitle?: string;
}

const DateChip: FunctionalComponent<IProps> = ({ fromTitle, toTitle }) => {
	return (
		<DateChipContainer>
			<CalendarIcon></CalendarIcon>
			<DateFromTitle>{fromTitle}</DateFromTitle>
			{toTitle && (
				<>
					<SwapIcon></SwapIcon>
					<CalendarIcon></CalendarIcon>
					<DateToTitle>{toTitle}</DateToTitle>
				</>
			)}
		</DateChipContainer>
	);
};

export default DateChip;
