import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

import NotificationIcon from 'assets/notification.svg';

const IconWrapper = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => (props.active ? '#342245' : '#fff')};
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	border-radius: 50%;
	position: relative;
	> svg path {
		fill: ${props => (props.active ? '#fff' : '#342245')};
	}
	&:hover {
		background-color: ${props => (props.active ? '#342245' : '#efedf0;')};
		cursor: pointer;
	}
`;

const Notification = styled.div`
	position: absolute;
	top: -5px;
	right: -3px;
`;

const NotificationValueWrapper = styled.div`
	display: flex;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
	background-color: ${Colors.info};
	align-items: center;
	justify-content: center;
	border: 1px solid ${Colors.V0};
	position: absolute;
	top: -4px;
	right: -6px;
	line-height: 0.625rem;
`;
const NotificationValue = styled.span`
	font-size: ${FontSize.xsSize};
	font-weight: ${FontWeight.bold};
	color: ${Colors.V0};
`;

interface IProps {
	active?: boolean;
	disabled?: boolean;
	Icon: any;
	showAlert?: boolean;
	notification?: string | number;
	onClick?: () => void;
}

const StyledIcon: FunctionalComponent<IProps> = ({
	active,
	disabled,
	Icon,
	showAlert,
	notification,
	onClick
}) => {
	return (
		<IconWrapper active={active} onClick={disabled ? undefined : onClick}>
			<Icon />
			{showAlert && !notification ? (
				<Notification>
					<NotificationIcon></NotificationIcon>
				</Notification>
			) : null}
			{notification ? (
				<NotificationValueWrapper>
					<NotificationValue>{notification}</NotificationValue>
				</NotificationValueWrapper>
			) : null}
		</IconWrapper>
	);
};

export default StyledIcon;
