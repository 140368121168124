import { useMemo } from 'preact/hooks';

import useCodebook from 'redesigncache/codebook/useCodebook';
import { TransportSortOption } from 'redesigntypes/TransportSortOption';

const useAirplaneTransportSortCodebookOptions = () => {
	const { airplaneTransportSortOptions: airplaneTransportSortOptionsData } =
		useCodebook(['airplaneTransportSortOptions']);

	const airplaneTransportSortOptions = useMemo(() => {
		return airplaneTransportSortOptionsData.map((tso: TransportSortOption) => ({
			value: tso.key,
			label: tso.name
		}));
	}, [airplaneTransportSortOptionsData]);

	return { airplaneTransportSortOptions };
};

export default useAirplaneTransportSortCodebookOptions;
