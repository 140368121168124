import useIntl from 'hooks/useIntl';

export default function (isForgotPassword: boolean) {
	const { t: messages } = useIntl('common.validationMessages');

	const constraints = {
		email: {
			presence: {
				message: messages('emailRequired')
			},
			email: {
				message: messages('emailInvalidWithExample')
			}
		},
		password: null
	};

	return { constraints };
}
