import { FunctionalComponent } from 'preact';

import { Box, IconContainer, Value } from './StyledComponents';

type Props = {
	Icon: FunctionalComponent;
	value: string;
};

const IconValue: FunctionalComponent<Props> = ({ Icon, value }) => {
	return (
		<Box>
			<IconContainer>
				<Icon />
			</IconContainer>
			<Value>{value}</Value>
		</Box>
	);
};

export default IconValue;
