import { useCheckout } from 'redesigncache/checkout/useCheckout';

import { IComposedTravelPlanItem } from '../../TravelPlan/useTravelPlan';

const useRemoveTravelPlanItem = (
	travelPlanItem: IComposedTravelPlanItem,
	travelPlanId: number | string
) => {
	const { checkout, updateCheckout } = useCheckout();

	const removeTravelPlanItem = () => {
		const oldTravelPlans = [...checkout.travelPlans];
		const travelPlanIndex = oldTravelPlans.findIndex(
			tp => tp.id === travelPlanId
		);
		if (travelPlanIndex !== -1) {
			const cacheTravelPlanItemIndex = oldTravelPlans[
				travelPlanIndex
			].travelPlanItems.findIndex(tpi => tpi.id === travelPlanItem.id);
			oldTravelPlans[travelPlanIndex].travelPlanItems.splice(
				cacheTravelPlanItemIndex,
				1
			);
			if (!oldTravelPlans[travelPlanIndex].travelPlanItems.length) {
				oldTravelPlans.splice(travelPlanIndex, 1);
			} else {
				oldTravelPlans[travelPlanIndex].updatedAt = Date.now();
			}

			updateCheckout([...oldTravelPlans]);
		}
	};

	return {
		removeTravelPlanItem
	};
};

export default useRemoveTravelPlanItem;
