import { useEffect } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';
import TrainTransportDetailsContainer from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer';
import { TrainTransportDetailsStep } from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/cache';
import useTrainTransportDetailsCache from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/cache/useTrainTransportDetailsCache';
import { FooterButtonsWrapper } from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/components/StyledComponents';
import SummaryStep from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/components/SummaryStep';
import WizardSteps from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/components/WizardSteps';
import useTransportDetailsWizard from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/components/WizardSteps/useTransportDetailsWizard';
import useAddTrainTransportToWishlist from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/hooks/useAddTrainTransportToWishlist';
import useTrainTransportDetails from 'redesignscreens/Main/Transport/TrainTransportDetails/TrainTransportDetailsContainer/hooks/useTrainTransportDetails';
import { TransportTripType } from 'types/enums';

import { StyledBodyWrapper } from './components/StyledComponents';

const TrainTransportDetails = () => {
	const { t: screenTranslations } = useIntl('screens');
	const defaultTravelPlanName = useDefaultTravelPlanName();
	const history = useHistory();
	const { currentStep, goToNextStep, goToFirstStep } =
		useTransportDetailsWizard();
	const { setDepartureDetails, setReturnDetails } =
		useTrainTransportDetailsCache();
	const { createTrainTransportWishlistItem } = useAddTrainTransportToWishlist();
	const { addToCheckout } = useCheckout();
	const [redirectToGetGestOpen, setRedirectToGetGestOpen] = useToggle();
	const {
		offerDetails,
		transportDetails,
		transportPrice,
		transportPassengerString,
		selectedOptions,
		selectedOptionsValid,
		openFormSection
	} = useTrainTransportDetails();

	useEffect(() => {
		goToFirstStep();
		openFormSection(0, undefined, 'ticketType');
	}, []);

	const submit = () => {
		if (currentStep !== TrainTransportDetailsStep.Summary) {
			if (currentStep === TrainTransportDetailsStep.Departure) {
				setDepartureDetails(
					transportPassengerString,
					transportPrice,
					offerDetails,
					transportDetails,
					selectedOptions
				);
			} else if (currentStep === TrainTransportDetailsStep.Return) {
				setReturnDetails(
					transportPassengerString,
					transportPrice,
					offerDetails,
					transportDetails,
					selectedOptions
				);
			}
			goToNextStep();
			openFormSection(0, undefined, 'ticketType');
		} else {
			// submit
			bookNow();
		}
	};

	useEffect(() => {
		openFormSection(0, undefined, 'ticketType');
	}, [currentStep]);

	const cancelTickets = () => {
		history.replace('/affiliate-link/transport-details/trainbus', {
			backNavigation: true
		});
	};

	const bookNow = () => {
		const wishlistItem = createTrainTransportWishlistItem();
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPeriod:
				offerDetails.tripType.value === TransportTripType.RoundTrip
					? offerDetails.travelPeriod
					: [
							offerDetails.travelPeriod[0],
							dayjs(offerDetails.travelPeriod[0]).add(1, 'days').toDate()
					  ],
			travelPlanItems: [wishlistItem],
			travelPlanName: defaultTravelPlanName,
			travelItineraryId: null
		});

		setRedirectToGetGestOpen(true);
	};

	return (
		<AffiliateLinkWrapper
			currentRouteSlug={screenTranslations('Transportation')}
		>
			<StyledBodyWrapper>
				<WizardSteps></WizardSteps>
				{currentStep === TrainTransportDetailsStep.Summary ? (
					<SummaryStep />
				) : (
					<TrainTransportDetailsContainer />
				)}
				{redirectToGetGestOpen && (
					<GetGestRedirectModal
						affiliateLink
						open={redirectToGetGestOpen}
						toggleOpen={setRedirectToGetGestOpen}
					/>
				)}
			</StyledBodyWrapper>
			<Footer>
				<FooterButtonsWrapper>
					<Button
						title="Cancel tickets"
						buttonStyleType={ButtonStyleTypes.primary}
						onClick={cancelTickets}
					></Button>

					<Button
						title={
							currentStep === TrainTransportDetailsStep.Summary
								? 'Book now'
								: 'Continue'
						}
						buttonStyleType={ButtonStyleTypes.primary}
						disabled={!selectedOptionsValid}
						onClick={submit}
					></Button>
				</FooterButtonsWrapper>
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default TrainTransportDetails;
