import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const CardWrapper = styled.div`
	width: 100%;
	background-color: ${Colors.V10};
	display: flex;
	flex-direction: column;
	border: 1px solid ${Colors.V100};
	padding: 0.75rem 1.25rem;
	color: ${Colors.V900};
	border-radius: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		padding: 0.5rem;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	flex: 1;
`;

export const RightWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
	gap: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		flex: 0;
		> div {
			display: none;
		}
	}
`;

export const CardName = styled.div`
	display: flex;
	align-items: center;
`;

export const Name = styled.h4`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.base};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const CardLogoWrapper = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
	background-color: ${Colors.V20};
	margin-right: 0.625rem;
	svg {
		width: 1.25rem;
	}
`;

export const HeadWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	@media ${DeviceBreakpoints.small} {
		gap: 0.5rem;
	}
`;

export const InfoBoxWrapper = styled.div`
	display: flex;
	gap: 0.25rem;
	align-items: center;
`;

export const DetailsBox = styled.div`
	display: ${props => (props.collapsed ? 'block' : 'none')};
`;

export const TableBody = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 1.25rem;
	padding: 0 0.5rem 0.5rem 0.5rem;
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: 1fr;
	}
`;

export const TableRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem;
	&:nth-child(odd) {
		background-color: ${Colors.V20};
	}
`;

export const TableFooter = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: ${Colors.V20};
	padding: 0.5rem;
	margin-top: 0.5rem;
`;

export const FooterLeft = styled.span``;

export const FooterRight = styled.span`
	font-weight: ${FontWeight.semibold};
`;

export const RowName = styled.span`
	font-size: ${FontSize.smSize};
`;

export const TableLeft = styled.div`
	padding-right: 0.375rem;
	border-right: 1px solid ${Colors.V50};
	@media (max-width: 680px) {
		padding-right: 0;
		border: none;
	}
`;

export const CheckboxWrapper = styled.div`
	width: 20px;
	height: 20px;
	margin-right: 12px;
`;

export const TableRight = styled.div`
	padding-left: 0.375rem;
	@media (max-width: 680px) {
		padding-left: 0;
	}
`;

export const RowValue = styled.span`
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.semibold};
`;

export const Link = styled.a`
	text-decoration: underline;
`;
