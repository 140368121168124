import { ComponentChildren, FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${Colors.V30};
  justify-content: space-between;
  padding: 0.625rem;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
`;

const HeaderTitle = styled.h4`
  font-size: ${FontSize.base};
  font-weight: ${FontWeight.bold};
  color: ${Colors.V900};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  button:last-child {
    margin-left: 0.75rem;
  }
`;

type Props = {
  title: string;
  children?: ComponentChildren;
};
const FormSectionHeader: FunctionalComponent<Props> = ({ title, children }) => {
  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      <ActionContainer>{children}</ActionContainer>
    </HeaderWrapper>
  );
};
export default FormSectionHeader;
