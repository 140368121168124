import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import {
	ITransportWishlistItem,
	WishlistItem
} from 'redesigncache/wishlist/types';
import { TransportType } from 'types/enums';
import { formatListsPrice } from 'utils/price/priceFormatting';

type TransportWishlistItemDetails = {
	tripType: string;
	title: string;
	departureDate: string;
	returnDate?: string;
	total: string;
};

const useTransportWishlistItemRedirectModal = (wishlistItem: WishlistItem) => {
	const { t } = useIntl('transportList.transportWishlistItemDetails');
	const { t: commonTranslations } = useIntl('common.airplaneTripTypes');
	const transportWishlistItemDetails =
		useMemo((): TransportWishlistItemDetails => {
			const transportItem = wishlistItem.item as ITransportWishlistItem;
			const roundTrip = transportItem.return_date ? true : false;
			const tripType = roundTrip
				? commonTranslations('RoundTrip')
				: commonTranslations('OneWay');
			const details: TransportWishlistItemDetails = {
				tripType: `(${tripType})`,
				title: `${transportItem.transport_type}: ${transportItem.title}`,
				departureDate: `${t('departure')} ${dayjs(
					transportItem.departure_time
				).format('DD.MM.YYYY')}`,
				returnDate:
					roundTrip &&
					`${t('return')} ${dayjs(transportItem.return_departure_time).format(
						'DD.MM.YYYY'
					)}`,
				total: t('price', {
					amount:
						transportItem.transport_type === TransportType.Airplane
							? formatListsPrice(transportItem.price.amount)
							: transportItem.price.amount,
					currency: transportItem.price.currency
				})
			};

			return details;
		}, [wishlistItem]);

	return {
		transportWishlistItemDetails
	};
};

export default useTransportWishlistItemRedirectModal;
