import { useApolloClient } from '@apollo/client';
import { useCallback } from 'preact/hooks';

import { SEARCH_STATION } from 'apollo/myDesti/queries';
import { Option } from 'redesigntypes/Option';
import { TransportType } from 'types/enums';
import asyncThrottle from 'utils/functions/asyncThrottle';

import { StationsSearchResponse } from '../../types';

const useLoadLocations = () => {
	const client = useApolloClient();

	const loadOptions = useCallback(async (searchTerm): Promise<Option[]> => {
		const response = await client.query<StationsSearchResponse>({
			query: SEARCH_STATION,
			variables: {
				city: searchTerm,
				transportationTypes: [TransportType.Airplane]
			}
		});

		if (response.data?.getStations) {
			return response.data?.getStations.map(s => ({
				value: s.id,
				label: s.name
			}));
		}

		return [];
	}, []);

	const loadLocationOptionsThrottled = asyncThrottle(loadOptions, 200);

	return {
		loadLocationOptions: loadLocationOptionsThrottled
	};
};

export default useLoadLocations;
