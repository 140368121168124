import { FunctionalComponent, createContext } from 'preact';

import styled from 'styled-components';

const StyledModal = styled.div`
	background-color: transparent;
	display: flex;
	flex-direction: column;
	font-family: 'Open Sans';
	margin: auto;
	max-width: 800px;
	height: 100%;
	width: 100%;
`;

const ModalContext = createContext(null);

type Props = {
	onDismiss(): void;
};

const WidgetModal: FunctionalComponent<Props> = ({
	children = null,
	onDismiss = () => 0
}) => (
	// <Overlay>
	<StyledModal onClick={e => e.stopPropagation()}>
		<ModalContext.Provider value={{ onModalClose: onDismiss }}>
			{children}
		</ModalContext.Provider>
	</StyledModal>
	// </Overlay>
);

export default WidgetModal;
export { ModalContext };
