import { FunctionalComponent } from 'preact';

import CSS from 'csstype';
import styled from 'styled-components';

const LoaderContainer = styled.div`
	display: inline-block;
	position: relative;

	div {
		position: absolute;
		top: 41.25%;
		width: 16.25%;
		height: 16.25%;
		border-radius: 50%;
		background: #fff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	div:nth-child(1) {
		left: 10%;
		animation: lds-ellipsis1 0.6s infinite;
	}
	div:nth-child(2) {
		left: 10%;
		animation: lds-ellipsis2 0.6s infinite;
	}
	div:nth-child(3) {
		left: 40%;
		animation: lds-ellipsis2 0.6s infinite;
	}
	div:nth-child(4) {
		left: 70%;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(184.61%, 0);
		}
	}
`;

interface Props {
	color?: string;
	size?: number;
	style?: CSS.Properties;
}

const ThreeDotLoader: FunctionalComponent<Props> = ({
	color = '#fff',
	size = 40,
	style,
	...rest
}) => {
	const circles = Array.from({ length: 4 }).map((_, index) => (
		<div key={index} style={{ background: `${color}` }} />
	));

	return (
		<LoaderContainer style={{ width: size, height: size, ...style }} {...rest}>
			{circles}
		</LoaderContainer>
	);
};

export default ThreeDotLoader;
