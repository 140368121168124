import { useMemo } from 'preact/hooks';

import useIntl from './useIntl';

const useHotelDescription = code => {
	const { t } = useIntl('accommodationDetails');
	const description = useMemo(() => {
		switch (code) {
			case 'GB008566': {
				return t('hotelDescription');
			}
			case 'SE001455': {
				return t('hotelDescription');
			}
		}
	}, [code]);

	return { description };
};

export default useHotelDescription;
