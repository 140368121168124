import { useMemo } from 'preact/hooks';

import config from 'config/config';
import useCodebook from 'redesigncache/codebook/useCodebook';
import { ITransportType } from 'redesigntypes/TransportType';

import useIntl from './useIntl';

const useTransportTypeCodebookOptions = () => {
	const { t } = useIntl('common.transportTypes');
	const { transportTypes } = useCodebook(['transportTypes']);

	const transportTypeOptions = useMemo(() => {
		return transportTypes
			.filter(
				transportType => config.enabledTransportTypes[transportType.key_name]
			)
			.map((transportType: ITransportType) => ({
				value: transportType.key_name,
				label: t(transportType.key_name)
			}));
	}, [transportTypes]);

	return { transportTypeOptions };
};

export default useTransportTypeCodebookOptions;
