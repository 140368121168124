import { useReactiveVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

import { activityListCacheVar } from '.';

const useActivityListCache = () => {
	const { initialized, adultsCount, activityTypes, sort, privateTour } =
		useReactiveVar(activityListCacheVar);

	const initializeActivityListCache = (
		adultsCount,
		activityTypes,
		sort,
		privateTour
	) => {
		activityListCacheVar({
			...activityListCacheVar(),
			adultsCount,
			activityTypes,
			sort,
			privateTour,
			initialized: true
		});
	};

	const setAdultCount = (adultsCount: number) => {
		activityListCacheVar({
			...activityListCacheVar(),
			adultsCount
		});
	};
	const setPrivateTour = (privateTour: boolean) => {
		activityListCacheVar({
			...activityListCacheVar(),
			privateTour
		});
	};
	const setActivityTypes = (activityTypes: Option[]) => {
		activityListCacheVar({
			...activityListCacheVar(),
			activityTypes
		});
	};

	const setSort = (sort: Option) => {
		activityListCacheVar({
			...activityListCacheVar(),
			sort
		});
	};

	return {
		initialized,
		adultsCount,
		privateTour,
		activityTypes,
		sort,
		initializeActivityListCache,
		setAdultCount,
		setPrivateTour,
		setActivityTypes,
		setSort
	};
};

export default useActivityListCache;
