import { FunctionalComponent } from 'preact';

import { ITravelPlan } from 'redesigncache/checkout/types';
import useTravelPlanLocationPeriod from 'redesignscreens/Account/TravelPlanner/components/SingleTravelDetails/useTravelPlanLocationPeriod';

import TravelPlanItem from '../TravelPlanItem';
import {
	TravelPlanHeader,
	TravelPlanSubtitle,
	TravelPlanTitle
} from './components/StyledComponents';
import useTravelPlan from './useTravelPlan';

interface IProps {
	travelPlan: ITravelPlan;
	hideHeader?: boolean;
	canBeEdited?: boolean;
}

const TravelPlan: FunctionalComponent<IProps> = ({
	travelPlan,
	hideHeader,
	canBeEdited
}) => {
	const { composedTravelPlanItems } = useTravelPlan(travelPlan);

	const { travelPlanLocationAndPeriod } =
		useTravelPlanLocationPeriod(travelPlan);
	return (
		<>
			{!hideHeader ? (
				<TravelPlanHeader>
					<TravelPlanTitle>{travelPlan.travelPlanName}</TravelPlanTitle>
					<TravelPlanSubtitle>{travelPlanLocationAndPeriod}</TravelPlanSubtitle>
				</TravelPlanHeader>
			) : undefined}
			{composedTravelPlanItems.map(composedTravelPlanItem => (
				<TravelPlanItem
					travelPlanId={travelPlan.id} // unique id generated for every plan on frontend (even plans that were not saved at the time)
					travelItineraryId={travelPlan.travelItineraryId} // unique itinerary id that the plan was saved into
					travelPlanName={travelPlan.travelPlanName}
					travelPeriod={travelPlan.travelPeriod}
					travelPlanItem={composedTravelPlanItem}
					canBeRemoved={canBeEdited}
				/>
			))}
		</>
	);
};

export default TravelPlan;
