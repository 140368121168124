import { useReactiveVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

import { accommodationListCacheVar } from '.';

const useAccommodationListCache = () => {
	const {
		initialized,
		adultsCount,
		childrenAgesOptions,
		childrenCount,
		childrenAges,
		numberOfRoomsCount,
		sort
	} = useReactiveVar(accommodationListCacheVar);

	const initializeAccommodationListCache = (
		adultsCount,
		childrenAgesOptions,
		childrenCount,
		childrenAges,
		numberOfRoomsCount,
		sort
	) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			adultsCount,
			childrenAgesOptions,
			childrenCount,
			childrenAges,
			numberOfRoomsCount,
			sort,
			initialized: true
		});
	};

	const setAdultsCount = (adultsCount: number) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			adultsCount
		});
	};
	const setChildrenAgesOptions = (childrenAgesOptions: Option[]) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			childrenAgesOptions
		});
	};
	const setChildrenCount = (childrenCount: number) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			childrenCount
		});
	};

	const setChildrenAges = (childrenAges: number[]) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			childrenAges
		});
	};

	const setNumberOfRoomsCount = (numberOfRoomsCount: number) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			numberOfRoomsCount
		});
	};

	const setSort = (sort: Option) => {
		accommodationListCacheVar({
			...accommodationListCacheVar(),
			sort
		});
	};

	return {
		initialized,
		adultsCount,
		childrenAgesOptions,
		childrenCount,
		childrenAges,
		numberOfRoomsCount,
		sort,
		initializeAccommodationListCache,
		setAdultsCount,
		setChildrenAgesOptions,
		setChildrenCount,
		setChildrenAges,
		setNumberOfRoomsCount,
		setSort
	};
};

export default useAccommodationListCache;
