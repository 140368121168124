import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

type ActivityListCache = {
	initialized: boolean;
	adultsCount: number;
	activityTypes: Option[];
	privateTour: boolean;
	sort: Option;
};

export const initialActivityListValue: ActivityListCache = {
	initialized: false,
	adultsCount: 2,
	activityTypes: [],
	privateTour: false,
	sort: undefined
};

export const activityListCacheVar = makeVar<ActivityListCache>(
	initialActivityListValue
);
