import { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import { WishlistItem } from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonContainer,
	LogoContainer,
	Modal,
	ModalInfoText
} from 'redesigncomponents/Modal';
import { Paths } from 'router';
import { FontWeight } from 'styles/defaultTheme';

import FavoritesIcon from 'assets/favorites.svg';

import useActivityWishlistItemRedirectModal from './useActivityWishlistItemRedirectModal';

export const TitleText = styled.p`
	font-size: 18px;
	margin: 1rem 0;
	text-transform: uppercase;
`;

export const InfoText = styled.p`
	margin: 0.5rem 0;
	${({ bold }) => bold && `font-weight: ${FontWeight.bold}`}
`;

type Props = {
	item: WishlistItem;
	open: boolean;
	toggleOpen: () => void;
};

const ActivityAddedToWishlistRedirectModal: FunctionalComponent<Props> = ({
	item,
	open,
	toggleOpen
}) => {
	const { t } = useIntl('activityDetails.activityWishlistItemDetails');
	const history = useHistory();
	const { activityWishlistItemDetails } =
		useActivityWishlistItemRedirectModal(item);
	const goToWishlist = () => history.push(Paths.Wishlist);
	const goBackToStartingScreen = () => history.push(Paths.Main);

	return (
		<Modal isOpen={open} onClose={toggleOpen}>
			<LogoContainer>
				<FavoritesIcon />
			</LogoContainer>
			<ModalInfoText>
				<div>{t('youveAddedTitle')}</div>
				<TitleText>{activityWishlistItemDetails.title}</TitleText>
				<InfoText>{activityWishlistItemDetails.city}</InfoText>
				<InfoText bold>{activityWishlistItemDetails.datetime}</InfoText>
				<InfoText bold>{activityWishlistItemDetails.total}</InfoText>
			</ModalInfoText>
			<ButtonContainer>
				<Button
					title={t('continueExploringButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goBackToStartingScreen}
				></Button>
				<Button
					title={t('goToWishlistButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goToWishlist}
				></Button>
			</ButtonContainer>
		</Modal>
	);
};

export default ActivityAddedToWishlistRedirectModal;
