import { makeVar } from '@apollo/client';

export enum AffiliateLinkType {
	Activity = 'Activity',
	Merchandise = 'Merchandise',
	Stay = 'Stay',
	Transportation = 'Transportation'
}

type AffiliateLinkTransportParameters = {
	kind: string;
	destination: string;
};
export type AffiliateLinkConfig = {
	id: string;
	parameters: AffiliateLinkTransportParameters; // more to be added
};

export type AffiliateLinkDetails = {
	externalId: string;
	isActive: boolean;
	type: AffiliateLinkType;
	parameters: { transportType: string } | any;
	productOwner: {
		code: string;
		globalId: string;
		enabled: boolean;
		integrations?: {
			name: string;
		};
	};
};

export type AffiliateLink = {
	id: string;
	config: AffiliateLinkConfig;
	details?: AffiliateLinkDetails;
	startedAt: number;
};

export const initialValueAffiliateLink: AffiliateLink = undefined;

export const affiliateLinkVar = makeVar<AffiliateLink>(
	initialValueAffiliateLink
);
