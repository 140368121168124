import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import {
	IActivityWishlistItem,
	IMerchandiseWishlistItem,
	IStayWishlistItem,
	ITransportWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlistItemDetails from 'redesigncache/wishlist/useWishlistItemDetails';
import { TransportType } from 'types/enums';
import { formatListsPrice } from 'utils/price/priceFormatting';

const useWishlistItemCard = (wishlistItem: WishlistItem) => {
	const { t } = useIntl('wishlist.travelPlanItem.header');
	const { getFormattedNumberOfPeople, getWishlistItemDetails } =
		useWishlistItemDetails();
	const title = useMemo(() => {
		switch (wishlistItem.type) {
			case WishlistItemTypes.ACTIVITY: {
				const activityItem = wishlistItem.item as IActivityWishlistItem;
				return `${activityItem.supplierName}: ${activityItem.name}`;
			}
			case WishlistItemTypes.MERCHANDISE: {
				const merchandiseItem = wishlistItem.item as IMerchandiseWishlistItem;
				return `${merchandiseItem.supplierName}: ${merchandiseItem.name}`;
			}
			case WishlistItemTypes.STAY: {
				const stayItem = wishlistItem.item as IStayWishlistItem;
				return `${stayItem.accommodationName}: ${stayItem.roomDescription}`;
			}
			case WishlistItemTypes.TRANSPORT: {
				const transportItem = wishlistItem.item as ITransportWishlistItem;
				return `${transportItem.city_from} - ${transportItem.city_to} (${transportItem.operatorNames})`;
			}
			default:
				'';
		}
	}, [wishlistItem]);

	const infos = useMemo(() => {
		switch (wishlistItem.type) {
			case WishlistItemTypes.ACTIVITY: {
				const activityItem = wishlistItem.item as IActivityWishlistItem;

				return [
					{
						title: t('numberOfPeopleInfoBoxTitle'),
						subtitle: getFormattedNumberOfPeople(wishlistItem)
					},
					{
						title: t('totalPriceInfoBoxTitle'),
						subtitle: `${activityItem.price.amount} ${activityItem.price.currency}`
					}
				];
			}
			case WishlistItemTypes.MERCHANDISE: {
				const merchandiseItem = wishlistItem.item as IMerchandiseWishlistItem;

				return [
					{
						title: t('totalPriceInfoBoxTitle'),
						subtitle: `${merchandiseItem.price.amount} ${merchandiseItem.price.currency}`
					}
				];
			}
			case WishlistItemTypes.STAY: {
				const stayItem = wishlistItem.item as IStayWishlistItem;

				return [
					{
						title: t('numberOfPeopleInfoBoxTitle'),
						subtitle: getFormattedNumberOfPeople(wishlistItem)
					},
					{
						title: t('totalPriceInfoBoxTitle'),
						subtitle: `${stayItem.price.amount} ${stayItem.price.currency}`
					}
				];
			}
			case WishlistItemTypes.TRANSPORT: {
				const transportItem = wishlistItem.item as ITransportWishlistItem;

				const infos = [
					{
						title: t('numberOfPeopleInfoBoxTitle'),
						subtitle: getFormattedNumberOfPeople(wishlistItem)
					}
				];

				infos.push({
					title: t('totalPriceInfoBoxTitle'),
					subtitle: `${
						transportItem.transport_type === TransportType.Airplane
							? formatListsPrice(transportItem.price.amount)
							: transportItem.price.amount
					} ${transportItem.price.currency}`
				});
				return infos;
			}
			default:
				'';
		}
	}, [wishlistItem]);

	const details = useMemo(
		() => getWishlistItemDetails(wishlistItem),
		[wishlistItem]
	);

	return {
		title,
		infos,
		details
	};
};

const generateTransportTimeSubtitle = (departureDate: Date) => {
	const departureDateConverted = dayjs.utc(departureDate);

	return `${departureDateConverted.format('HH:mm DD.MM.YYYY')}`;
};

export default useWishlistItemCard;
