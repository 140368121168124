import { FunctionalComponent } from 'preact';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import {
	SubmitErrorsSpy,
	submitErrorsMutators
} from 'final-form-submit-errors';

import { RESET_PASSWORD } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import Button from 'redesigncomponents/Button';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Footer from 'redesigncomponents/Footer';
import Toast from 'redesigncomponents/Toast';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import useForgotPasswordConstraints from 'redesignscreens/Auth/ForgotPassword/useForgotPasswordConstraints';
import { Paths } from 'router';
import validateForm from 'utils/form/validateForm';

import {
	ContentWrapper,
	FormHeader,
	SignupContent,
	SignupLink,
	SignupText
} from '../components/StyledComponents';

interface IProps {}

const REDIRECT_FORM_TIMEOUT = 100;

const ForgotPassword: FunctionalComponent<IProps> = () => {
	const history = useHistory();
	const { t } = useIntl('forgotPassword');
	const [resetPassword] = useMyPagesMutation(RESET_PASSWORD);
	const { constraints: forgotPasswordConstraints } =
		useForgotPasswordConstraints(true);

	const validate = (values: Record<string, any>): any =>
		validateForm(values, forgotPasswordConstraints);

	const onSubmit = async (values: Record<string, any>) => {
		const { email } = values;
		const response = await resetPassword({ variables: { email } })
			.then(() => {
				setTimeout(() => {
					Toast.success(t('pleaseCheckYourEmail'), { autoClose: false });
				}, REDIRECT_FORM_TIMEOUT);
				history.replace({
					pathname: Paths.SignIn
				});
			})
			.catch(error => ({ message: error.message }));

		if (response && response.message) {
			return { email: response.message };
		}
	};

	const goToSignIn = () => history.push(Paths.SignIn);

	return (
		<MainWrapper>
			<Form
				onSubmit={values => onSubmit(values)}
				mutators={{
					...submitErrorsMutators
				}}
				validate={validate}
				render={({
					handleSubmit,
					submitting,
					pristine,
					hasValidationErrors
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<BodyWrapper>
								<ContentWrapper>
									<FormHeader>{t('forgotPassword')}</FormHeader>
									<Field
										name="email"
										component={FieldTextInput}
										label={t('emailLabel')}
										type="email"
									/>
									<Button
										title={t('resetPassword')}
										type="submit"
										disabled={submitting || pristine || hasValidationErrors}
									/>
									<SignupContent>
										<SignupText>{t('rememberTheCredentials')}</SignupText>
										<SignupLink onClick={goToSignIn}>
											{t('signUpHereLink')}
										</SignupLink>
									</SignupContent>
								</ContentWrapper>
							</BodyWrapper>
							<Footer />
							<SubmitErrorsSpy />
						</form>
					);
				}}
			/>
		</MainWrapper>
	);
};

export default ForgotPassword;
