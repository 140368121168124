import accommodationDetails from './accommodation-details.json';
import accommodation from './accommodation.json';
import account from './account.json';
import activityDetails from './activity-details.json';
import checkout from './checkout.json';
import common from './common.json';
import forgotPassword from './forgot-password.json';
import main from './main.json';
import merchandiseDetails from './merchandise-details.json';
import screens from './screens.json';
import signIn from './sign-in.json';
import signUp from './sign-up.json';
import termsOfUse from './terms-of-use.json';
import tooltips from './tooltips.json';
import transportList from './transport-list.json';
import travelPlanner from './travel-planner.json';
import travels from './travels.json';
import wishlist from './wishlist.json';

export default {
	main,
	common,
	signIn,
	signUp,
	forgotPassword,
	screens,
	account,
	accommodation,
	activityDetails,
	merchandiseDetails,
	checkout,
	accommodationDetails,
	transportList,
	travelPlanner,
	travels,
	termsOfUse,
	wishlist,
	tooltips
};
