import { FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';

import storage from 'local-storage-fallback';

import { TooltipView, TooltipViewContext } from './TooltipViewContext';

const tooltipViewsLocalStorageKey = 'tooltipViews';

export const useTooltipViewed = (tooltipView: TooltipView): boolean => {
	const tooltipViewsArray: Array<TooltipView> = useContext(TooltipViewContext);
	if (
		tooltipViewsArray.findIndex(
			(tp: TooltipView) =>
				tp.pathName === tooltipView.pathName &&
				tp.tooltipKey === tooltipView.tooltipKey
		) !== -1
	) {
		return true;
	} else {
		tooltipViewsArray.push(tooltipView);
		storage.setItem(
			tooltipViewsLocalStorageKey,
			JSON.stringify(tooltipViewsArray)
		);
		return false;
	}
};

export const TooltipViewsProvider: FunctionalComponent = ({ children }) => {
	const tooltipViews: Array<TooltipView> = storage.getItem(
		tooltipViewsLocalStorageKey
	)
		? JSON.parse(storage.getItem(tooltipViewsLocalStorageKey))
		: Array<string>();

	return (
		<TooltipViewContext.Provider value={tooltipViews}>
			{children}
		</TooltipViewContext.Provider>
	);
};
