import { useReactiveVar } from '@apollo/client';

import cloneDeep from 'lodash.clonedeep';

import {
	TrainTransportDetailsStep,
	initialTrainTransportDetailsValue,
	trainTransportDetailsOptionsVar
} from '.';
import {
	ITransportDetails,
	ITransportOptionSection
} from '../hooks/useTrainTransportDetails';

const useTrainTransportDetailsCache = () => {
	const {
		currentStep,
		departureTransportDetails,
		returnTransportDetails,
		selectedDepartureOptions,
		selectedReturnOptions,
		openSection,
		departureDetails,
		returnDetails
	} = useReactiveVar(trainTransportDetailsOptionsVar);

	const setCurrentStep = (currentStepKey: TrainTransportDetailsStep) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			currentStep: currentStepKey
		});
	};

	const setDepartureTransportDetails = (
		departureTransportDetails: ITransportDetails
	) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			departureTransportDetails
		});
	};

	const setReturnTransportDetails = (
		returnTransportDetails: ITransportDetails
	) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			returnTransportDetails
		});
	};

	const setSelectedDepartureOptions = selectedDepartureOptions => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			selectedDepartureOptions
		});
	};

	const setSelectedReturnOptions = selectedReturnOptions => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			selectedReturnOptions
		});
	};

	const setOpenSection = (openSection: ITransportOptionSection) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			openSection
		});
	};

	const setDepartureDetails = (
		passengerString,
		price,
		offerDetails,
		transportDetails,
		selectedOptions
	) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			departureDetails: {
				passengerString,
				price,
				offerDetails,
				transportDetails,
				selectedOptions
			}
		});
	};

	const setReturnDetails = (
		passengerString,
		price,
		offerDetails,
		transportDetails,
		selectedOptions
	) => {
		trainTransportDetailsOptionsVar({
			...trainTransportDetailsOptionsVar(),
			returnDetails: {
				passengerString,
				price,
				offerDetails,
				transportDetails,
				selectedOptions
			}
		});
	};

	const clearTransportDetails = () => {
		trainTransportDetailsOptionsVar({
			...cloneDeep(initialTrainTransportDetailsValue)
		});
	};

	return {
		currentStep,
		departureTransportDetails,
		returnTransportDetails,
		selectedDepartureOptions,
		selectedReturnOptions,
		openSection,
		departureDetails,
		returnDetails,
		setCurrentStep,
		setDepartureTransportDetails,
		setReturnTransportDetails,
		setSelectedDepartureOptions,
		setSelectedReturnOptions,
		setOpenSection,
		setDepartureDetails,
		setReturnDetails,
		clearTransportDetails
	};
};

export default useTrainTransportDetailsCache;
