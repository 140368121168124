import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';

import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import { affiliateLinkVar } from 'redesigncache/affiliateLink/cache';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';
import AccommodationDetailsContainer from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer';
import useAccommodationOffer from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAccommodationOffer';
import capitalizeWords from 'utils/string/capitalizeWords';

import useAccommodationDetails from '../../Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAccommodationDetails';
import useAddAccommodationRoomToWishlist from '../../Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAddAccommodationRoomToWishlist';
import { StyledBodyWrapper } from './components/StyledComponents';

const AccommodationDetails: FunctionalComponent = () => {
	const { t: accommodationDetailsTranslations } = useIntl(
		'accommodationDetails'
	);
	const defaultTravelPlanName = useDefaultTravelPlanName();
	const [redirectToGetGestOpen, setRedirectToGetGestOpen] = useToggle();
	const { details: { externalId } = { externalId: '' } } =
		useReactiveVar(affiliateLinkVar);
	const {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		selectedRoomOption,
		isSelectedRoomOptionValid
	} = useAccommodationOffer();
	const { accommodation, getAvailableRoomByKey, loadingAvailableRooms } =
		useAccommodationDetails();
	const { createAccommodationWishlistItem } =
		useAddAccommodationRoomToWishlist();
	const { addToCheckout } = useCheckout();

	const bookNow = () => {
		const wishlistItem = createAccommodationWishlistItem(
			numberOfRooms,
			numberOfAdults,
			numberOfChildren,
			childrenAges,
			travelPeriod,
			getAvailableRoomByKey(selectedRoomOption.value as string),
			accommodation
		);
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPeriod,
			travelPlanItems: [wishlistItem],
			travelPlanName: defaultTravelPlanName,
			travelItineraryId: null
		});
		setRedirectToGetGestOpen(true);
	};
	return (
		<AffiliateLinkWrapper
			currentRouteSlug={
				accommodation?.name && capitalizeWords(accommodation?.name)
			}
		>
			<StyledBodyWrapper>
				<AccommodationDetailsContainer
					externalId={externalId}
					travelPeriod={travelPeriod as [Date, Date]}
				/>
			</StyledBodyWrapper>
			{redirectToGetGestOpen && (
				<GetGestRedirectModal
					affiliateLink
					open={redirectToGetGestOpen}
					toggleOpen={setRedirectToGetGestOpen}
				/>
			)}
			<Footer>
				{isSelectedRoomOptionValid && !loadingAvailableRooms && (
					<Button
						title={accommodationDetailsTranslations('bookNowButtonTitle')}
						onClick={bookNow}
					/>
				)}
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default AccommodationDetails;
