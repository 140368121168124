import { useState } from 'preact/hooks';

import {
	IStayWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import { AccommodationModel } from 'redesigntypes/AccommodationModel';
import { AccommodationRoom } from 'redesigntypes/AccommodationRoom';

const useAddAccommodationRoomToWishlist = () => {
	const { createWishlistItem, addItemToWishlist } = useWishlist();
	const [addedWishlistItem, setAddedWishlistItem] = useState<WishlistItem>();

	const addToWishlist = (
		numberORooms: number,
		numberOfAdults: number,
		numberOfChildren: number,
		childrenAges: number[],
		travelPeriod: [Date, Date],
		selectedRoom: AccommodationRoom,
		accommodation: AccommodationModel
	): WishlistItem => {
		const newWishlistItem = createAccommodationWishlistItem(
			numberORooms,
			numberOfAdults,
			numberOfChildren,
			childrenAges,
			travelPeriod,
			selectedRoom,
			accommodation
		);
		addItemToWishlist(newWishlistItem);
		setAddedWishlistItem(newWishlistItem);
		return newWishlistItem;
	};

	const createAccommodationWishlistItem = (
		numberORooms: number,
		numberOfAdults: number,
		numberOfChildren: number,
		childrenAges: number[],
		travelPeriod: [Date, Date],
		selectedRoom: AccommodationRoom,
		accommodation: AccommodationModel
	) => {
		const item: Omit<WishlistItem, 'id'> = {
			type: WishlistItemTypes.STAY,
			item: {
				people: numberOfAdults,
				children: numberOfChildren,
				travelPeriod,
				arrivalDate: travelPeriod[0],
				departureDate: travelPeriod[1],
				accommodationName: selectedRoom.accommodationName,
				price: {
					...selectedRoom.rate,
					amount: selectedRoom.rate.amount * numberORooms
				},
				title: `${selectedRoom.roomType} ${selectedRoom.roomDescription}`,
				room_type: selectedRoom.roomType,
				roomDescription: selectedRoom.roomDescription,
				priceRateCode: selectedRoom.priceRateCode,
				city: selectedRoom.cityName,
				number_of_rooms: numberORooms,
				product_owner: accommodation.productOwner,
				integration: accommodation.integration,
				stayEstablishmentId: accommodation.code,
				childrenAges: childrenAges,
				roomCapacity: selectedRoom.roomCapacity,
				rooms: [],
				product_location: accommodation.productLocation,
				check_in_time: accommodation.checkInTime,
				check_out_time: accommodation.checkOutTime
			} as IStayWishlistItem
		};

		if (numberORooms > 1) {
			const rooms = [];
			for (let i = 0; i < numberORooms; i++) {
				rooms.push({
					people: numberOfAdults,
					children: numberOfChildren,
					room_type: selectedRoom.roomType
				});
			}
			(item.item as IStayWishlistItem).rooms = rooms;
		}

		return createWishlistItem(item);
	};

	return { addToWishlist, createAccommodationWishlistItem, addedWishlistItem };
};

export default useAddAccommodationRoomToWishlist;
