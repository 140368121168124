import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { BodyWrapperHeight, Colors } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	padding: 0.75rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: block;
	border-top: 1px solid #efedf0;
	border-bottom: 1px solid #efedf0;
`;
