import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';

import { hostConfigurationVar } from 'redesigncache/hostConfiguration/cache';
import IconButton from 'redesigncomponents/IconButton';
import { ModalContext } from 'redesigncomponents/WidgetModal';

import CloseIcon from 'assets/close.svg';
import GetGestLogo from 'assets/get-gest-logo.svg';

import {
	HeaderWrapper,
	LeftWrapper,
	RightWrapper
} from './components/StyledComponents';

interface IProps {}

const AffiliateCheckoutHeader: FunctionalComponent<IProps> = ({}) => {
	const { onModalClose } = useContext(ModalContext);
	const { logoUrl } = useReactiveVar(hostConfigurationVar);

	return (
		<HeaderWrapper>
			<LeftWrapper>
				{logoUrl ? <img src={logoUrl} /> : <GetGestLogo />}
			</LeftWrapper>
			<RightWrapper>
				<IconButton Icon={CloseIcon} onClick={onModalClose} />
			</RightWrapper>
		</HeaderWrapper>
	);
};

export default AffiliateCheckoutHeader;
