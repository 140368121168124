import { useState } from 'preact/hooks';

import {
	ITransportWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import { TransportTripType, TransportType } from 'types/enums';

import { OfferDetails } from '../../types';
import { OptionCategoryTypes } from '../components/OptionCategories/useOptionCategories';
import useTransportOfferItem from '../components/TransportOfferItem/useTransportOfferItem';
import { getPassengersForSearch } from './useTransportationOffers';

const useAddTransportToWishlist = (offerDetails: OfferDetails) => {
	const { createWishlistItem, addItemToWishlist } = useWishlist();
	const [addedWishlistItem, setAddedWishlistItem] = useState<WishlistItem>();
	const { expandedDetails } = useTransportOfferItem(
		offerDetails?.offer,
		offerDetails?.tripType?.value as TransportTripType
	);

	const addToWishlist = (): WishlistItem => {
		const newWishlistItem = createAirTransportWishlistItem();

		addItemToWishlist(newWishlistItem);
		setAddedWishlistItem(newWishlistItem);

		return newWishlistItem;
	};

	const createAirTransportWishlistItem = () => {
		const item: Omit<WishlistItem, 'id'> = {
			type: WishlistItemTypes.TRANSPORT,
			item: {
				title: `${offerDetails.fromLocation?.label} - ${offerDetails.toLocation?.label}`,
				people:
					offerDetails.optionCategories[OptionCategoryTypes.passengers][
						'adults'
					],
				children:
					offerDetails.optionCategories[OptionCategoryTypes.passengers][
						'children'
					],
				infants:
					offerDetails.optionCategories[OptionCategoryTypes.passengers][
						'infants'
					],
				price: offerDetails.offer?.price,
				operatorNames: offerDetails.offer.operator_name,
				city_from: offerDetails.fromLocation?.label,
				city_to: offerDetails.toLocation?.label,

				date: offerDetails.offer.departure_time,
				return_date: expandedDetails.returning?.arrivalTime,
				departure_time: offerDetails.offer.departure_time,
				return_departure_time: expandedDetails.returning?.outgoingTime,
				itineraries: offerDetails.offer.itineraries,

				transport_type: TransportType.Airplane,
				product_owner: 'kiwi',
				integration: 'Kiwi',
				booking_url: offerDetails.offer.transport_item.bookingLink,
				booking_token: offerDetails.offer.transport_item.bookingToken,
				bags:
					offerDetails.optionCategories[OptionCategoryTypes.addons][
						'cabinBaggage'
					] +
					offerDetails.optionCategories[OptionCategoryTypes.addons][
						'checkedBaggage'
					],
				passengers: getPassengersForSearch(offerDetails.optionCategories),
				trip_type: offerDetails.tripType.value,
				product_location: offerDetails.offer.transport_item.productLocation, // possible to get from offer only for air travel
				duration: offerDetails.offer.duration
			} as ITransportWishlistItem
		};

		return createWishlistItem(item);
	};

	return { addToWishlist, createAirTransportWishlistItem, addedWishlistItem };
};

export default useAddTransportToWishlist;
