import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import NavArrowIcon from 'assets/arrow.svg';

const IconWrapper = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ disabled }) =>
		disabled ? 'rgba(255, 255, 255, 0.4)' : '#fff'};
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	border-radius: 50%;
	position: relative;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	> svg path {
		fill: #342245;
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.6);
	}

	${({ right }) =>
		right &&
		`
        > svg  {
            transform: rotate(180deg);
	    }
        
    `}
`;

interface IProps {
	right?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

const ArrowButton: FunctionalComponent<IProps> = ({
	right = false,
	disabled = false,
	onClick
}) => {
	return (
		<IconWrapper right={right} disabled={disabled} onClick={onClick}>
			<NavArrowIcon />
		</IconWrapper>
	);
};

export default ArrowButton;
