import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import { Image } from 'redesigntypes/ActivityModel';
import { FontWeight } from 'styles/defaultTheme';

import CloseIcon from 'assets/close.svg';

import ArrowButton from './components/ArrowButton';

const StyledCarouselWrapper = styled.div`
	position: relative;
	height: 100%;
	.BrainhubCarousel__container {
		height: 100%;
	}
	.BrainhubCarousel {
		height: 100%;
	}
	.BrainhubCarousel .BrainhubCarousel__trackContainer {
		height: 100%;
	}

	.BrainhubCarousel__track {
		height: 100%;
	}
`;

const ImageWrapper = styled.div`
	height: 100%;
	display: flex;
	max-width: 100%;
`;

const StyledImage = styled.img`
	margin-left: auto;
	margin-right: auto;
	align-self: center;
	object-fit: contain;
	border-radius: 5px;
	max-width: 100%;
	max-height: 100%;
`;

const LeftArrowContainer = styled.div`
	position: absolute;
	left: 1.5rem;
	z-index: 150;
	top: 50%;
	transform: translateY(-50%);
`;

const RightArrowContainer = styled.div`
	position: absolute;
	right: 1.5rem;
	z-index: 150;
	top: 50%;
	transform: translateY(-50%);
`;

export const Pagination = styled.div`
	display: inline-flex;
	align-items: center;
	border-radius: 0.25rem;
	background: linear-gradient(
		92.97deg,
		#ffffff 9.27%,
		rgba(255, 255, 255, 0) 146.57%
	);
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	backdrop-filter: blur(12.5px);

	border-radius: 4px;
	padding: 0.625rem 2rem;
	position: absolute;
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);
	text-transform: uppercase;
	font-weight: ${FontWeight.bold};
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ disabled }) =>
		disabled ? 'rgba(255, 255, 255, 0.4)' : '#fff'};
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	border-radius: 50%;
	> svg path {
		fill: #000;
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.6);
	}
`;

interface IProps {
	imageUrls: string[];
	onClose: () => void;
}

const ImageGallery: FunctionalComponent<IProps> = ({ imageUrls, onClose }) => {
	const { t } = useIntl('common.imageGallery');
	const [currentIndex, setCurrentIndex] = useState(0);

	const goBack = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex => currentIndex - 1);
		} else {
			setCurrentIndex(imageUrls.length - 1);
		}
	};

	const goForward = () => {
		if (currentIndex < imageUrls.length - 1) {
			setCurrentIndex(currentIndex => currentIndex + 1);
		} else {
			setCurrentIndex(0);
		}
	};

	return (
		<StyledCarouselWrapper>
			<LeftArrowContainer>
				<ArrowButton onClick={goBack} disabled={!imageUrls.length} />
			</LeftArrowContainer>
			<Carousel value={currentIndex} onChange={setCurrentIndex}>
				{imageUrls.map(i => (
					<ImageWrapper>
						<StyledImage src={i} />
					</ImageWrapper>
				))}
			</Carousel>
			<Pagination>
				{currentIndex + 1} / {imageUrls.length} {t('photos')}
			</Pagination>
			<RightArrowContainer>
				<ArrowButton right onClick={goForward} disabled={!imageUrls.length} />
			</RightArrowContainer>
			<CloseButton onClick={onClose}>
				<CloseIcon />
			</CloseButton>
		</StyledCarouselWrapper>
	);
};

export default ImageGallery;
