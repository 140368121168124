import { FunctionalComponent } from 'preact';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import AccountMenu from 'redesigncomponents/AccountMenu';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import Footer from 'redesigncomponents/Footer';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { Colors } from 'styles/defaultTheme';

import SingleTravelDetailsCard from './components/SingleTravelDetails';
import {
	CardsWrapper,
	ContentWrapper,
	Divider,
	LoaderContainer,
	MainHeading,
	YearInfo
} from './components/StyledComponents';
import useTravelHistoryDetails from './useTravelHistoryDetails';

interface IProps {}

const TravelHistory: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('travels');
	const { sortedTravelPlans, loading } = useTravelHistoryDetails();

	return (
		<MainWrapper>
			<BodyWrapper topAlign>
				<ContentWrapper>
					<AccountMenu></AccountMenu>
					<Divider></Divider>
					<MainHeading>{t('travels')}</MainHeading>
					{loading ? (
						<LoaderContainer>
							<ThreeDotLoader color={Colors.V900} size={40} />
						</LoaderContainer>
					) : (
						Object.entries(sortedTravelPlans)
							.reverse()
							.map((travelPlans: any) => {
								return (
									<>
										<YearInfo>{travelPlans[0]}</YearInfo>
										{travelPlans[1].map(travelPlan => {
											return (
												<CardsWrapper>
													<SingleTravelDetailsCard
														travelPlan={{
															...travelPlan
														}}
													/>
												</CardsWrapper>
											);
										})}
									</>
								);
							})
					)}
				</ContentWrapper>
			</BodyWrapper>
			<Footer />
		</MainWrapper>
	);
};

export default TravelHistory;
