import { useReactiveVar } from '@apollo/client';

import { Image, MerchandiseModel } from 'redesigntypes/ActivityModel';

import { initialMerchandiseDetailsValue, merchandiseDetailsVar } from '.';

const useMerchandiseDetailsCache = () => {
	const { merchandise, heroImage, quantities } = useReactiveVar(
		merchandiseDetailsVar
	);

	const setMerchandiseDetails = (
		merchandise: MerchandiseModel,
		heroImage: Image
	) => {
		merchandiseDetailsVar({
			...initialMerchandiseDetailsValue,
			merchandise,
			heroImage
		});
	};

	const updateQuantity = (id: string, quantity: number) => {
		const oldValue = merchandiseDetailsVar();

		merchandiseDetailsVar({
			...oldValue,
			quantities: {
				...oldValue.quantities,
				[id]: quantity
			}
		});
	};

	const setQuantities = quantities => {
		merchandiseDetailsVar({
			...merchandiseDetailsVar(),
			quantities
		});
	};

	const clearMerchandiseDetailsCache = () => {
		merchandiseDetailsVar({
			...initialMerchandiseDetailsValue
		});
	};

	return {
		merchandise,
		heroImage,
		quantities,
		setMerchandiseDetails,
		updateQuantity,
		setQuantities,
		clearMerchandiseDetailsCache
	};
};

export default useMerchandiseDetailsCache;
