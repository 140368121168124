import { useMutation } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import { GET_INTEGRATIONS_MERCHANDISE } from 'apollo/myDesti/mutations';
import useIntl from 'hooks/useIntl';
import Toast from 'redesigncomponents/Toast';
import { MerchandiseModel } from 'redesigntypes/ActivityModel';

import useMerchandiseDetailsCache from './cache/useMerchandiseDetailsCache';

const useMerchandiseDetails = (id: string) => {
	const { t: merchandiseDetailsTranslations } = useIntl('merchandiseDetails');
	const {
		merchandise,
		heroImage,
		setMerchandiseDetails,
		clearMerchandiseDetailsCache
	} = useMerchandiseDetailsCache();

	const [loadMerchandiseDetails, { loading, called }] = useMutation<{
		getMerchandiseDetails;
	}>(GET_INTEGRATIONS_MERCHANDISE, {
		onCompleted: ({ getMerchandiseDetails }) => {
			const merchandiseData: MerchandiseModel = getMerchandiseDetails;

			const heroImage = merchandiseData?.images?.[0];

			setMerchandiseDetails(merchandiseData, heroImage);
		},
		onError: () => {
			Toast.error(merchandiseDetailsTranslations('itemCouldNotBeLoaded'));
		}
	});

	const getMerchandise = (id: string) => {
		loadMerchandiseDetails({
			variables: {
				id: id
			}
		});
	};

	const selectedMerchandiseDetails = useMemo(() => {
		return merchandise?.id === id ? merchandise : undefined;
	}, [merchandise]);

	return {
		loading,
		called,
		merchandise: selectedMerchandiseDetails,
		heroImage,
		getMerchandise,
		clearMerchandiseDetailsCache
	};
};

export default useMerchandiseDetails;
