import { FunctionalComponent, Ref } from 'preact';
import { forwardRef } from 'preact/compat';
import InputMask from 'react-input-mask';

import styled, { css } from 'styled-components';

import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';

export const commonLabelStyles = css`
	position: absolute;
	padding: 0 5px;
	left: 10px;
	cursor: text;
	color: #857a8f;
	max-width: 85%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-color: #fff;
	pointer-events: none;
`;

const InputWrapper = styled.div`
	position: relative;
	opacity: ${props => (props.disabled ? '0.4' : '1')};
	pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
	width: 100%;
`;

const TextInput = styled.input`
	border-radius: 4px;
	font-size: 1rem;
	outline: none;
	height: 3.5rem;
	color: #2c2c2c;
	padding: ${({ smallPadding }) => (smallPadding ? '13px' : '15px')};
	width: 100%;
	border: ${props => (props.error ? '2px solid #b3261e' : '1px solid #91889b')};
	&:focus ~ label,
	&:not(:placeholder-shown)&:not(:focus) ~ label {
		top: -8px;
		font-size: 11px;
		left: 10px;
		color: ${props => (props.error ? '#b3261e' : '#857a8f')};
		:after {
			content: '';
			color: #857a8f;
		}
	}
	// &:placeholder-shown - placeholder exists and is shown
	// &:not(:placeholder-shown) - placeholder exists, but is not shown because the value exists
	${({ placeholder }) =>
		placeholder !== ' ' &&
		`&:placeholder-shown ~ label {
		top: -8px;
		font-size: 11px;
		left: 10px;
		color: ${props => (props.error ? '#b3261e' : '#857a8f')};
		:after {
			content: '';
			color: #857a8f;
		}
	}`}

	&::placeholder {
		font-size: 1rem;
	}

	&:focus {
		box-shadow: ${props =>
			props.error
				? '0px 0px 0px 1px #b3261e inset'
				: '0px 0px 0px 1px #91889b inset'};
	}

	${({ secondary }) =>
		secondary &&
		`
		background-color: #f7f6f8;

	`}
`;

const TextLabel = styled.label`
	${commonLabelStyles};
	top: ${({ smallPadding }) => (smallPadding ? '13px' : '17px')};
	transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
	${({ secondary }) =>
		secondary &&
		`
		background-color: #f7f6f8;

	`}
	${({ required }) =>
		required &&
		`
			:after {
				content: ' *';
				color: #857a8f;
			}
	`}
`;

const InfoText = styled.span`
	font-size: 11px;
	line-height: 14px;
	padding: 5px 5px 0 5px;
	color: ${props => (props.error ? '#b3261e' : '#2c2c2c')};
	display: inline-block;
`;

const AddonsWrapper = styled.span`
	display: flex;
	flex-direction: row;
	justify-items: center;
	align-items: center;
	position: absolute;
	top: 12px;
	right: 12px;
	gap: 0.7rem;
`;

const InputIconWrapper = styled.div`
	display: flex;
	position: absolute;
	right: 1rem;
	top: 1rem;
`;

interface IBasicProps {
	id?: string;
	active?: boolean;
	label?: string;
	error?: string;
	style?: React.CSSProperties;
	secondary?: boolean;
	info?: string;
	disabled?: boolean;
	placeholder?: string;
	required?: boolean;
	inputProps?: any;
	type?: string;
	autoFocus?: boolean;
	withIcon?: boolean;
	icon?: any;
	Icon?: any;
	smallPadding?: boolean;
	onClick?: () => void;
	loading?: boolean;
	mask?: string;
}

interface IProps extends IBasicProps {
	inputRef?: Ref<unknown>;
}

const StyledTextInput: FunctionalComponent<IProps> = forwardRef(
	(
		{
			id,
			label,
			active,
			error,
			style,
			secondary,
			info,
			disabled,
			required,
			inputProps,
			type,
			autoFocus,
			icon,
			Icon,
			onClick,
			smallPadding,
			loading,
			withIcon,
			mask
		}: IProps,
		inputRef
	) => {
		const renderTextInput = props => {
			return (
				<TextInput
					ref={inputRef}
					type={type}
					error={error}
					style={style}
					required={required}
					autoFocus={autoFocus}
					{...inputProps}
					{...props}
					placeholder={
						inputProps?.placeholder
							? `${inputProps.placeholder}${required ? ' *' : ''}`
							: ' '
					}
					active={active}
					smallPadding={smallPadding}
					secondary={secondary}
				/>
			);
		};

		return (
			<InputWrapper onClick={onClick} disabled={disabled}>
				{mask ? (
					<InputMask mask={mask} maskChar={null} {...inputProps}>
						{props => renderTextInput(props)}
					</InputMask>
				) : (
					renderTextInput({})
				)}

				{(icon || loading) && (
					<AddonsWrapper>
						{loading && <ThreeDotLoader size={24} color="#342245" />}
						{icon && icon}
					</AddonsWrapper>
				)}
				<TextLabel
					secondary={secondary}
					smallPadding={smallPadding}
					required={required}
				>
					{label}
				</TextLabel>
				{withIcon ? (
					<InputIconWrapper>
						<Icon></Icon>
					</InputIconWrapper>
				) : null}

				{(error || info) && <InfoText error={error}>{error || info}</InfoText>}
			</InputWrapper>
		);
	}
);

export default StyledTextInput;
