import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import { ITravelPlan } from 'redesigncache/checkout/types';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import AccountMenu from 'redesigncomponents/AccountMenu';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';
import { Colors } from 'styles/defaultTheme';

import SingleTravelDetailsCard from './components/SingleTravelDetails';
import {
	CardsWrapper,
	ContentWrapper,
	Divider,
	InstructionText,
	LoaderContainer,
	MainHeading,
	StyledBodyWrapper,
	YearInfo
} from './components/StyledComponents';
import useTravelPlannerDetails from './useTravelPlannerDetails';

const TravelPlanner: FunctionalComponent = () => {
	const { t } = useIntl('travelPlanner');
	const [redirectToGetGestOpen, setRedirectToGetGestOpen] = useToggle();
	const { sortedTravelPlans, loading, handleItemRemove } =
		useTravelPlannerDetails();
	const { addAllToCheckout } = useCheckout();
	const [selectedPlans, setSelectedPlans] = useState<ITravelPlan[]>([]);

	const handleButtonClick = () => {
		setRedirectToGetGestOpen(true);
	};

	const determineDisabledState = () => {
		// TODO write this in a more readable way
		return !Object.values(sortedTravelPlans).some(
			(travelPlans: ITravelPlan[]) => {
				return travelPlans.some(travelPlan => {
					return selectedPlans.some(plan => {
						return plan.id === travelPlan.id;
					});
				});
			}
		);
	};

	const addSelectedPlan = (plan: ITravelPlan) => {
		setSelectedPlans(curr => [...curr, plan]);
	};

	const removeSelectedPlan = (id: string) => {
		const index = selectedPlans.findIndex(el => el.id === id);
		const newPlans = [...selectedPlans];
		newPlans.splice(index, 1);
		setSelectedPlans(newPlans);
	};

	const addSelectedPlansToCheckout = () => {
		addAllToCheckout(selectedPlans);
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<ContentWrapper>
					<AccountMenu />
					<Divider></Divider>
					<MainHeading>{t('travelPlanner')}</MainHeading>
					<InstructionText>
						{t('instructionText')} {t('travelPlanDeleteWarningText')}
					</InstructionText>
					{loading ? (
						<LoaderContainer>
							<ThreeDotLoader color={Colors.V900} size={40} />
						</LoaderContainer>
					) : Object.entries(sortedTravelPlans).length > 0 ? (
						Object.entries(sortedTravelPlans).map((travelPlans: any) => {
							return (
								<>
									<YearInfo>{travelPlans[0]}</YearInfo>
									{travelPlans[1].map(travelPlan => {
										return (
											<CardsWrapper>
												<SingleTravelDetailsCard
													travelPlan={{
														...travelPlan
													}}
													addSelectedPlan={addSelectedPlan}
													removeSelectedPlan={removeSelectedPlan}
													handleItemRemove={handleItemRemove}
												/>
											</CardsWrapper>
										);
									})}
								</>
							);
						})
					) : (
						<InstructionText>{t('emptyListMessage')}</InstructionText>
					)}
				</ContentWrapper>
			</StyledBodyWrapper>
			<Footer>
				<Button
					title={t('getgestButton')}
					buttonStyleType={ButtonStyleTypes.primary}
					onClick={handleButtonClick}
					disabled={determineDisabledState()}
				></Button>
			</Footer>
			{redirectToGetGestOpen && (
				<GetGestRedirectModal
					open={redirectToGetGestOpen}
					handleCheckoutActions={addSelectedPlansToCheckout}
					toggleOpen={setRedirectToGetGestOpen}
				/>
			)}
		</MainWrapper>
	);
};

export default TravelPlanner;
