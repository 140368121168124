import styled from 'styled-components';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	Colors,
	FontSize,
	FontWeight,
	BodyWrapperHeight
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: #979797;
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContentWrapper = styled.div`
	margin: auto;
`;

export const MenuItem = styled.div`
	width: 100%;
	background: ${Colors.V0};
	border-radius: 0.25rem;
	padding: 2.8125rem 2.1875rem;
	text-align: center;
	&:first-of-type {
		margin-bottom: 1.25rem;
	}
`;

export const ButtonContainer = styled.div`
	text-align: center;
	margin-top: 1.25rem;
`;

export const ItemHeader = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.normal};
	margin-bottom: 0.75rem;
`;

export const ItemInfoText = styled.p``;

export const Bold = styled.span`
	font-weight: ${FontWeight.bold};
`;
