import { FunctionalComponent } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { useEffect } from 'preact/hooks';
import { Route, Switch, useHistory } from 'react-router-dom';

import { usePrevious } from 'hooks/usePrevious';
import { WidgetFlow, WidgetFlowType } from 'redesigncache/bookingFlow/cache';
import { Paths } from 'router';

const AffiliateLink = lazy(() => import('./flows/AffiliateLink'));
const Main = lazy(() => import('./flows/Main'));

type Props = {
	flow: WidgetFlow;
};

const Router: FunctionalComponent<Props> = ({ flow }) => {
	const history = useHistory();
	const previousFlow = usePrevious(flow);

	useEffect(() => {
		if (
			flow.type === WidgetFlowType.AffiliateLink &&
			(!previousFlow ||
				previousFlow.type === WidgetFlowType.Main ||
				flow.config.id !== previousFlow.config.id)
		) {
			history.push(`/affiliate-link/${flow.config.id}/product-type-redirect`);
		} else if (
			flow.type === WidgetFlowType.Main &&
			(!previousFlow || previousFlow.type === WidgetFlowType.AffiliateLink)
		) {
			history.replace(Paths.Main);
		}
	}, [flow, previousFlow]);

	return (
		<Suspense fallback={<></>}>
			<Switch>
				<Route path="/affiliate-link">
					<AffiliateLink />
				</Route>
				<Route path="/">
					<Main />
				</Route>
			</Switch>
		</Suspense>
	);
};

export default Router;
