import validate from 'validate.js';

import { BookingFieldTypes } from 'redesigntypes/ActivityModel';

import { getPhoneConstraints } from './constraints';

const validateRequired = (required: boolean, value, t) => {
	return required
		? validate.single(value, {
				presence: { message: t('fieldRequired') }
		  })
		: undefined;
};

export const validateField = (
	value,
	type: BookingFieldTypes,
	required: boolean,
	t
) => {
	switch (type) {
		case BookingFieldTypes.Phone: {
			const withoutMask = value ? value.replace('+', '') : '';
			if (withoutMask || required) {
				return validate.single(withoutMask, getPhoneConstraints(t));
			}
			return;
		}
		default:
			return validateRequired(required, value, t);
	}
};
