import { makeVar } from '@apollo/client';
import dayjs from 'dayjs';

export type OptionType = {
  value: string;
  label: string;
};

export type SignUpFormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  country: OptionType;
  gender: OptionType;
  dateOfBirth: Date;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
};

export const maxDateOfBirth = dayjs()
  .subtract(1, 'day')
  .subtract(18, 'year')
  .toDate();

export const initialSignUpFormValues: SignUpFormValuesType = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  country: undefined,
  gender: undefined,
  dateOfBirth: undefined,
  password: undefined,
  confirmPassword: undefined,
  termsOfUse: false,
};

export const signUpFormVar = makeVar(initialSignUpFormValues);
