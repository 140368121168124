import styled from 'styled-components';

import { Colors, FontWeight } from 'styles/defaultTheme';

export const CounterWrapper = styled.div`
	display: inline-flex;
	background-color: ${Colors.V0};
	gap: 0.8125rem;
	border: 1px solid ${Colors.V400};
	padding: 0.5rem;
	border-radius: 6.25rem;
	box-shadow: 0px 4px 4px rgba(158, 149, 166, 0.3);
`;

export const CounterButton = styled.button`
	background-color: ${Colors.V30};
	border: 1px solid ${Colors.V50};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 1.375rem;
	height: 1.375rem;
	cursor: pointer;
`;

export const CounterValue = styled.span`
	font-weight: ${FontWeight.semibold};
`;

export const CounterInputValue = styled.input`
	font-weight: ${FontWeight.semibold};
	border: none;
	outline: none;
	width: 35px;
	text-align: center;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	appearance: textfield;
	-moz-appearance: textfield;
`;
