import { WishlistItemTypes } from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../../TravelPlan/useTravelPlan';
import useBookAccommodation from './useBookAccommodation';
import useBookActivity from './useBookActivity';
import useBookTransportation from './useBookTransportation';
import useMerchandisePurchase from './useMerchandisePurchase';

const useBookItem = (item: IComposedTravelPlanItem, user) => {
	const { createActivityBooking } = useBookActivity(item, user);
	const { createMerchandisePurchase } = useMerchandisePurchase(item, user);
	const { createAccommodationBooking } = useBookAccommodation(item);
	const { createTransportationBooking } = useBookTransportation(item);

	const bookItem = async (
		formValues,
		productOwnerGlobalId: string,
		paymentIntentId: string
	) => {
		if (item.type === WishlistItemTypes.ACTIVITY) {
			return await createActivityBooking(
				formValues,
				productOwnerGlobalId,
				paymentIntentId
			)
				.then(response => {
					return response;
				})
				.catch(err => {
					console.error(err);
				});
		} else if (item.type === WishlistItemTypes.MERCHANDISE) {
			return await createMerchandisePurchase(
				formValues,
				productOwnerGlobalId,
				paymentIntentId
			)
				.then(response => {
					return response;
				})
				.catch(err => {
					console.error(err);
				});
		} else if (item.type === WishlistItemTypes.STAY) {
			return await createAccommodationBooking(
				formValues,
				productOwnerGlobalId,
				paymentIntentId
			)
				.then(response => {
					return response;
				})
				.catch(err => {
					console.error(err);
				});
		} else if (item.type === WishlistItemTypes.TRANSPORT) {
			return await createTransportationBooking(
				formValues,
				productOwnerGlobalId,
				paymentIntentId
			);
		}
	};

	return { bookItem };
};

export default useBookItem;
