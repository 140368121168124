import { components } from 'react-select';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';

import TrashIcon from 'assets/trash.svg';

const StyledMenu = styled.div`
	background-color: #efedf0;
	max-height: 300px;
`;

const StyledTrashIcon = styled(TrashIcon)`
	path {
		fill: #7f7f7f;

		${props =>
			props.isEmpty &&
			`
    fill: #c4c4c4;
    `}
	}
`;

const MenuFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 4px 8px 4px;
	border-top: 1px solid #91889b;
	background-color: #efedf0;
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

const StyledMenuButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const StyledMenuButton = styled.button`
	outline: none;
	border: none;
	cursor: pointer;
	font-size: 11px;
	font-weight: 600;
`;

const StyledMenuLeftButton = styled(StyledMenuButton)`
	color: #7f7f7f;
	${props =>
		props.isAllSelected &&
		`
    color: #c4c4c4;
    `}
`;

const StyledMenuRightButton = styled(StyledMenuButton)`
	color: #7f7f7f;
	${props =>
		props.isEmpty &&
		`
    color: #c4c4c4;
    `}
`;

const Menu = props => {
	const {
		value,
		fetchingData,
		selectAll,
		clearAll,
		enableSelectAndClearButtons
	} = props.selectProps;
	const isAllSelected = value.length === props.options.length;
	const isEmpty = value.length === 0;

	const { t } = useIntl('common.Components.FieldMultiselect');

	return (
		<>
			<components.Menu {...props}>
				<StyledMenu>
					{fetchingData ? (
						<span className="fetching">Fetching data...</span>
					) : (
						<div>{props.children}</div>
					)}
					{enableSelectAndClearButtons && (
						<MenuFooter>
							<StyledMenuLeftButton
								isAllSelected={isAllSelected}
								onClick={selectAll}
							>
								{t('selectAll')}
							</StyledMenuLeftButton>
							<StyledMenuButtonWrapper onClick={clearAll}>
								<StyledTrashIcon isEmpty={isEmpty} />
								<StyledMenuRightButton isEmpty={isEmpty}>
									{t('clearSelection')}
								</StyledMenuRightButton>
							</StyledMenuButtonWrapper>
						</MenuFooter>
					)}
				</StyledMenu>
			</components.Menu>
		</>
	);
};

export default Menu;
