import { FunctionalComponent } from 'preact';

import dayjs from 'dayjs';

import DestinationPath from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer/components/TrainTransportOfferItem/components/DestinationPath';
import { SectionContainer } from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer/components/TrainTransportOfferItem/components/StyledComponents';
import { OfferDetails } from 'redesignscreens/Main/Transport/TrainTransportList/types';

import DestinationToArrow from 'assets/destination-to-arrow.svg';
import LocationIcon from 'assets/location.svg';

import {
	DestinationSectionWrapper,
	FromTitle,
	LocationIconWrapper,
	SectionHeader,
	SectionSubtitle,
	SectionTitle,
	ToTitle
} from '../StyledComponents';
import useTrainTransportItemPreview from './useTrainTransportItemPreview';

interface IProps {
	offerDetails: OfferDetails;
}

const TransportItemPreview: FunctionalComponent<IProps> = ({
	offerDetails
}) => {
	const { previewDetails } = useTrainTransportItemPreview(offerDetails);

	return (
		<DestinationSectionWrapper>
			<SectionHeader>
				<SectionTitle>
					<LocationIconWrapper>
						<LocationIcon></LocationIcon>
					</LocationIconWrapper>
					<FromTitle>{previewDetails.fromLocation}</FromTitle>
					<DestinationToArrow></DestinationToArrow>
					<ToTitle>{previewDetails.toLocation}</ToTitle>
				</SectionTitle>
				<SectionSubtitle>
					{previewDetails.offerTravelPeriodSummaryString}
				</SectionSubtitle>
			</SectionHeader>
			{previewDetails?.outgoingItineraries.map(itinierary => (
				<SectionContainer>
					<DestinationPath
						darkerBckg
						fromTitle={`${dayjs(itinierary?.outgoingTime).format('HH:mm')} ${
							itinierary?.outgoingStation
						}`}
						toTitle={`${dayjs(itinierary?.arrivalTime).format('HH:mm')} ${
							itinierary?.arrivalStation
						}`}
						itineraries={itinierary.segments}
					></DestinationPath>
				</SectionContainer>
			))}
		</DestinationSectionWrapper>
	);
};

export default TransportItemPreview;
