import { useEffect } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';

import ArrowIcon from 'assets/icons/transport/direction-arrow-right.svg';

import TrainTransportListContainer from './TrainTransportListContainer';
import useTransportSearchOptionsCache from './TrainTransportListContainer/cache/useTransportSearchCache';
import { StyledBodyWrapper } from './TrainTransportListContainer/components/StyledComponents';

const TrainTransportList = () => {
	const { t: transportSearchTranslations } = useIntl('transportList');
	const history = useHistory();
	const { travelPeriod: initialTravelPeriod } = useDestinationAndPeriod();
	const { travelPeriod, isSelectedOfferValid, setTravelPeriod } =
		useTransportSearchOptionsCache();

	useEffect(() => {
		setTravelPeriod([initialTravelPeriod[0], initialTravelPeriod[1]]);
	}, [initialTravelPeriod]);

	const goToTransportDetails = () => {
		history.push(history.push('/transport/trainbus/details'));
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<TrainTransportListContainer
					travelPeriod={travelPeriod as [Date, Date]}
				/>
			</StyledBodyWrapper>
			<Footer>
				{isSelectedOfferValid && (
					<Button
						title={transportSearchTranslations(
							'goToTransportDetailsButtonTitle'
						)}
						Icon={ArrowIcon}
						onClick={goToTransportDetails}
					/>
				)}
			</Footer>
		</MainWrapper>
	);
};

export default TrainTransportList;
