import { FunctionalComponent } from 'preact';

import {
	Input,
	InputLabel,
	PriceContainer,
	PriceValue,
	RadioContainer,
	SingleRadio
} from '../components/StyledComponents';

interface IProps {
	id?: string;
	radioLabel?: string;
	infoLabel?: string;
	onClick: () => void;
	checked?: boolean;
}

const RadioButton: FunctionalComponent<IProps> = ({
	id = undefined,
	onClick,
	radioLabel,
	infoLabel,
	checked
}) => {
	return (
		<RadioContainer>
			<SingleRadio>
				<Input
					id={id}
					type="radio"
					checked={checked}
					onChange={() => onClick()}
				></Input>
				<InputLabel>{radioLabel}</InputLabel>
			</SingleRadio>
			<PriceContainer>
				<PriceValue>{infoLabel}</PriceValue>
			</PriceContainer>
		</RadioContainer>
	);
};

export default RadioButton;
