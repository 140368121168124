import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { createClient } from 'graphql-ws';
import storage from 'local-storage-fallback';

const createWsLink = (url: string) =>
	new GraphQLWsLink(
		createClient({
			url,
			connectionParams: async () => {
				const token = storage.getItem('DESTI1_REDESIGN:TOKEN');
				if (token) {
					return {
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
				}

				return {};
			}
		})
	);

export default createWsLink;
