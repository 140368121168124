import { useMemo } from 'preact/hooks';

import { ITravelPlan } from 'redesigncache/checkout/types';
import useWishlistItemDetails from 'redesigncache/wishlist/useWishlistItemDetails';

const useSingleTravelDetails = (travelPlan: ITravelPlan) => {
	const { getWishlistItemDetails } = useWishlistItemDetails();

	const travelPlanItems = useMemo(() => {
		const travelPlanItems = [];
		if (travelPlan?.travelPlanItems?.length > 0) {
			travelPlan?.travelPlanItems.map(travelPlanItem => {
				travelPlanItems.push(getWishlistItemDetails(travelPlanItem));
			});
		}
		return travelPlanItems;
	}, [travelPlan]);

	return {
		travelPlanItems
	};
};

export default useSingleTravelDetails;
