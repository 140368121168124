import { FunctionalComponent } from 'preact';

import AffiliateLinkHeader from 'redesigncomponents/Header/AffiliateLinkHeader';
import StyledToastContainer from 'redesigncomponents/Toast/ToastContainer';

interface IProps {
	currentRouteSlug?: string;
}

const AffiliateLinkWrapper: FunctionalComponent<IProps> = ({
	children,
	currentRouteSlug
}) => {
	return (
		<>
			<AffiliateLinkHeader currentRouteSlug={currentRouteSlug} />
			<StyledToastContainer />
			{children}
		</>
	);
};

export default AffiliateLinkWrapper;
