import { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import { WishlistItem } from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonContainer,
	LogoContainer,
	Modal,
	ModalInfoText
} from 'redesigncomponents/Modal';
import { Paths } from 'router';
import { FontSize, FontWeight } from 'styles/defaultTheme';

import FavoritesIcon from 'assets/favorites.svg';

import useTransportWishlistItemRedirectModal from './useTransportWishlistItemRedirectModal';

export const TitleText = styled.p`
	font-size: 18px;
	margin: 1rem 1rem 0 1rem;
	text-transform: uppercase;
`;

export const TripTypeText = styled.p`
	font-size: ${FontSize.xsSize};
	font-weight: ${FontWeight.semibold};
`;

export const InfoText = styled.p`
	margin: 0.5rem 0;
	${({ bold }) => bold && `font-weight: ${FontWeight.bold}`};
	display: flex;
	justify-content: center;
	gap: 0.75rem;
`;

type Props = {
	item: WishlistItem;
	open: boolean;
	toggleOpen: () => void;
};

const TransportAddedToWishlistRedirectModal: FunctionalComponent<Props> = ({
	item,
	open,
	toggleOpen
}) => {
	const { t } = useIntl('transportList.transportWishlistItemDetails');
	const history = useHistory();
	const { transportWishlistItemDetails } =
		useTransportWishlistItemRedirectModal(item);
	const goToWishlist = () => history.push(Paths.Wishlist);
	const goBackToStartingScreen = () => history.push(Paths.Main);
	
	return (
		<Modal isOpen={open} onClose={toggleOpen}>
			<LogoContainer>
				<FavoritesIcon />
			</LogoContainer>
			<ModalInfoText>
				<div>{t('youveAddedTitle')}</div>
				<TitleText>{transportWishlistItemDetails.title}</TitleText>
				<TripTypeText>{transportWishlistItemDetails.tripType}</TripTypeText>
				<InfoText>
					<p>{transportWishlistItemDetails.departureDate}</p>
					<p>{transportWishlistItemDetails.returnDate}</p>
				</InfoText>
				<InfoText bold>{transportWishlistItemDetails.total}</InfoText>
			</ModalInfoText>
			<ButtonContainer>
				<Button
					title={t('continueExploringButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goBackToStartingScreen}
				></Button>
				<Button
					title={t('goToWishlistButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goToWishlist}
				></Button>
			</ButtonContainer>
		</Modal>
	);
};

export default TransportAddedToWishlistRedirectModal;
