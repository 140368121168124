import { FunctionalComponent } from 'preact';
import { useHistory, useLocation } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import { Paths } from 'router';

import PlannerIcon from 'assets/icons/account/account-planner.svg';
import TravelIcon from 'assets/icons/account/account-travels.svg';
import UserIcon from 'assets/icons/account/account-user.svg';

import {
	IconWrapper,
	ItemText,
	MenuItem,
	MenuWrapper
} from './components/StyledComponents';

type Props = {};

const AccountMenu: FunctionalComponent<Props> = ({ }) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { t } = useIntl('account.accountMenu');

	const goToAccountDetails = () => history.push(Paths.AccountDetails);
	const goToTravelHistory = () => history.push(Paths.TravelHistory);
	const goToTravelPlanner = () => history.push(Paths.TravelPlanner);

	const isRouteActive = (route: string) => pathname === route;

	return (
		<MenuWrapper>
			<MenuItem onClick={goToAccountDetails}>
				<IconWrapper
					active={isRouteActive(Paths.AccountDetails)}
				>
					<UserIcon></UserIcon>
				</IconWrapper>
				<ItemText>{t('accountDetails')}</ItemText>
			</MenuItem>

			<MenuItem onClick={goToTravelHistory}>
				<IconWrapper
					active={isRouteActive(Paths.TravelHistory)}
				>
					<TravelIcon></TravelIcon>
				</IconWrapper>
				<ItemText>{t('yourTravels')}</ItemText>
			</MenuItem>
			<MenuItem onClick={goToTravelPlanner}>
				<IconWrapper
					active={isRouteActive(Paths.TravelPlanner)}
				>
					<PlannerIcon style={{ marginRight: -7 }}></PlannerIcon>
				</IconWrapper>
				<ItemText>{t('travelPlanner')}</ItemText>
			</MenuItem>
		</MenuWrapper>
	);
};

export default AccountMenu;
