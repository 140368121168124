import BodyWrapper from 'redesigncomponents/BodyWrapper';
import styled from 'styled-components';
import { Colors, BodyWrapperHeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
  as: 'div',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${Colors.V30};
  padding: 3px 0 3px 0;
  height: ${BodyWrapperHeight.base};
  overflow-y: auto;
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: 1fr;
    height: auto;
    padding: 1px 0 1px 0;
	}
`;

export const LeftSide = styled.div`
  background: ${Colors.V0};
  border-radius: 0 4px 4px 0;
  padding: 1.25rem;
  height: 100%;
	@media ${DeviceBreakpoints.small} {
		border-radius: 0;
    padding: 0.5rem;
    > div {
      margin-bottom: 0.75rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
	}
`;

export const RightSide = styled.div`
  padding: 1rem 1.25rem;
  height: 100%;
	@media ${DeviceBreakpoints.small} {
    padding: 0.5rem;
	}
`;
