import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const secondsToDMH = (seconds: number) => {
	const durationS = dayjs.duration(seconds, 's');

	return durationS.days() > 0
		? durationS.format('DD[d] HH[h] mm[min]')
		: durationS.hours() > 0
		? durationS.format('HH[h] mm[min]')
		: durationS.format('mm[min]');
};

export default secondsToDMH;
