import styled from 'styled-components';

import { Colors, FontSize } from 'styles/defaultTheme';

export const ItemWrapper = styled.div`
	color: ${Colors.V900};
	display: flex;
	align-items: top;
	justify-content: space-between;
	padding: 0.4rem 0.25rem;
`;

export const Left = styled.div`
	display: flex;
	align-items: center;
	margin-right: 0.5rem;
`;

export const Right = styled.div``;

export const ItemTitle = styled.div`
	font-size: ${FontSize.base};
`;

export const ItemSubtitle = styled.div`
	font-size: ${FontSize.xsSize};
`;

export const TextWrapper = styled.div``;
