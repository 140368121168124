import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import { WidgetFlowType } from 'redesigncache/bookingFlow/cache';
import { hostConfigurationVar } from 'redesigncache/hostConfiguration/cache';
import { AffiliateLinkFlowRoutes, MainFlowRoutes, Paths } from 'router';

import HomeIcon from 'assets/home.svg';
import NavArrowIcon from 'assets/nav-arrow.svg';

import {
	Breadcrumb,
	BreadcrumbItem,
	HomeIconWrapper,
	Link,
	NavIconWrapper
} from './components/StyledComponents';

interface IProps {
	flowType?: WidgetFlowType;
	currentRouteSlug?: string;
}

const Breadcrumbs: FunctionalComponent<IProps> = ({
	flowType = WidgetFlowType.Main,
	currentRouteSlug
}) => {
	const { push } = useHistory();
	const params = useParams();
	const { path: pathPattern } = useRouteMatch();
	const hostConfiguration = useReactiveVar(hostConfigurationVar);

	const { t } = useIntl('screens');

	const routes = useMemo(
		() =>
			flowType === WidgetFlowType.AffiliateLink
				? AffiliateLinkFlowRoutes
				: MainFlowRoutes,
		[flowType]
	);

	const getRouteName = (path: string, nameKey: string, params) => {
		if (path === Paths.Main) {
			return hostConfiguration.name;
		} else if (path === pathPattern && currentRouteSlug) {
			return currentRouteSlug;
		}
		return params.slug || t(nameKey);
	};

	const crumbs = useMemo(() => {
		return routes
			.filter(({ path }) => pathPattern.includes(path))
			.map(({ path, nameKey, ...rest }, index, array) => ({
				path: Object.keys(params).length
					? Object.keys(params).reduce(
							(path, param) => path.replace(`:${param}`, params[param]),
							path
					  )
					: path,
				name: getRouteName(path, nameKey, params),
				onClick:
					index === array.length - 1
						? undefined
						: () => push(path, { breadcrumbNavigation: true }),
				...rest
			}));
	}, [pathPattern, params, currentRouteSlug]);

	return (
		<Breadcrumb>
			{crumbs.map((crumb, index) => {
				return (
					crumb.name && (
						<BreadcrumbItem
							active={
								index === crumbs.length - 1 ||
								(index === crumbs.length - 2 && !crumbs[crumbs.length - 1].name)
									? true
									: false
							}
							onClick={crumb.onClick}
						>
							{index === 0 && (
								<HomeIconWrapper>
									<HomeIcon></HomeIcon>
								</HomeIconWrapper>
							)}
							<Link>{crumb.name}</Link>
							<NavIconWrapper>
								<NavArrowIcon />
							</NavIconWrapper>
						</BreadcrumbItem>
					)
				);
			})}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
