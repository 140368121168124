import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'preact/hooks';

import useAirplaneTransportSortCodebookOptions from 'hooks/useAirplaneTransportSortOptions';
import useTransportTypeCodebookOptions from 'hooks/useTransportTypeOptions';
import { Option } from 'redesigntypes/Option';
import { TransportType } from 'types/enums';

import { transportSearchOptionsVar } from '.';
import { OfferDetails } from '../../types';
import { OptionCategoriesType } from '../components/OptionCategories/useOptionCategories';

const useTransportSearchOptionsCache = () => {
	const { airplaneTransportSortOptions } =
		useAirplaneTransportSortCodebookOptions();
	const { transportTypeOptions } = useTransportTypeCodebookOptions();
	const {
		fromLocation,
		toLocation,
		transportType,
		transportSort,
		tripType,
		airClass,
		travelPeriod,
		optionCategories,
		selectedTransportOffer
	} = useReactiveVar(transportSearchOptionsVar);

	useEffect(() => {
		if (airplaneTransportSortOptions?.length && !transportSort) {
			setTransportSort(airplaneTransportSortOptions?.[0]);
		}
		if (!travelPeriod?.every(d => d)) {
			setTravelPeriod([
				new Date(Date.now() + 60 * 60 * 1000),
				new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			]);
		}
	}, []);

	useEffect(() => {
		if (transportTypeOptions?.length && !transportType) {
			setTransportType(
				transportTypeOptions.find(tt => tt.value === TransportType.Airplane)
			);
		}
	}, [transportTypeOptions]);

	const initializeTransportSearchCache = (
		transportType,
		tripType,
		airClass,
		transportSort,
		optionCategories,
		toLocation,
		travelPeriod
	) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			transportType,
			tripType,
			airClass,
			transportSort,
			optionCategories,
			fromLocation: undefined,
			toLocation,
			travelPeriod,
			selectedTransportOffer: undefined
		});
	};

	const setTransportType = (transportType: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			transportType
		});
	};

	const setFromLocation = (fromLocation: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			fromLocation
		});
	};
	const setTravelPeriod = (travelPeriod: [Date, Date]) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			travelPeriod
		});
	};

	const setTripType = (tripType: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			tripType
		});
	};

	const setTransportSort = (transportSort: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			transportSort
		});
	};

	const setAirClass = (airClass: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			airClass
		});
	};

	const setOptionCategories = (optionCategories: OptionCategoriesType) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			optionCategories
		});
	};

	const resetTransportSearchOptionsCache = () => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			fromLocation: undefined,
			selectedTransportOffer: undefined
		});
	};

	const setToLocation = (toLocation: Option) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			toLocation
		});
	};

	const setSelectedTransportOffer = (selectedTransportOffer: OfferDetails) => {
		transportSearchOptionsVar({
			...transportSearchOptionsVar(),
			selectedTransportOffer
		});
	};

	return {
		transportType,
		fromLocation,
		toLocation,
		travelPeriod,
		tripType,
		transportSort,
		airClass,
		optionCategories,
		selectedTransportOffer,
		setTransportType,
		setFromLocation,
		setToLocation,
		setTravelPeriod,
		setTripType,
		setTransportSort,
		setAirClass,
		setOptionCategories,
		setSelectedTransportOffer,
		initializeTransportSearchCache,
		resetTransportSearchOptionsCache
	};
};

export default useTransportSearchOptionsCache;
