import { FunctionalComponent } from 'preact';
import { useEffect, useMemo } from 'preact/hooks';

import useChildrenAgesOptions from 'hooks/useChildrenAgesOptions';
import useHotelDescription from 'hooks/useHotelDescription';
import useIntl from 'hooks/useIntl';
import Accordion from 'redesigncomponents/Accordion';
import CounterItem from 'redesigncomponents/CounterItem';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldSelect from 'redesigncomponents/FieldSelect';
import HeroHeader from 'redesigncomponents/HeroHeader';
import ImageGallery from 'redesigncomponents/ImageGallery';
import LocationMap from 'redesigncomponents/LocationMap';
import OptionBoxContainer from 'redesigncomponents/OptionBoxContainer';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import Toast from 'redesigncomponents/Toast';
import AvailableRoomSelectOption from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/components/AvailableRoomSelectOption';
import useAccommodationDetails from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAccommodationDetails';
import { Option } from 'redesigntypes/Option';

import DateIcon from 'assets/calendar.svg';
import AdultsIcon from 'assets/icons/counter/adults.svg';
import RoomsIcon from 'assets/icons/counter/rooms.svg';
import HotelIcon from 'assets/icons/transport/hotel.svg';

import useIndividualAccommodationRestrictions from '../../AccommodationList/useIndividualAccommodationRestrictions';
import HeroSubtitle from './components/HeroSubtitle';
import SelectedRoom from './components/SelectedRoom';
import {
	ChildAgeWrapper,
	DetailsContainer,
	HeaderTitle,
	ImageGalleryContainer,
	InfoMessage,
	LoaderContainer,
	MapDivider,
	OptionsContainer,
	PriceTitle,
	PriceValue,
	SectionContainer,
	SectionHeader,
	TotalPrice
} from './components/StyledComponents';
import useAccommodationOffer from './useAccommodationOffer';

interface IProps {
	externalId: string;
	travelPeriod?: [Date, Date];
	defaultNumberOfRooms?: number;
}

const AccommodationDetailsContainer: FunctionalComponent<IProps> = ({
	externalId = '',
	travelPeriod,
	defaultNumberOfRooms
}) => {
	const { t: accommodationDetailsTranslations } = useIntl(
		'accommodationDetails'
	);
	const {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		imageGalleryOpen,
		selectedRoomOption,
		setNumberOfRoomsValue,
		setNumberOfAdultsValue,
		setNumberOfChildrenValue,
		setChildrenAgesValue,
		setTravelPeriodValue,
		toggleImageGalleryOpen,
		setSelectedRoomOptionValue
	} = useAccommodationOffer();

	const { childrenAgesOptions } = useChildrenAgesOptions();
	const {
		accommodation,
		availableRoomOptions,
		headerImage,
		galleryImages,
		geographicaLocation,
		getAccommodation,
		getAvailableRooms,
		getAvailableRoomByKey,
		loading: loadingAccommodationDetails,
		loadingAvailableRooms
	} = useAccommodationDetails();
	const { description } = useHotelDescription(accommodation?.code);

	const { restrictedDates, getRestrictedDates, isTravelPeriodValid } =
		useIndividualAccommodationRestrictions(externalId);

	useEffect(() => {
		if (externalId) {
			getAccommodation(externalId);
		}
	}, [externalId]);

	const totalPrice = useMemo(() => {
		if (selectedRoomOption) {
			const selectedRoom = getAvailableRoomByKey(
				selectedRoomOption.value as string
			);

			if (selectedRoom) {
				return {
					amount: selectedRoom.rate.amount * numberOfRooms,
					currency: selectedRoom.rate.currency
				};
			}
		}
	}, [selectedRoomOption, numberOfRooms, availableRoomOptions]);

	const isTravelPeriodFreeOfRestrictions = useMemo(() => {
		if (travelPeriod) {
			return travelPeriod.length < 2
				? true
				: isTravelPeriodValid(travelPeriod[0], travelPeriod[1]);
		}
	}, [travelPeriod, isTravelPeriodValid]);

	useEffect(() => {
		if (travelPeriod) {
			getRestrictedDates(travelPeriod[0], travelPeriod[1]);
		}
	}, [travelPeriod]);

	useEffect(() => {
		// if there are restrictions in the picked travel period, reset the current stay travel period
		// if the user picks a new travel period at this screen, this period will again be checked for the restrictions
		// if it has restrictions, it will again be reset on this screen
		// if the travel period has no restrictions, it will be saved as the global date period
		if (isTravelPeriodFreeOfRestrictions === false) {
			setTravelPeriodValue(undefined);
			Toast.info(accommodationDetailsTranslations('dateRestrictionsFoundInfo'));
		} else if (isTravelPeriodFreeOfRestrictions && travelPeriod.length === 2) {
			setTravelPeriodValue(travelPeriod);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTravelPeriodFreeOfRestrictions]);

	//* TODO: ne može se otvoriti više booking linkova zaredom  */
	useEffect(() => {
		if (
			accommodation &&
			numberOfAdults &&
			numberOfRooms &&
			travelPeriod?.length == 2
		) {
			getAvailableRooms(
				numberOfAdults,
				numberOfChildren,
				childrenAges,
				travelPeriod,
				1 // we can only search for one room at the moment - the user gets the count of available rooms in the results and then he can pick any number of rooms he wants
			);
		}
	}, [
		accommodation,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod
	]);

	useEffect(() => {
		// clear selected option if new returned available rooms do not contain the same option
		if (selectedRoomOption && availableRoomOptions) {
			if (
				availableRoomOptions.findIndex(
					aro => aro.value === selectedRoomOption.value
				) === -1
			) {
				setSelectedRoomOptionValue(undefined);
			}
		}
	}, [selectedRoomOption, availableRoomOptions]);

	const onChangeNumberOfChildren = (value: number) => {
		setNumberOfChildrenValue(value);
		if (value < childrenAges.length) {
			const updatedChildrenAges = [...childrenAges];
			updatedChildrenAges.length = value;
			setChildrenAgesValue(updatedChildrenAges);
		} else {
			setChildrenAgesValue([
				...childrenAges,
				...Array.from({ length: value - childrenAges.length }).map(_ => 3) // default child age: 3
			]);
		}
	};

	const onChangeChildrenAges = (index: number, value: Option) => {
		const updatedChildrenAges = [...childrenAges];
		updatedChildrenAges[index] = value.value as number;
		setChildrenAgesValue(updatedChildrenAges);
	};

	useEffect(() => {
		if (selectedRoomOption) {
			const numberOfRoomsForRoomOption =
				getAvailableRoomByKey(selectedRoomOption?.value as string)?.count || 1;

			if (defaultNumberOfRooms <= numberOfRoomsForRoomOption) {
				setNumberOfRoomsValue(defaultNumberOfRooms);
			} else {
				setNumberOfRoomsValue(1);
			}
		} else {
			setNumberOfRoomsValue(defaultNumberOfRooms || 1);
		}
	}, [selectedRoomOption, defaultNumberOfRooms]);

	return !accommodation || loadingAccommodationDetails ? (
		<LoaderContainer>
			<ThreeDotLoader style={{ zIndex: 101 }} color="#342245" size={80} />
		</LoaderContainer>
	) : imageGalleryOpen ? (
		<ImageGalleryContainer>
			<ImageGallery
				imageUrls={galleryImages}
				onClose={toggleImageGalleryOpen}
			/>
		</ImageGalleryContainer>
	) : (
		<>
			<HeroHeader
				title={accommodation?.name}
				infos={[<HeroSubtitle priceRate={accommodation?.minimumPriceRate} />]}
				backgroundImage={headerImage}
				onClick={galleryImages?.length && toggleImageGalleryOpen}
			></HeroHeader>

			<SectionContainer>
				<Accordion
					title={accommodationDetailsTranslations(
						'hotelDescriptionSectionTitle'
					)}
					defaultValue={false}
					Icon={HotelIcon}
				>
					{description || accommodation?.description}
				</Accordion>
				<DetailsContainer>
					<SectionHeader>
						<HeaderTitle>
							{accommodationDetailsTranslations('bookingDetailsSectionTitle')}
						</HeaderTitle>
					</SectionHeader>

					<OptionsContainer>
						<OptionBoxContainer
							title={accommodationDetailsTranslations('stayDatesSectionTitle')}
							Icon={DateIcon}
						>
							<FieldDatepicker
								label={accommodationDetailsTranslations(
									'stayDatesDateRangeLabel'
								)}
								minDate={new Date()}
								dateFormatCalendarHeader="MMMM, YYYY."
								excludeDates={restrictedDates && restrictedDates}
								isRange
								secondary
								showMonthYearDropdown
								input={{
									onChange: value => {
										if (value.length === 2 && value.every(d => d)) {
											setTravelPeriodValue(value);
										}
									},
									value: travelPeriod,
									name: '',
									onBlur: () => null,
									onFocus: () => null
								}}
							/>
						</OptionBoxContainer>
						<TotalPrice>
							<PriceTitle>
								{accommodationDetailsTranslations('totalPriceSectionTitle')}
							</PriceTitle>
							<PriceValue>
								{totalPrice
									? `${totalPrice?.amount} ${totalPrice?.currency}`
									: '0 EUR'}
							</PriceValue>
						</TotalPrice>
						<OptionBoxContainer
							title={accommodationDetailsTranslations(
								'passengerNumbersSectionTitle'
							)}
							Icon={AdultsIcon}
						>
							<CounterItem
								value={numberOfAdults}
								onChange={setNumberOfAdultsValue}
								title={accommodationDetailsTranslations('adultsCounterLabel')}
								min={1}
								max={8}
							></CounterItem>
							<CounterItem
								value={numberOfChildren}
								onChange={onChangeNumberOfChildren}
								title={accommodationDetailsTranslations('childrenCounterLabel')}
								subtitle={accommodationDetailsTranslations('childrenSubtitle')}
								min={0}
								max={8}
							></CounterItem>
							{numberOfChildren
								? Array.from({ length: numberOfChildren }).map(
										(child, index) => {
											return (
												<ChildAgeWrapper>
													<FieldSelect
														label={accommodationDetailsTranslations(
															'childAgeSelectLabel'
														)}
														placeholder={accommodationDetailsTranslations(
															'childAgeSelectLabel'
														)}
														secondary
														input={{
															onChange: value =>
																onChangeChildrenAges(index, value),
															value: childrenAges?.[index],
															name: '',
															onBlur: () => null,
															onFocus: () => null
														}}
														options={childrenAgesOptions}
													/>
												</ChildAgeWrapper>
											);
										}
								  )
								: null}
							{numberOfChildren > 0 && (
								<InfoMessage>
									{accommodationDetailsTranslations('extraBedsInfoMessage')}
								</InfoMessage>
							)}
						</OptionBoxContainer>

						<OptionBoxContainer
							title={accommodationDetailsTranslations(
								'stayingOptionsSectionTitle'
							)}
							Icon={RoomsIcon}
						>
							<FieldSelect
								label={accommodationDetailsTranslations('roomTypeSelectLabel')}
								placeholder={accommodationDetailsTranslations(
									'roomTypeSelectLabel'
								)}
								secondary
								loading={loadingAvailableRooms}
								input={{
									onChange: setSelectedRoomOptionValue,
									value: selectedRoomOption,
									name: '',
									onBlur: () => null,
									onFocus: () => null
								}}
								options={availableRoomOptions}
								CustomOptionComponent={AvailableRoomSelectOption}
							/>
							<CounterItem
								value={numberOfRooms}
								onChange={setNumberOfRoomsValue}
								title={accommodationDetailsTranslations(
									'numberOfRoomsCounterLabel'
								)}
								min={1}
								max={
									getAvailableRoomByKey(selectedRoomOption?.value as string)
										?.count || 1
								}
							></CounterItem>
						</OptionBoxContainer>
					</OptionsContainer>
				</DetailsContainer>
				<SelectedRoom
					selectedRoom={
						selectedRoomOption &&
						getAvailableRoomByKey(selectedRoomOption?.value as string)
					}
				/>
			</SectionContainer>
			<MapDivider />
			<LocationMap markers={[geographicaLocation]} />
		</>
	);
};

export default AccommodationDetailsContainer;
