import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { FunctionalComponent } from 'preact';

import PinIcon from 'assets/icons/pin.svg';

const libraries = [''];

type Props = {
	center: any;
	containerStyle: any;
	zoom: number;
	markers: any[];
	onMapLoad?: (map: any) => void;
	onMapUnmount?: (map: any) => void;
};

const MapComponent: FunctionalComponent<Props> = ({
	center,
	containerStyle,
	zoom,
	markers = [],
	onMapLoad,
	onMapUnmount
}) => {
	const { isLoaded } = useLoadScript({
		id: 'google-map-script',
		googleMapsApiKey: process.env.PREACT_APP_GOOGLE_MAP_KEY,
		libraries: libraries
	});

	return (
		<>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={zoom}
					onLoad={onMapLoad}
					onUnmount={onMapUnmount}
				>
					{markers.length &&
						markers.map(({ lng, lat, onClick }) => (
							<Marker
								onClick={onClick}
								position={{ lng, lat }}
								icon={PinIcon}
							/>
						))}
				</GoogleMap>
			)}
		</>
	);
};

export default MapComponent;
