import { ComponentChildren, FunctionalComponent, VNode } from 'preact';
import { useRouteMatch } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import PoweredByModal from 'redesigncomponents/Footer/components/PoweredByModal';
import { TooltipViewsProvider } from 'redesigncomponents/Footer/components/TooltipInfo/components/TooltipViewProvider';
import { Modal } from 'redesigncomponents/Modal';
import { usePageVisited } from 'redesigncomponents/PageVisitedProvider';

import {
	FooterContent,
	FooterWrapper,
	LeftWrapper,
	PoweredBy,
	PoweredByLink,
	RightWrapper
} from './components/StyledComponents';
import TooltipInfo from './components/TooltipInfo';

interface IProps {
	children?: ComponentChildren;
	toolTipProps?: {
		toolTipKey?: string;
	};
	infoTip?: VNode;
}

const Footer: FunctionalComponent<IProps> = ({
	children,
	toolTipProps = {},
	infoTip
}) => {
	const [poweredByActive, togglePoweredByActive] = useToggle();
	const { path: pathPattern } = useRouteMatch();
	const pageVisited = usePageVisited(pathPattern);
	const { t } = useIntl('common');

	const [infoTipActive, toggleInfoTipActive] = useToggle(
		!pageVisited && !!infoTip
	);

	return (
		<TooltipViewsProvider>
			<FooterWrapper>
				<FooterContent>
					<LeftWrapper>
						<TooltipInfo {...toolTipProps} />
					</LeftWrapper>
					<RightWrapper>{children}</RightWrapper>
				</FooterContent>
				<PoweredBy>
					<PoweredByLink onClick={togglePoweredByActive}>
						{t('destiOne')}
					</PoweredByLink>
				</PoweredBy>
			</FooterWrapper>
			{poweredByActive && (
				<PoweredByModal
					isOpen={poweredByActive}
					onClose={togglePoweredByActive}
				/>
			)}
			{infoTip && (
				<Modal
					isOpen={infoTipActive}
					onClose={toggleInfoTipActive}
					animation="slideDown"
				>
					{infoTip}
				</Modal>
			)}
		</TooltipViewsProvider>
	);
};

export default Footer;
