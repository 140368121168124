import { useMutation } from '@apollo/client';
import { useState } from 'preact/hooks';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { GET_INTEGRATIONS_TRANSPORTATION_OFFER } from 'apollo/myDesti/mutations';
import { PAGE_DEFAULT } from 'config/network';
import { Option } from 'redesigntypes/Option';
import { TransportTripType, TransportType } from 'types/enums';

import {
	OptionCategoriesType,
	OptionCategoryTypes
} from '../components/OptionCategories/useOptionCategories';

dayjs.extend(isToday);

const useLoadTransportationOffers = () => {
	// saving the parameters from the current search
	const [fromLocation, setFromLocation] = useState<Option>();
	const [toLocation, setToLocation] = useState<Option>();
	const [transportType, setTransportType] = useState<Option>();
	const [tripType, setTripType] = useState<Option>();
	const [airClass, setAirClass] = useState<Option>();
	const [travelPeriod, setTravelPeriod] = useState<[Date, Date]>();
	const [optionCategories, setOptionCategories] =
		useState<OptionCategoriesType>();

	const [transportOffer, setTransportOffer] = useState(undefined);
	const [currentPage, setCurrentPage] = useState(PAGE_DEFAULT);
	const [hasMore, setHasMore] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [
		getTransportationOffer,
		{ loading: loadingTransportOffer, called: calledLoadTransportOffer }
	] = useMutation(GET_INTEGRATIONS_TRANSPORTATION_OFFER, {});

	const loadTransportationOffer = async (
		travelPeriod,
		fromLocation: Option,
		toLocation: Option,
		travelClass: Option,
		optionCategories: OptionCategoriesType,
		page: number,
		pageSize = 20,
		transportType: Option,
		tripType: Option,
		transportSort: Option
	) => {
		const departureDate = (
			dayjs(travelPeriod[0]).isToday()
				? dayjs
						.utc(dayjs(travelPeriod[0]))
						.local()
						.startOf('hour')
						.add(1, 'hour')
				: dayjs.utc(dayjs(travelPeriod[0])).local().startOf('day')
		).format('YYYY-MM-DDTHH:mm:ssZ');

		const returnDate =
			tripType.value === TransportTripType.RoundTrip
				? dayjs
						.utc(travelPeriod[1])
						.local()
						.startOf('date')
						.format('YYYY-MM-DDTHH:mm:ssZ')
				: null;

		const { data } = await getTransportationOffer({
			variables: {
				originLocationName: fromLocation.value,
				destinationLocationName: toLocation.value,
				departureDate: departureDate,
				returnDate: returnDate,
				nonStop: false,
				hasPets: false,
				transportationType: [TransportType.Airplane],
				passengers: getPassengersForSearch(optionCategories),
				travelClass: travelClass.value,
				travelClassMix: false,
				cabinBagCount:
					optionCategories[OptionCategoryTypes.addons].cabinBaggage,
				checkedBagCount:
					optionCategories[OptionCategoryTypes.addons].checkedBaggage,
				pageNumber: page,
				pageSize: pageSize,
				orderBy: transportSort.value
			},
			onCompleted: () => {
				// saving the search parameters of the search in local state as the use has to press search to start a new search - until then, old paramaters are used
				setFromLocation(fromLocation);
				setToLocation(toLocation);
				setTravelPeriod(travelPeriod);
				setTransportType(transportType);
				setTripType(tripType);
				setAirClass(travelClass);
				setOptionCategories(optionCategories);
			}
		});

		handleResultList(
			data,
			page,
			tripType.value as TransportTripType,
			toLocation
		);
	};

	const handleResultList = (
		data: { getTransportation },
		page: number,
		tripType?: TransportTripType,
		destination?: Option
	) => {
		const { flightOffer } = data.getTransportation;

		const { data: flightOffers, totalPages, totalCount } = flightOffer;
		const mappedTransports = mapFlightResults(flightOffers, destination.label);

		setHasMore(page <= totalPages);
		setTotalCount(totalCount);
		setCurrentPage(page);

		if (page === PAGE_DEFAULT) {
			setTransportOffer(mappedTransports);
		} else {
			setTransportOffer([...transportOffer, ...mappedTransports]);
		}
	};

	const mapFlightResults = (data, destinationName: string) => {
		return (
			data?.map(item => {
				const itineraries = item.itineraries;

				const destination = itineraries.find(
					x => x.arrivalLocation.city === destinationName
				);

				return {
					timetable_id: item.id,
					transport_line: `${itineraries[0]?.departureLocation.city}-${destination.arrivalLocation.city}`,
					transport_type: TransportType.Airplane,
					operator_name: itineraries[0]?.airline,
					departure_time: itineraries[0]?.departureTime,
					departure_station_code: itineraries[0]?.departureLocation.airportName,
					arrival_time: destination?.arrivalTime,
					arrival_station_code: destination?.arrivalLocation.airportName,
					price: item.fromPrice,
					transport_item: item,
					duration: item.duration
				};
			}) || []
		);
	};

	return {
		loadingTransportOffer,
		calledLoadTransportOffer,
		loadTransportationOffer,
		transportOfferTripType: tripType,
		transportOfferTravelPeriod: travelPeriod,
		transportOffer,
		page: currentPage,
		hasMore,
		totalCount
	};
};

export default useLoadTransportationOffers;

export const getPassengersForSearch = optionCategories => {
	const passengers = [];
	let idIndex = 1;
	Object.entries(optionCategories[OptionCategoryTypes.passengers]).forEach(
		([key, value]) => {
			if (value > 0) {
				passengers.unshift(
					...Array.from({ length: value as number }).map(_ => ({
						id: `${idIndex++}`,
						age: 100,
						passengerCategoryId: key
					}))
				);
			}
		}
	);

	return passengers;
};
