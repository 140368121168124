import { ApolloClient, createHttpLink, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { CLIENTS } from 'config/network';

import { cache } from './cache';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { hostLink } from './links/hostLink';
import { refreshToken } from './links/refreshToken';
import createWsLink from './links/wsLink';

const CONNECT_TO_DEVTOOLS = true;

const myDestiLink = createHttpLink({
	uri: process.env.PREACT_APP_MYDESTI_URL,
	credentials: 'include'
});
const myPagesLink = createHttpLink({
	uri: process.env.PREACT_APP_MYPAGES_URL,
	credentials: 'include'
});
const myBookingsLink = createHttpLink({
	uri: process.env.PREACT_APP_MYBOOKINGS_URL,
	credentials: 'include'
});
const myPagesWsLink = createWsLink(process.env.PREACT_APP_MYPAGES_WS_URL);

const myPagesOperationSplitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);

		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	myPagesWsLink,
	myPagesLink
);
const myBookingsAnonymousLink = createHttpLink({
	uri: process.env.PREACT_APP_MYBOOKINGS_URL,
	credentials: 'include'
});

//** myDestiClient - is the main application client */
export const myDestiClient = new ApolloClient({
	link: from([errorLink, authLink, hostLink, refreshToken, myDestiLink]),
	cache,
	connectToDevTools: CONNECT_TO_DEVTOOLS,
	name: CLIENTS.MY_DESTI
});

export const myPagesClient = new ApolloClient({
	link: from([errorLink, authLink, refreshToken, myPagesOperationSplitLink]),
	cache,
	connectToDevTools: CONNECT_TO_DEVTOOLS,
	name: CLIENTS.MY_PAGES
});

export const myBookingsClient = new ApolloClient({
	link: from([errorLink, authLink, refreshToken, myBookingsLink]),
	cache,
	connectToDevTools: CONNECT_TO_DEVTOOLS,
	name: CLIENTS.MY_BOOKINGS
});

export const myBookingsAnonymousClient = new ApolloClient({
	link: from([errorLink, hostLink, myBookingsAnonymousLink]),
	cache,
	connectToDevTools: CONNECT_TO_DEVTOOLS,
	name: CLIENTS.MY_BOOKINGS
});
