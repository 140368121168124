import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import {
	IActivityWishlistItem,
	IMerchandiseWishlistItem,
	IStayWishlistItem,
	ITransportWishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import { IconValue } from 'redesigncomponents/IconValue/types';
import { IComposedTravelPlanItem } from 'redesignscreens/Checkout/components/TravelPlan/useTravelPlan';
import { TransportType } from 'types/enums';
import { formatListsPrice } from 'utils/price/priceFormatting';

import Calendar from 'assets/icons/transport/calendar.svg';
import PlaneArrival from 'assets/icons/transport/plane-arrival.svg';
import PlaneDeparture from 'assets/icons/transport/plane-departure.svg';
import PriceTag from 'assets/icons/transport/price-tag.svg';
import TrainArrival from 'assets/icons/transport/train-arrival.svg';
import TrainDeparture from 'assets/icons/transport/train-departure.svg';

export interface InfoSpacer {
	key: string;
	spacer: boolean;
}

const useTravelPlanItemHeader = (travelPlanItem: IComposedTravelPlanItem) => {
	const title = useMemo(() => {
		switch (travelPlanItem.type) {
			case WishlistItemTypes.ACTIVITY: {
				const activityItem = travelPlanItem.item as IActivityWishlistItem;
				return `${activityItem.supplierName}: ${activityItem.name}`;
			}
			case WishlistItemTypes.MERCHANDISE: {
				const merchandiseItem = travelPlanItem.item as IMerchandiseWishlistItem;
				return `${merchandiseItem.supplierName}: ${merchandiseItem.name}`;
			}
			case WishlistItemTypes.STAY: {
				const stayItem = travelPlanItem.item as IStayWishlistItem;
				return `${stayItem.accommodationName}: ${stayItem.roomDescription}`;
			}
			case WishlistItemTypes.TRANSPORT: {
				const transportItem = travelPlanItem.item as ITransportWishlistItem;
				return `${transportItem.city_from} - ${transportItem.city_to} (${transportItem.operatorNames})`;
			}
			default:
				'';
		}
	}, [travelPlanItem]);

	const infos = useMemo((): (IconValue | InfoSpacer)[] => {
		const generateKey = combineKeys(travelPlanItem.id);
		switch (travelPlanItem.type) {
			case WishlistItemTypes.ACTIVITY: {
				const activityItem = travelPlanItem.item as IActivityWishlistItem;
				return [
					{
						icon: PriceTag,
						key: generateKey('activity-price'),
						value: `${activityItem.price.amount} ${activityItem.price.currency}`
					},

					{
						icon: Calendar,
						key: generateKey('activity-start-time-local'),
						value: formatDateTime(activityItem.startTimeLocal)
					}
				];
			}
			case WishlistItemTypes.MERCHANDISE: {
				const merchandiseItem = travelPlanItem.item as IMerchandiseWishlistItem;
				return [
					{
						icon: PriceTag,
						key: generateKey('activity-price'),
						value: `${merchandiseItem.price.amount} ${merchandiseItem.price.currency}`
					}
				];
			}
			case WishlistItemTypes.STAY: {
				const stayItem = travelPlanItem.item as IStayWishlistItem;
				return [
					{
						icon: PriceTag,
						key: generateKey('stay-price'),
						value: `${stayItem.price.amount} ${stayItem.price.currency}`
					},
					{
						spacer: true,
						key: generateKey('stay-spacer')
					},
					{
						icon: Calendar,
						key: generateKey('stay-arrival-date'),
						value: formatDate(stayItem.arrivalDate)
					},
					{
						icon: Calendar,
						key: generateKey('stay-departure-date'),
						value: formatDate(stayItem.departureDate)
					}
				];
			}
			case WishlistItemTypes.TRANSPORT: {
				const transportItem = travelPlanItem.item as ITransportWishlistItem;
				const isAirplane =
					transportItem.transport_type === TransportType.Airplane;
				const infos = [
					{
						icon: PriceTag,
						key: generateKey('price-transport'),
						value: `${
							isAirplane
								? formatListsPrice(transportItem.price.amount)
								: transportItem.price.amount
						} ${transportItem.price.currency}`
					},
					{
						spacer: true,
						key: generateKey('spacer-transport')
					},
					{
						key: generateKey('departure-time-transport'),
						icon: isAirplane ? PlaneDeparture : TrainDeparture,
						value: formatDateTime(transportItem.departure_time)
					}
				];
				if (transportItem.return_departure_time) {
					infos.push({
						key: generateKey('return-departure-time-transport'),
						icon: isAirplane ? PlaneArrival : TrainArrival,
						value: formatDateTime(transportItem.return_departure_time)
					});
				}
				return infos;
			}
			default:
				return [];
		}
	}, [travelPlanItem]);

	return {
		title,
		infos
	};
};

const formatDateTime = (date: Date | string) => {
	const converted = dayjs.utc(date);
	return `${converted.format('HH:mm DD.MM.YYYY')}`;
};

const formatDate = (date: Date | string) => {
	const converted = dayjs.utc(date);
	return `${converted.format('DD.MM.YYYY')}`;
};

export const isInfoSpacer = (
	item: InfoSpacer | IconValue
): item is InfoSpacer => {
	return (item as InfoSpacer).spacer !== undefined;
};

const combineKeys = (firstKey: string) => (secondKey: string) =>
	`${firstKey}-${secondKey}`;

export default useTravelPlanItemHeader;
