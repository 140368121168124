import { FunctionalComponent } from 'preact';
import { Field, Form } from 'react-final-form';

import useIntl from 'hooks/useIntl';
import { ITransportWishlistItem } from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import { IComposedTravelPlanItem } from 'redesignscreens/Checkout/components/TravelPlan/useTravelPlan';
import validateForm from 'utils/form/validateForm';

import TrashIcon from 'assets/trash.svg';

import useCheckPaidStatus from '../../hooks/useCheckPaidStatus';
import useUpdateTravelItineraryIdForTravelPlan from '../../hooks/useUpdateTravelItineraryIdForTravelPlan';
import useUpdateTravelPlanItem from '../../hooks/useUpdateTravelPlanItem';
import { GetGestButton } from '../GetGestButton';
import NotificationBox from '../NotificationBox';
import { TextInputHalfWidth } from '../StyledComponents';
import TravelPlanItemHeader from '../TravelPlanItemHeader';
import useRefreshFlightsAvailabilityAndPrice from './useCheckFlightAvailabilityAndPrice';
import useFlightCheckoutConstraints from './useFlightCheckoutConstraints';
import useFlightsItineraryPaymentStatus from './useFlightsItineraryStatus';
import usePrepareCheckoutItinerary from './usePrepareCheckoutItinerary';

type Props = {
	travelPlanId: string | number;
	travelItineraryId: string;
	travelPlanName: string;
	travelPlanItem: IComposedTravelPlanItem;
	removeTravelPlanItem?: () => void;
};
const FlightCheckoutForm: FunctionalComponent<Props> = ({
	travelPlanId,
	travelItineraryId,
	travelPlanName,
	travelPlanItem,
	removeTravelPlanItem
}) => {
	const { t: checkoutTranslations } = useIntl('checkout');

	const { constraints } = useFlightCheckoutConstraints();
	const { setTravelPlanItemExpired } = useUpdateTravelPlanItem(
		travelPlanItem,
		travelPlanId
	);

	const { flightPriceChanged } = useRefreshFlightsAvailabilityAndPrice(
		travelPlanId,
		travelPlanItem
	);
	const { savedTravelItineraryId, flightPaymentStatus, startFlightPayment } =
		useFlightsItineraryPaymentStatus(travelPlanId, travelPlanItem);

	const { prepareCheckoutItinerary, loading } =
		usePrepareCheckoutItinerary(travelItineraryId);
	const { setTravelItineraryIdForTravelPlan } =
		useUpdateTravelItineraryIdForTravelPlan(travelPlanId);

	const { isTravelPlanItemPaid } = useCheckPaidStatus(travelPlanItem);

	const onSubmit = async values => {
		try {
			const travelItineraryId = await prepareCheckoutItinerary(
				travelPlanItem,
				travelPlanName,
				values.contactEmail
			);
			setTravelItineraryIdForTravelPlan(travelItineraryId);
			startFlightPayment(travelItineraryId);

			const transportItem = travelPlanItem.item as ITransportWishlistItem;
			window.open(
				`${transportItem.booking_url}&email=${values.contactEmail}`,
				'_blank'
			);
		} catch {
			setTravelPlanItemExpired();
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			validate={values => validateForm(values, constraints)}
		>
			{({ handleSubmit, valid }) => {
				return (
					<form id="flightCheckoutForm" onSubmit={handleSubmit}>
						<TravelPlanItemHeader travelPlanItem={travelPlanItem}>
							{isTravelPlanItemPaid ? (
								<GetGestButton
									title={checkoutTranslations(
										'travelPlanItemHeader.paidButtonTitle'
									)}
									paid
									type="button"
								></GetGestButton>
							) : (
								<>
									{removeTravelPlanItem && (
										<Button
											buttonStyleType={ButtonStyleTypes.tertiary}
											Icon={TrashIcon}
											onlyIcon
											tightPadding
											disabled={loading || savedTravelItineraryId}
											onClick={removeTravelPlanItem}
											type="button"
										></Button>
									)}
									<GetGestButton
										disabled={!valid || savedTravelItineraryId}
										title={
											checkoutTranslations(
												`travelPlanItemHeader.flightPaymentStatus.${flightPaymentStatus}`
											) ||
											checkoutTranslations(
												'travelPlanItemHeader.payButtonTitle'
											)
										}
										loading={loading}
									></GetGestButton>
								</>
							)}
						</TravelPlanItemHeader>
						<TextInputHalfWidth>
							<Field
								id="contactEmail"
								name="contactEmail"
								label={checkoutTranslations(
									'fligthCheckoutForm.contactEmailInputLabel'
								)}
								component={FieldTextInput}
								disabled={loading || savedTravelItineraryId}
								smallPadding
							/>
						</TextInputHalfWidth>
						<NotificationBox
							message={checkoutTranslations(
								'fligthCheckoutForm.redirectToKiwiNotificationMessage'
							)}
						/>
						<div></div>
						{flightPriceChanged && (
							<NotificationBox
								message={`${checkoutTranslations(
									'fligthCheckoutForm.flightPriceChanged'
								)} ${travelPlanItem.item.price.amount} ${
									travelPlanItem.item.price.currency
								}`}
								infoLevel="error"
							/>
						)}
						{travelPlanItem.expired && (
							<NotificationBox
								message={checkoutTranslations(
									'fligthCheckoutForm.flightIsNoLongerAvailableMessage'
								)}
								infoLevel="error"
							/>
						)}
					</form>
				);
			}}
		</Form>
	);
};

export default FlightCheckoutForm;
