import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonFlexContainer,
	Modal,
	OptionModalText,
	OptionModalTitle
} from 'redesigncomponents/Modal';

const LeaveGetGestModal = ({ open, toggleOpen }) => {
	const { t } = useIntl('checkout.getGestLeaveModal');
	const history = useHistory();

	const goBack = () => history.goBack();

	return (
		<Modal isOpen={open} onClose={toggleOpen}>
			<OptionModalTitle>{t('title')}</OptionModalTitle>

			<OptionModalText>{t('content')}</OptionModalText>

			<ButtonFlexContainer>
				<Button
					title={t('cancelButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={toggleOpen}
				></Button>
				<Button
					title={t('leaveButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goBack}
				></Button>
			</ButtonFlexContainer>
		</Modal>
	);
};

export default LeaveGetGestModal;
