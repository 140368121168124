import { gql } from '@apollo/client';

export const GET_ACCOMMODATION_CATEGORIES = gql`
	query {
		accommodation_category {
			name
			id
			price_from
			price_to
		}
	}
`;

export const GET_ACCOMMODATION_CATEGORY_AND_TYPE = gql`
	query {
		accommodation_category {
			id
			name
		}
		accommodation_type {
			value
		}
	}
`;

export const GET_INTEGRATIONS_ACCOMMODATION_BY_TYPE = gql`
	query ($city: String!, $type: String!, $categoryId: Int!) {
		getIntegrationsAccommodationByType(
			city: $city
			type: $type
			categoryId: $categoryId
		) {
			id
			name
			type
			categoryId
			integration
			minimumPriceRate {
				currency
				endDate
				price
				startDate
			}
			latitude
			longitude
		}
	}
`;

export const GET_INTEGRATIONS_ACCOMMODATION_BY_PK = gql`
	query ($city: String!, $id: String!) {
		getIntegrationsAccommodationByPk(city: $city, id: $id) {
			code
			description
			name
			productOwner
			integration
			productLocation
			checkInTime
			checkOutTime
			minimumPriceRate {
				price
				startDate
				endDate
				currency
			}
		}
	}
`;

export const SEARCH_STATION = gql`
	query (
		$city: String!
		$transportationTypes: [String]
		$longitude: Float
		$latitude: Float
	) {
		getStations(
			city: $city
			transportationTypes: $transportationTypes
			longitude: $longitude
			latitude: $latitude
		) {
			id
			name
			producerId
			transportationType
		}
	}
`;

export const STAY_DETAILS = gql`
	query {
		stayDetails @client {
			people
			children
			rooms
			bookingType
		}
	}
`;

export const GET_ALL_ACTIVITY_TYPES = gql`
	query ActivityTypes {
		activity_type: activity_type {
			value
		}
	}
`;

export const SEARCH_CITY_BY_NAME = gql`
	query ($search: String!) {
		city: city(where: { name: { _ilike: $search } }) {
			id
			name
		}
	}
`;

export const GET_INTEGRATIONS_STAY_COUNT = gql`
	query GetIntegrationStayCount(
		$city: String!
		$startDate: date!
		$endDate: date!
		$language: String
	) {
		getIntegrationsStayCount(
			city: $city
			startDate: $startDate
			endDate: $endDate
			language: $language
		) {
			count
			providers
		}
	}
`;

export const GET_INTEGRATIONS_TRANSPORT_COUNT = gql`
	query ($cities: [cityInput]!, $startDate: date!, $endDate: date!) {
		getIntegrationsTransportCount(
			cities: $cities
			startDate: $startDate
			endDate: $endDate
		) {
			count
			providers
		}
	}
`;

export const GET_COUNTRIES = gql`
	query {
		countries: country(order_by: { name: asc }) {
			name
			id
			country_code
		}
	}
`;

export const GET_COUNTRY_BY_PK = gql`
	query ($id: Int!) {
		country: country_by_pk(id: $id) {
			name
			id
			country_code
		}
	}
`;

export const GET_TRANSPORT_CATEGORIES = gql`
	query {
		transport_category {
			value
			transport_subcategories {
				name
				id
			}
		}
	}
`;

export const GET_TRANSPORT_TYPES = gql`
	query {
		transport_type {
			id
			order
			key_name
		}
	}
`;

export const TRANSPORT_DETAILS = gql`
	query {
		transportDetails @client {
			selectedTransportTypes
		}
	}
`;

export const TRANSPORT_LIST = gql`
	query {
		transportList @client {
			transportItem
		}
	}
`;

export const GET_INTEGRATIONS_ACTIVITY_COUNT = gql`
	query ($city: String!, $startDate: date!, $endDate: date!) {
		getIntegrationsActivityCount(
			city: $city
			startDate: $startDate
			endDate: $endDate
		) {
			count
			providers
		}
	}
`;

export const GET_INTEGRATIONS_MERCHADISE_COUNT = gql`
	query {
		getIntegrationsMerchandiseCount {
			count
			providers
		}
	}
`;

export const GET_PRODUCT_OWNERS_BY_CODE = gql`
	query ($codes: [String!]) {
		product_owners: product_owner(where: { code: { _in: $codes } }) {
			id
			name
			global_id
			code
		}
	}
`;

export const GET_PRODUCT_OWNER_BY_CODE = gql`
	query ($id: uuid!) {
		product_owner_by_pk(id: $id) {
			product_owner_legal_documents {
				url
				type
				name
				url
				location
				language
			}
			product_owner_profile {
				terms
			}
		}
	}
`;

export const GET_AFFILIATE_LINK_BY_PK = gql`
	query GetAffiliateLink($id: uuid!) {
		affiliate_link_by_pk(id: $id) {
			external_id
			is_active
			type
			parameters
			product_owner {
				code
				global_id
				id
				enabled
				integrations {
					name
				}
			}
		}
	}
`;

export const GET_TRANSPORT_SORT_OPTIONS_BY_TRANSPORT_TYPE = gql`
	query getTransportSortOptions($transportType: String!) {
		getTransportSortOptions(transportType: $transportType) {
			key
			name
		}
	}
`;

export const GET_CITIES = gql`
	query getCities {
		city {
			id
			latitude
			longitude
			name
			country_id
		}
	}
`;
