import { useEffect, useState } from 'preact/hooks';

import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

import useCountryCodebookOptions from 'hooks/useCountryCodebookOptions';
import useIntl from 'hooks/useIntl';
import { IActivityWishlistItem } from 'redesigncache/wishlist/types';
import { BookingField, BookingFieldTypes } from 'redesigntypes/ActivityModel';
import { Option } from 'redesigntypes/Option';

import BookingFieldLabel from './BookingFieldLabel';
import { validateField } from './validation';

const dest1dot = '_dest1dot_';

const allowedLinkAttributes = { ADD_ATTR: ['target'] };
const mapBookingFieldLabelToReactFormName = (label: string) =>
	label.replace(/\./g, dest1dot);
export const mapReactFormNameToBookingFieldLabel = (label: string) =>
	label.replace(new RegExp(dest1dot, 'g'), '.');

export interface AdditionalBookingFieldsConfiguration {
	requiredPerBookingFields: Array<Array<BookingField>>;
	requiredPerParticipantFields: Array<Array<BookingField>>;
	requiredPerBookingFieldsValidation: any;
	requiredPerParticipantFieldsValidation: any;
}

const chunk = array => {
	const chunkSize = 2;
	const newArray = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		const chunk = array.slice(i, i + chunkSize);
		if (chunk.some(c => c.fullWidth)) {
			newArray.push(...chunk.map(c => [c]));
		} else {
			newArray.push(chunk);
		}
	}
	return newArray;
};

const setBookingFieldsFormConfiguration = (
	field: BookingField,
	t,
	countryOptions: Option[]
) => {
	return {
		...field,
		required: field.requiredPerBooking,
		name: mapBookingFieldLabelToReactFormName(field.label), // react final form considers . as a sign for the object property and parses the label as an object - replacing the dot to circumvent the issue
		fullWidth: field.label?.length > 40,
		LabelComponent:
			field.fieldType === BookingFieldTypes.Boolean ? (
				<BookingFieldLabel
					html={DOMPurify.sanitize(field.label, allowedLinkAttributes)}
				/>
			) : undefined,
		validate: value =>
			validateField(value, field.fieldType, field.requiredPerBooking, t),
		maxDate:
			field.fieldType === BookingFieldTypes.Date &&
			field.label.toLowerCase().indexOf('birth') !== -1
				? dayjs().subtract(18, 'year').toDate()
				: undefined,
		listOptionsParsed:
			field.fieldType === BookingFieldTypes.List
				? generateFieldListOptions(field, countryOptions)
				: undefined,
		updatedAt: Date.now()
	};
};

const sortFields = (a: BookingField, b: BookingField) => {
	if (
		a.fieldType === BookingFieldTypes.Boolean &&
		b.fieldType !== BookingFieldTypes.Boolean
	) {
		return 1;
	}
	if (
		a.fieldType !== BookingFieldTypes.Boolean &&
		b.fieldType === BookingFieldTypes.Boolean
	) {
		return -1;
	}
	if (a.fieldType === b.fieldType) {
		return -1;
	}
};

const generateFieldListOptions = (
	field: BookingField,
	countryOptions: Option[]
): Array<Option> => {
	if (field.label === 'Country') {
		return countryOptions;
	}

	return field.listOptions
		?.split('\r\n')
		.filter(o => o?.trim())
		.map(
			option =>
				({
					value: option,
					label: option
				} as Option)
		);
};

const setParticipantFieldsFormConfiguration = (
	field: BookingField,
	t,
	countryOptions: Option[]
) => {
	return {
		...field,
		name: mapBookingFieldLabelToReactFormName(field.label), // react final form considers . as a sign for the object property and parses the label as an object - replacing the dot to circumvent the issue
		fullWidth: field.label?.length > 40,
		LabelComponent:
			field.fieldType === BookingFieldTypes.Boolean ? (
				<BookingFieldLabel
					html={(DOMPurify.sanitize(field.label), allowedLinkAttributes)}
				/>
			) : undefined,
		required: field.requiredPerParticipant,
		validate: value =>
			validateField(value, field.fieldType, field.requiredPerParticipant, t),

		maxDate:
			field.fieldType === BookingFieldTypes.Date &&
			field.label.toLowerCase().indexOf('birth') !== -1
				? dayjs().subtract(18, 'year').toDate()
				: undefined,
		listOptionsParsed:
			field.fieldType === BookingFieldTypes.List
				? generateFieldListOptions(field, countryOptions)
				: undefined,
		updatedAt: Date.now()
	};
};

const useAdditionalBookingFieldsConfiguration = (
	item: IActivityWishlistItem
) => {
	const { t } = useIntl('common.validationMessages');
	const { countryOptions } = useCountryCodebookOptions();

	const [additionalBookingFields, setAdditionalBookingFields] =
		useState<AdditionalBookingFieldsConfiguration>({
			requiredPerBookingFields: [],
			requiredPerParticipantFields: [],
			requiredPerBookingFieldsValidation: {},
			requiredPerParticipantFieldsValidation: {}
		});
	const [additionalBookingDetailsState, setAdditionalBookingDetailsState] =
		useState<{
			hasAdditionalBookingFields: boolean;
			hasAdditionalPerBookingFields: boolean;
			hasAdditionalPerParticipantFields: boolean;
		}>({
			hasAdditionalBookingFields: false,
			hasAdditionalPerBookingFields: false,
			hasAdditionalPerParticipantFields: false
		});

	useEffect(() => {
		const allRequiredPerBookingFields =
			item.fields
				?.filter(bf => bf.visiblePerBooking)
				.map(f => setBookingFieldsFormConfiguration(f, t, countryOptions)) ||
			[];

		const allRequiredPerParticipantFields =
			item.fields
				?.filter(bf => bf.visiblePerParticipant)
				.map(f =>
					setParticipantFieldsFormConfiguration(f, t, countryOptions)
				) || [];

		const hasAdditionalBookingFields =
			allRequiredPerBookingFields.length !== 0 ||
			allRequiredPerParticipantFields.length !== 0;

		setAdditionalBookingDetailsState({
			hasAdditionalBookingFields,
			hasAdditionalPerBookingFields: allRequiredPerBookingFields.length !== 0,
			hasAdditionalPerParticipantFields:
				allRequiredPerParticipantFields.length !== 0
		});

		if (hasAdditionalBookingFields) {
			const requiredPerBookingFieldsValidation =
				allRequiredPerBookingFields.reduce((accumulator, value) => {
					accumulator[`requiredPerBookingFields.${value.label}`] = {
						presence: value.required
							? {
									message: t('fieldRequired')
							  }
							: undefined
					};

					return accumulator;
				}, {});

			const requiredPerBookingFields = chunk(allRequiredPerBookingFields); // chunking to smaller arrays of size 2 - creating input rows to render

			const requiredPerParticipantFields = chunk(
				allRequiredPerParticipantFields
			); // chunking to smaller arrays of size 2 - creating input rows to render
			setAdditionalBookingFields({
				requiredPerBookingFields,
				requiredPerParticipantFields,
				requiredPerBookingFieldsValidation,
				requiredPerParticipantFieldsValidation: {}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, countryOptions]);

	return {
		hasAdditionalBookingFields:
			additionalBookingDetailsState.hasAdditionalBookingFields,
		hasAdditionalPerBookingFields:
			additionalBookingDetailsState.hasAdditionalPerBookingFields,
		hasAdditionalPerParticipantFields:
			additionalBookingDetailsState.hasAdditionalPerParticipantFields,
		additionalBookingFields
	};
};

export default useAdditionalBookingFieldsConfiguration;
