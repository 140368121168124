import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'preact/hooks';

import { GET_PRODUCT_OWNER_BY_CODE } from 'apollo/myDesti/queries';
import useIntl from 'hooks/useIntl';
import { IActivityWishlistItem } from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../../../../../TravelPlan/useTravelPlan';

enum DocumentType {
	Payment = 'payment',
	Booking = 'booking',
	Merchandise = 'merchandise',
	Checkout = 'checkout'
}

interface IDocument {
	type: string;
	name: string;
	url: string;
	location: string;
	language: string;
}

interface IProductOwnerTerms {
	documents?: IDocument[];
	terms?: string;
}

export enum TermsStringSectionType {
	String = 'STRING',
	LinkedDocument = 'LINKED_DOCUMENT',
	Inline = 'INLINE'
}

export interface ITermsStringSection {
	title?: string;
	url?: string;
	content?: string;
	type: TermsStringSectionType;
}

const usePoTermsAndConditions = (travelPlanItem: IComposedTravelPlanItem) => {
	const { t } = useIntl('checkout.termsAndConditionsFormSection');
	const [productOwnerTerms, setProductOwnerTerms] =
		useState<IProductOwnerTerms>();
	const [selectedInlineTerms, setSelectedInlineTerms] =
		useState<ITermsStringSection>();

	const { loading: termsLoading, called: termsCalled } = useQuery(
		GET_PRODUCT_OWNER_BY_CODE,
		{
			variables: {
				id: travelPlanItem.productOwner.id
			},
			onCompleted: ({ product_owner_by_pk = {} }) => {
				const {
					product_owner_legal_documents = [],
					product_owner_profile = {}
				} = product_owner_by_pk;

				const productOwnerTerms = {
					documents: product_owner_legal_documents,
					terms: product_owner_profile?.terms
				};

				setProductOwnerTerms(productOwnerTerms);
			}
		}
	);

	const selectInlineTerms = (e, termsConfig: ITermsStringSection) => {
		e.preventDefault();
		setSelectedInlineTerms(termsConfig);
	};

	const getInlineTerms = (
		globalTerms: string,
		itemTerms: string
	): ITermsStringSection[] => {
		if (!globalTerms?.length && !itemTerms?.length) {
			return [
				{
					title: t('termsOfBookingLinkText'),
					url: undefined,
					type: TermsStringSectionType.String
				}
			];
		}

		const elements: ITermsStringSection[] = [];
		if (globalTerms?.length) {
			elements.push({
				title: t('termsOfBookingLinkText'),
				url: undefined,
				content: globalTerms,
				type: TermsStringSectionType.Inline
			});
		}
		if (itemTerms?.length) {
			if (elements.length) {
				elements.push({
					content: ` ${t('and')} `,
					type: TermsStringSectionType.String
				});
			}
			elements.push({
				title: t('productTermsLinkText'),
				url: undefined,
				content: itemTerms,
				type: TermsStringSectionType.Inline
			});
		}

		return elements;
	};

	const getDocumentTerms = (documents: IDocument[]): ITermsStringSection[] => {
		const checkoutDocs = documents.filter(
			d => d.location.toLowerCase() === DocumentType.Checkout
		);
		const bookingDoc = checkoutDocs.find(
			d => d.type.toLowerCase() === DocumentType.Booking
		);
		const paymentDoc = checkoutDocs.find(
			d => d.type.toLowerCase() === DocumentType.Payment
		);
		const merchandiseDoc = checkoutDocs.find(
			d => d.type.toLowerCase() === DocumentType.Merchandise
		);

		// TODO: lang

		if (!bookingDoc && !paymentDoc && !merchandiseDoc) {
			return [
				{
					title: t('termsOfBookingLinkText'),
					url: undefined,
					type: TermsStringSectionType.String
				}
			];
		}

		const elements: ITermsStringSection[] = [];

		if (bookingDoc) {
			elements.push({
				title: t('termsOfBookingLinkText'),
				url: bookingDoc.url,
				type: TermsStringSectionType.LinkedDocument
			});
		}

		if (paymentDoc) {
			if (elements.length) {
				elements.push({
					content: ` ${t('and')} `,
					type: TermsStringSectionType.String
				});
			}

			elements.push({
				title: t('termsOfPaymentLinkText'),
				url: paymentDoc.url,
				type: TermsStringSectionType.LinkedDocument
			});
		}

		if (merchandiseDoc) {
			if (elements.length) {
				elements.push({
					content: ` ${t('and')} `,
					type: TermsStringSectionType.String
				});
			}

			elements.push({
				title: t('productTermsLinkText'),
				url: merchandiseDoc.url,
				type: TermsStringSectionType.LinkedDocument
			});
		}

		return elements;
	};

	const terms = useMemo((): ITermsStringSection[] => {
		if (productOwnerTerms?.documents?.length) {
			return getDocumentTerms(productOwnerTerms.documents);
		}
		if (
			productOwnerTerms?.terms?.length ||
			(travelPlanItem.item as IActivityWishlistItem).terms?.length
		) {
			return getInlineTerms(
				productOwnerTerms?.terms,
				(travelPlanItem.item as IActivityWishlistItem).terms
			);
		}
		return [
			{
				title: t('termsOfBookingLinkText'),
				url: undefined,
				type: TermsStringSectionType.String
			}
		];
	}, [productOwnerTerms]);

	return {
		terms,
		termsCalled,
		termsLoading,
		selectedInlineTerms,
		selectInlineTerms,
		clearSelectedInlineTerms: () => setSelectedInlineTerms(undefined)
	};
};

export default usePoTermsAndConditions;
