import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import CounterItem from 'redesigncomponents/CounterItem';
import Toast from 'redesigncomponents/Toast';
import { PriceOption } from 'redesigntypes/ActivityModel';

import getNumberOfSelectedSeats from '../../getNumberOfSelectedSeats';
import { BoxHeader, BoxWrapper, TotalPrice } from '../StyledComponents';

interface IProps {
	numberOfSeatsAvailable: number;
	ticketsAvailable: PriceOption[];
	selectedTickets: {
		[key: string]: number;
	};
	setSelectedTickets: (selectedTickets: { [key: string]: number }) => void;
}
const SessionTickets: FunctionalComponent<IProps> = ({
	numberOfSeatsAvailable,
	ticketsAvailable,
	selectedTickets,
	setSelectedTickets
}) => {
	const { t } = useIntl('activityDetails.sessionTickets');

	const totalPriceString: string = useMemo(() => {
		if (ticketsAvailable?.length) {
			const currencyCode: string = ticketsAvailable?.[0].currencyCode;
			const total: number = ticketsAvailable?.reduce(
				(previousValue: number, currentValue: PriceOption) => {
					const numberOfTickets = selectedTickets?.[currentValue.id] || 0;
					const ticketPrice = currentValue.price;
					return previousValue + numberOfTickets * ticketPrice;
				},
				0
			);

			return `${total} ${currencyCode}`;
		}
		return '';
	}, [selectedTickets, ticketsAvailable]);

	const onChangeTicketQuantity = (ticketId: string, value: number) => {
		const updatedSelectedTickets = {
			...selectedTickets,
			[ticketId]: value
		};
		const updatedNumberOfSelectedSeats = getNumberOfSelectedSeats(
			updatedSelectedTickets,
			ticketsAvailable
		);
		if (updatedNumberOfSelectedSeats > numberOfSeatsAvailable) {
			Toast.info(
				t(
					'onlyLimitedNumberOfSeatsIsAvailableToast',
					{ numberOfSeatsAvailable },
					numberOfSeatsAvailable
				)
			);
		} else {
			setSelectedTickets(updatedSelectedTickets);
		}
	};

	return (
		<>
			<BoxWrapper>
				<BoxHeader>
					{t('seatsAvailableTitle')}: {numberOfSeatsAvailable}
				</BoxHeader>
				{ticketsAvailable.map((ta: PriceOption) => {
					return (
						<CounterItem
							key={ta.id}
							title={ta.label}
							subtitle={t(
								'pricePerPersonSubtitle',
								{
									price: ta.price,
									currency: ta.currencyCode,
									seatsUsed: ta.seatsUsed
								},
								ta.seatsUsed
							)}
							onChange={value => onChangeTicketQuantity(ta.id, value)}
							value={selectedTickets?.[ta.id] || 0}
							min={ta.minQuantity}
							max={ta.maxQuantity === 0 ? undefined : ta.maxQuantity}
						/>
					);
				})}
			</BoxWrapper>
			<TotalPrice>
				{t('totalPrice')}: {totalPriceString}
			</TotalPrice>
		</>
	);
};

export default SessionTickets;
