import { useMemo } from 'preact/hooks';

import {
	IActivityWishlistItem,
	IMerchandiseWishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../../../../TravelPlan/useTravelPlan';

const useTravelPlanItemFormSetup = (
	travelPlanItem: IComposedTravelPlanItem
) => {
	const hasLeadTravelerSection = useMemo(() => {
		return (
			travelPlanItem.type === WishlistItemTypes.TRANSPORT ||
			travelPlanItem.type === WishlistItemTypes.STAY ||
			true // direct booking - hardcoded for now
		);
	}, [travelPlanItem]);

	const hasAdditionalBookingFieldsSection = useMemo(() => {
		if (travelPlanItem.type === WishlistItemTypes.ACTIVITY) {
			const activityWishlistItem = travelPlanItem.item as IActivityWishlistItem;
			return (
				activityWishlistItem.fields?.length ||
				activityWishlistItem.pickupLocations?.length
			);
		} else if (travelPlanItem.type === WishlistItemTypes.MERCHANDISE) {
			const merchandiseWishlistItem =
				travelPlanItem.item as IMerchandiseWishlistItem;
			return (
				merchandiseWishlistItem.fields?.length ||
				merchandiseWishlistItem.pickupLocations?.length
			);
		}
	}, [travelPlanItem]);

	return {
		hasLeadTravelerSection,
		hasAdditionalBookingFieldsSection
	};
};

export default useTravelPlanItemFormSetup;
