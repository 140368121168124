import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import cloneDeep from 'lodash.clonedeep';

import { WishlistItemTypes } from 'redesigncache/wishlist/types';

import { checkoutVar, initialCheckoutValue } from './cache';
import { ICheckout, ITravelPlan } from './types';

export function useCheckout() {
	const checkout: ICheckout = useReactiveVar<ICheckout>(checkoutVar);

	const addTravelPlanToCheckout = (
		oldValue: ICheckout,
		payload: ITravelPlan
	) => {
		const { travelPlans } = oldValue;
		if (payload.savedTravelPlan) {
			const index = travelPlans.findIndex(el => el.id === payload.id);
			if (index === -1) {
				travelPlans.push(payload);
			}
		} else {
			const index = travelPlans.findIndex(
				el =>
					el.travelPlanName === payload.travelPlanName && !el.savedTravelPlan
			);
			if (index === -1) {
				travelPlans.push(payload);
			} else {
				travelPlans[index] = {
					...payload,
					travelPlanItems: [
						...travelPlans[index].travelPlanItems,
						...payload.travelPlanItems
					]
				};
			}
		}
		return {
			...oldValue,
			travelPlans
		};
	};

	const addToCheckout = (payload: ITravelPlan) => {
		const oldValue = cloneDeep(checkout);
		const updated = addTravelPlanToCheckout(oldValue, payload);
		checkoutVar(updated);
	};

	const addAllToCheckout = (payload: ITravelPlan[]) => {
		let oldValue = cloneDeep(checkout);
		for (const item of payload) {
			oldValue = addTravelPlanToCheckout(oldValue, item);
		}
		checkoutVar(oldValue);
	};

	const removeFromCheckout = (payload: ITravelPlan) => {
		const oldValue = checkout;
		const { travelPlans } = oldValue;

		const marketplaceItemIdx = travelPlans.findIndex(
			i => i.travelPlanName === payload.travelPlanName
		);

		travelPlans.splice(marketplaceItemIdx, 1);

		const updated = {
			...oldValue,
			travelPlans
		};
		checkoutVar(updated);
	};

	const updateCheckout = (payload: ITravelPlan[]) => {
		checkoutVar({ travelPlans: payload });
	};

	const clearCheckout = () => {
		checkoutVar(initialCheckoutValue);
	};

	const allItemsFullyPaid = useMemo(() => {
		return (
			checkout.travelPlans.length &&
			checkout.travelPlans.every(travelPlan =>
				travelPlan.travelPlanItems.every(travelPlanItem => travelPlanItem.paid)
			)
		);
	}, [checkout?.travelPlans]);

	const merchandiseOnlyCheckout = useMemo(() => {
		return (
			checkout.travelPlans.length &&
			checkout.travelPlans.every(travelPlan =>
				travelPlan.travelPlanItems.every(
					travelPlanItem =>
						travelPlanItem.type === WishlistItemTypes.MERCHANDISE
				)
			)
		);
	}, [checkout?.travelPlans]);

	return {
		checkout,
		allItemsFullyPaid,
		merchandiseOnlyCheckout,
		addToCheckout,
		addAllToCheckout,
		removeFromCheckout,
		updateCheckout,
		clearCheckout
	};
}
