import { FunctionalComponent } from 'preact';
import { Field } from 'react-final-form';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import FieldCheckbox from 'redesigncomponents/FieldCheckbox';
import { IComposedTravelPlanItem } from 'redesignscreens/Checkout/components/TravelPlan/useTravelPlan';
import { FontWeight } from 'styles/defaultTheme';

import TermsAndConditionsPreview from './TermsAndConditionsPreview';
import usePoTermsAndConditions, {
	ITermsStringSection,
	TermsStringSectionType
} from './usePoTermsAndConditions';

export const TermsLink = styled.a`
	font-weight: ${FontWeight.semibold};
	cursor: pointer;
`;

export const CheckboxContainer = styled.div`
	margin-top: 1.5rem;
`;

export const CheckboxLabel = styled.span``;

interface IProps {
	travelPlanItem: IComposedTravelPlanItem;
}

const TermsAndConditionsFormSection: FunctionalComponent<IProps> = ({
	travelPlanItem
}) => {
	const { t } = useIntl('checkout.termsAndConditionsFormSection');
	const {
		terms,
		termsCalled,
		selectInlineTerms,
		selectedInlineTerms,
		clearSelectedInlineTerms
	} = usePoTermsAndConditions(travelPlanItem);

	return (
		termsCalled && (
			<>
				<CheckboxContainer>
					<Field
						label={
							<CheckboxLabel>
								{t('iAcceptTheCheckboxLabel')}{' '}
								{terms.map((term: ITermsStringSection) => {
									switch (term.type) {
										case TermsStringSectionType.String:
											return term.content;
										case TermsStringSectionType.Inline:
											return (
												<TermsLink onClick={e => selectInlineTerms(e, term)}>
													{term.title}
												</TermsLink>
											);
										case TermsStringSectionType.LinkedDocument:
											return (
												<TermsLink href={term.url} target="_blank">
													{term.title}
												</TermsLink>
											);
									}
								})}
							</CheckboxLabel>
						}
						required
						component={FieldCheckbox}
						name="termsAndConditions"
						id="termsAndConditions"
						type="checkbox"
					/>
				</CheckboxContainer>
				{selectedInlineTerms && (
					<TermsAndConditionsPreview
						isOpen={!!selectedInlineTerms}
						title={selectedInlineTerms?.title}
						content={selectedInlineTerms?.content}
						onClose={clearSelectedInlineTerms}
					/>
				)}
			</>
		)
	);
};

export default TermsAndConditionsFormSection;
