import { useReactiveVar } from '@apollo/client';
import { StateUpdater } from 'preact/hooks';

import {
	WidgetFlow,
	WidgetFlowType,
	widgetFlowVar
} from 'redesigncache/bookingFlow/cache';

const useFlowSetup = (): {
	flow: WidgetFlow;
	setFlow: StateUpdater<WidgetFlow>;
	setMainFlow: () => void;
} => {
	const flow = useReactiveVar(widgetFlowVar);

	const setFlow = (flow: WidgetFlow) => widgetFlowVar(flow);

	const setMainFlow = () => setFlow({ type: WidgetFlowType.Main });

	return {
		flow,
		setFlow,
		setMainFlow
	};
};

export default useFlowSetup;
