import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import {
	IActivityWishlistItem,
	WishlistItem
} from 'redesigncache/wishlist/types';

type ActivityWishlistItemDetails = {
	title: string;
	city: string;
	datetime: string;
	total: string;
};

const useActivityWishlistItemRedirectModal = (wishlistItem: WishlistItem) => {
	const { t } = useIntl('activityDetails.activityWishlistItemDetails');

	const activityWishlistItemDetails =
		useMemo((): ActivityWishlistItemDetails => {
			const activityItem = wishlistItem.item as IActivityWishlistItem;
			const details: ActivityWishlistItemDetails = {
				title: `${activityItem.supplierName}: ${activityItem.name}`,
				city: activityItem.product_location?.city,
				datetime: `${dayjs(activityItem.startTimeLocal).format(
					'ddd, DD MMM YYYY'
				)} @ ${dayjs.utc(activityItem.startTimeLocal).format('HH:mm')}`,
				total: t('total', {
					amount: activityItem.price.amount,
					currency: activityItem.price.currency
				})
			};

			return details;
		}, [wishlistItem]);

	return {
		activityWishlistItemDetails
	};
};

export default useActivityWishlistItemRedirectModal;
