import { FunctionalComponent } from 'preact';
import { MarkupText } from 'preact-i18n';

import { PriceRate } from 'redesigntypes/AccommodationModel';

type Props = {
	priceRate: PriceRate;
};
const HeroSubtitle: FunctionalComponent<Props> = ({ priceRate }) => {
	return (
		priceRate && (
			<MarkupText
				id="accommodationDetails.heroSubtitle"
				fields={{ price: priceRate?.price, currency: priceRate?.currency }}
			/>
		)
	);
};

export default HeroSubtitle;
