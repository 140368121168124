import { FunctionalComponent } from 'preact';

import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import FieldSelect from 'redesigncomponents/FieldSelect';
import { ISegment } from 'redesigntypes/Itinerary';

import {
	ButtonWrapper,
	HeaderTitle,
	HeaderTitleInfo,
	LeftHeaderContent,
	RightHeaderContent,
	TicketAccommodationRow,
	TicketAccommodationRowElement,
	TicketAccommodationSubtitle,
	TicketChipContainer,
	TicketSectionHeader,
	TicketTypeSection
} from './StyledComponents';
import TicketChip from './TicketChip';

interface IProps {
	segment: ISegment;
	selectedSegmentOptions: any;
	open: boolean;
	priceGroupValue: string;
	setSegmentSeatPlacementOrientation: (orientationValue) => void;
	setSegmentSeatPlacementCompartment: (compartmentValue) => void;
	setSegmentSeatPlacementCharacteristic: (characteristicValue) => void;

	setOpenSection: () => void;
}

const AccommodationTypeSectionForm: FunctionalComponent<IProps> = ({
	segment,
	setSegmentSeatPlacementOrientation,
	setSegmentSeatPlacementCompartment,
	setSegmentSeatPlacementCharacteristic,
	selectedSegmentOptions,
	priceGroupValue,
	open,
	setOpenSection
}) => {
	const selectedSeatPlacement = segment.segmentsPlacements?.find(
		placement => priceGroupValue === placement.priceGroup
	);

	return (
		<>
			<TicketTypeSection>
				<TicketSectionHeader>
					<LeftHeaderContent>
						<HeaderTitle>
							{open ? 'Choose accommodation' : 'Accommodation'}
						</HeaderTitle>
						<HeaderTitleInfo>
							{segment.departureLocation.name} - {segment.arrivalLocation.name}
						</HeaderTitleInfo>
					</LeftHeaderContent>
					{!open && (
						<RightHeaderContent>
							<TicketChipContainer>
								{selectedSegmentOptions?.compartmentValue?.value && (
									<TicketChip
										title={selectedSegmentOptions?.compartmentValue?.label}
									></TicketChip>
								)}
								{selectedSegmentOptions?.orientationValue?.value && (
									<TicketChip
										title={selectedSegmentOptions?.orientationValue?.label}
									></TicketChip>
								)}
								{selectedSegmentOptions?.characteristicValue?.value && (
									<TicketChip
										title={selectedSegmentOptions?.characteristicValue?.label}
									></TicketChip>
								)}
							</TicketChipContainer>
							<ButtonWrapper>
								<Button
									title="Edit"
									onClick={setOpenSection}
									buttonStyleType={ButtonStyleTypes.secondary}
								></Button>
							</ButtonWrapper>
						</RightHeaderContent>
					)}
				</TicketSectionHeader>

				{open && (
					<>
						<TicketAccommodationSubtitle>
							Compartment property and desired placement
						</TicketAccommodationSubtitle>
						<TicketAccommodationRow>
							{selectedSeatPlacement?.placementCompartments?.length !== 0 && (
								<TicketAccommodationRowElement>
									<FieldSelect
										label="Desired seat type"
										primary
										input={{
											onChange: value =>
												setSegmentSeatPlacementCompartment(value),
											value: selectedSegmentOptions?.compartmentValue,
											name: ''
										}}
										options={selectedSeatPlacement?.placementCompartments.map(
											compartment => ({
												value: compartment.value,
												label: compartment.title
											})
										)}
										smallPadding
									/>
								</TicketAccommodationRowElement>
							)}
							{selectedSeatPlacement?.placementOrientation?.length !== 0 && (
								<TicketAccommodationRowElement>
									<FieldSelect
										label="Desired seat"
										primary
										input={{
											onChange: value =>
												setSegmentSeatPlacementOrientation(value),
											value: selectedSegmentOptions?.orientationValue,
											name: ''
										}}
										options={selectedSeatPlacement?.placementOrientation.map(
											orientation => ({
												value: orientation.value,
												label: orientation.title
											})
										)}
										smallPadding
									/>
								</TicketAccommodationRowElement>
							)}
							{selectedSeatPlacement?.placementCharacteristics?.length !==
								0 && (
								<TicketAccommodationRowElement>
									<FieldSelect
										label="Seat characteristics"
										primary
										input={{
											onChange: value =>
												setSegmentSeatPlacementCharacteristic(value),
											value: selectedSegmentOptions?.characteristicValue,
											name: ''
										}}
										options={selectedSeatPlacement?.placementCharacteristics.map(
											orientation => ({
												value: orientation.value,
												label: orientation.title
											})
										)}
										smallPadding
									/>
								</TicketAccommodationRowElement>
							)}
						</TicketAccommodationRow>
					</>
				)}
			</TicketTypeSection>
		</>
	);
};

export default AccommodationTypeSectionForm;
