import styled from 'styled-components';

const DismissWrapper = styled.span`
  text-transform: uppercase;
  align-self: center;
  margin-right: 6px;
  &:hover {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

const DismissButton = ({ closeToast }) => (
  <DismissWrapper className="material-icons" onClick={closeToast}>
    Dismiss
  </DismissWrapper>
);

export default DismissButton;
