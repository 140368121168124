import { makeVar } from '@apollo/client';

import { IAccommodationCategory } from 'redesigntypes/AccommodationCategory';
import { IActivityType } from 'redesigntypes/ActivityType';
import { ICountry } from 'redesigntypes/Country';
import { IGender } from 'redesigntypes/Gender';
import { TransportSortOption } from 'redesigntypes/TransportSortOption';
import { ITransportType } from 'redesigntypes/TransportType';

export type Codebook = {
	countries: ICountry[];
	genders: IGender[];
	accommodationCategories: IAccommodationCategory[];
	transportTypes: ITransportType[];
	airplaneTransportSortOptions: TransportSortOption[];
	trainbusTransportSortOptions: TransportSortOption[];
	activityTypes: IActivityType[];
};

export const codebookVar = makeVar<Codebook>({
	countries: [],
	genders: [],
	accommodationCategories: [],
	transportTypes: [],
	airplaneTransportSortOptions: [],
	trainbusTransportSortOptions: [],
	activityTypes: []
});
