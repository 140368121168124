import { components } from 'react-select';

import styled from 'styled-components';

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Title = styled.div``;

const Subtitle = styled.div`
	font-size: 12px;
`;

const Price = styled.div`
	display: flex;
	flex: 1 0 auto;
	align-items: flex-end;
	font-weight: bold;
	justify-content: flex-end;
	padding-left: 0.5rem;
`;

const AvailableRoomSelectOption = ({ data, ...rest }) => {
	const {
		label,
		extra: {
			rate: { amount, currency, description }
		}
	} = data;
	return (
		<components.Option {...rest}>
			<OptionWrapper>
				<div>
					<Title>{label}</Title>
					<Subtitle>{description}</Subtitle>
				</div>

				<Price>
					{amount} {currency}
				</Price>
			</OptionWrapper>
		</components.Option>
	);
};

export default AvailableRoomSelectOption;
