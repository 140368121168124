import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import { IStayWishlistItem, WishlistItem } from 'redesigncache/wishlist/types';

type AccommodationWishlistItemDetails = {
	title: string;
	city: string;
	datetime: string;
	total: string;
};

const useAccommodationWishlistItemRedirectModal = (
	wishlistItem: WishlistItem
) => {
	const { t } = useIntl('activityDetails.activityWishlistItemDetails');

	const accommodationWishlistItemDetails =
		useMemo((): AccommodationWishlistItemDetails => {
			const accommodationItem = wishlistItem.item as IStayWishlistItem;
			const details: AccommodationWishlistItemDetails = {
				title: `${accommodationItem.accommodationName}: ${accommodationItem.title}`,
				city: accommodationItem.product_location?.city,
				datetime: `${dayjs(accommodationItem.travelPeriod[0]).format(
					'ddd, DD MMM YYYY'
				)} @ ${dayjs.utc(accommodationItem.travelPeriod[0]).format('HH:mm')}`,
				total: t('total', {
					amount: accommodationItem.price.amount,
					currency: accommodationItem.price.currency
				})
			};

			return details;
		}, [wishlistItem]);

	return {
		accommodationWishlistItemDetails
	};
};

export default useAccommodationWishlistItemRedirectModal;
