import { useMemo } from 'preact/hooks';

import useCodebook from 'redesigncache/codebook/useCodebook';
import { TransportSortOption } from 'redesigntypes/TransportSortOption';

const useTrainbusTransportSortCodebookOptions = () => {
	const { trainbusTransportSortOptions: trainbusTransportSortOptionsData } =
		useCodebook(['trainbusTransportSortOptions']);

	const trainbusTransportSortOptions = useMemo(() => {
		return trainbusTransportSortOptionsData.map((tso: TransportSortOption) => ({
			value: tso.key,
			label: tso.name
		}));
	}, [trainbusTransportSortOptionsData]);

	return { trainbusTransportSortOptions };
};

export default useTrainbusTransportSortCodebookOptions;
