import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import {
	AutofillDialogPortal,
	AutofillDialogWrapper
} from './components/StyledComponents';

const useAutofill = () => {
	const elementId = 'autofill-dialog-portal';
	const AutofillContainer = useMemo(() => {
		const AutofillContainerComponent: FunctionalComponent = () => {
			return (
				<AutofillDialogWrapper>
					<AutofillDialogPortal id={elementId}></AutofillDialogPortal>
				</AutofillDialogWrapper>
			);
		};
		return AutofillContainerComponent;
	}, []);

	return {
		elementId,
		AutofillContainer
	};
};

export default useAutofill;
