import validate from 'validate.js';

import useIntl from 'hooks/useIntl';
import { IActivityWishlistItem } from 'redesigncache/wishlist/types';
import { Option } from 'redesigntypes/Option';

const usePickupLocations = (item: IActivityWishlistItem) => {
	const { t } = useIntl('checkout.activityBookingFieldsFormSection');
	const { t: validationMessages } = useIntl('common.validationMessages');
	let pickupLocationOptions: Option[];
	if (Array.isArray(item.pickupLocations) && item.pickupLocations.length) {
		pickupLocationOptions = item.pickupLocations
			.sort((pL1, pL2) => pL1.minutesPrior - pL2.minutesPrior)
			.map(pL => ({
				label: `${pL.locationName}, ${pL.address || ''} (${t(
					'minutesPrior',
					{ minutesPrior: pL.minutesPrior },
					pL.minutesPrior || 0
				)})`,
				value: pL.locationName,
				extra: {
					pickupLocation: pL
				}
			}));
	}

	return {
		hasPickupLocations:
			Array.isArray(item.pickupLocations) && item.pickupLocations.length,
		pickupLocationOptions,
		validatePickupLocations: value =>
			validate.single(value, {
				presence: { message: validationMessages('fieldRequired') }
			})
	};
};

export default usePickupLocations;
