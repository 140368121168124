import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const ArrowIconWrapper = styled.li`
	svg {
		width: 93px;
		@media ${DeviceBreakpoints.small} {
			width: 56px;
		}
	}
`;

export const ItemIconWrapper = styled.div`
	margin-right: 0.625rem;
	display: flex;
	@media ${DeviceBreakpoints.small} {
		margin-right: 0.5rem;
	}
`;

export const Link = styled.span`
	white-space: nowrap;
	${({ onClick }) => onClick && 'cursor: pointer;'};
	${({ active }) => active && 'text-transform: uppercase;'};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const StepsContainer = styled.ul`
	list-style: none;
	display: flex;
	font-size: ${FontSize.smSize};
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
	background-color: ${Colors.V0};
	border: 1px solid ${Colors.V50};
	border-radius: 0.125rem;
	padding: 0.5rem 0.75rem;
	margin-bottom: 0.5rem;
	overflow-y: auto;
	@media ${DeviceBreakpoints.small} {
		padding: 0.5rem;
	}
`;

export const WizardItem = styled.li`
	background-color: ${props =>
		props.active ? `${Colors.V30}` : `${Colors.V10}`};
	color: ${props => (props.active ? `${Colors.V700}` : `${Colors.V100}`)};
	font-weight: ${props =>
		props.active ? `${FontWeight.bold}` : `${FontWeight.normal}`};
	border: ${props =>
		props.active ? `1px solid ${Colors.V50}` : `1px solid ${Colors.V30}`};
	border-radius: 0.125rem;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1.75rem;
`;
