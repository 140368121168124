import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import { Option } from 'redesigntypes/Option';

import CheckIcon from 'assets/check.svg';
import SortByIcon from 'assets/sort.svg';

import {
	CheckSvgContainer,
	DropDownBody,
	DropdownContainer,
	DropdownHeader,
	DropdownItem,
	ItemTitle,
	SelectedTitle,
	SortByTitle,
	SvgWrapper
} from './components/StyledComponents';

interface IProps {
	options: Option[];
	value: Option;
	onSortChange: (option: Option) => void;
}

const SortBy: FunctionalComponent<IProps> = ({
	options,
	value,
	onSortChange
}) => {
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	return (
		<DropdownContainer>
			<DropdownHeader onClick={toggleCollaped}>
				<SortByTitle>Sort by:</SortByTitle>
				<SelectedTitle>{value?.label || 'Default'}</SelectedTitle>
				<SvgWrapper collapsed={collapsed}>
					{<SortByIcon></SortByIcon>}
				</SvgWrapper>
			</DropdownHeader>
			{collapsed && (
				<DropDownBody>
					{options.map(o => (
						<DropdownItem
							onClick={() => {
								onSortChange(o);
								toggleCollaped();
							}}
						>
							<CheckSvgContainer>
								{value?.value === o.value && <CheckIcon></CheckIcon>}
							</CheckSvgContainer>
							<ItemTitle active={value?.value === o.value}>{o.label}</ItemTitle>
						</DropdownItem>
					))}
				</DropDownBody>
			)}
		</DropdownContainer>
	);
};

export default SortBy;
