import styled from 'styled-components';

import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

export const EmptyWishlistContainer = styled.div`
	text-align: center;
`;

export const WishlistHeaderTitle = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
	margin-bottom: 0.5rem;
`;

export const WishlistHeaderSubTitle = styled.p`
	color: ${Colors.V500};
`;

export const IconContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	margin-top: 4.5rem;
	cursor: pointer;
`;

export const IconWrapper = styled.div`
	svg {
		width: 3.625rem;
	}
`;

export const IconTitle = styled.span`
	font-size: ${FontSize.lgSize};
`;
