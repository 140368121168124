import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import useToggle from 'hooks/useToggle';
import { ITravelPlan } from 'redesigncache/checkout/types';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import GetGestFooter from 'redesigncomponents/Footer/GetGestFooter';
import LoadingModal from 'redesigncomponents/LoadingModal';
import CheckoutWrapper from 'redesigncomponents/WidgetContentWrappers/CheckoutWrapper';

import { isPaymentProcessingVar } from './cache';
import { StyledBodyWrapper } from './components/StyledComponents';
import TravelPlan from './components/TravelPlan';
import EmptyCheckout from './components/TravelPlanItem/components/EmptyCheckout';
import GetGestPaymentConfirmationModal from './components/TravelPlanItem/components/GetGestPaymentConfirmationModal';
import LeaveGetGestModal from './components/TravelPlanItem/components/LeaveGetGestModal';
import useAutofill from './useAutofill';

interface IProps {}

const Checkout: FunctionalComponent<IProps> = () => {
	const isLoadingModalOpen = useReactiveVar(isPaymentProcessingVar);
	const history = useHistory();
	const { AutofillContainer } = useAutofill();
	const {
		checkout: { travelPlans },
		allItemsFullyPaid
	} = useCheckout();

	const [paymentConfirmationDialogOpen, togglePaymentConfirmationDialogOpen] =
		useToggle();
	const [leaveCheckoutModalOpen, toggleLeaveCheckoutModalOpen] = useToggle();

	useEffect(() => {
		if (allItemsFullyPaid) {
			togglePaymentConfirmationDialogOpen();
		}
	}, [allItemsFullyPaid]);

	const goBack = () => history.goBack();

	return (
		<CheckoutWrapper
			onBack={!allItemsFullyPaid ? toggleLeaveCheckoutModalOpen : goBack}
		>
			<StyledBodyWrapper>
				{travelPlans.map((travelPlan: ITravelPlan) => (
					<TravelPlan travelPlan={travelPlan} canBeEdited />
				))}

				{paymentConfirmationDialogOpen && (
					<GetGestPaymentConfirmationModal
						open={paymentConfirmationDialogOpen}
					/>
				)}
				{leaveCheckoutModalOpen && (
					<LeaveGetGestModal
						open={leaveCheckoutModalOpen}
						toggleOpen={toggleLeaveCheckoutModalOpen}
					/>
				)}
				{!travelPlans.length && <EmptyCheckout />}
			</StyledBodyWrapper>
			<AutofillContainer />
			<GetGestFooter />
			<LoadingModal isOpen={isLoadingModalOpen} />
		</CheckoutWrapper>
	);
};

export default Checkout;
