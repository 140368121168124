import styled from 'styled-components';

import Loading from 'components/Loading';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	DeviceBreakpoints,
	FontSize,
	FontWeight
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	display: flex;
	background: ${Colors.V30};
	padding: 3px 0 3px 0;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	@media ${DeviceBreakpoints.small} {
		flex-direction: column;
		height: ${BodyWrapperHeight.mobile};
	}
`;

export const LeftSide = styled.div`
	background: ${Colors.V0};
	border-radius: 0 4px 4px 0;
	padding: 1.25rem;
	height: 100%;
	width: 50%;
	position: relative;
	> div {
		margin-bottom: 1.25rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@media ${DeviceBreakpoints.small} {
		width: 100%;
		padding: 0.5rem 0.75rem;
		background: ${Colors.V20};
		> div {
			border: 1px solid transparent;
		}
		> div > label,
		> div > div > div {
			background: ${Colors.V20};
		}
	}
`;

export const RightSide = styled.div`
	padding: 1rem 1.25rem;
	height: 100%;
	width: ${props => (props.expand ? '100%' : '50%')};
	@media ${DeviceBreakpoints.small} {
		width: 100%;
		padding: 0.75rem;
	}
`;

export const SectionTitle = styled.h4`
	margin-bottom: 0.625rem;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const WidgetTitle = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.semibold};
	margin-bottom: 1.25rem;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const ThumbnailContainer = styled.div`
	overflow-y: auto;
	max-height: calc(100% - 3.4rem);
	> div {
		margin-bottom: ${props => (props.grid ? 0 : '1.25rem')};
		&:last-child {
			margin-bottom: 0;
		}
	}

	display: ${props => props.grid && 'grid'};
	grid-template-columns: ${props => props.grid && '1fr 1fr'};
	gap: ${props => props.grid && '1.25rem'};
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: ${props => props.grid && '1fr'};
		gap: ${props => props.grid && '0.75rem'};
		> div {
			margin-bottom: ${props => (props.grid ? 0 : '0.5rem')};
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
`;

export const CheckboxContainer = styled.div`
	padding: 0.5rem;
	background: ${Colors.V20};
	border-radius: 0.25rem;
	border: 1px solid ${Colors.V30};
	position: absolute;
	bottom: 1.25rem;
	width: calc(100% - 2.5rem);
	label {
		font-weight: ${FontWeight.bold};
	}
	@media ${DeviceBreakpoints.small} {
		position: relative;
		width: 100%;
		border: 1px solid transparent;
		bottom: 0.25rem;
	}
`;

export const InfiniteScrollLoader = styled(Loading)`
	height: 20px;
	padding-bottom: 20px;
`;

export const ListWrapper = styled.div`
	overflow-y: auto;
	max-height: calc(100% - 3.4rem);
	padding-right: 5px;
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 1.25rem;
`;
