import { FunctionalComponent } from 'preact';
import { useParams } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import ActivityAddedToWishlistRedirectModal from 'redesignscreens/Main/Activities/ActivityDetails/components/ActivityAddedToWishlistRedirectModal';
import capitalizeWords from 'utils/string/capitalizeWords';

import useActivityListCache from '../ActivityList/cache/useActivityListCache';
import ActivityDetailsContainer from './ActivityDetailsContainer';
import useActivityDetails from './ActivityDetailsContainer/useActivityDetails';
import useActivityOffer from './ActivityDetailsContainer/useActivityOffer';
import useAddActivityToWishlist from './ActivityDetailsContainer/useAddActivityToWishlist';
import { StyledBodyWrapper } from './components/StyledComponents';

interface IProps {}

const ActivityDetails: FunctionalComponent<IProps> = () => {
	const { t: activityDetailsTranslations } = useIntl('activityDetails');
	const [itemAddedToWishlistOpen, setItemAddedToWishlistOpen] = useToggle();
	const { travelPeriod } = useDestinationAndPeriod();
	const { id: externalId } = useParams();
	const { activity } = useActivityDetails(externalId);
	const { selectedSession, selectedTickets, selectedActivityOfferValid } =
		useActivityOffer();

	const { addToWishlist, addedWishlistItem } = useAddActivityToWishlist(
		activity,
		selectedSession,
		selectedTickets
	);

	const { adultsCount } = useActivityListCache();

	const handleAddToWishlist = () => {
		addToWishlist();

		setItemAddedToWishlistOpen(true);
	};

	return (
		<MainWrapper
			currentRouteSlug={activity?.name && capitalizeWords(activity?.name)}
		>
			<StyledBodyWrapper>
				<ActivityDetailsContainer
					externalId={externalId}
					travelPeriod={travelPeriod as [Date, Date]}
					people={adultsCount}
				/>
			</StyledBodyWrapper>
			{itemAddedToWishlistOpen && addedWishlistItem && (
				<ActivityAddedToWishlistRedirectModal
					item={addedWishlistItem}
					open={itemAddedToWishlistOpen}
					toggleOpen={setItemAddedToWishlistOpen}
				/>
			)}
			<Footer>
				{selectedActivityOfferValid && (
					<Button
						title={activityDetailsTranslations('addToWishlistButtonTitle')}
						onClick={handleAddToWishlist}
					/>
				)}
			</Footer>
		</MainWrapper>
	);
};

export default ActivityDetails;
