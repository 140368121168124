import { FunctionalComponent } from 'preact';

import CheckoutHeader from 'redesigncomponents/Header/CheckoutHeader';
import StyledToastContainer from 'redesigncomponents/Toast/ToastContainer';

interface IProps {
	onBack?: () => void;
}

const CheckoutWrapper: FunctionalComponent<IProps> = ({ children, onBack }) => {
	return (
		<>
			<CheckoutHeader onBack={onBack} />
			<StyledToastContainer />
			{children}
		</>
	);
};

export default CheckoutWrapper;
