import { FunctionalComponent } from 'preact';

import Counter from 'redesigncomponents/Counter';

import {
	ItemSubtitle,
	ItemTitle,
	ItemWrapper,
	Left,
	Right,
	TextWrapper
} from './components/StyledComponents';

interface IProps {
	title?: string;
	subtitle?: string;
	value: number;
	onChange: (number) => void;
	min?: number;
	max?: number;
	enableKeyboardInput?: boolean;
}

const CounterItem: FunctionalComponent<IProps> = ({
	title,
	subtitle,
	value,
	onChange,
	min,
	max,
	enableKeyboardInput
}) => {
	return (
		<ItemWrapper>
			<Left>
				<TextWrapper>
					<ItemTitle>{title}</ItemTitle>
					<ItemSubtitle>{subtitle}</ItemSubtitle>
				</TextWrapper>
			</Left>
			<Right>
				<Counter
					value={value}
					onChange={onChange}
					min={min}
					max={max}
					enableKeyboardInput={enableKeyboardInput}
				></Counter>
			</Right>
		</ItemWrapper>
	);
};

export default CounterItem;
