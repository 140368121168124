import { ComponentChildren, FunctionalComponent } from 'preact';

import {
	BoxContainer,
	OptionsHeader,
	HeaderWrapper
} from './components/StyledComponents';

interface IProps {
	Icon?: FunctionalComponent;
	children: ComponentChildren;
	title?: string;
}

const OptionBoxContainer: FunctionalComponent<IProps> = ({
	children,
	Icon,
	title
}) => {
	return (
		<BoxContainer>
			<HeaderWrapper>
				{Icon && <Icon />}
				<OptionsHeader>{title}</OptionsHeader>
			</HeaderWrapper>
			{children}
		</BoxContainer>
	);
};

export default OptionBoxContainer;
