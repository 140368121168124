import { FunctionalComponent } from 'preact';

import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import RadioButton from 'redesigncomponents/RadioButton/stories';
import { IItinerary } from 'redesigntypes/Itinerary';

import RadioGroup from './RadioGroup';
import {
	ButtonWrapper,
	HeaderTitle,
	HeaderTitleInfo,
	LeftHeaderContent,
	RightHeaderContent,
	TicketChipContainer,
	TicketSectionHeader,
	TicketTypeSection
} from './StyledComponents';
import TicketChip from './TicketChip';

interface IProps {
	itinerary: IItinerary;
	defaultOpen?: boolean;
	selectedItineraryOptions: any;
	open: boolean;
	setItineraryPriceOptions: (
		priceGroupKey: string,
		flexibilityIndex: number,
		flexibilityVariantNumber: number
	) => void;
	setOpenSection: () => void;
}

const TicketTypeSectionForm: FunctionalComponent<IProps> = ({
	itinerary,
	selectedItineraryOptions,
	setItineraryPriceOptions,
	open,
	setOpenSection
}) => {
	const selectedFlexibility = itinerary.priceGroups?.find(
		priceGroup => priceGroup.value === selectedItineraryOptions?.priceGroupValue
	)?.passengersPrices?.[0].priceFlexibilities?.[
		selectedItineraryOptions?.flexibilityIndex
	];
	const firstSegment = itinerary.segments[0];
	const lastSegment = itinerary.segments[itinerary.segments.length - 1];
	return (
		<>
			<TicketTypeSection>
				<TicketSectionHeader>
					<LeftHeaderContent>
						<HeaderTitle>
							{open ? 'Choose ticket type' : 'Ticket type'}
						</HeaderTitle>
						<HeaderTitleInfo>
							{firstSegment.departureLocation.name} -{' '}
							{lastSegment.arrivalLocation.name}
						</HeaderTitleInfo>
					</LeftHeaderContent>
					{!open && (
						<RightHeaderContent>
							<TicketChipContainer>
								{selectedFlexibility?.flexibility && (
									<TicketChip
										title={selectedFlexibility?.flexibility}
									></TicketChip>
								)}
							</TicketChipContainer>
							<ButtonWrapper>
								<Button
									title="Edit"
									onClick={setOpenSection}
									buttonStyleType={ButtonStyleTypes.secondary}
								></Button>
							</ButtonWrapper>
						</RightHeaderContent>
					)}
				</TicketSectionHeader>

				{open && (
					<>
						{itinerary.priceGroups.map(priceGroup => {
							return (
								<RadioGroup
									title={`Seat from ${priceGroup.totalBasePrice.amount} ${priceGroup.totalBasePrice.currency}`}
									additionalTitle={priceGroup.title}
								>
									{priceGroup.passengersPrices[0].priceFlexibilities.map(
										(flexibility, flexibilityIndex) => {
											return (
												<RadioButton
													radioLabel={flexibility.flexibility}
													infoLabel={`+ ${flexibility.price.amount} ${
														flexibility.price.currency ||
														priceGroup.totalBasePrice.currency
													}`}
													checked={
														selectedItineraryOptions?.priceGroupValue ===
															priceGroup.value &&
														selectedItineraryOptions?.flexibilityIndex ===
															flexibilityIndex
													}
													onClick={() => {
														setItineraryPriceOptions(
															priceGroup.value,
															flexibilityIndex,
															flexibility.flexibilityVariantNumber
														);
													}}
												></RadioButton>
											);
										}
									)}
								</RadioGroup>
							);
						})}
					</>
				)}
			</TicketTypeSection>
		</>
	);
};

export default TicketTypeSectionForm;
