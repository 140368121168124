import { useMemo } from 'preact/hooks';

import {
	ITransportWishlistItem,
	WishlistItemType,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import { DetailTransportTypes } from 'types/enums';

import BusIcon from 'assets/icons/transport/bus.svg';
import ActivityIcon from 'assets/icons/transport/destination.svg';
import AccommodationIcon from 'assets/icons/transport/hotel.svg';
import AirplaneIcon from 'assets/icons/transport/plane.svg';
import TrainIcon from 'assets/icons/transport/train.svg';

export const TransportTypesIconConfig = {
	[DetailTransportTypes.Airplane]: AirplaneIcon,
	[DetailTransportTypes.TrainBus]: TrainIcon,
	[DetailTransportTypes.Air]: AirplaneIcon,
	[DetailTransportTypes.Bus]: BusIcon,
	[DetailTransportTypes.Train]: TrainIcon
};

const useItemIcon = (type: WishlistItemTypes, item: WishlistItemType) => {
	const Icon = useMemo(() => {
		switch (type) {
			case WishlistItemTypes.ACTIVITY:
				return ActivityIcon;
			case WishlistItemTypes.MERCHANDISE:
				return ActivityIcon;
			case WishlistItemTypes.STAY:
				return AccommodationIcon;
			case WishlistItemTypes.TRANSPORT: {
				const transportItem = item as ITransportWishlistItem;
				const transportType = transportItem.transport_type
					? transportItem.transport_type.split(', ')[0]
					: transportItem.type_of_transport.split(', ')[0];
				return TransportTypesIconConfig[transportType];
			}
		}
	}, [type, item]);

	return { Icon };
};

export default useItemIcon;
