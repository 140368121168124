import styled from 'styled-components';

import { FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const TravelPlanHeader = styled.div`
	margin-bottom: 1.2rem;
`;

export const TravelPlanTitle = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
	margin-bottom: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.lgSize};
	}
`;

export const TravelPlanSubtitle = styled.h6`
	font-size: ${FontSize.smSize};
	color: #42526d;
	font-weight: ${FontWeight.normal};
`;
