import { onError } from '@apollo/client/link/error';

import storage from 'local-storage-fallback';

import { HTTP_ERROR_MESSAGES } from 'config/network';
import Toast from 'redesigncomponents/Toast';

export const errorLink = onError(({ graphQLErrors /*, networkError*/ }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, extensions }) => {
			/* const isServerError = Object.values(HTTP_STATUS_CODES).includes(
          parseInt(extensions.code, 10)
        );
        */

			if (extensions && extensions.code === 'validation-error') {
				return;
			}

			switch (message) {
				case HTTP_ERROR_MESSAGES.JWT_EXPIRED:
					storage.removeItem('DESTI1_REDESIGN:TOKEN');
					storage.removeItem('DESTI1_REDESIGN:REFRESH:TOKEN');

					// TODO: Add better redirect strategy
					window.location.reload();
					break;

				default:
					Toast.error(message);
			}
		});
	}
});
