import { FunctionalComponent } from 'preact';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import StyledCheckbox from './StyledCheckbox';

type Props = {
	id?: string;
	label?: string | any;
	input?: FieldInputProps<any>;
	meta?: FieldMetaState<any>;
	name?: string;
	required?: boolean;
};

const FieldCheckbox: FunctionalComponent<Props> = ({
	input,
	meta = {},
	label = '',
	id = null,
	required,
	...rest
}) => (
	<StyledCheckbox
		id={id}
		label={label}
		required={required}
		error={meta?.touched && (meta.error || meta.submitError)}
		inputProps={{ ...input, ...rest }}
	/>
);

export default FieldCheckbox;
