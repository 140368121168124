import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'preact/hooks';
import { Destination, destinationAndPeriodVar } from './cache';

const useDestinationAndPeriod = () => {
  const { travelPeriod, destinations } = useReactiveVar(
    destinationAndPeriodVar
  );

  const setTravelPeriod = (travelPeriod: Array<Date>) =>
    destinationAndPeriodVar({ ...destinationAndPeriodVar(), travelPeriod });

  const setDestinations = (destinations: Array<Destination>) =>
    destinationAndPeriodVar({ ...destinationAndPeriodVar(), destinations });

  const isLocationAndPeriodSelected = useMemo(() => {
    return (
      destinations &&
      destinations?.length !== 0 &&
      travelPeriod?.every(selected => selected !== null)
    );
  }, [travelPeriod, destinations]);

  return {
    travelPeriod,
    destinations,
    isLocationAndPeriodSelected,
    setTravelPeriod,
    setDestinations,
  };
};

export default useDestinationAndPeriod;
