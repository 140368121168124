import { setContext } from '@apollo/client/link/context';

import storage from 'local-storage-fallback';

import { CLIENTS } from 'config/network';

export const authLink = setContext((_, { _headers, clientAwareness }) => {
	const { name } = clientAwareness;
	const token = storage.getItem('DESTI1_REDESIGN:TOKEN');
	let headers = {
		..._headers,
		cookie: document.cookie
	};

	if (token) {
		headers = { ...headers, authorization: `Bearer ${token}` };
	}

	switch (name) {
		case CLIENTS.MY_BOOKINGS:
			return {
				headers: {
					...headers,
					'widget-language': window.DEST1_REDESIGN_CONFIG.lang
				}
			};
		case CLIENTS.MY_PAGES:
			return {
				headers: {
					...headers,
					'widget-language': window.DEST1_REDESIGN_CONFIG.lang
				}
			};
		default:
			return {
				headers: {
					...headers,
					'widget-language': window.DEST1_REDESIGN_CONFIG.lang
				}
			};
	}
});
