import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { PAGE_DEFAULT } from 'config/network';
import useChildrenAgesOptions from 'hooks/useChildrenAgesOptions';
import useIntl from 'hooks/useIntl';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import CounterItem from 'redesigncomponents/CounterItem';
import FieldSelect from 'redesigncomponents/FieldSelect';
import Footer from 'redesigncomponents/Footer';
import OptionBoxContainer from 'redesigncomponents/OptionBoxContainer';
import OptionsNumberTitle from 'redesigncomponents/OptionsNumberTitle';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { Option } from 'redesigntypes/Option';
import { InfiniteScrollWithRef } from 'shared/components';
import { Colors } from 'styles/defaultTheme';
import { ActivityOrderBy } from 'types/enums';

import AdultsIcon from 'assets/icons/counter/adults.svg';
import RoomsIcon from 'assets/icons/counter/rooms.svg';

import {
	ChildAgeWrapper,
	InfoMessage
} from '../AccommodationDetails/AccommodationDetailsContainer/components/StyledComponents';
import useAccommodationListCache from './cache/useAccommodationListCache';
import { AccommodationOfferItem } from './components/AccommodationOfferItem';
import {
	AccommodationWrapper,
	LeftSide,
	LoaderWrapper,
	RightSide,
	StyledBodyWrapper,
	ThumbnailContainer,
	WidgetTitle,
	Wrapper
} from './components/StyledComponents';
import { AccommodationListTooltip } from './tooltipConfig';
import useAccommodationSearch from './useAccommodationSearch';

interface IProps {}

const Accommodations: FunctionalComponent<IProps> = () => {
	const [infiniteScrollWraperID] = useState<string>(uuidv4());
	const { t } = useIntl('accommodation');
	const { t: orderByTranslation } = useIntl('common.OrderBy');
	const { t: accommodationDetailsTranslations } = useIntl(
		'accommodationDetails'
	);
	const history = useHistory();
	const location = useLocation();
	const { travelPeriod, destinations } = useDestinationAndPeriod();
	const { childrenAgesOptions } = useChildrenAgesOptions();
	const [expandView, setExpandView] = useState<boolean>(false);

	const {
		initialized,
		adultsCount,
		childrenCount,
		childrenAges,
		numberOfRoomsCount,
		sort,
		initializeAccommodationListCache,
		setAdultsCount,
		setChildrenCount,
		setChildrenAges,
		setNumberOfRoomsCount,
		setSort
	} = useAccommodationListCache();

	const {
		accommodations,
		page,
		totalCount,
		hasMore,
		loading,
		getAvailableAccommodations,
		setPage
	} = useAccommodationSearch();

	useEffect(() => {
		if (!location?.state?.breadcrumbNavigation && childrenAgesOptions?.length) {
			// breadcrumbNavigation added to state in Breadcrumbs component
			// initialize the accommodation list cache only if we got here from a regular link and if the screen has not been initialized before
			// if we got here by breadcrumb navigation, we expect to have the same state as we are going back in history
			initializeAccommodationListCache(2, childrenAgesOptions, 0, [], 1, false);
		}
	}, [childrenAgesOptions, location?.state?.breadcrumbNavigation]);

	useEffect(() => {
		if (initialized) {
			if (page === PAGE_DEFAULT) {
				return;
			}
			loadAvailableAccommodations();
		}
	}, [initialized, page]);

	useEffect(() => {
		if (initialized) {
			loadAvailableAccommodations();
		}
	}, [
		initialized,
		adultsCount,
		childrenCount,
		numberOfRoomsCount,
		childrenAges,
		sort
	]);

	const loadAvailableAccommodations = () => {
		getAvailableAccommodations(
			destinations[destinations.length - 1].label,
			dayjs(travelPeriod[0]).format('YYYY-MM-DD'),
			dayjs(travelPeriod[1]).format('YYYY-MM-DD'),
			adultsCount,
			childrenAges,
			numberOfRoomsCount,
			sort?.value
		);
	};

	const onAdultsCountChange = (count: number) => {
		setPage(PAGE_DEFAULT);
		setAdultsCount(count);
	};

	const onChangeNumberOfChildren = (value: number) => {
		setPage(PAGE_DEFAULT);
		setChildrenCount(value);
		if (value < childrenAges.length) {
			const updatedChildrenAges = [...childrenAges];
			updatedChildrenAges.length = value;
			setChildrenAges(updatedChildrenAges);
		} else {
			setChildrenAges([
				...childrenAges,
				...Array.from({ length: value - childrenAges.length }).map(_ => 3) // default child age: 3
			]);
		}
	};

	const onChangeChildrenAges = (index: number, value: Option) => {
		setPage(PAGE_DEFAULT);
		const updatedChildrenAges = [...childrenAges];
		updatedChildrenAges[index] = value.value as number;
		setChildrenAges(updatedChildrenAges);
	};

	const onNumberOfRoomsCountChange = (count: number) => {
		setPage(PAGE_DEFAULT);
		setNumberOfRoomsCount(count);
	};

	const onNextPage = () => {
		const nextPage = page + 1;
		setPage(nextPage);
	};

	const onSortChange = sort => {
		setPage(PAGE_DEFAULT);
		setSort(sort);
	};

	const goToAccommodationDetails = accommodationId => {
		history.push(`/accommodations/${accommodationId}`);
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				{!expandView && (
					<LeftSide>
						<WidgetTitle>{t('accommodationDetails')}</WidgetTitle>
						<OptionBoxContainer title={t('passengerNumbers')} Icon={AdultsIcon}>
							<CounterItem
								value={adultsCount}
								onChange={onAdultsCountChange}
								title={t('adults')}
								min={1}
							></CounterItem>
							<CounterItem
								value={childrenCount}
								onChange={onChangeNumberOfChildren}
								title={t('children')}
								subtitle={accommodationDetailsTranslations('childrenSubtitle')}
								min={0}
							></CounterItem>
							{childrenCount
								? Array.from({ length: childrenCount }).map((child, index) => {
										return (
											<ChildAgeWrapper>
												<FieldSelect
													label={accommodationDetailsTranslations(
														'childAgeSelectLabel'
													)}
													placeholder={accommodationDetailsTranslations(
														'childAgeSelectLabel'
													)}
													secondary
													input={{
														onChange: value =>
															onChangeChildrenAges(index, value),
														value: childrenAges?.[index],
														name: '',
														onBlur: () => null,
														onFocus: () => null
													}}
													options={childrenAgesOptions}
												/>
											</ChildAgeWrapper>
										);
								  })
								: null}
							{childrenCount > 0 && (
								<InfoMessage>
									{accommodationDetailsTranslations('extraBedsInfoMessage')}
								</InfoMessage>
							)}
						</OptionBoxContainer>
						<OptionBoxContainer title={t('roomOptions')} Icon={RoomsIcon}>
							<CounterItem
								value={numberOfRoomsCount}
								onChange={onNumberOfRoomsCountChange}
								title={t('numberOfRooms')}
								min={1}
							></CounterItem>
						</OptionBoxContainer>
					</LeftSide>
				)}
				<RightSide expand={expandView}>
					<OptionsNumberTitle
						count={totalCount}
						sortOptions={Object.values(ActivityOrderBy).map(ob => ({
							value: ob,
							label: orderByTranslation(ob)
						}))}
						sort={sort}
						onSortChange={onSortChange}
					/>
					{loading && page === PAGE_DEFAULT ? (
						<LoaderWrapper>
							<ThreeDotLoader color={Colors.V900} size={40} />
						</LoaderWrapper>
					) : (
						<AccommodationWrapper id={infiniteScrollWraperID}>
							<InfiniteScrollWithRef
								dataLength={accommodations.length}
								next={onNextPage}
								hasMore={hasMore}
								loader={
									loading && (
										<Wrapper>
											<ThreeDotLoader color={Colors.V900} size={40} />
										</Wrapper>
									)
								}
								endMessage={<Wrapper>{t('inifiniteScrollEndMessage')}</Wrapper>}
								scrollableTarget={infiniteScrollWraperID}
							>
								<ThumbnailContainer grid={expandView}>
									{accommodations.map((offer, index) => (
										<AccommodationOfferItem
											key={index}
											offer={offer}
											onClick={() =>
												goToAccommodationDetails(offer.stayEstablishmentId)
											}
										/>
									))}
								</ThumbnailContainer>
							</InfiniteScrollWithRef>
						</AccommodationWrapper>
					)}
				</RightSide>
			</StyledBodyWrapper>
			<Footer
				toolTipProps={{
					toolTipKey: expandView
						? AccommodationListTooltip.Expanded
						: AccommodationListTooltip.Main
				}}
			>
				<Button
					title={expandView ? t('backToSearch') : t('seeAllAccommodation')}
					buttonStyleType={ButtonStyleTypes.primary}
					onClick={() => setExpandView(!expandView)}
				></Button>
			</Footer>
		</MainWrapper>
	);
};

export default Accommodations;
