import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import cloneDeep from 'lodash.clonedeep';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import CounterItem from 'redesigncomponents/CounterItem';

import AccordionIcon from 'assets/accordion-arrow.svg';

import {
	DropDownContainer,
	DropDownHeader,
	DropDownHeaderCell,
	HeaderTitle,
	MenuBody,
	MenuBodySection,
	MenuContainer,
	MenuFooter,
	MenuHeader,
	MenuRow,
	SelectedValue
} from './components/StyledComponents';
import useOptionCategories, {
	OptionCategoriesType,
	initialValuesOptionCategories
} from './useOptionCategories';

interface IProps {
	value: OptionCategoriesType;
	onChange: (value: OptionCategoriesType) => void;
}

const OptionCategories: FunctionalComponent<IProps> = ({ value, onChange }) => {
	const { t } = useIntl('transportList.options.optionCategories');
	const [collapsed, toggleCollapsed] = useToggle();

	const {
		updateCategoryCount,
		resetOptionCategories,
		optionCategoriesArray,
		optionCategories
	} = useOptionCategories();

	useEffect(() => {
		resetOptionCategories(cloneDeep(initialValuesOptionCategories));
		onChange(cloneDeep(initialValuesOptionCategories));
	}, []);

	const onSave = () => {
		onChange(cloneDeep(optionCategories));
		toggleCollapsed();
	};

	const onCancel = () => {
		if (collapsed) {
			resetOptionCategories(cloneDeep(value));
			toggleCollapsed(false);
		}
	};

	const onDropdownClick = () => {
		if (collapsed) {
			onChange(cloneDeep(optionCategories));
		}
		toggleCollapsed();
	};

	return (
		<DropDownContainer>
			<DropDownHeader onClick={onDropdownClick}>
				{optionCategoriesArray.map(({ count, Icon }) => (
					<DropDownHeaderCell>
						<Icon />
						<SelectedValue>{count}</SelectedValue>
					</DropDownHeaderCell>
				))}
				<DropDownHeaderCell>
					<AccordionIcon></AccordionIcon>
				</DropDownHeaderCell>
			</DropDownHeader>
			{collapsed && (
				<MenuContainer>
					<MenuBody>
						{optionCategoriesArray.map(
							({ id: categoryTypeId, title, Icon, categories }) => {
								return (
									<MenuBodySection>
										<MenuHeader>
											<Icon />
											<HeaderTitle>{title}</HeaderTitle>
										</MenuHeader>
										{categories.map(
											({
												id: categoryId,
												title,
												subtitle,
												value,
												min,
												max
											}) => (
												<MenuRow>
													<CounterItem
														value={value}
														onChange={value =>
															updateCategoryCount(
																categoryTypeId,
																categoryId,
																value
															)
														}
														min={min}
														max={max}
														title={title}
														subtitle={subtitle}
													></CounterItem>
												</MenuRow>
											)
										)}
									</MenuBodySection>
								);
							}
						)}
					</MenuBody>
					<MenuFooter>
						<Button
							title={t('cancelButtonTitle')}
							buttonStyleType={ButtonStyleTypes.secondary}
							onClick={onCancel}
						></Button>
						<Button
							title={t('saveButtonTitle')}
							buttonStyleType={ButtonStyleTypes.primary}
							onClick={onSave}
						></Button>
					</MenuFooter>
				</MenuContainer>
			)}
		</DropDownContainer>
	);
};

export default OptionCategories;
