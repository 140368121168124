import { Elements } from '@stripe/react-stripe-js';
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import {
	ITransportWishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../TravelPlan/useTravelPlan';
import CheckoutForm from './components/CheckoutForm';
import ELEMENTS_OPTIONS from './components/CheckoutForm/stripeElementsOptions';
import FlightCheckoutForm from './components/FlightTravelPlanItem';
import { GetGestButton } from './components/GetGestButton';
import NotificationBox from './components/NotificationBox';
import RemoveItemConfirmationModal from './components/RemoveItemConfirmationModal';
import { FormWrapper } from './components/StyledComponents';
import TravelPlanItemHeader from './components/TravelPlanItemHeader';
import useCheckPaidStatus from './hooks/useCheckPaidStatus';

interface IProps {
	travelPlanId: string | number;
	travelPeriod: Date[];
	travelItineraryId: string;
	travelPlanName: string;
	travelPlanItem: IComposedTravelPlanItem;
	canBeRemoved?: boolean;
}

const TravelPlanItem: FunctionalComponent<IProps> = ({
	travelPlanId,
	travelPeriod,
	travelItineraryId,
	travelPlanName,
	travelPlanItem,
	canBeRemoved
}) => {
	const { t } = useIntl('checkout.travelPlanItemHeader');
	const { t: checkoutTranslations } = useIntl('checkout');
	const [
		travelPlanItemExpandedForPayment,
		toggleTravelPlanItemExpandedForPayment
	] = useToggle();
	const [
		removeItemConfirmationModalOpen,
		toggleRemoveItemConfirmationModalOpen
	] = useToggle();

	useEffect(() => {
		if (travelPlanItem.paid) {
			toggleTravelPlanItemExpandedForPayment(false);
		}
	}, [travelPlanItem.paid]);

	const { isTravelPlanItemPaid } = useCheckPaidStatus(travelPlanItem);

	const isFlightTravelPlanItem =
		travelPlanItem.type === WishlistItemTypes.TRANSPORT &&
		!!(travelPlanItem.item as ITransportWishlistItem).booking_token;

	return (
		<FormWrapper>
			{!isFlightTravelPlanItem ? (
				<>
					<TravelPlanItemHeader travelPlanItem={travelPlanItem}>
						{isTravelPlanItemPaid ? (
							<GetGestButton title={t('paidButtonTitle')} paid></GetGestButton>
						) : travelPlanItemExpandedForPayment ? (
							<GetGestButton
								title={t('closeButtonTitle')}
								onClick={toggleTravelPlanItemExpandedForPayment}
							></GetGestButton>
						) : (
							<GetGestButton
								disabled={!travelPlanItem.productOwner.loader}
								onClick={toggleTravelPlanItemExpandedForPayment}
								title={t('payButtonTitle')}
							></GetGestButton>
						)}
					</TravelPlanItemHeader>
					{!travelPlanItem.productOwner.loader && (
						<NotificationBox
							message={checkoutTranslations('itemCanNotBeBookedPoError')}
							infoLevel="error"
						/>
					)}
					{travelPlanItemExpandedForPayment &&
						travelPlanItem.productOwner.loader && (
							<Elements
								stripe={travelPlanItem.productOwner.loader}
								options={ELEMENTS_OPTIONS}
							>
								<CheckoutForm
									travelPeriod={travelPeriod}
									travelPlanId={travelPlanId}
									travelItineraryId={travelItineraryId}
									travelPlanName={travelPlanName}
									travelPlanItem={travelPlanItem}
									removeTravelPlanItem={
										canBeRemoved && toggleRemoveItemConfirmationModalOpen
									}
								/>
							</Elements>
						)}
				</>
			) : (
				<FlightCheckoutForm
					travelPlanId={travelPlanId}
					travelItineraryId={travelItineraryId}
					travelPlanName={travelPlanName}
					travelPlanItem={travelPlanItem}
					removeTravelPlanItem={
						canBeRemoved && toggleRemoveItemConfirmationModalOpen
					}
				/>
			)}
			{removeItemConfirmationModalOpen && (
				<RemoveItemConfirmationModal
					travelPlanId={travelPlanId}
					travelPlanItem={travelPlanItem}
					toggleOpen={toggleRemoveItemConfirmationModalOpen}
				/>
			)}
		</FormWrapper>
	);
};

export default TravelPlanItem;
