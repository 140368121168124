import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { PAGE_DEFAULT } from 'config/network';
import useActivityTypeCodebookOptions from 'hooks/useActivityTypeCodebookOptions';
import useIntl from 'hooks/useIntl';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import CounterItem from 'redesigncomponents/CounterItem';
import FieldCheckbox from 'redesigncomponents/FieldCheckbox';
import FieldMultiselect from 'redesigncomponents/FieldMultiselect';
import Footer from 'redesigncomponents/Footer';
import OptionBoxContainer from 'redesigncomponents/OptionBoxContainer';
import OptionsNumberTitle from 'redesigncomponents/OptionsNumberTitle';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { InfiniteScrollWithRef } from 'shared/components';
import { Colors } from 'styles/defaultTheme';
import { ActivityOrderBy } from 'types/enums';

import AdultsIcon from 'assets/icons/counter/adults.svg';

import useActivityListCache from './cache/useActivityListCache';
import { ActivityOfferItem } from './components/ActivityOfferItem';
import {
	ActivitiesWrapper,
	CheckboxContainer,
	LeftSide,
	LoaderWrapper,
	RightSide,
	SectionTitle,
	StyledBodyWrapper,
	ThumbnailContainer,
	WidgetTitle,
	Wrapper
} from './components/StyledComponents';
import { ActivityListTooltip } from './tooltipConfig';
import useActivitySearch from './useActivitySearch';

interface IProps {}

const Activities: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('activityDetails');
	const { t: orderByTranslation } = useIntl('common.OrderBy');
	const history = useHistory();
	const location = useLocation();
	const [infiniteScrollWraperID] = useState<string>(uuidv4());
	const { activityTypeOptions } = useActivityTypeCodebookOptions();
	const { travelPeriod, destinations } = useDestinationAndPeriod();
	const [expandView, setExpandView] = useState<boolean>(false);
	const sortOptions = Object.values(ActivityOrderBy).map(ob => ({
		value: ob,
		label: orderByTranslation(ob)
	}));

	const {
		initialized,
		adultsCount,
		activityTypes,
		privateTour,
		sort,
		initializeActivityListCache,
		setAdultCount,
		setActivityTypes,
		setPrivateTour,
		setSort
	} = useActivityListCache();

	const {
		activities,
		page,
		totalCount,
		hasMore,
		loading,
		getActivities,
		setPage
	} = useActivitySearch();

	useEffect(() => {
		if (!location?.state?.breadcrumbNavigation && activityTypeOptions?.length) {
			// breadcrumbNavigation added to state in Breadcrumbs component
			// initialize the activity list cache only if we got here from a regular link and if the screen has not been initialized before
			// if we got here by breadcrumb navigation, we expect to have the same state as we are going back in history
			initializeActivityListCache(
				2,
				activityTypeOptions,
				sortOptions[0],
				false
			);
		}
	}, [activityTypeOptions, location?.state?.breadcrumbNavigation]);

	useEffect(() => {
		if (initialized) {
			if (page === PAGE_DEFAULT) {
				return;
			}
			loadActivities();
		}
	}, [initialized, page]);

	useEffect(() => {
		if (initialized) {
			loadActivities();
		}
	}, [initialized, adultsCount, privateTour, activityTypes, sort]);

	const onAdultsCountChange = count => {
		setAdultCount(count);
		setPage(PAGE_DEFAULT);
	};

	const onActivityTypeChange = type => {
		setActivityTypes(type);
		setPage(PAGE_DEFAULT);
	};

	const onPrivateTourChange = () => {
		setPage(PAGE_DEFAULT);
		setPrivateTour(!privateTour);
	};

	const onSortChange = sort => {
		setPage(PAGE_DEFAULT);
		setSort(sort);
	};

	const loadActivities = () => {
		getActivities(
			adultsCount,
			destinations[destinations.length - 1].label,
			activityTypes.map(activity => activity.value as string),
			travelPeriod,
			sort?.value,
			privateTour
		);
	};

	const onNextPage = () => {
		const nextPage = page + 1;
		setPage(nextPage);
	};

	const goToActivityDetails = activityId => {
		history.push(`/activities/${activityId}`);
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				{!expandView && (
					<LeftSide>
						<WidgetTitle>{t('activitiesDetails')}</WidgetTitle>
						<OptionBoxContainer title={t('passengerNumbers')} Icon={AdultsIcon}>
							<CounterItem
								value={adultsCount}
								onChange={onAdultsCountChange}
								title={t('adults')}
								min={1}
							></CounterItem>
						</OptionBoxContainer>
						<SectionTitle>{t('preferableActivity')}</SectionTitle>
						<FieldMultiselect
							label={t('chooseActivityType')}
							enableSelectAndClearButtons
							input={{
								onChange: onActivityTypeChange,
								value: activityTypes,
								name: '',
								onBlur: () => null,
								onFocus: () => null
							}}
							options={activityTypeOptions}
						/>
						<CheckboxContainer>
							<FieldCheckbox
								label={t('privateTour')}
								input={{
									onChange: onPrivateTourChange,
									value: [],
									checked: privateTour,
									name: '',
									onBlur: () => null,
									onFocus: () => null
								}}
							></FieldCheckbox>
						</CheckboxContainer>
					</LeftSide>
				)}
				<RightSide expand={expandView}>
					<OptionsNumberTitle
						count={totalCount}
						sortOptions={sortOptions}
						sort={sort}
						onSortChange={onSortChange}
					/>
					{loading && page === PAGE_DEFAULT ? (
						<LoaderWrapper>
							<ThreeDotLoader color={Colors.V900} size={40} />
						</LoaderWrapper>
					) : (
						<ActivitiesWrapper id={infiniteScrollWraperID}>
							<InfiniteScrollWithRef
								dataLength={activities.length}
								next={onNextPage}
								hasMore={hasMore}
								loader={
									loading && (
										<Wrapper>
											<ThreeDotLoader color={Colors.V900} size={40} />
										</Wrapper>
									)
								}
								endMessage={<Wrapper>{t('inifiniteScrollEndMessage')}</Wrapper>}
								scrollableTarget={infiniteScrollWraperID}
							>
								<ThumbnailContainer grid={expandView}>
									{activities.map((offer, index) => (
										<ActivityOfferItem
											key={index}
											offer={offer}
											onClick={() => goToActivityDetails(offer.id)}
										/>
									))}
								</ThumbnailContainer>
							</InfiniteScrollWithRef>
						</ActivitiesWrapper>
					)}
				</RightSide>
			</StyledBodyWrapper>
			<Footer
				toolTipProps={{
					toolTipKey: expandView
						? ActivityListTooltip.Expanded
						: ActivityListTooltip.Main
				}}
			>
				<Button
					title={expandView ? t('backToSearch') : t('seeAllActivities')}
					buttonStyleType={ButtonStyleTypes.primary}
					onClick={() => setExpandView(!expandView)}
				></Button>
			</Footer>
		</MainWrapper>
	);
};

export default Activities;
