import { FunctionalComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import useIntl from 'hooks/useIntl';
import { IActivityWishlistItem } from 'redesigncache/wishlist/types';
import FieldCheckbox from 'redesigncomponents/FieldCheckbox';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldSelect from 'redesigncomponents/FieldSelect';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import { IComposedTravelPlanItem } from 'redesignscreens/Checkout/components/TravelPlan/useTravelPlan';
import { BookingField, BookingFieldTypes } from 'redesigntypes/ActivityModel';

import { TextInputSection, TwoInputRow } from '../../../StyledComponents';
import FormSectionTitle from '../FormSectionTitle';
import useAdditionalBookingFieldsConfiguration from './useAdditionalBookingFieldsConfiguration';
import usePickupLocations from './usePickupLocations';

const InputSwitchComponent = (field: BookingField, name = '') => {
	switch (field?.fieldType) {
		case BookingFieldTypes.String: {
			return (
				<Field
					label={field?.label}
					required={field?.required}
					validate={field?.validate}
					component={FieldTextInput}
					name={name}
					id={name}
				/>
			);
		}
		case BookingFieldTypes.List: {
			return (
				<Field
					label={field?.label}
					required={field?.required}
					validate={field?.validate}
					component={FieldSelect}
					options={field.listOptionsParsed}
					name={name}
					id={name}
					tertiary
					searchable
				/>
			);
		}
		case BookingFieldTypes.Boolean: {
			return (
				<Field
					label={field?.LabelComponent}
					required={field?.required}
					validate={field?.validate}
					component={FieldCheckbox}
					name={name}
					id={name}
					type="checkbox"
				/>
			);
		}
		case BookingFieldTypes.Phone: {
			return (
				<Field
					validate={field?.validate}
					name={name}
					label={field?.label}
					required={field?.required}
					id={name}
					mask="+999999999999999"
					component={FieldTextInput}
				/>
			);
		}
		case BookingFieldTypes.Date: {
			return (
				<Field
					component={FieldDatepicker}
					showMonthYearDropdown
					label={field?.label}
					required={field?.required}
					validate={field?.validate}
					name={name}
					id={name}
					maxDate={field?.maxDate}
					formatAsPlaceholder
				/>
			);
		}
		default: {
			return <></>;
		}
	}
};

interface IProps {
	travelPlanItem: IComposedTravelPlanItem;
	isMerchandise?: boolean;
}

const BookingFieldsFormSection: FunctionalComponent<IProps> = ({
	travelPlanItem,
	isMerchandise
}) => {
	const { t } = useIntl('checkout.activityBookingFieldsFormSection');

	const activityWishListItem = travelPlanItem.item as IActivityWishlistItem;
	const {
		hasAdditionalPerBookingFields,
		hasAdditionalPerParticipantFields,
		additionalBookingFields
	} = useAdditionalBookingFieldsConfiguration(activityWishListItem);
	const { hasPickupLocations, pickupLocationOptions, validatePickupLocations } =
		usePickupLocations(activityWishListItem);

	const ParticipantFields = useCallback(
		({ fields: participants }) => {
			return participants.map((name, index) => (
				<TextInputSection>
					<FormSectionTitle
						title={`${t('travelerSeatSectionTitle')} ${index + 1} `}
					/>
					{additionalBookingFields?.requiredPerParticipantFields.map(f => (
						<TwoInputRow>
							{InputSwitchComponent(f[0], `${name}.${f[0]?.name}`)}

							{InputSwitchComponent(f[1], `${name}.${f[1]?.name}`)}
						</TwoInputRow>
					))}
				</TextInputSection>
			));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[additionalBookingFields]
	);

	return (
		<TextInputSection>
			<FormSectionTitle
				title={isMerchandise ? t('merchandiseTitle') : t('title')}
			/>
			{hasPickupLocations ? (
				<TwoInputRow>
					<Field
						label={t('pickupLocation')}
						required={true}
						validate={validatePickupLocations}
						component={FieldSelect}
						options={pickupLocationOptions}
						name={'pickupLocation'}
						tertiary
					/>
				</TwoInputRow>
			) : null}
			{hasAdditionalPerBookingFields &&
				additionalBookingFields?.requiredPerBookingFields.map(f => (
					<TwoInputRow>
						{InputSwitchComponent(
							f[0],
							`requiredPerBookingFields.${f[0]?.name}`
						)}

						{f[1] &&
							InputSwitchComponent(
								f[1],
								`requiredPerBookingFields.${f[1]?.name}`
							)}
					</TwoInputRow>
				))}
			{hasAdditionalPerParticipantFields && (
				<FieldArray name="requiredPerParticipantFields">
					{props => ParticipantFields(props)}
				</FieldArray>
			)}
		</TextInputSection>
	);
};

export default BookingFieldsFormSection;
