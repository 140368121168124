import styled from 'styled-components';

import {
	Colors,
	FontSize,
	FontWeight,
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const SectionContainer = styled.div`
	display: flex;
	gap: 0.625rem;
	margin-top: 1.25rem;
	@media ${DeviceBreakpoints.small} {
		flex-direction: column;
		margin-top: 0rem;
		gap: 0;
		padding: 0.5rem;
	}
`;

export const LeftSection = styled.div`
	flex: 1;
	background: ${Colors.V0};
	padding: 0.625rem 0.5rem;
	height: fit-content;
	> div {
		margin-bottom: 0.625rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@media ${DeviceBreakpoints.small} {
		order: 2;
		padding: 0;
		background: ${Colors.V20};
	}
`;

export const RightSection = styled.div`
	flex: 1;
	> div {
		&:first-child {
			margin-bottom: 0.625rem;
		}
	}
	@media ${DeviceBreakpoints.small} {
		order: 1;
	}
`;

export const SectionHeader = styled.div`
	display: flex;
	align-items: center;
	div {
		&:hover {
			background-color: ${Colors.V0};
			cursor: default;
		}
	}
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const HeaderTitle = styled.h4`
	font-size: ${FontSize.base};
	padding-left: 0.25rem;
`;

export const BoxTitle = styled.h6`
	font-size: ${FontSize.base};
	margin-top: 0;
	margin-bottom: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		text-transform: uppercase;
		font-size: ${FontSize.smSize};
		margin-bottom: 0.5rem;
	}
`;

export const TotalPrice = styled.div`
	background: ${Colors.V200};
	border-radius: 0.25rem;
	font-weight: ${FontWeight.semibold};
	padding: 0.5rem;
	color: ${Colors.V0};
	border: 1px solid ${Colors.V100};
	@media ${DeviceBreakpoints.small} {
		align-items: center;
		justify-content: space-between;
	}
`;

export const InputContainer = styled.div`
	& > {
		&:first-child {
			margin-bottom: 0.5rem;
		}
	}
`;

export const LoaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const BoxWrapper = styled.div`
	color: ${Colors.V900};
	background-color: ${Colors.V20};
	border: 1px solid ${Colors.V30};
	border-radius: 0.25rem;
	padding: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		background-color: ${Colors.V0};
	}
`;

export const BoxHeader = styled.h6`
	font-size: ${FontSize.base};
	margin: 0;
	margin-bottom: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		text-transform: uppercase;
		font-size: ${FontSize.smSize};
	}
`;

export const ImageGalleryContainer = styled.div`
	height: 100%;
	width: 100%;
`;

export const MapDivider = styled.div`
	border-bottom: 2px solid #e5e2e7;
	position: relative;
	margin: 1rem 0;

	:before,
	:after {
		position: absolute;
		bottom: -6px;
		left: 0;
		height: 10px;
		width: 10px;
		background: #e5e2e7;
		content: '';
		border-radius: 5px;
	}

	:after {
		right: 0;
		left: auto;
	}
`;
