import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import TrainTransportListContainer from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer';
import useTransportSearchCache from 'redesignscreens/Main/Transport/TrainTransportList/TrainTransportListContainer/cache/useTransportSearchCache';

import { StyledBodyWrapper } from './components/StyledComponents';

const TrainTransportList = () => {
	const history = useHistory();
	const { t: screenTranslations } = useIntl('screens');
	const { t: transportSearchTranslations } = useIntl('transportList');
	const { travelPeriod, isSelectedOfferValid } = useTransportSearchCache();

	const goToTransportDetails = () => {
		history.push('/affiliate-link/transport-details/trainbus');
	};

	return (
		<AffiliateLinkWrapper
			currentRouteSlug={screenTranslations('Transportation')}
		>
			<StyledBodyWrapper>
				<TrainTransportListContainer
					travelPeriod={travelPeriod as [Date, Date]}
				/>
			</StyledBodyWrapper>
			<Footer>
				{isSelectedOfferValid && (
					<Button
						title={transportSearchTranslations('bookNowButtonTitle')}
						onClick={goToTransportDetails}
					/>
				)}
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default TrainTransportList;
