import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import { myPagesClient as myPagesClientImport } from 'apollo/client';
import { GET_COUNTRY_BY_PK } from 'apollo/myDesti/queries';
import { GET_LOGGED_IN_USER, GET_USER_BY_PK } from 'apollo/myPages/queries';
import useToken from 'hooks/useToken';
import { Option } from 'redesigntypes/Option';

import { UserType, userVar } from './cache';

function useUserAuth() {
	const client = useApolloClient();
	const myPagesClient = useApolloClient(myPagesClientImport);
	const { saveToken, loadToken, removeToken, getSubjectFromToken } = useToken();

	// SAVED IN CLIENT APOLLO STATE
	const { data: { user } = {} } =
		useQuery<{ user: UserType }>(GET_LOGGED_IN_USER);

	const getUserByPk = async (userId: string) => {
		try {
			const userData = await myPagesClient.query({
				query: GET_USER_BY_PK,
				variables: { id: userId },
				fetchPolicy: 'no-cache'
			});
			const userResponse = userData.data.user;
			let country: Option = undefined;
			const dateOfBirth = userResponse?.date_of_birth
				? new Date(userResponse?.date_of_birth)
				: undefined;
			if (userResponse.country_id) {
				const countryData = await client.query({
					query: GET_COUNTRY_BY_PK,
					variables: { id: userResponse.country_id },
					fetchPolicy: 'no-cache'
				});

				country = {
					value: countryData.data.country.id,
					label: countryData.data.country.name,
					extra: {
						isoCode2: countryData.data.country.country_code
					}
				};
			}

			userVar({
				id: userResponse.id,
				email: userResponse.email,
				firstName: userResponse.first_name,
				lastName: userResponse.last_name,
				countryId: userResponse.country_id,
				createdAt: userResponse.created_at,
				country: country,
				dateOfBirth: dateOfBirth,
				gender: userResponse.gender,
				connection: userResponse.connection
			});
		} catch {
			logout();
		}
	};

	const isLoggedIn = useMemo(() => {
		return !!user;
	}, [user]);

	const tokenLogin = async (idToken, refreshToken) => {
		saveToken(idToken, refreshToken);

		const userId = getSubjectFromToken(idToken);
		await getUserByPk(userId);
	};

	const refreshAuth = async () => {
		const { subject: userId } = loadToken();
		if (userId) {
			await getUserByPk(userId);
		}
	};

	const logout = () => {
		removeToken();
		userVar(undefined);
	};

	return { user, isLoggedIn, tokenLogin, refreshAuth, logout };
}

export default useUserAuth;
