import styled from 'styled-components';

import { GetGestColors } from 'styles/defaultTheme';

export const Breadcrumb = styled.ul`
	list-style: none;
	display: flex;
	font-family: 'Open Sans';
	font-size: 13px;
	gap: 5px;
	margin: 0;
	padding: 0;
`;

export const BreadcrumbItem = styled.li`
	font-size: 13px;
	background-color: ${props => (props.active ? '#635570' : '#fbfbfb;')};
	color: ${props => (props.active ? '#fff' : '#342245')};
	border: ${props =>
		props.active ? '1px solid #ccc8d1' : '1px solid #efedf0'};
	padding: 8px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	div {
		display: ${props => (props.active ? 'none' : 'block')};
	}
	&:hover {
		background-color: ${props => (props.active ? '#635570' : '#f7f6f8')};
		border: ${props =>
			props.active ? '1px solid #ccc8d1' : '1px solid #ccc8d1'};
		cursor: pointer;
	}
	${({ getGest }) =>
		getGest &&
		`
		background-color: ${props => (props.active ? '#635570' : GetGestColors.V10)};
		border: ${props =>
			props.active ? '1px solid #ccc8d1' : `1px solid ${GetGestColors.V30}`};
	`}
`;

export const Link = styled.a``;

export const NavIconWrapper = styled.div`
	${({ flipped }) => (flipped ? 'margin-right: 10px;' : 'margin-left: 10px;')}
	${({ flipped }) => flipped && 'transform: rotate(180deg);'}
	${({ back }) => back && 'margin-right: 10px;'}
`;

export const HomeIconWrapper = styled.div`
	margin-right: 0.625rem;
	display: none;
`;
