import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

type AccommodationListCache = {
	initialized: boolean;
	adultsCount: number;
    childrenAgesOptions: Option[];
	childrenCount: number;
    childrenAges: number[];
	numberOfRoomsCount: number;
	sort: Option;
};

export const initialAccommodationListValue: AccommodationListCache = {
	initialized: false,
	adultsCount: 2,
	childrenAgesOptions: [],
	childrenCount: 0,
    childrenAges: [],
    numberOfRoomsCount: 1,
	sort: undefined
};

export const accommodationListCacheVar = makeVar<AccommodationListCache>(
	initialAccommodationListValue
);
