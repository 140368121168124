import { makeVar } from '@apollo/client';

import { Image, MerchandiseModel } from 'redesigntypes/ActivityModel';

type MerchandiseDetailsCache = {
	merchandise: MerchandiseModel;
	heroImage: Image;
	quantities: { [key: string]: number };
};

export const initialMerchandiseDetailsValue: MerchandiseDetailsCache = {
	merchandise: undefined,
	heroImage: undefined,
	quantities: {}
};

export const merchandiseDetailsVar = makeVar<MerchandiseDetailsCache>(
	initialMerchandiseDetailsValue
);
