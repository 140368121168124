import styled from 'styled-components';

import { Colors, FontSize, DeviceBreakpoints } from 'styles/defaultTheme';

export const BoxContainer = styled.div`
	color: ${Colors.V900};
	padding: 0.5rem;
	background: ${Colors.V20};
	border-radius: 0.25rem;
	border: 1px solid ${Colors.V30};
	@media ${DeviceBreakpoints.small} {
		background: ${Colors.V0};
	}
`;

export const OptionsHeader = styled.h4`
	margin-left: 0.625rem;
	font-size: ${FontSize.base};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
		text-transform: uppercase;
	}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.625rem;
	@media ${DeviceBreakpoints.small} {
		svg {
			width: 16px;
		}
	}
`;
