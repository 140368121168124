import { useLazyQuery, useMutation } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_INTEGRATIONS_AVAILABLE_ROOMS } from 'apollo/myDesti/mutations';
import { GET_INTEGRATIONS_ACCOMMODATION_BY_PK } from 'apollo/myDesti/queries';
import useIntl from 'hooks/useIntl';
import Toast from 'redesigncomponents/Toast';
import useAccommodationDetailsCache from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/cache/useAccommodationDetailsCache';

const useAccommodationDetails = () => {
	const { t: accommodationDetailsTranslations } = useIntl(
		'accommodationDetails'
	);
	const {
		accommodation,
		availableRoomsById,
		setAccommodationDetails,
		setAvailableRoomsById,
		clearAccommodationDetailsCache
	} = useAccommodationDetailsCache();

	const getAvailableRoomKey = ar => `${ar.roomType}-${ar.priceRateCode}`;

	const [loadAccommodation, { loading, error }] = useLazyQuery(
		GET_INTEGRATIONS_ACCOMMODATION_BY_PK,
		{
			variables: {
				city: undefined,
				id: undefined
			},
			fetchPolicy: 'no-cache',
			onCompleted: data => {
				const accommodationData = data?.getIntegrationsAccommodationByPk;
				if (accommodationData) {
					setAccommodationDetails(
						accommodationData,
						headerImage,
						galleryImages,
						{
							lat: accommodationData?.productLocation?.latitude,
							lng: accommodationData?.productLocation?.longitude
						}
					);
				}
			},
			onError: () => {
				Toast.error(
					accommodationDetailsTranslations('accommodationCouldNotBeLoaded')
				);
			}
		}
	);

	const [loadAvailableRooms, { loading: loadingAvailableRooms }] = useMutation(
		GET_INTEGRATIONS_AVAILABLE_ROOMS,
		{
			fetchPolicy: 'no-cache',
			onCompleted: data => {
				if (data?.getAccommodationAvailableRooms) {
					const availableRoomsById =
						data?.getAccommodationAvailableRooms.reduce(
							(accummulator, currentValue) => {
								accummulator[getAvailableRoomKey(currentValue)] = currentValue;
								return accummulator;
							},
							{}
						);
					setAvailableRoomsById(availableRoomsById);
				}
			}
		}
	);

	const getAccommodation = (id: string) => {
		loadAccommodation({
			variables: {
				city: 'test',
				id
			}
		});
	};

	const getAvailableRooms = (
		numberOfAdults: number,
		numberOfChildren: number,
		childrenAges: number[],
		travelPeriod: [Date, Date],
		numberOfRooms: number
	) => {
		loadAvailableRooms({
			variables: {
				city: 'test',
				arrivalDate: dayjs(travelPeriod[0]).format('YYYY-MM-DD'),
				departureDate: dayjs(travelPeriod[1]).format('YYYY-MM-DD'),
				currency: '',
				numOfAdults: numberOfAdults,
				childrenAges: childrenAges,
				numOfRooms: numberOfRooms,
				integration: accommodation?.integration,
				stayEstablishmentId: accommodation?.code
			}
		});
	};

	const headerImage = useMemo(
		() =>
			accommodation?.code
				? `assets/images/hotels/${accommodation?.code}/hotel-cover.png`
				: '',
		[accommodation]
	);

	const galleryImages = useMemo(() => {
		switch (accommodation?.code) {
			case 'GB008566': {
				return [
					'assets/images/hotels/GB008566/hotel-1.png',
					'assets/images/hotels/GB008566/hotel-2.png',
					'assets/images/hotels/GB008566/hotel-3.png',
					'assets/images/hotels/GB008566/hotel-4.png',
					'assets/images/hotels/GB008566/hotel-5.png',
					'assets/images/hotels/GB008566/hotel-6.png',
					'assets/images/hotels/GB008566/hotel-7.png'
				];
			}
			case 'SE001455': {
				return [
					'assets/images/hotels/SE001455/hotel-1.png',
					'assets/images/hotels/SE001455/hotel-2.png',
					'assets/images/hotels/SE001455/hotel-3.png',
					'assets/images/hotels/SE001455/hotel-4.png',
					'assets/images/hotels/SE001455/hotel-5.png',
					'assets/images/hotels/SE001455/hotel-6.png',
					'assets/images/hotels/SE001455/hotel-7.png'
				];
			}
		}
	}, [accommodation]);

	const availableRoomOptions = useMemo(() => {
		return (
			availableRoomsById &&
			Object.keys(availableRoomsById)?.map(availableRoomKey => {
				const room = availableRoomsById[availableRoomKey];
				return {
					value: availableRoomKey,
					label: room.roomDescription,
					extra: {
						rate: room.rate
					}
				};
			})
		);
	}, [availableRoomsById]);

	const getAvailableRoomByKey = (key: string) =>
		availableRoomsById && availableRoomsById[key];

	return {
		getAccommodation,
		getAvailableRooms,
		getAvailableRoomByKey,
		getAvailableRoomKey,
		accommodation,
		availableRoomOptions,
		headerImage,
		galleryImages,
		geographicaLocation: {
			lat: accommodation?.productLocation?.latitude,
			lng: accommodation?.productLocation?.longitude
		},
		error,
		loading,
		loadingAvailableRooms,
		clearAccommodationDetailsCache
	};
};

export default useAccommodationDetails;
