import styled from 'styled-components';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { Colors, FontSize, BodyWrapperHeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	border-top: 1px solid ${Colors.V30};
    border-bottom: 1px solid ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: block;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.mobile};
		padding: 0.75rem;
	}
`;

export const TermsTitle = styled.h4`
	font-size: ${FontSize.xlSize};
	text-align: center;
`;

export const TermsContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

export const TermsSubtitle = styled.p`

`;

export const TermsParagraph = styled.p`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const ParagraphHeading = styled.h6`
	font-size: ${FontSize.base};
`;

export const ParagraphItem = styled.p`

`;