import { useState } from 'preact/hooks';

import useWishlist from 'redesigncache/wishlist/useWishlist';

const useWishlistItems = () => {
	const { wishlist } = useWishlist();
	const [selectedWishlistItemIds, setSelectedWishlistItemIds] = useState<
		string[]
	>([]);

	const toggleWishlistItemSelected = wishlistItemId => {
		if (selectedWishlistItemIds.includes(wishlistItemId)) {
			const newSelectedWishlistItemIds = [...selectedWishlistItemIds];
			newSelectedWishlistItemIds.splice(
				selectedWishlistItemIds.indexOf(wishlistItemId),
				1
			);

			setSelectedWishlistItemIds(newSelectedWishlistItemIds);
		} else {
			setSelectedWishlistItemIds([...selectedWishlistItemIds, wishlistItemId]);
		}
	};

	const selectedItems = wishlist.wishlistItems.filter(wi =>
		selectedWishlistItemIds.includes(wi.id)
	);

	const notSelectedItems = wishlist.wishlistItems.filter(
		wi => !selectedWishlistItemIds.includes(wi.id)
	);

	return {
		selectedItems,
		notSelectedItems,
		selectedWishlistItemIds,
		setSelectedWishlistItemIds,
		toggleWishlistItemSelected
	};
};

export default useWishlistItems;
