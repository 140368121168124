import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

export type UserType = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	countryId: string;
	country: Option;
	createdAt: Date;
	dateOfBirth: Date;
	gender: string;
	connection: string;
};

export const userVar = makeVar<UserType>(undefined);
