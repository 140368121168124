import styled from 'styled-components';
import { fonts } from 'styles/defaultTheme';
import { checkIfIframe } from 'utils/iframe/checkIfIframe';

export default styled.div`
  font-family: ${fonts.default};
  margin: 0;
  height: 100%;
  width: 100%;

  ${() => {
    if (!checkIfIframe()) {
      return `
      background-color: #2c3d5e;`;
    }
  }}
`;
