import { ComponentChildren, FunctionalComponent } from 'preact';

import styled from 'styled-components';
import { BodyWrapperHeight, Colors, DeviceBreakpoints } from 'styles/defaultTheme';

const BodyContainer = styled.div`
	background-color: ${Colors.V0};
	color: ${Colors.V900};
	border-top: 1px solid ${Colors.V30};
	border-bottom: 1px solid ${Colors.V30};
	height: ${BodyWrapperHeight.base};
	display: ${({ block }) => (block ? 'block' : 'flex')};
	align-items: ${({ topAlign }) => (topAlign ? 'start' : 'center')};
	justify-content: center;
	padding: ${({ topAlign }) => (topAlign ? '1.25rem 1.5rem' : '1.25rem')};
	overflow-y: auto;
	position: relative;
	@media ${DeviceBreakpoints.small} {
		padding: ${({ topAlign }) => (topAlign ? '1.25rem 0.75rem' : '1.25rem')};
		height: ${BodyWrapperHeight.mobile};
	}
`;
interface IProps {
	topAlign?: boolean;
	children: ComponentChildren;
	block?: boolean;
}

const BodyWrapper: FunctionalComponent<IProps> = ({
	children,
	topAlign,
	block
}) => {
	return (
		<BodyContainer topAlign={topAlign} block={block}>
			{children}
		</BodyContainer>
	);
};

export default BodyWrapper;
