import { useMutation } from '@apollo/client';
import { useState } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_INTEGRATIONS_MERCHANDISE_LIST } from 'apollo/myDesti/mutations';
import { PAGE_LIMIT } from 'config/network';
import { MerchandiseOverviewModel } from 'redesigntypes/ActivityModel';
import utcDateWithLocalTimePlugin from 'utils/date/utcDateWithLocalTimePlugin';

dayjs.extend(utcDateWithLocalTimePlugin);

const useMerchandiseSearch = () => {
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [hasMore, setHasMore] = useState(true);
	const [merchandise, setMerchandise] = useState<MerchandiseOverviewModel[]>(
		[]
	);

	const [loadMerchandise, { loading }] = useMutation<{
		getMerchandise;
	}>(GET_INTEGRATIONS_MERCHANDISE_LIST, {
		onCompleted: ({ getMerchandise }) => {
			setHasMore(totalPages > page);

			const result = getMerchandise.products;

			if (page > 1) {
				setMerchandise([...merchandise, ...result]);
			} else {
				setMerchandise(result);
			}

			setTotalCount(getMerchandise.totalCount);
			setPage(getMerchandise.pageNumber);
			setTotalPages(getMerchandise.totalPages);
		}
	});

	const getMerchandise = (sort: string | number) => {
		loadMerchandise({
			variables: {
				pageSize: PAGE_LIMIT,
				pageNumber: page,
				orderBy: sort
			}
		});
	};

	return {
		merchandise,
		totalCount,
		page,
		hasMore,
		loading,
		getMerchandise,
		setPage
	};
};

export default useMerchandiseSearch;
