import { FunctionalComponent } from 'preact';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { MIN_DATE } from 'config/inputs';

import ArrowIcon from 'assets/nav-arrow.svg';

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4rem 1rem;
`;

const HeaderButtons = styled.button`
	outline: none;
	border: none;
	background-color: unset;
	cursor: pointer;
`;

const RightArrowIcon = styled(ArrowIcon)`
	width: 15px;
	height: 15px;
`;

const LeftArrowIcon = styled(RightArrowIcon)`
	transform: rotate(180deg);
`;

const StyledLabel = styled.label`
	font-size: 15px;
	font-weight: 400;
	line-height: 120%;
`;

const StyledSelect = styled.select`
	height: 32px;
	padding: 0px 5px;
	border: unset;
	background: #fbfbfb;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 120%;
	text-align: center;
	color: #796d84;
`;

type Props = {
	date?: Date;
	maxDate?: Date;
	minDate?: Date;
	changeYear?: (yearNum: number) => void;
	changeMonth?: (monthNum: number) => void;
	decreaseMonth?: () => void;
	increaseMonth?: () => void;
	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;
	showMonthYearDropdown?: boolean;
	dateFormatCalendarHeader?: string;
};

const generateArrayOfYears = (min, max) => {
	var years = [];
	for (var i = max; i >= min; i--) {
		years.push(i);
	}
	return years.reverse();
};

const CustomDateHeader: FunctionalComponent<Props> = ({
	date,
	minDate = new Date(),
	maxDate,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	showMonthYearDropdown,
	dateFormatCalendarHeader = 'MMMM, YYYY.'
}) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const max = maxDate ? dayjs(maxDate).year() : dayjs(minDate).year() + 4;
	const years = generateArrayOfYears(dayjs(minDate || MIN_DATE).year(), max);

	return (
		<Header>
			{showMonthYearDropdown ? (
				<>
					<StyledSelect
						value={months[dayjs(date).month()]}
						onChange={({ target: { value } }) => {
							changeMonth(months.indexOf(value));
						}}
					>
						{months.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</StyledSelect>
					<StyledSelect
						value={dayjs(date).year()}
						onChange={({ target: { value } }) => changeYear(value)}
					>
						{years.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</StyledSelect>
				</>
			) : (
				<>
					<HeaderButtons
						type="button"
						onClick={decreaseMonth}
						disabled={prevMonthButtonDisabled}
					>
						<LeftArrowIcon />
					</HeaderButtons>
					<StyledLabel>
						{dayjs(date).format(dateFormatCalendarHeader)}
					</StyledLabel>
					<HeaderButtons
						type="button"
						onClick={increaseMonth}
						disabled={nextMonthButtonDisabled}
					>
						<RightArrowIcon />
					</HeaderButtons>
				</>
			)}
		</Header>
	);
};

export default CustomDateHeader;
