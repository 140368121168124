import { ApolloProvider } from '@apollo/client';
import { render } from 'preact';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import 'assets/fonts/stylesheet.css';

import App from './App';
import { myDestiClient } from './apollo/client';

render(
	<ApolloProvider client={myDestiClient}>
		<App />
	</ApolloProvider>,
	document.querySelector('#app')
);
