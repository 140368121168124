import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { PAGE_DEFAULT } from 'config/network';
import useIntl from 'hooks/useIntl';
import Footer from 'redesigncomponents/Footer';
import OptionsNumberTitle from 'redesigncomponents/OptionsNumberTitle';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { InfiniteScrollWithRef } from 'shared/components';
import { Colors } from 'styles/defaultTheme';
import { MerchandiseOrderBy } from 'types/enums';

import useMerchandiseListCache from './cache/useMerchandiseListCache';
import { MerchandiseOfferItem } from './components/MerchandiseOfferItem';
import {
	ListWrapper,
	LoaderWrapper,
	RightSide,
	StyledBodyWrapper,
	ThumbnailContainer,
	Wrapper
} from './components/StyledComponents';
import useMerchandiseSearch from './useMerchandiseSearch';

interface IProps {}

const MerchandiseList: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('merchandiseDetails');
	const { t: orderByTranslation } = useIntl('common.OrderBy');
	const history = useHistory();
	const location = useLocation();
	const [infiniteScrollWraperID] = useState<string>(uuidv4());
	const sortOptions = Object.values(MerchandiseOrderBy).map(ob => ({
		value: ob,
		label: orderByTranslation(ob)
	}));

	const { initialized, sort, initializeMerchandiseListCache, setSort } =
		useMerchandiseListCache();

	const {
		merchandise,
		page,
		totalCount,
		hasMore,
		loading,
		getMerchandise,
		setPage
	} = useMerchandiseSearch();

	useEffect(() => {
		if (!location?.state?.breadcrumbNavigation) {
			// breadcrumbNavigation added to state in Breadcrumbs component
			// initialize the merchandise list cache only if we got here from a regular link and if the screen has not been initialized before
			// if we got here by breadcrumb navigation, we expect to have the same state as we are going back in history
			initializeMerchandiseListCache(sortOptions[0]);
		}
	}, [location?.state?.breadcrumbNavigation]);

	useEffect(() => {
		if (initialized) {
			if (page === PAGE_DEFAULT) {
				return;
			}
			loadMerchandise();
		}
	}, [initialized, page]);

	useEffect(() => {
		if (initialized) {
			loadMerchandise();
		}
	}, [initialized, sort]);

	const onSortChange = sort => {
		setPage(PAGE_DEFAULT);
		setSort(sort);
	};

	const loadMerchandise = () => {
		getMerchandise(sort?.value);
	};

	const onNextPage = () => {
		const nextPage = page + 1;
		setPage(nextPage);
	};

	const goToMerchandiseDetails = merchandiseId => {
		history.push(`/merchandise/${merchandiseId}`);
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<RightSide expand={true}>
					<OptionsNumberTitle
						count={totalCount}
						sortOptions={sortOptions}
						sort={sort}
						onSortChange={onSortChange}
					/>
					{loading && page === PAGE_DEFAULT ? (
						<LoaderWrapper>
							<ThreeDotLoader color={Colors.V900} size={40} />
						</LoaderWrapper>
					) : (
						<ListWrapper id={infiniteScrollWraperID}>
							<InfiniteScrollWithRef
								dataLength={merchandise.length}
								next={onNextPage}
								hasMore={hasMore}
								loader={
									loading && (
										<Wrapper>
											<ThreeDotLoader color={Colors.V900} size={40} />
										</Wrapper>
									)
								}
								endMessage={<Wrapper>{t('inifiniteScrollEndMessage')}</Wrapper>}
								scrollableTarget={infiniteScrollWraperID}
							>
								<ThumbnailContainer grid={true}>
									{merchandise.map((offer, index) => (
										<MerchandiseOfferItem
											key={index}
											offer={offer}
											onClick={() => goToMerchandiseDetails(offer.id)}
										/>
									))}
								</ThumbnailContainer>
							</InfiniteScrollWithRef>
						</ListWrapper>
					)}
				</RightSide>
			</StyledBodyWrapper>
			<Footer></Footer>
		</MainWrapper>
	);
};

export default MerchandiseList;
