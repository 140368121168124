import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import useAirplaneClassOptions from 'hooks/useAirplaneClassOptions';
import useIntl from 'hooks/useIntl';
import FieldSelect from 'redesigncomponents/FieldSelect';
import { Option } from 'redesigntypes/Option';
import { AirplaneClass } from 'types/enums';

type Props = {
	value: Option;
	onChange: (Option) => void;
};

const ClassSelect: FunctionalComponent<Props> = ({ value, onChange }) => {
	const { t } = useIntl('transportList.options');
	const { airplaneClassOptions } = useAirplaneClassOptions();

	useEffect(() => {
		// setting the Economy as the initial value
		if (airplaneClassOptions?.length && !value) {
			const economyOption = airplaneClassOptions.find(
				tt => tt.value === AirplaneClass.Economy
			);
			onChange(economyOption || airplaneClassOptions[0]);
		}
	}, [airplaneClassOptions]);

	return (
		<FieldSelect
			options={airplaneClassOptions}
			onChange={onChange}
			value={value}
			label={t('classSelectLabel')}
		/>
	);
};

export default ClassSelect;
