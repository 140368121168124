import { useMutation } from '@apollo/client';
import { useCallback } from 'preact/hooks';

import dayjs from 'dayjs';

import {
	PURCHASE_MERCHANDISE,
	VALIDATE_PURCHASE_MERCHANDISE
} from 'apollo/myDesti/mutations';
import config from 'config/config';
import { IMerchandiseWishlistItem } from 'redesigncache/wishlist/types';

import { mapReactFormNameToBookingFieldLabel } from '../components/CheckoutForm/components/BookingFieldsFormSection/useAdditionalBookingFieldsConfiguration';

const DAYJS_REZDY_ACTIVITY_DATE_FORMAT = 'YYYY-MM-DD';

const getBookingFieldValue = (
	fieldName,
	value: string | { label: string; value: string; extra?: any } | Date | boolean
) => {
	if (value instanceof Date) {
		return dayjs(value).format(DAYJS_REZDY_ACTIVITY_DATE_FORMAT);
	} else if (
		fieldName === 'Country' &&
		typeof value === 'object' &&
		value.hasOwnProperty('label') &&
		value.hasOwnProperty('value')
	) {
		return value.extra.isoCode2;
	} else if (
		typeof value === 'object' &&
		value.hasOwnProperty('label') &&
		value.hasOwnProperty('value')
	) {
		return value.value;
	} else if (typeof value === 'boolean') {
		return value.toString();
	} else {
		return value;
	}
};

const useMerchandisePurchase = (item, user) => {
	const [merchandisePurchase] = useMutation(PURCHASE_MERCHANDISE, {
		fetchPolicy: 'no-cache'
	});
	const [validateMerchandisePurchase] = useMutation(
		VALIDATE_PURCHASE_MERCHANDISE,
		{
			fetchPolicy: 'no-cache'
		}
	);

	const createMerchandisePurchase = useCallback(
		async (
			formValues: any,
			productOwnerGlobalId: string,
			paymentIntentId: string
		) => {
			try {
				const merchandiseItem = item.item as IMerchandiseWishlistItem;
				const merchandiseVariables = {
					customer: {
						firstName: formValues.firstName || user.first_name,
						lastName: formValues.lastName || user.last_name,
						email: formValues.email || user.email
						//gender: user.gender,
						//dob: user.date_of_birth,
						// countryCode:
						//   user && countries.length
						//    ? countries.find(c => c.id === user.country_id).country_code
						// : '',
					},
					payments: [
						{
							amount: item.item.price.amount,
							currency: item.item.price.currency
							//date: Date.now(),
							//label: '',
							//recipient: 'SUPPLIER',
							//type: 'CREDITCARD',
						}
					],
					items: [
						{
							productCode: merchandiseItem.id,
							quantities: merchandiseItem.quantity.map(q => {
								return { optionId: q.optionId, value: q.quantity };
							}),
							pickupLocation: formValues.pickupLocation
								? {
										locationName:
											formValues.pickupLocation?.extra.pickupLocation
												?.locationName
								  }
								: undefined
						}
					],
					fields:
						formValues.requiredPerBookingFields &&
						Object.keys(formValues.requiredPerBookingFields).map(key => {
							return {
								label: mapReactFormNameToBookingFieldLabel(key),
								value: getBookingFieldValue(
									key,
									formValues.requiredPerBookingFields[key]
								)
							};
						}),
					datePaid: dayjs().format('YYYY-MM-DD'), // only used in bookActivity, not needed for validateBookActivity,
					globalId: productOwnerGlobalId,
					paymentIntentId,
					sendNotifications: true
				};

				if (config.activityBookingTestingEnabled) {
					const response = await validateMerchandisePurchase({
						variables: merchandiseVariables
					});
					if (response instanceof Error) {
						return response;
					}

					return {
						data: {
							merchandisePurchase: {
								orderNumber: getRandomInt(1, 1000000).toString(),
								status: 'success',
								__typename: 'PurchaseMerchandiseResponse'
							}
						}
					};
				} else {
					// live activity booking
					console.log('Live purchase');
					return await merchandisePurchase({
						variables: merchandiseVariables
					});
				}
			} catch (error) {
				console.log(error);
				return error;
			}
		},
		[item]
	);

	return { createMerchandisePurchase };
};

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default useMerchandisePurchase;
