import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Chip from 'redesigncomponents/Chip';
import { TransportTripType } from 'types/enums';

import AccordionIcon from 'assets/accordion-arrow.svg';
import PlaneIcon from 'assets/icons/transport/plane.svg';

import DateChip from './components/DateChip';
import DestinationPath from './components/DestinationPath';
import {
	AccordionBody,
	AccordionContainer,
	AccordionHeader,
	ButtonContainer,
	ContentContainer,
	DepartureData,
	HorizontalLine,
	LeftContainer,
	NightStayWrapper,
	PriceTitle,
	RightContainer,
	SectionContainer,
	StayDurationTitle,
	SvgWrapper
} from './components/StyledComponents';
import useTransportOfferItem from './useTransportOfferItem';

interface IProps {
	offer: any; // TODO: add types
	tripType: TransportTripType;
	selectTransportOffer?: any;
	clearSelectedTransportOffer?: any;
}

const TransportOfferItem: FunctionalComponent<IProps> = ({
	offer,
	tripType,
	selectTransportOffer,
	clearSelectedTransportOffer
}) => {
	const { t } = useIntl('transportList.results');
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { headerDetails, expandedDetails } = useTransportOfferItem(
		offer,
		tripType
	);
	return (
		<AccordionContainer>
			<AccordionHeader onClick={toggleCollaped}>
				<LeftContainer>
					<DateChip
						fromTitle={headerDetails.fromDate}
						toTitle={headerDetails.toDate}
					/>
					<Chip title={headerDetails.startingAirline} Icon={PlaneIcon} />
					<Chip title={headerDetails.numberOfStops} />
				</LeftContainer>
				<RightContainer>
					<PriceTitle>{headerDetails.price}</PriceTitle>
					<SvgWrapper collapsed={collapsed}>
						<AccordionIcon></AccordionIcon>
					</SvgWrapper>
				</RightContainer>
			</AccordionHeader>
			{collapsed && (
				<AccordionBody>
					<ContentContainer>
						<SectionContainer>
							<DepartureData>
								{expandedDetails?.outgoing.outgoingTimeString}
							</DepartureData>
							<DestinationPath
								fromTitle={expandedDetails?.outgoing.outgoingAirport}
								toTitle={expandedDetails?.outgoing.arrivalAirport}
								itineraries={expandedDetails.outgoing.itineraries}
							></DestinationPath>
						</SectionContainer>
						{expandedDetails?.returning && (
							<>
								<NightStayWrapper>
									<HorizontalLine></HorizontalLine>
									<StayDurationTitle>
										{t(
											'stayLengthNightsMessage',
											{ stayLengthNights: expandedDetails.stayLengthNights },
											expandedDetails.stayLengthNights
										)}
									</StayDurationTitle>
									<HorizontalLine></HorizontalLine>
								</NightStayWrapper>

								<SectionContainer>
									<DepartureData>
										{expandedDetails?.returning.outgoingTimeString}
									</DepartureData>
									<DestinationPath
										fromTitle={expandedDetails?.returning.outgoingAirport}
										toTitle={expandedDetails?.returning.arrivalAirport}
										itineraries={expandedDetails.returning.itineraries}
									></DestinationPath>
								</SectionContainer>
							</>
						)}
					</ContentContainer>
					{selectTransportOffer ? (
						<ButtonContainer>
							<Button
								title={t('selectButtonTitle')}
								buttonStyleType={ButtonStyleTypes.primary}
								onClick={selectTransportOffer}
							></Button>
						</ButtonContainer>
					) : (
						<ButtonContainer>
							<Button
								title={t('clearButtonTitle')}
								buttonStyleType={ButtonStyleTypes.primary}
								onClick={clearSelectedTransportOffer}
							></Button>
						</ButtonContainer>
					)}
				</AccordionBody>
			)}
		</AccordionContainer>
	);
};

export default TransportOfferItem;
