import { useMutation } from '@apollo/client';

import { myPagesClient } from 'apollo/client';
import { SAVE_TRAVEL_PLAN } from 'apollo/myPages/mutations';

const useSaveTravelPlan = () => {
	const [saveTravelPlanMutation, { loading }] = useMutation(SAVE_TRAVEL_PLAN, {
		client: myPagesClient
	});

	const saveTravelPlan = async (
		travelPlanName,
		travelPeriod,
		travelPlanItems
	) => {
		await saveTravelPlanMutation({
			variables: {
				travelPlan: {
					travelPeriod,
					travelPlanItems: travelPlanItems,
					travelPlanName,
					travelItineraryId: null
				}
			}
		});
	};

	return { saveTravelPlan, loading };
};

export default useSaveTravelPlan;
