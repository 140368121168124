import { FunctionalComponent } from 'preact';

import dayjs from 'dayjs';

import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import { TransportTripType } from 'types/enums';

type Props = {
	tripType: TransportTripType;
	travelPeriod: [Date, Date];
	setTravelPeriod: (travelPeriod: [Date, Date]) => void;
};

const TripDatePicker: FunctionalComponent<Props> = ({
	tripType,
	travelPeriod,
	setTravelPeriod
}) => {
	return (
		<FieldDatepicker
			minDate={new Date()}
			dateFormatCalendarHeader="MMMM, YYYY."
			isRange={tripType === TransportTripType.RoundTrip}
			smallPadding
			showMonthYearDropdown
			popperPlacement="bottom-end"
			input={{
				onChange: value => {
					value[0] =
						dayjs(value[0]).diff(dayjs(new Date())) > 0
							? value[0]
							: dayjs(new Date()).add(1, 'h').toDate();
					if (value.length === 2) {
						setTravelPeriod(value);
					} else setTravelPeriod([value, undefined]);
				},
				value:
					travelPeriod?.length && tripType === TransportTripType.OneWay
						? [travelPeriod[0], undefined]
						: travelPeriod,
				name: '',
				onBlur: () => null,
				onFocus: () => null
			}}
		/>
	);
};

export default TripDatePicker;
