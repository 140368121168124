import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import Chip from 'redesigncomponents/Chip';
import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

import ArrowIcon from 'assets/direction-arrow.svg';
import ClockIcon from 'assets/icons/transport/clock.svg';

const DestinationContainer = styled.div`
	color: ${Colors.V900};
`;

const DestinationFrom = styled.p`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
`;

const DestinationTo = styled.p`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
`;

const RowContainer = styled.div`
	display: flex;
`;

const Circle = styled.div`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: ${Colors.V400};
`;

const SectorArrowWrapper = styled.div`
	position: relative;
	display: flex;
	flex-shrink: 0;
	-webkit-box-align: center;
	align-items: center;
	margin-right: 8px;
	width: 8px;
	justify-content: center;
`;

const FromVerticalLine = styled.div`
	position: absolute;
	background-color: ${Colors.V400};
	left: 50%;
	width: 1px;
	height: 50%;
	bottom: 50%;
`;

const ToVerticalLine = styled.div`
	position: absolute;
	left: 50%;
	width: 1px;
	height: 50%;
	top: 50%;
	background-color: ${Colors.V400};
`;

const ChipGroupContainer = styled.div`
	display: inline-flex;
	align-items: center;
	padding: 0.5rem;
	border-radius: 2.5rem;
	background: ${Colors.V0};
	border: 1px solid ${Colors.V30};
	gap: 0.5rem;
	flex-wrap: wrap;
	@media ${DeviceBreakpoints.medium} {
		border-radius: 1rem;
	}
`;

const ArrowIconContainer = styled.div`
	position: relative;
	z-index: 1;
	background: ${props => (props.darkerBckg ? '#efedf0' : '#fff')};
	display: flex;
	height: 2.25rem;
	align-items: center;
	width: 8px;
	justify-content: center;
`;

export const GroupWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem 0;
`;

interface IProps {
	fromTitle?: string;
	toTitle?: string;
	active?: boolean;
	itineraries?: Array<any>;
	darkerBckg?: boolean;
}

const DestinationPath: FunctionalComponent<IProps> = ({
	fromTitle,
	toTitle,
	active,
	itineraries,
	darkerBckg
}) => {
	return (
		<DestinationContainer>
			<RowContainer>
				<SectorArrowWrapper>
					<Circle></Circle>
					<ToVerticalLine></ToVerticalLine>
				</SectorArrowWrapper>
				<DestinationFrom>{fromTitle}</DestinationFrom>
			</RowContainer>
			<RowContainer>
				<SectorArrowWrapper>
					<FromVerticalLine></FromVerticalLine>
					<ArrowIconContainer darkerBckg={darkerBckg}>
						<ArrowIcon></ArrowIcon>
					</ArrowIconContainer>
					<ToVerticalLine></ToVerticalLine>
				</SectorArrowWrapper>
				<GroupWrapper>
					{itineraries?.map(itinerary => (
						<ChipGroupContainer active={active}>
							<Chip
								title={itinerary.outgoingTime}
								Icon={ClockIcon}
								outlined
							></Chip>
							<Chip title={itinerary.outgoingStation} outlined></Chip>
							<Chip
								title={itinerary.line}
								Icon={itinerary.travelMethodIcon}
								outlined
							></Chip>
							<Chip title={itinerary.duration} outlined></Chip>
						</ChipGroupContainer>
					))}
				</GroupWrapper>
			</RowContainer>
			<RowContainer>
				<SectorArrowWrapper>
					<FromVerticalLine></FromVerticalLine>
					<Circle></Circle>
				</SectorArrowWrapper>
				<DestinationTo>{toTitle}</DestinationTo>
			</RowContainer>
		</DestinationContainer>
	);
};

export default DestinationPath;
