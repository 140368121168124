import { makeVar } from '@apollo/client';

import { AffiliateLinkConfig } from 'redesigncache/affiliateLink/cache';

export enum WidgetFlowType {
	Main = 'Main',
	AffiliateLink = 'AffiliateLink'
}

export type WidgetFlow = {
	type: WidgetFlowType;
	config?: AffiliateLinkConfig | undefined;
};
export const initialValueWidgetFlow: WidgetFlow = { type: WidgetFlowType.Main };

export const widgetFlowVar = makeVar<WidgetFlow>(initialValueWidgetFlow);
