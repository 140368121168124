import storage from 'local-storage-fallback';
import { FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';
import { PageVisitedContext } from './PageVisitedContext';

export const usePageVisited = (pageName: string): boolean => {
  const pagesVisitedArray: Array<string> = useContext(PageVisitedContext);
  if (pagesVisitedArray.includes(pageName)) {
    return true;
  } else {
    pagesVisitedArray.push(pageName);
    storage.setItem('pagesVisited', JSON.stringify(pagesVisitedArray));
    return false;
  }
};

export const PageVisitedProvider: FunctionalComponent = ({ children }) => {
  const pagesVisited: Array<string> = storage.getItem('pagesVisited')
    ? JSON.parse(storage.getItem('pagesVisited'))
    : Array<string>();

  return (
    <PageVisitedContext.Provider value={pagesVisited}>
      {children}
    </PageVisitedContext.Provider>
  );
};
