import { ComponentChildren, FunctionalComponent } from 'preact';

import styled, { css, keyframes } from 'styled-components';

import { FontSize } from 'styles/defaultTheme';

import CloseIcon from 'assets/close.svg';

const SlideUpAnimation = keyframes`
   0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0%);
    }
`;

const SlideDownAnimation = keyframes`
   0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
`;

const ModalWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	position: fixed;
	padding: 0.5rem;
`;

const ModalMask = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	position: absolute;
	background: rgba(0, 0, 0, 0.3);
`;

const ModalDialog = styled.div`
	background: #fff;
	padding: 30px;
	position: relative;
	z-index: 110;
	border-radius: 4px;
	max-width: ${props => (props.big ? '670px' : '500px')};
	text-align: center;
	font-size: 16px;
	color: #342245;
	animation-duration: 0.3s;
	animation-name: ${props => {
		return props.animation === 'slideUp'
			? SlideUpAnimation
			: SlideDownAnimation;
	}};
	${props =>
		props.customStyle &&
		css`
			${Object.keys(props.customStyle).map(
				key => `${key}: ${props.customStyle[key]};`
			)}
		`}
`;

const CloseIconContainer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 15px;
	right: 15px;
`;

export const ModalHeader = styled.h6`
	font-size: 23px;
`;

export const GetGestModalWrapper = styled.div`
	color: #243757;
	h6 {
		font-size: 32px;
		font-weight: 400;
		margin-bottom: 1.25rem;
	}
	div {
		button {
			width: auto;
			background-color: #243757;
			&:hover {
				background-color: #293d61;
			}
		}
	}
	> p {
		margin: 0;
	}
`;

export const ModalBody = styled.p`
	margin-top: 20px;
	max-height: 65vh;
	overflow-y: auto;
`;

export const ModalParagraph = styled.p`
	margin-bottom: 0.5rem;
`;

export const BoldText = styled.span`
	font-weight: bold;
`;

export const LogoContainer = styled.div`
	svg {
		width: 78px;
		height: 72px;
	}
`;

export const ModalInfoText = styled.p`
	margin: 1rem 0 2rem 0;
`;

export const OptionModalText = styled.p`
	margin: 0.75rem 0 1.5rem 0;
	font-size: ${FontSize.smSize};
`;

export const OptionModalTitle = styled.h4``;

export const OptionModalSubtitle = styled.p`
	font-size: ${FontSize.xsSize};
	margin-top: 0.5rem;
`;

export const ButtonFlexContainer = styled.div`
	display: flex;
	gap: 0.75rem;
	button {
		flex: 1;
	}
`;

export const ProminentMessage = styled.p`
	color: #091e42;
	font-weight: 600;
	background: #ebedf0;
	padding: 1.25rem;
	border-radius: 0.25rem;
	margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;

	button {
		flex: 1;
	}
`;

interface CustomStyle {
	[key: string]: string;
}

type Props = {
	children: ComponentChildren[] | ComponentChildren;
	animation?: 'slideDown' | 'slideUp';
	onClose?: () => void;
	isOpen: boolean;
	big?: boolean;
	dialogCustomStyle?: CustomStyle;
};

export const Modal: FunctionalComponent<Props> = ({
	children,
	animation = 'slideDown',
	onClose,
	isOpen,
	big,
	dialogCustomStyle
}) => {
	return (
		isOpen && (
			<ModalWrapper>
				<ModalMask></ModalMask>
				<ModalDialog
					animation={animation}
					big={big}
					customStyle={dialogCustomStyle}
				>
					{children}
					{onClose && (
						<CloseIconContainer onClick={onClose}>
							<CloseIcon></CloseIcon>
						</CloseIconContainer>
					)}
				</ModalDialog>
			</ModalWrapper>
		)
	);
};
