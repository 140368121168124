import { useMutation, useReactiveVar } from '@apollo/client';

import { GET_HOST_CONFIGRATION } from 'apollo/myDesti/mutations';
import { onMsg } from 'utils/iframe/postMessage';

import { HostConfiguration, ProductType, hostConfigurationVar } from './cache';

const useHostConfiguration = (topLevelSite?: string) => {
	const hostConfiguration = useReactiveVar(hostConfigurationVar);

	const [loadHostConfigurationMutation] = useMutation(GET_HOST_CONFIGRATION, {
		fetchPolicy: 'network-only',
		onError: () => {
			onMsg('dest1RedesignHideIframe', topLevelSite);
		},
		onCompleted: data => {
			const hostConfiguration: HostConfiguration =
				data.getPublishingPartnerSite;

			hostConfigurationVar(hostConfiguration);
		}
	});

	const loadHostConfiguration = (hostId: string) =>
		loadHostConfigurationMutation({ variables: { hostId } });

	const productTypeEnabled = (type: ProductType) => {
		return hostConfiguration?.productTypes.findIndex(pt => pt === type) !== -1;
	};

	return { loadHostConfiguration, productTypeEnabled, hostConfiguration };
};

export default useHostConfiguration;
