import { VNode } from 'preact';
import { ToastOptions, toast } from 'react-toastify';

import ErrorIcon from 'assets/toast/error-sign.svg';
import InfoIcon from 'assets/toast/info-sign.svg';
import SuccessIcon from 'assets/toast/success-sign.svg';
import WarningIcon from 'assets/toast/warning-sign.svg';

const StyledToast = {
	info: (message: string | VNode, options: ToastOptions = undefined) =>
		toast.info(message, {
			position: 'top-right',
			icon: InfoIcon,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			...options
		}),
	error: (message: string | VNode, options: ToastOptions = undefined) =>
		toast.error(message, {
			position: 'top-right',
			icon: ErrorIcon,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			...options
		}),
	warning: (message: string | VNode, options: ToastOptions = undefined) =>
		toast.warning(message, {
			position: 'top-right',
			icon: WarningIcon,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			...options
		}),
	success: (message: string | VNode, options: ToastOptions = undefined) =>
		toast.success(message, {
			position: 'top-right',
			icon: SuccessIcon,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			...options
		})
};

export default StyledToast;
