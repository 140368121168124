import { useMutation } from '@apollo/client';
import { useState } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_INTEGRATIONS_ACTIVITIES } from 'apollo/myDesti/mutations';
import { PAGE_LIMIT } from 'config/network';
import { ActivityOverviewModel } from 'redesigntypes/ActivityModel';
import utcDateWithLocalTimePlugin from 'utils/date/utcDateWithLocalTimePlugin';

dayjs.extend(utcDateWithLocalTimePlugin);

const useActivitySearch = () => {
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [hasMore, setHasMore] = useState(true);
	const [activities, setActivities] = useState<ActivityOverviewModel[]>([]);

	const [loadActivities, { loading }] = useMutation<{
		getActivities;
	}>(GET_INTEGRATIONS_ACTIVITIES, {
		onCompleted: ({ getActivities }) => {
			setHasMore(totalPages > page);
			const result = getActivities.activities;
			if (page > 1) {
				setActivities([...activities, ...result]);
			} else {
				setActivities(result);
			}
			setTotalCount(getActivities.totalCount);
			setPage(getActivities.pageNumber);
			setTotalPages(getActivities.totalPages);
		}
	});

	const getActivities = (
		adultsCount: number,
		destination: string,
		activityTypes: string[],
		travelPeriod: Date[],
		sort: string | number,
		privateTour: boolean
	) => {
		loadActivities({
			variables: {
				people: adultsCount,
				startTime: dayjs(travelPeriod[0])
					.toUTCDateWithLocalTime()
					.toISOString(),
				endTime: dayjs(travelPeriod[1]).toUTCDateWithLocalTime().toISOString(),
				location: destination,
				activityTypes: activityTypes,
				pageSize: PAGE_LIMIT,
				pageNumber: page,
				orderBy: sort,
				privateTour: privateTour
			}
		});
	};

	return {
		activities,
		totalCount,
		page,
		hasMore,
		loading,
		getActivities,
		setPage
	};
};

export default useActivitySearch;
