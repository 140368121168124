import { useState } from 'preact/hooks';

import useToggle from 'hooks/useToggle';
import { ITravelPlan } from 'redesigncache/checkout/types';
import StyledCheckbox from 'redesigncomponents/FieldCheckbox/StyledCheckbox';

import {
	CardName,
	CardWrapper,
	CheckboxWrapper,
	DetailsBox,
	HeadWrapper,
	LeftWrapper,
	Location,
	Name,
	RightWrapper
} from '../StyledComponents';
import DetailsButton from './DetailsButton';
import TravelItineneryDetails from './TravelItineraryDetails';
import useSingleTravelDetails from './useSingleTravelDetails';
import useTravelPlanLocationPeriod from './useTravelPlanLocationPeriod';

type Props = {
	travelPlan: any;
	handleItemRemove: any;
	addSelectedPlan: (plan: ITravelPlan) => void;
	removeSelectedPlan: (id: string) => void;
};

const SingleTravelDetails = ({
	travelPlan,
	handleItemRemove,
	addSelectedPlan,
	removeSelectedPlan
}: Props) => {
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { travelPlanItems } = useSingleTravelDetails(travelPlan);
	const { travelPlanLocationAndPeriod } =
		useTravelPlanLocationPeriod(travelPlan);

	const [checked, setChecked] = useToggle();

	const handleItemCheck = (isChecked: boolean): void => {
		setChecked(isChecked);
		if (isChecked) {
			addSelectedPlan({
				id: travelPlan.id,
				updatedAt: Date.now(),
				travelPeriod: travelPlan.travelPeriod,
				travelPlanItems: travelPlan.travelPlanItems,
				travelPlanName: travelPlan.travelPlanName,
				travelItineraryId: travelPlan.travelItineraryId,
				savedTravelPlan: true
			});
		} else {
			removeSelectedPlan(travelPlan.id);
		}
	};
	return (
		<CardWrapper collapsed={collapsed}>
			<HeadWrapper>
				<LeftWrapper>
					<CheckboxWrapper>
						<StyledCheckbox
							inputProps={{
								checked: checked,
								onChange: e => handleItemCheck(e.target.checked)
							}}
						/>
					</CheckboxWrapper>
					<CardName>
						<Name>{travelPlan.travelPlanName}</Name>
						<Location>{travelPlanLocationAndPeriod}</Location>
					</CardName>
				</LeftWrapper>
				<RightWrapper>
					<DetailsButton collapsed={collapsed} onClick={toggleCollaped} />
				</RightWrapper>
			</HeadWrapper>
			<DetailsBox collapsed={collapsed}>
				{travelPlanItems.map(item => {
					return (
						<TravelItineneryDetails
							travelPlanId={travelPlan.id}
							travelPlanItem={item}
							handleItemRemove={handleItemRemove}
						/>
					);
				})}
			</DetailsBox>
		</CardWrapper>
	);
};

export default SingleTravelDetails;
