import { gql } from '@apollo/client';

export const PREPARE_CHECKOUT_ITINERARY = gql`
	mutation ($contactEmail: String!, $travelPlan: String!) {
		prepareCheckoutItinerary(
			contactEmail: $contactEmail
			travelPlan: $travelPlan
		) {
			travelItineraryId
		}
	}
`;

export const GUEST_PREPARE_CHECKOUT_ITINERARY = gql`
	mutation ($contactEmail: String!, $travelPlan: String!) {
		guestPrepareCheckoutItinerary(
			contactEmail: $contactEmail
			travelPlan: $travelPlan
		) {
			travelItineraryId
		}
	}
`;

export const CANCEL_PAYMENT_INTENT = gql`
	mutation ($paymentIntentId: String!, $productOwnerExternalId: String!) {
		cancelCardFundsReservation(
			paymentIntentId: $paymentIntentId
			productOwnerExternalId: $productOwnerExternalId
		) {
			message
		}
	}
`;

export const CHECKOUT = gql`
	mutation (
		$paymentIntentId: String!
		$travelPlan: String!
		$globalId: String!
	) {
		checkout(
			paymentIntentId: $paymentIntentId
			travelPlan: $travelPlan
			globalId: $globalId
		) {
			message
			travelItineraryId
		}
	}
`;

export const GUEST_CHECKOUT = gql`
	mutation (
		$paymentIntentId: String!
		$travelPlan: String!
		$globalId: String!
		$guestEmail: String!
	) {
		guestCheckout(
			paymentIntentId: $paymentIntentId
			travelPlan: $travelPlan
			globalId: $globalId
			guestEmail: $guestEmail
		) {
			message
			travelItineraryId
		}
	}
`;

export const INITIALIZE_PAYMENT = gql`
	mutation (
		$paymentMethodId: String!
		$travelPlan: String!
		$globalId: String!
	) {
		initializePayment(
			paymentMethodId: $paymentMethodId
			travelPlan: $travelPlan
			globalId: $globalId
		) {
			clientSecret
		}
	}
`;

export const GUEST_INITIALIZE_PAYMENT = gql`
	mutation (
		$paymentMethodId: String!
		$travelPlan: String!
		$globalId: String!
		$guestEmail: String!
	) {
		guestInitializePayment(
			paymentMethodId: $paymentMethodId
			travelPlan: $travelPlan
			globalId: $globalId
			guestEmail: $guestEmail
		) {
			clientSecret
		}
	}
`;
