import { useQuery, useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useEffect, useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_CITIES } from 'apollo/myDesti/queries';
import useIntl from 'hooks/useIntl';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import { hostConfigurationVar } from 'redesigncache/hostConfiguration/cache';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldMultiselect from 'redesigncomponents/FieldMultiselect';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';

import DateIcon from 'assets/calendar.svg';
import LocationIcon from 'assets/location.svg';

import Covid19InfoTip from './components/Covid19InfoTip';
import { InputHeader } from './components/InputHeader';
import OfferMenu from './components/OfferMenu';
import {
	LeftSide,
	RightSide,
	StyledBodyWrapper
} from './components/StyledComponents';

type IProps = {};

const Main: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('main');
	const hostConfiguration = useReactiveVar(hostConfigurationVar);
	const siteName = hostConfiguration.name;
	const { travelPeriod, destinations, setTravelPeriod, setDestinations } =
		useDestinationAndPeriod();

	const { data: citiesData } = useQuery(GET_CITIES, {
		fetchPolicy: 'no-cache'
	});

	const getLocationOptions = citiesData => {
		return (
			citiesData &&
			citiesData.city.map((city, idx) => ({
				label: city.name,
				value: city.id,
				selected: !!destinations?.find(
					destination => city.id === destination.id
				),
				id: city.id,
				longitude: city.longitude,
				latitude: city.latitude
			}))
		);
	};

	const locationOptions = useMemo(() => {
		return getLocationOptions(citiesData);
	}, [getLocationOptions]);

	useEffect(() => {
		if (typeof destinations === 'undefined') {
			// selecting all the regions on first load
			const initialValues = getLocationOptions(citiesData)?.map(option => ({
				...option,
				selected: true
			}));
			setDestinations(initialValues);
		}
	}, [citiesData, destinations]);

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<LeftSide>
					<InputHeader
						mobile
						label={t('mobileLocationHeader')}
						Icon={LocationIcon}
					/>
					<InputHeader label={t('locationHeader')} Icon={LocationIcon} />
					<FieldMultiselect
						label={siteName}
						placeholder={t('chooseLocation')}
						enableSelectAndClearButtons
						input={{
							onChange: setDestinations,
							value: destinations,
							name: '',
							onBlur: () => null,
							onFocus: () => null
						}}
						options={locationOptions}
					/>
					<InputHeader label={t('dateHeader')} Icon={DateIcon} />
					<FieldDatepicker
						minDate={new Date()}
						dateFormatCalendarHeader="MMMM, YYYY."
						isRange
						showMonthYearDropdown
						input={{
							onChange: value => {
								value[0] =
									dayjs(value[0]).diff(dayjs(new Date())) > 0
										? value[0]
										: dayjs(new Date()).add(1, 'h').toDate();
								if (value.length === 2) {
									return new Date(value[0]).toDateString() ===
										new Date(value[1]).toDateString()
										? setTravelPeriod([])
										: setTravelPeriod(value);
								} else setTravelPeriod([value]);
							},
							value: travelPeriod,
							name: '',
							onBlur: () => null,
							onFocus: () => null
						}}
					/>
				</LeftSide>
				<RightSide>
					<OfferMenu
						selectedRegions={destinations}
						selectedTravelPeriod={travelPeriod}
					/>
				</RightSide>
			</StyledBodyWrapper>
			<Footer infoTip={<Covid19InfoTip />}></Footer>
		</MainWrapper>
	);
};

export default Main;
