import { PASSWORD_REGEX } from 'config/inputs';
import useIntl from 'hooks/useIntl';

export default function () {
	const { t: messages } = useIntl('common.validationMessages');

	const constraints = {
		firstName: {
			presence: {
				message: messages('firstNameRequired')
			}
		},
		lastName: {
			presence: {
				message: messages('lastNameRequired')
			}
		},
		email: {
			presence: {
				message: messages('emailRequired')
			},
			email: {
				message: messages('emailInvalid')
			}
		},
		password: {
			presence: {
				message: messages('passwordRequired')
			},
			format: {
				pattern: PASSWORD_REGEX,
				message: messages('passwordInvalid')
			}
		},
		confirmPassword: {
			presence: {
				message: messages('passwordInvalid')
			},
			equality: {
				attribute: 'password',
				message: messages('passwordMustMatch')
			}
		},
		olderThan18AndTermsOfUse: {
			presence: {
				message: messages('olderThan18AndTermsOfUseRequired')
			},
			inclusion: {
				within: [true],
				message: messages('olderThan18AndTermsOfUseRequired')
			}
		}
	};

	return { constraints };
}
