import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import { formatListsPrice } from 'utils/price/priceFormatting';
import secondsToDMH from 'utils/time/secondsToDMH';

import { WishlistItem, WishlistItemTypes } from './types';

const useWishlistItemDetails = () => {
	const { t } = useIntl('common.plurals');
	const { t: translationGeneral } = useIntl('common.general');

	const getFormattedNumberOfPeople = (item: WishlistItem) => {
		const offerItem = item?.item || { people: 0, children: 0, infants: 0 };
		const { people = 0, children = 0, infants = 0 } = offerItem;

		const numberStringFragments = [];
		if (people)
			numberStringFragments.push(t('adults', { count: people }, people));
		if (children)
			numberStringFragments.push(t('children', { count: children }, children));
		if (infants)
			numberStringFragments.push(t('infants', { count: infants }, infants));

		return numberStringFragments.join(' + ');
	};

	const getWishlistItemDetails = (item: any) => {
		if (item.type === WishlistItemTypes.MERCHANDISE) {
			return {};
		}

		const accommodationStartDate =
			item.item?.travelPeriod && item.item?.travelPeriod[0];
		const accommodationEndDate =
			item.item?.travelPeriod && item.item?.travelPeriod[1];

		return {
			...item,
			startTime: dayjs
				.utc(item.item.startTimeLocal || item.item.date)
				.format('HH:mm A'),
			startDate: dayjs(accommodationStartDate || item.item.date).format(
				'DD.MM.YYYY'
			),
			endDate: dayjs(accommodationEndDate || item.item.date).format(
				'DD.MM.YYYY'
			),
			numberOfPeople: getFormattedNumberOfPeople(item),
			numberOfTickets:
				item?.item?.number_of_tickets || translationGeneral('noInfoAvailable'),
			checkInTime: item.item.check_in_time,
			checkOutTime: item.item.check_out_time,
			duration: item.item.duration && secondsToDMH(item.item.duration),
			totalPrice: `${item.item.price.currency} ${formatListsPrice(
				item.item.price.amount
			)}`,
			address: item?.item.product_location?.address,
			longitude: item?.item?.product_location?.longitude,
			latitude: item?.item.product_location?.latitude
		};
	};

	return { getFormattedNumberOfPeople, getWishlistItemDetails };
};

export default useWishlistItemDetails;
