import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { useState } from 'preact/hooks';

import {
	CANCEL_PAYMENT_INTENT,
	CHECKOUT,
	GUEST_CHECKOUT,
	GUEST_INITIALIZE_PAYMENT,
	INITIALIZE_PAYMENT
} from 'apollo/myBookings/mutations';
import { useBookingMutation } from 'apollo/myBookings/useBooking';
import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import {
	IStayWishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import Toast from 'redesigncomponents/Toast';
import { isPaymentProcessingVar } from 'redesignscreens/Checkout/cache';

import { IComposedTravelPlanItem } from '../../../../TravelPlan/useTravelPlan';
import useBookItem from '../../../hooks/useBookItem';

type CreatePaymentIntentResponse = {
	clientSecret: string;
};
type PayTravelPlanItemResult = {
	orderNumber?: string;
	travelItineraryId?: string;
	error?: string;
	success: boolean;
};

const usePayTravelPlanItem = (
	travelItineraryId: string,
	travelPlanItem: IComposedTravelPlanItem,
	travelPeriod: Date[],
	travelPlanName = undefined
) => {
	const { t } = useIntl('checkout');
	const { t: commonTranslations } = useIntl('common');
	const [loading, setLoading] = useState<boolean>();
	const { user, isLoggedIn } = useUserAuth();
	const stripe = useStripe();
	const elements = useElements();
	const [checkout] = useBookingMutation(CHECKOUT);
	const [guestCheckout] = useBookingMutation(GUEST_CHECKOUT);
	const [initializePayment] = useBookingMutation(INITIALIZE_PAYMENT);
	const [guestInitializePayment] = useBookingMutation(GUEST_INITIALIZE_PAYMENT);
	const [cancelPaymentIntent] = useBookingMutation(CANCEL_PAYMENT_INTENT);

	const { bookItem } = useBookItem(travelPlanItem, user);
	const onErrorPayment = (message: string): void => {
		Toast.error(`${t('toasts.paymentFailed')} ${message}`);
	};

	const createPaymentMethod = async (
		cardElement,
		cardholderName
	): Promise<PaymentMethod> => {
		const paymentMethodResult = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: cardholderName
			}
		});
		if (paymentMethodResult.error) {
			throw paymentMethodResult.error;
		}

		return paymentMethodResult.paymentMethod;
	};

	const payTravelPlanItem = async (
		formValues
	): Promise<PayTravelPlanItemResult> => {
		const { cardholderName } = formValues;
		// add  billing values as cardholder name
		setLoading(true);
		isPaymentProcessingVar(true);
		try {
			const paymentMethod = await createPaymentMethod(
				elements.getElement(CardElement),
				cardholderName
			);

			const paymentIntentResponse = await createPaymentIntent(
				paymentMethod,
				travelPlanItem.productOwner.globalId,
				formValues
			);

			const confirmedPaymentIntent = await confirmPayment(
				paymentIntentResponse.clientSecret
			);

			let orderNumber;
			try {
				orderNumber = await bookTravelPlanItem(
					travelPlanItem,
					formValues,
					travelPlanItem.productOwner.globalId,
					confirmedPaymentIntent.id
				);
			} catch (error) {
				cancelPayment(
					confirmedPaymentIntent.id,
					travelPlanItem.productOwner.externalId
				);
				throw error;
			}

			if (orderNumber) {
				const capturePaymentResult = await capturePayment(
					orderNumber,
					confirmedPaymentIntent.id,
					travelPlanItem.productOwner.globalId,
					formValues
				);

				if (capturePaymentResult instanceof Error) {
					throw capturePaymentResult;
				} else {
					return {
						orderNumber,
						travelItineraryId: capturePaymentResult.travelItineraryId,
						success: true
					};
				}
			}
		} catch (error) {
			onErrorPayment(error.message);
			return { error: error.message, success: false };
		} finally {
			setLoading(false);
			isPaymentProcessingVar(false);
		}
	};

	const createPaymentIntent = async (
		paymentMethod: PaymentMethod,
		productOwnerGlobalId: string,
		formValues
	): Promise<CreatePaymentIntentResponse> => {
		// create payment intent, but do not confirm it
		if (isLoggedIn) {
			const paymentIntentResult = await initializePayment({
				variables: {
					paymentMethodId: paymentMethod.id,
					travelPlan: getTravelPlanObject({
						...travelPlanItem,
						pickup_location: formValues.pickupLocation?.extra.pickupLocation
					}),
					globalId: productOwnerGlobalId
				}
			});
			return paymentIntentResult.data.initializePayment;
		} else {
			const paymentIntentResult = await guestInitializePayment({
				variables: {
					paymentMethodId: paymentMethod.id,
					travelPlan: getTravelPlanObject({
						...travelPlanItem,
						pickup_location: formValues.pickupLocation?.extra.pickupLocation
					}),
					globalId: productOwnerGlobalId,
					guestEmail: formValues.email
				}
			});
			return paymentIntentResult.data.guestInitializePayment;
		}
	};

	const confirmPayment = async (clientSecret: string) => {
		const { error: errorOnPaymentIntentRetrieve, paymentIntent } =
			await stripe.retrievePaymentIntent(clientSecret);

		if (errorOnPaymentIntentRetrieve) {
			throw new Error(errorOnPaymentIntentRetrieve.message);
		}

		// Handle authorization and/or confirm payment intent, check card errors - stolen, insufficien funds etc.
		const {
			error: errorOnPaymentIntentConfirm,
			paymentIntent: confirmedPaymentIntent
		} = await stripe.confirmCardPayment(clientSecret, {
			// @ts-ignore
			payment_method: paymentIntent.payment_method
		});

		if (errorOnPaymentIntentConfirm) {
			await cancelPayment(
				paymentIntent.id,
				travelPlanItem.productOwner.externalId
			);
			throw new Error(errorOnPaymentIntentConfirm.message);
		}

		return confirmedPaymentIntent;
	};

	const capturePayment = async (
		orderNumber: string,
		paymentIntentId: string,
		productOwnerGlobalId: string,
		formValues
	) => {
		if (isLoggedIn) {
			const response = await checkout({
				variables: {
					paymentIntentId,
					travelPlan: getTravelPlanObject({
						...travelPlanItem,
						order_number: orderNumber,
						pickup_location: formValues.pickupLocation?.extra.pickupLocation
					}),
					globalId: productOwnerGlobalId
				}
			});
			return { travelItineraryId: response.data.checkout.travelItineraryId };
		} else {
			const response = await guestCheckout({
				variables: {
					paymentIntentId,
					travelPlan: getTravelPlanObject({
						...travelPlanItem,
						order_number: orderNumber,
						pickup_location: formValues.pickupLocation?.extra.pickupLocation
					}),
					globalId: productOwnerGlobalId,
					guestEmail: formValues.email
				}
			});
			return {
				travelItineraryId: response.data.guestCheckout.travelItineraryId
			};
		}
	};

	const cancelPayment = async (paymentIntentId, productOwnerExternalId) => {
		try {
			await cancelPaymentIntent({
				variables: {
					paymentIntentId: paymentIntentId,
					productOwnerExternalId: productOwnerExternalId
				}
			});
		} catch (error) {
			throw new Error(t('toasts.paymentFailedContactSupport'));
		}
	};

	const bookTravelPlanItem = async (
		travelPlanItems: IComposedTravelPlanItem,
		formValues,
		productOwnerGlobalId: string,
		paymentIntentId: string
	) => {
		// Booking
		const bookingResponse = await bookItem(
			formValues,
			productOwnerGlobalId,
			paymentIntentId
		);

		if (bookingResponse instanceof Error) {
			throw new Error(t('toasts.bookingFailed'));
		}

		const { data = {} } = bookingResponse;
		const {
			bookAccommodation = {},
			bookGroupAccommodation = {},
			bookActivity = {},
			merchandisePurchase = {},
			bookTransportation = {}
		} = data;
		let orderNumber = '';
		if (travelPlanItems.type === WishlistItemTypes.ACTIVITY) {
			orderNumber = bookActivity.orderNumber;
		} else if (travelPlanItems.type === WishlistItemTypes.MERCHANDISE) {
			orderNumber = merchandisePurchase.orderNumber;
		} else if (travelPlanItems.type === WishlistItemTypes.STAY) {
			orderNumber =
				(travelPlanItems.item as IStayWishlistItem).number_of_rooms > 1
					? bookGroupAccommodation.groupReservationId
					: bookAccommodation.reservationId;
		} else {
			orderNumber = bookTransportation.id;
		}

		return orderNumber;
	};

	const getTravelPlanObject = (item: IComposedTravelPlanItem) => {
		const travelPlanObject = {
			id: travelItineraryId,
			name: travelPlanName || commonTranslations('guestTravelPlan'),
			travelPlan: [
				{
					id: item.id,
					type: item.type.toLowerCase(),
					item: {
						...item.item,
						order_number: item.order_number,
						product_owner: item.productOwner.name,
						merchant_global_id: item.productOwner.globalId,
						pickup_location: item.pickup_location
					}
				}
			]
		};

		if (travelPeriod) {
			travelPlanObject['start_date'] = new Date(travelPeriod[0]);
			travelPlanObject['end_date'] = new Date(travelPeriod[1]);
		}

		return JSON.stringify(travelPlanObject);
	};

	return { payTravelPlanItem, loading };
};

export default usePayTravelPlanItem;
