import styled from 'styled-components';
import {
	DeviceBreakpoints,
	FontSize
} from 'styles/defaultTheme';

import useIntl from 'hooks/useIntl';

export const EmptyWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	color: #6f6a7b;
	font-size: ${FontSize.base};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
		padding: 0.75rem;
	}
`;

const DefaultStateTransportList = () => {
	const { t } = useIntl('transportList');
	return <EmptyWrapper>{t('defaultStateListInfo')}</EmptyWrapper>;
};

export default DefaultStateTransportList;
