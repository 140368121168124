import { useHistory } from 'react-router';

import useIntl from 'hooks/useIntl';
import { Paths } from 'router';

import { TravelPlannerLink } from './StyledComponents';

const SavedTravelPlanToast = () => {
	const { t } = useIntl('wishlist.savedTravelPlanToast');
	const history = useHistory();
	const goToTravelPlanner = () => history.push(Paths.TravelPlanner);

	return (
		<>
			{t('travelPlanSavedInfo')} {t('youCanAccessYourTravelPlanInfo')}{' '}
			<TravelPlannerLink onClick={goToTravelPlanner}>
				{t('youCanAccessYourTravelPlanInfoLink')}
			</TravelPlannerLink>
			.
		</>
	);
};

export default SavedTravelPlanToast;
