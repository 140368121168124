import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import useItemIcon from 'hooks/useItemIcon';
import {
	WishlistItemType,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import LocationMap from 'redesigncomponents/LocationMap';
import { GetGestButton } from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestButton';
import { MapDivider } from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/components/StyledComponents';
import {
	Colors,
	DeviceBreakpoints,
	FontSize,
	FontWeight
} from 'styles/defaultTheme';

import TrashIcon from 'assets/trash.svg';

import DetailsButton from './DetailsButton';

const CardWrapper = styled.div`
	width: 100%;
	background-color: ${Colors.V10};
	display: flex;
	flex-direction: column;
	border: 1px solid ${Colors.V100};
	padding: 0.75rem 1.25rem;
	color: ${Colors.V900};
	border-radius: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		padding: 0.625rem 0.5rem;
	}
`;

const LeftWrapper = styled.div`
	display: flex;
`;

const RightWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
`;

const CardName = styled.div`
	display: flex;
	align-items: center;
	> h4 {
		@media ${DeviceBreakpoints.small} {
			font-size: ${FontSize.smSize};
			font-weight: ${FontWeight.semibold};
			padding-right: 0.5rem;
		}
	}
`;

const Name = styled.h4`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.lgSize};
`;

const CardLogoWrapper = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
	background-color: ${Colors.V20};
	margin-right: 0.625rem;

	svg {
		width: 1.25rem;
	}
`;

const HeadWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const DetailsBox = styled.div`
	display: ${props => (props.collapsed ? 'block' : 'none')};
`;

const TableBody = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 1.25rem;
	/* border-bottom: 1px solid ${Colors.V100}; */
	padding: 0 0.5rem 0.5rem 0.5rem;
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: 1fr;
	}
`;

const TableRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem;
	&:nth-child(odd) {
		background-color: ${Colors.V20};
	}
`;

const RowName = styled.span`
	font-size: ${FontSize.smSize};
`;

const TableLeft = styled.div`
	padding-right: 0.375rem;
	border-right: 1px solid ${Colors.V50};
	@media ${DeviceBreakpoints.small} {
		border: none;
		padding-right: 0;
	}
`;

const TableRight = styled.div`
	padding-left: 0.375rem;
	@media ${DeviceBreakpoints.small} {
		padding-left: 0;
	}
`;

const RowValue = styled.span`
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.semibold};
`;

const Link = styled.a`
	text-decoration: underline;
	cursor: pointer;
`;

const LocationMessage = styled.p`
	display: flex;
	justify-content: center;
`;
interface IProps {
	travelPlanId: string;
	travelPlanItem: any;
	handleItemRemove: (
		travelPlanId: string,
		itemId: string,
		type: WishlistItemType
	) => void;
}

const TravelItineneryDetails: FunctionalComponent<IProps> = ({
	travelPlanId,
	travelPlanItem,
	handleItemRemove
}) => {
	const { t } = useIntl('travelPlanner');
	const { t: commonTranslations } = useIntl('common.general');
	const [collapsed, setCollapsed] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { Icon } = useItemIcon(travelPlanItem.type, travelPlanItem.item);
	return (
		<CardWrapper>
			<HeadWrapper>
				<LeftWrapper>
					<CardName>
						<CardLogoWrapper>
							<Icon />
						</CardLogoWrapper>
						{travelPlanItem.type === WishlistItemTypes.TRANSPORT ? (
							<Name>{`${travelPlanItem.item.city_from} - ${travelPlanItem.item.city_to}`}</Name>
						) : (
							<Name>{travelPlanItem.item.title}</Name>
						)}
					</CardName>
				</LeftWrapper>
				<RightWrapper>
					{travelPlanItem.paid && (
						<GetGestButton
							title={t('paid')}
							type="button"
							paid={travelPlanItem.paid}
						/>
					)}
					<DetailsButton collapsed={collapsed} onClick={toggleCollaped} />
					{!travelPlanItem.paid && (
						<Button
							buttonStyleType={ButtonStyleTypes.tertiary}
							tightPadding
							onlyIcon
							Icon={TrashIcon}
							onClick={() =>
								handleItemRemove(
									travelPlanId,
									travelPlanItem.id,
									travelPlanItem.type
								)
							}
						/>
					)}
				</RightWrapper>
			</HeadWrapper>
			<DetailsBox collapsed={collapsed}>
				<TableBody>
					<TableLeft>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{t('checkInTime')}</RowName>
									<RowValue>{travelPlanItem.checkInTime}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('startTime')}</RowName>
									<RowValue>{travelPlanItem.startTime}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{t('checkInDate')}</RowName>
									<RowValue>{travelPlanItem.startDate}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('startingDate')}</RowName>
									<RowValue>{travelPlanItem.startDate}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{t('checkOutTime')}</RowName>
									<RowValue>
										{travelPlanItem.checkOutTime ||
											commonTranslations('noInfoAvailable')}
									</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.ACTIVITY && (
								<>
									<RowName>{t('activityLength')}</RowName>
									<RowValue>{travelPlanItem.duration}</RowValue>
								</>
							)}
							{travelPlanItem.type === WishlistItemTypes.TRANSPORT && (
								<>
									<RowName>{t('tripDuration')}</RowName>
									<RowValue>{travelPlanItem.duration}</RowValue>
								</>
							)}
							{travelPlanItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{t('checkOutDate')}</RowName>
									<RowValue>{travelPlanItem.endDate}</RowValue>
								</>
							)}
						</TableRow>
					</TableLeft>
					<TableRight>
						<TableRow>
							<RowName>{t('location')}</RowName>
							<RowValue>
								<Link onCLick={() => setShowMap(true)}>
									{travelPlanItem.address || t('showOnMap')}
								</Link>
							</RowValue>
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.ACTIVITY ? (
								<>
									<RowName>{t('numberOfTickets')}</RowName>
									<RowValue>{travelPlanItem.numberOfTickets}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('numberOfPeople')}</RowName>
									<RowValue>{travelPlanItem.numberOfPeople}</RowValue>
								</>
							)}
						</TableRow>
						{travelPlanItem.type === WishlistItemTypes.STAY && (
							<TableRow>
								<RowName>{t('numberOfRooms')}</RowName>
								<RowValue>{travelPlanItem.item.number_of_rooms}</RowValue>
							</TableRow>
						)}
						<TableRow>
							<RowName>{t('totalPrice')}</RowName>
							<RowValue>{travelPlanItem.totalPrice}</RowValue>
						</TableRow>
					</TableRight>
				</TableBody>

				{showMap && (
					<>
						<MapDivider />
						{travelPlanItem.longitude && travelPlanItem.latitude ? (
							<LocationMap
								markers={[
									{
										lng: travelPlanItem.longitude,
										lat: travelPlanItem.latitude
									}
								]}
							/>
						) : (
							<LocationMessage>
								{t('mapCoordinatesAreNotProvided')}
							</LocationMessage>
						)}
					</>
				)}
			</DetailsBox>
		</CardWrapper>
	);
};

export default TravelItineneryDetails;
