import { FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';

import { WidgetFlowType } from 'redesigncache/bookingFlow/cache';
import Breadcrumbs from 'redesigncomponents/Breadcrumbs';
import IconButton from 'redesigncomponents/IconButton';
import { ModalContext } from 'redesigncomponents/WidgetModal';

import CloseIcon from 'assets/close.svg';

import {
	HeaderWrapper,
	LeftWrapper,
	RightWrapper
} from './components/StyledComponents';

interface IProps {
	currentRouteSlug?: string;
}
const AffiliateLinkHeader: FunctionalComponent<IProps> = ({
	currentRouteSlug
}) => {
	const { onModalClose } = useContext(ModalContext);
	return (
		<HeaderWrapper>
			<LeftWrapper>
				<Breadcrumbs
					flowType={WidgetFlowType.AffiliateLink}
					currentRouteSlug={currentRouteSlug}
				/>
			</LeftWrapper>
			<RightWrapper>
				<IconButton Icon={CloseIcon} onClick={onModalClose} />
			</RightWrapper>
		</HeaderWrapper>
	);
};

export default AffiliateLinkHeader;
