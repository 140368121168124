import { useLazyQuery } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';

import {
	GET_INTEGRATIONS_ACTIVITY_COUNT,
	GET_INTEGRATIONS_MERCHADISE_COUNT,
	GET_INTEGRATIONS_STAY_COUNT,
	GET_INTEGRATIONS_TRANSPORT_COUNT
} from 'apollo/myDesti/queries';
import useIntl from 'hooks/useIntl';
import { ProductType } from 'redesigncache/hostConfiguration/cache';
import useHostConfiguration from 'redesigncache/hostConfiguration/useHostConfiguration';
import { Paths } from 'router';
import { Colors, DeviceBreakpoints } from 'styles/defaultTheme';

import AccomodationImage from 'assets/images/accomodation-bckg.png';
import ActivityImage from 'assets/images/activities-bckg.png';
import TransportImage from 'assets/images/transport-bckg.png';

import OfferCard from './OfferCard';

dayjs.extend(utc);

export const MainWrapper = styled.div`
	text-transform: uppercase;
	display: grid;
	grid-gap: 0.75rem;
	height: 100%;
	grid-auto-rows: 0.5fr;

	> div {
		border: 1px solid ${Colors.V10};
		border-radius: 0.25rem;
		position: relative;
		background-repeat: no-repeat;
	}
	@media ${DeviceBreakpoints.small} {
		grid-gap: 0.75rem;
	}
`;

type IProps = {
	selectedRegions: any;
	selectedTravelPeriod: any[];
};

export const OfferMenu: FunctionalComponent<IProps> = ({
	selectedRegions,
	selectedTravelPeriod
}) => {
	const history = useHistory();
	const { t } = useIntl('main');
	const { productTypeEnabled } = useHostConfiguration();

	const isRegionAndTravelPeriodValid = useMemo(() => {
		return (
			selectedRegions &&
			selectedRegions?.length !== 0 &&
			selectedTravelPeriod?.length !== 0 &&
			selectedTravelPeriod?.every(selected => selected !== null)
		);
	}, [selectedRegions, selectedTravelPeriod]);

	const [
		loadStayCount,
		{ loading: stayCountLoading, data: integrationsStayCount }
	] = useLazyQuery(GET_INTEGRATIONS_STAY_COUNT, {
		fetchPolicy: 'cache-and-network'
	});

	const [
		loadTransportationCount,
		{ loading: transportCountLoading, data: integrationsTransportCount }
	] = useLazyQuery(GET_INTEGRATIONS_TRANSPORT_COUNT, {
		fetchPolicy: 'cache-and-network'
	});

	const [
		loadActivityCount,
		{ loading: activityCountLoading, data: integrationsActivityCount }
	] = useLazyQuery(GET_INTEGRATIONS_ACTIVITY_COUNT, {
		fetchPolicy: 'cache-and-network'
	});

	const [
		loadMerchandiseCount,
		{ loading: merchandiseCountLoading, data: integrationsMerchandiseCount }
	] = useLazyQuery(GET_INTEGRATIONS_MERCHADISE_COUNT, {
		fetchPolicy: 'cache-and-network'
	});

	useEffect(() => {
		if (isRegionAndTravelPeriodValid) {
			const startDate = dayjs
				.utc(dayjs(selectedTravelPeriod[0]))
				.local()
				.format('YYYY-MM-DDTHH:mm:ssZ');
			const endDate = dayjs
				.utc(dayjs(selectedTravelPeriod[1]))
				.local()
				.format('YYYY-MM-DDTHH:mm:ssZ');

			const variables = {
				city: selectedRegions[selectedRegions?.length - 1].label,
				startDate,
				endDate
			};

			const travelVariables = {
				cities: selectedRegions?.map(s => {
					return {
						name: s.label,
						longitude: s.longitude,
						latitude: s.latitude
					};
				}),
				startDate,
				endDate
			};

			if (productTypeEnabled(ProductType.Stay)) {
				loadStayCount({ variables });
			}
			if (productTypeEnabled(ProductType.Transportation)) {
				loadTransportationCount({
					variables: {
						...travelVariables
					}
				});
			}
			if (productTypeEnabled(ProductType.Activity)) {
				loadActivityCount({
					variables: {
						...variables
					}
				});
			}
			if (productTypeEnabled(ProductType.Merchandise)) {
				loadMerchandiseCount();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedRegions,
		selectedTravelPeriod,
		loadActivityCount,
		loadStayCount,
		loadTransportationCount,
		isRegionAndTravelPeriodValid
	]);

	const activitiesCount =
		integrationsActivityCount?.getIntegrationsActivityCount?.count;

	const merchandiseCount =
		integrationsMerchandiseCount?.getIntegrationsMerchandiseCount?.count;

	const stayCount = integrationsStayCount?.getIntegrationsStayCount?.count;

	const transportCount =
		integrationsTransportCount?.getIntegrationsTransportCount?.count;

	const categories = useMemo(() => {
		const categoryList = [
			{
				type: ProductType.Activity,
				title: t('activities'),
				isLoading: activityCountLoading,
				count: activitiesCount,
				image: ActivityImage,
				enabled: productTypeEnabled(ProductType.Activity)
			},
			{
				type: ProductType.Merchandise,
				title: t('merchandise'),
				isLoading: merchandiseCountLoading,
				count: merchandiseCount,
				image: ActivityImage,
				enabled: productTypeEnabled(ProductType.Merchandise)
			},
			{
				type: ProductType.Stay,
				title: t('accomodation'),
				isLoading: stayCountLoading,
				count: stayCount,
				image: AccomodationImage,
				enabled: productTypeEnabled(ProductType.Stay)
			},
			{
				type: ProductType.Transportation,
				title: t('transport'),
				isLoading: transportCountLoading,
				count: transportCount,
				image: TransportImage,
				enabled: productTypeEnabled(ProductType.Transportation)
			}
		];

		return categoryList.filter(c => c.enabled);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activitiesCount,
		activityCountLoading,
		transportCount,
		transportCountLoading,
		stayCount,
		stayCountLoading
	]);

	const onCategoryClick = useCallback(
		(categoryType: ProductType) => {
			let path = {};
			switch (categoryType) {
				case ProductType.Activity:
					path = Paths.Activities;
					break;
				case ProductType.Merchandise:
					path = Paths.Merchandise;
					break;
				case ProductType.Transportation:
					path = Paths.AirTransport;
					break;
				case ProductType.Stay:
					path = Paths.Accommodations;
					break;
			}
			history.push(path);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[history, isRegionAndTravelPeriodValid]
	);

	const cards = categories.map((category, id) => {
		return (
			<OfferCard
				key={id}
				showOfferValue={isRegionAndTravelPeriodValid}
				label={category.title}
				image={category.image}
				count={category.count}
				isLoading={category.isLoading}
				onClick={() =>
					isRegionAndTravelPeriodValid && onCategoryClick(category.type)
				}
			/>
		);
	});

	return <MainWrapper>{cards}</MainWrapper>;
};

export default OfferMenu;
