import { useMemo } from 'preact/hooks';

import useCodebook from 'redesigncache/codebook/useCodebook';
import { ICountry } from 'redesigntypes/Country';

const useCountryCodebookOptions = () => {
	const { countries } = useCodebook(['countries']);

	const countryOptions = useMemo(() => {
		return countries.map((c: ICountry) => ({
			value: c.id,
			label: c.name,
			extra: { isoCode2: c.countryCode }
		}));
	}, [countries]);

	return { countryOptions };
};

export default useCountryCodebookOptions;
