import { useMutation } from '@apollo/client';
import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

import { GET_INTEGRATIONS_ACTIVITY } from 'apollo/myDesti/mutations';
import useIntl from 'hooks/useIntl';
import Toast from 'redesigncomponents/Toast';
import {
	ActivityModel,
	PriceOption,
	Session
} from 'redesigntypes/ActivityModel';

import useActivityDetailsCache from './cache/useActivityDetailsCache';

export const getActivityDetailsMapDateKey = (date: Date) =>
	dayjs(date).format('YYYY-MM-DD');

export const sanitizeActivityDescription = (activityDescription: string) => {
	return DOMPurify.sanitize(activityDescription, {
		FORBID_TAGS: [
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'style',
			'a',
			'link',
			'iframe',
			'script'
		],
		FORBID_ATTR: ['style']
	});
};

const useActivityDetails = (activityId: string) => {
	const { t: activityDetailsTranslations } = useIntl('activityDetails');
	const {
		activity,
		heroImage,
		geographicaLocation,
		lowestSessionTicketPrice,
		availableDates,
		sessionsByDate,
		setActivityDetails,
		setActivityAvailabilityDetails,
		clearActivityDetailsCache
	} = useActivityDetailsCache();

	const [loadActivityDetails, { loading, called }] = useMutation<{
		getActivityDetails;
	}>(GET_INTEGRATIONS_ACTIVITY, {
		onCompleted: ({ getActivityDetails }) => {
			const activityData: ActivityModel = getActivityDetails;

			const activityHeroImage = activityData?.images?.[0];
			const activityGeographicalLocation = {
				lat: activityData.productLocation.latitude,
				lng: activityData.productLocation.longitude
			};
			setActivityDetails(
				activityData,
				activityHeroImage,
				activityGeographicalLocation
			);

			const sessionsByDateNew = {};
			if (activityData.sessions?.length) {
				let lowestSessionTicketPrice = Infinity;
				activityData.sessions.forEach((s: Session) => {
					s.prices.forEach((s: PriceOption) => {
						s.currencyCode = activityData.displayPrice.currency;
						if (lowestSessionTicketPrice > s.price) {
							lowestSessionTicketPrice = s.price;
						}
					});

					const dateString = getActivityDetailsMapDateKey(
						dayjs(s.startTimeLocal).toDate()
					);
					if (sessionsByDateNew[dateString]) {
						sessionsByDateNew[dateString].push(s);
					} else {
						sessionsByDateNew[dateString] = [s];
					}
				});

				const mergedSessionsByDate = {
					...sessionsByDate,
					...sessionsByDateNew
				};
				const allAvailableDates = Object.keys(mergedSessionsByDate).map(d =>
					dayjs(d).toDate()
				);

				setActivityAvailabilityDetails(
					lowestSessionTicketPrice,
					allAvailableDates,
					mergedSessionsByDate
				);
			}
		},
		onError: () => {
			Toast.error(activityDetailsTranslations('activityCouldNotBeLoaded'));
		}
	});

	const getActivity = (
		id: string,
		startTime: Date,
		endTime: Date,
		people?: number
	) => {
		loadActivityDetails({
			variables: {
				id: id,
				startTime: startTime.toISOString(),
				endTime: endTime.toISOString(),
				people: people
			}
		});
	};

	const selectedActivityDetails = useMemo(() => {
		return activity?.id === activityId ? activity : undefined;
	}, [activity]);

	return {
		loading,
		called,
		activity: selectedActivityDetails,
		heroImage,
		geographicaLocation,
		availableDates,
		sessionsByDate,
		lowestSessionTicketPrice,
		getActivity,
		clearActivityDetailsCache
	};
};

export default useActivityDetails;
