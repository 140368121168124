import { useMemo } from 'preact/hooks';

import useCodebook from 'redesigncache/codebook/useCodebook';
import { IActivityType } from 'redesigntypes/ActivityType';

import useIntl from './useIntl';

const useActivityTypeCodebookOptions = () => {
	const { t: types } = useIntl('activityDetails.activityTypes');
	const { activityTypes } = useCodebook(['activityTypes']);

	const activityTypeOptions = useMemo(() => {
		return activityTypes.map((a: IActivityType, idx: number) => ({
			value: a.value,
			label: types(a.value),
			id: idx.toString(),
			extra: {}
		}));
	}, [activityTypes]);

	return { activityTypeOptions };
};

export default useActivityTypeCodebookOptions;
