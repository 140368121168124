import styled from 'styled-components';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { FontWeight, BodyWrapperHeight, Colors, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	border-top: 1px solid ${Colors.V30};
    border-bottom: 1px solid ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: grid;
  align-items: center;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.mobile};
	}
`;

export const BodyHeader = styled.h4`
  text-align: center;
  font-weight: ${FontWeight.normal};
  font-size: 36px;
  line-height: 49px;
`;

export const BodySubHeader = styled.h6`
  text-align: center;
  font-weight: ${FontWeight.normal};
  font-size: 20px;
  margin-bottom: 5.25rem;
  margin-top: 0.5rem;
`;

export const BodyContent = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 5.75rem;
`;
