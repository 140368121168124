import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';
import AirTransportListContainer from 'redesignscreens/Main/Transport/AirTransportList/AirTransportListContainer';
import useTransportSearchOptionsCache from 'redesignscreens/Main/Transport/AirTransportList/AirTransportListContainer/cache/useTransportSearchOptionsCache';
import useAddTransportToWishlist from 'redesignscreens/Main/Transport/AirTransportList/AirTransportListContainer/hooks/useAddTransportToWishlist';
import useTransportationDetails from 'redesignscreens/Main/Transport/AirTransportList/AirTransportListContainer/hooks/useTransportationDetails';
import useLoadTransportationOffers from 'redesignscreens/Main/Transport/AirTransportList/AirTransportListContainer/hooks/useTransportationOffers';

import { StyledBodyWrapper } from './components/StyledComponents';

const TransportSearch = () => {
	const { t: screenTranslations } = useIntl('screens');
	const { t: transportSearchTranslations } = useIntl('transportList');
	const [redirectToGetGestOpen, setRedirectToGetGestOpen] = useToggle();
	const defaultTravelPlanName = useDefaultTravelPlanName();
	const { selectedTransportOffer } = useTransportationDetails();
	const { transportOfferTravelPeriod } = useLoadTransportationOffers();
	const { travelPeriod } = useTransportSearchOptionsCache();

	const { createAirTransportWishlistItem } = useAddTransportToWishlist(
		selectedTransportOffer
	);

	const { addToCheckout } = useCheckout();

	const bookNow = () => {
		const wishlistItem = createAirTransportWishlistItem();
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPeriod: transportOfferTravelPeriod,
			travelPlanItems: [wishlistItem],
			travelPlanName: defaultTravelPlanName,
			travelItineraryId: null
		});
		setRedirectToGetGestOpen(true);
	};

	return (
		<AffiliateLinkWrapper
			currentRouteSlug={screenTranslations('Transportation')}
		>
			<StyledBodyWrapper>
				<AirTransportListContainer
					travelPeriod={travelPeriod as [Date, Date]}
				/>
				{redirectToGetGestOpen && (
					<GetGestRedirectModal
						affiliateLink
						open={redirectToGetGestOpen}
						toggleOpen={setRedirectToGetGestOpen}
					/>
				)}
			</StyledBodyWrapper>
			<Footer>
				{selectedTransportOffer && (
					<Button
						title={transportSearchTranslations('bookNowButtonTitle')}
						onClick={bookNow}
					/>
				)}
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default TransportSearch;
