import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontSize } from 'styles/defaultTheme';

const ChipContainer = styled.div`
	color: ${Colors.V900};
	background-color: ${({ outlined }) =>
		outlined ? `${Colors.V10}` : `${Colors.V30}`};
	padding: ${({ outlined }) => (outlined ? '0.20rem 0.5rem' : '0.5rem')};
	display: inline-flex;
	align-items: center;
	border-radius: ${({ outlined }) => (outlined ? '3.25rem' : '0.25rem')};
	gap: 0.5rem;
	border: ${({ outlined }) => (outlined ? `1px solid ${Colors.V30}` : 'none')};
	height: ${({ outlined }) => (outlined ? 'auto' : '2.25rem')};
	svg {
		width: 0.75rem;
	}
`;

const ChipTitle = styled.span`
	font-size: ${FontSize.smSize};
	white-space: nowrap;
`;

interface IProps {
	Icon?: FunctionalComponent;
	title?: string;
	outlined?: boolean;
}

const Chip: FunctionalComponent<IProps> = ({ title, Icon, outlined }) => {
	return (
		<ChipContainer outlined={outlined}>
			{Icon && <Icon />}
			<ChipTitle>{title}</ChipTitle>
		</ChipContainer>
	);
};

export default Chip;
