import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

import { OfferDetails } from '../../types';
import { OptionCategoriesType } from '../components/OptionCategories/useOptionCategories';

type TransportSearchOptionsCache = {
	fromLocation?: Option;
	toLocation?: Option;
	transportType?: Option;
	tripType?: Option;
	airClass?: Option;
	transportSort?: Option;
	travelPeriod?: [Date, Date];
	optionCategories?: OptionCategoriesType;
	selectedTransportOffer?: OfferDetails;
};

export const initialTransportSearchOptionsValue: TransportSearchOptionsCache =
	{};

export const transportSearchOptionsVar = makeVar<TransportSearchOptionsCache>(
	initialTransportSearchOptionsValue
);
