import { FunctionalComponent } from 'preact';
import { useContext, useMemo } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import { WidgetFlowType } from 'redesigncache/bookingFlow/cache';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import Breadcrumbs from 'redesigncomponents/Breadcrumbs';
import IconButton from 'redesigncomponents/IconButton';
import { ModalContext } from 'redesigncomponents/WidgetModal';
import { Paths } from 'router';

import CloseIcon from 'assets/close.svg';
import FavoritesIcon from 'assets/favorites.svg';
import InfoIcon from 'assets/user.svg';

import {
	HeaderWrapper,
	LeftWrapper,
	RightWrapper
} from './components/StyledComponents';

interface IProps {
	flowType?: WidgetFlowType;
	currentRouteSlug?: string;
}
const Header: FunctionalComponent<IProps> = ({ currentRouteSlug }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const { wishlistItemsCount } = useWishlist();
	const goToAccount = () => history.push(Paths.AccountMain);
	const goToWishlist = () => history.push(Paths.Wishlist);

	const isFavouritesIconActive = useMemo(() => {
		return pathname.includes(Paths.Wishlist);
	}, [pathname]);

	const isAccountIconActive = useMemo(() => {
		return pathname.includes(Paths.AccountMain);
	}, [pathname]);

	const { onModalClose } = useContext(ModalContext);
	return (
		<HeaderWrapper>
			<LeftWrapper>
				<Breadcrumbs currentRouteSlug={currentRouteSlug} />
			</LeftWrapper>
			<RightWrapper>
				<IconButton
					Icon={FavoritesIcon}
					onClick={goToWishlist}
					active={isFavouritesIconActive}
					notification={wishlistItemsCount}
				/>
				<IconButton
					Icon={InfoIcon}
					onClick={goToAccount}
					active={isAccountIconActive}
				/>
				<IconButton Icon={CloseIcon} onClick={onModalClose} />
			</RightWrapper>
		</HeaderWrapper>
	);
};

export default Header;
