import { FunctionalComponent } from 'preact';
import { Field, Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';

import { SIGNIN } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import useSocialSignIn from 'redesigncache/auth/useSocialSignIn';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import Button from 'redesigncomponents/Button';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Footer from 'redesigncomponents/Footer';
import GoogleButton from 'redesigncomponents/GoogleButton';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { Paths } from 'router';
import validateForm from 'utils/form/validateForm';

import {
	DividerLine,
	DividerText,
	DividerWrapper
} from '../SignUp/components/StyledComponents';
import {
	ContentWrapper,
	FormHeader,
	SignupContent,
	SignupLink,
	SignupText
} from '../components/StyledComponents';
import {
	PasswordUpdate,
	PasswordUpdateLink,
	StyledBodyWrapper
} from './components/StyledComponents';
import useSignInConstraints from './useSignInConstraints';

interface IProps {}

const SignIn: FunctionalComponent<IProps> = () => {
	const history = useHistory();
	const { t } = useIntl('signIn');
	const { state: { from = undefined } = {} } = useLocation();
	const { constraints: loginConstraints } = useSignInConstraints();
	const { tokenLogin } = useUserAuth();
	const { startGoogleSignIn } = useSocialSignIn();

	const [login, { loading }] = useMyPagesMutation(SIGNIN);

	const validate = (values: Record<string, any>): any =>
		validateForm(values, loginConstraints);

	const onSubmit = async (values: Record<string, any>) => {
		const response = await login({
			variables: { ...values }
		})
			.then(async ({ data }) => {
				await tokenLogin(
					data.usernamePasswordLogin.idToken,
					data.usernamePasswordLogin.refreshToken
				);

				history.replace({
					pathname: from ? from : Paths.AccountMain
				});
			})
			.catch(error => ({ message: error.message }));

		if (response && response.message) {
			return { email: response.message };
		}
	};

	const goToSignUp = () => history.push(Paths.SignUp);
	const goToForgotPassword = () => history.push(Paths.ForgotPassword);

	return (
		<MainWrapper>
			<Form
				onSubmit={values => onSubmit(values)}
				validate={validate}
				render={({
					handleSubmit,
					submitting,
					pristine,
					hasValidationErrors
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<StyledBodyWrapper>
								<ContentWrapper>
									<FormHeader>{t('signInHeader')}</FormHeader>
									<Field
										name="username"
										component={FieldTextInput}
										label={t('emailLabel')}
										type="email"
									/>
									<Field
										name="password"
										component={FieldTextInput}
										label={t('passwordLabel')}
										type="password"
									/>
									<PasswordUpdate>
										<PasswordUpdateLink onClick={goToForgotPassword}>
											{t('forgotPasswordLink')}
										</PasswordUpdateLink>
									</PasswordUpdate>
									<Button
										title={t('signInHereButtonTitle')}
										type="submit"
										loading={loading}
										disabled={
											submitting || pristine || loading || hasValidationErrors
										}
									/>{' '}
									<DividerWrapper>
										<DividerLine></DividerLine>
										<DividerText>{t('dividerOr')}</DividerText>
									</DividerWrapper>
									<GoogleButton
										title={t('continueWithGoogle')}
										onClick={startGoogleSignIn}
									></GoogleButton>
									<SignupContent>
										<SignupText>{t('dontHaveAnAccount')}</SignupText>
										<SignupLink onClick={goToSignUp}>
											{t('signUpHereLink')}
										</SignupLink>
									</SignupContent>
								</ContentWrapper>
							</StyledBodyWrapper>
							<Footer />
						</form>
					);
				}}
			/>
		</MainWrapper>
	);
};

export default SignIn;
