import { useMemo } from 'preact/hooks';

import { Option } from 'redesigntypes/Option';
import { TransportTripType } from 'types/enums';

import useIntl from './useIntl';

const useTransportTripTypeCodebookOptions = () => {
	const { t } = useIntl('common.airplaneTripTypes');

	const transportTripTypeOptions = useMemo(() => {
		return Object.keys(TransportTripType).map(
			key =>
				({
					value: key,
					label: t(key)
				} as Option)
		);
	}, []);

	return { transportTripTypeOptions };
};

export default useTransportTripTypeCodebookOptions;
