import { FunctionalComponent } from 'preact';

import useIntl from 'hooks/useIntl';
import OfferItem from 'redesigncomponents/OfferItem';
import { MerchandiseOverviewModel } from 'redesigntypes/ActivityModel';

type Props = {
	offer: MerchandiseOverviewModel;
	onClick: () => void;
};

export const MerchandiseOfferItem: FunctionalComponent<Props> = ({
	offer,
	onClick
}) => {
	const { t } = useIntl('merchandiseDetails');
	return (
		<OfferItem
			title={offer.name}
			infos={[
				offer.supplierName,
				offer.displayPrice &&
					t('fromPrice', {
						price: offer.displayPrice.amount,
						currency: offer.displayPrice.currency
					})
			]}
			image={offer?.image?.mediumUrl}
			onClick={onClick}
		></OfferItem>
	);
};
