import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	FontSize,
	FontWeight, 
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	padding: 0.75rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: block;
	border-top: 1px solid #efedf0;
	border-bottom: 1px solid #efedf0;
	@media ${DeviceBreakpoints.small} {
		padding: 0;
		height: ${BodyWrapperHeight.mobile};
	}
`;

export const RadioGroupContainer = styled.div`
	background-color: ${Colors.V30};
	color: ${Colors.V900};
	border: 1px solid ${Colors.V100};
	border-radius: 0.625rem;
	padding: 0.5rem;
`;

export const GroupHeader = styled.h4`
	font-weight: ${FontWeight.semibold};
	margin-bottom: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const AdditionalInfo = styled.p`
	font-size: ${FontSize.smSize};
	margin-bottom: 0.5rem;
`;

export const RadioContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: 1fr;
	}
`;

export const TicketTypeSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	border-bottom: 1px solid ${Colors.V400};
	padding: 0.75rem;
`;

export const DestinationSectionWrapper = styled.div`
	background-color: ${Colors.V30};
	border-radius: 0.25rem 0.25rem 0 0;
	padding: 0.25rem 0.75rem;
	border-bottom: 1px solid ${Colors.V400};
`;

export const SectionsContainer = styled.div`
	border: 1px solid ${Colors.V400};
	border-radius: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		border: none;
	}
`;

export const TicketSectionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

export const HeaderTitle = styled.h4`
	font-size: ${FontSize.lgSize};
	font-weight: ${FontWeight.semibold};
	margin-bottom: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.base};
	}
`;

export const HeaderTitleInfo = styled.p``;

export const SectionHeader = styled.div`
	margin-bottom: 0.25rem;
`;

export const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: ${FontSize.lgSize};
	font-weight: ${FontWeight.semibold};
	margin-bottom: 0.5rem;
	gap: 1rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.base};
	}
`;

export const SectionSubtitle = styled.div`
	font-size: ${FontSize.smSize};
`;

export const LocationIconWrapper = styled.div`
	display: flex;
`;

export const FromTitle = styled.span``;

export const ToTitle = styled.span``;

export const PriceSectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem;
`;

export const PriceInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const PassengersNumber = styled.span``;

export const PriceValue = styled.span`
	font-weight: ${FontWeight.bold};
	font-size: ${FontSize.lgSize};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.base};
	}
`;

export const TicketResultsSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem;
	border-bottom: 1px solid ${Colors.V400};
`;

export const TicketChipContainer = styled.div`
	display: flex;
	padding: 0 0.25rem;
	gap: 0.625rem;
	margin-right: 0.75rem;
`;

export const LeftHeaderContent = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;
export const RightHeaderContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const TicketAccommodationRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.15rem;
	@media ${DeviceBreakpoints.small} {
		flex-direction: column;
		gap: 0.5rem;
	}
`;

export const TicketAccommodationSubtitle = styled.div`
	font-size: ${FontSize.base};
	font-weight: ${FontWeight.semibold};
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
`;

export const TicketAccommodationRowElement = styled.div`
	width: 50%;
	@media ${DeviceBreakpoints.small} {
		width: 100%;
	}
`;

export const FooterButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.15rem;
	> button:first-of-type {
		@media ${DeviceBreakpoints.small} {
			background: transparent;
			border: none;
			span {
				color: ${Colors.V700};
			}
		}
	}
`;
