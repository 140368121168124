import { useEffect } from 'preact/hooks';
import { useHistory } from 'react-router';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';

import TransportAddedToWishlistRedirectModal from '../components/TransportAddedToWishlistRedirectModal';
import TrainTransportDetailsContainer from './TrainTransportDetailsContainer';
import { TrainTransportDetailsStep } from './TrainTransportDetailsContainer/cache';
import useTrainTransportDetailsCache from './TrainTransportDetailsContainer/cache/useTrainTransportDetailsCache';
import {
	FooterButtonsWrapper,
	StyledBodyWrapper
} from './TrainTransportDetailsContainer/components/StyledComponents';
import SummaryStep from './TrainTransportDetailsContainer/components/SummaryStep';
import WizardSteps from './TrainTransportDetailsContainer/components/WizardSteps';
import useTransportDetailsWizard from './TrainTransportDetailsContainer/components/WizardSteps/useTransportDetailsWizard';
import useAddTrainTransportToWishlist from './TrainTransportDetailsContainer/hooks/useAddTrainTransportToWishlist';
import useTrainTransportDetails from './TrainTransportDetailsContainer/hooks/useTrainTransportDetails';

const TrainTransportDetails = () => {
	const { t } = useIntl('transportList');
	const history = useHistory();
	const [itemAddedToWishlistOpen, setItemAddedToWishlistOpen] = useToggle();
	const { currentStep, goToNextStep, goToFirstStep } =
		useTransportDetailsWizard();
	const { setDepartureDetails, setReturnDetails } =
		useTrainTransportDetailsCache();
	const { addToWishlist, addedWishlistItem } = useAddTrainTransportToWishlist();
	const {
		offerDetails,
		transportDetails,
		transportPrice,
		transportPassengerString,
		selectedOptions,
		selectedOptionsValid,
		openFormSection
	} = useTrainTransportDetails();

	useEffect(() => {
		goToFirstStep();
		openFormSection(0, undefined, 'ticketType');
	}, []);

	useEffect(() => {
		openFormSection(0, undefined, 'ticketType');
	}, [currentStep]);

	const cancelTickets = () => {
		history.replace('/transport/trainbus', { backNavigation: true });
	};

	const submit = () => {
		if (currentStep !== TrainTransportDetailsStep.Summary) {
			if (currentStep === TrainTransportDetailsStep.Departure) {
				setDepartureDetails(
					transportPassengerString,
					transportPrice,
					offerDetails,
					transportDetails,
					selectedOptions
				);
			} else if (currentStep === TrainTransportDetailsStep.Return) {
				setReturnDetails(
					transportPassengerString,
					transportPrice,
					offerDetails,
					transportDetails,
					selectedOptions
				);
			}
			goToNextStep();
			openFormSection(0, undefined, 'ticketType');
		} else {
			// submit
			addToWishlist();
			setItemAddedToWishlistOpen(true);
		}
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<WizardSteps></WizardSteps>
				{currentStep === TrainTransportDetailsStep.Summary ? (
					<SummaryStep />
				) : (
					<TrainTransportDetailsContainer />
				)}
				{itemAddedToWishlistOpen && addedWishlistItem && (
					<TransportAddedToWishlistRedirectModal
						item={addedWishlistItem}
						open={itemAddedToWishlistOpen}
						toggleOpen={setItemAddedToWishlistOpen}
					/>
				)}
			</StyledBodyWrapper>
			<Footer
				toolTipProps={{
					toolTipKey:
						currentStep === TrainTransportDetailsStep.Summary
							? 'Summary'
							: 'Main'
				}}
			>
				<FooterButtonsWrapper>
					<Button
						title="Cancel tickets"
						buttonStyleType={ButtonStyleTypes.primary}
						onClick={cancelTickets}
					></Button>

					<Button
						title={
							currentStep === TrainTransportDetailsStep.Summary
								? t('addToWishlistButtonTitle')
								: t('continue')
						}
						buttonStyleType={ButtonStyleTypes.primary}
						disabled={!selectedOptionsValid}
						onClick={submit}
					></Button>
				</FooterButtonsWrapper>
			</Footer>
		</MainWrapper>
	);
};

export default TrainTransportDetails;
