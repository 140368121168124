import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';

import useTransportTripTypeCodebookOptions from 'hooks/useTransportTripTypeOptions';
import useIntl from 'hooks/useIntl';
import FieldSelect from 'redesigncomponents/FieldSelect';
import { Option } from 'redesigntypes/Option';
import { TransportTripType } from 'types/enums';

type Props = {
	value: Option;
	onChange: (Option) => void;
};

const TripTypeSelect: FunctionalComponent<Props> = ({ value, onChange }) => {
	const { t } = useIntl('transportList.options');
	const { transportTripTypeOptions } = useTransportTripTypeCodebookOptions();

	useEffect(() => {
		// setting the round trip as the initial value
		if (transportTripTypeOptions?.length && !value) {
			const roundTripOption = transportTripTypeOptions.find(
				tt => tt.value === TransportTripType.RoundTrip
			);
			onChange(roundTripOption || transportTripTypeOptions[0]);
		}
	}, [transportTripTypeOptions]);

	return (
		<FieldSelect
			options={transportTripTypeOptions}
			onChange={onChange}
			value={value}
			label={t('typeOfTripSelectLabel')}
		/>
	);
};

export default TripTypeSelect;
