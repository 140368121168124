import { useEffect, useMemo } from 'preact/hooks';

import { PriceOption } from 'redesigntypes/ActivityModel';

import useMerchandiseDetailsCache from './cache/useMerchandiseDetailsCache';

const useMerchandiseOffer = () => {
	const {
		merchandise: { prices, quantityRequiredMax } = {},
		quantities,
		updateQuantity,
		setQuantities
	} = useMerchandiseDetailsCache();

	useEffect(() => {
		if (prices) {
			const initialQuantities = prices.reduce((acc, curr) => {
				return {
					...acc,
					[curr.id]: curr.minQuantity || 1
				};
			}, {});
			setQuantities(initialQuantities);
		}
	}, [prices]);

	const totalPrice: number = useMemo(() => {
		if (prices && quantities) {
			const total: number = prices?.reduce(
				(previousValue: number, currentValue: PriceOption) => {
					const numberOfTickets = quantities[currentValue.id] || 0;
					const ticketPrice = currentValue.price;
					return previousValue + numberOfTickets * ticketPrice;
				},
				0
			);
			return total;
		}
		return 0;
	}, [prices, quantities]);

	return {
		prices,
		quantityRequiredMax,
		quantities,
		updateQuantity,
		currencyCode: 'SEK',
		totalPrice
	};
};

export default useMerchandiseOffer;
