import { FunctionalComponent } from 'preact';
import { useParams } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import capitalizeWords from 'utils/string/capitalizeWords';

import MerchandiseDetailsContainer from './MerchandiseDetailsContainer';
import useAddMerchandiseToWishlist from './MerchandiseDetailsContainer/useAddMerchandiseToWishlist';
import useMerchandiseDetails from './MerchandiseDetailsContainer/useMerchandiseDetails';
import useMerchandiseOffer from './MerchandiseDetailsContainer/useMerchandiseOffer';
import MerchandiseAddedToWishlistRedirectModal from './components/MerchandiseAddedToWishlistRedirectModal';
import { StyledBodyWrapper } from './components/StyledComponents';

interface IProps {}

const MerchandiseDetails: FunctionalComponent<IProps> = () => {
	const { t: merchandiseDetailsTranslations } = useIntl('merchandiseDetails');
	const [itemAddedToWishlistOpen, setItemAddedToWishlistOpen] = useToggle();
	const { id: externalId } = useParams();
	const { merchandise } = useMerchandiseDetails(externalId);

	const { quantities, totalPrice, currencyCode } = useMerchandiseOffer();

	const { addToWishlist, addedWishlistItem } = useAddMerchandiseToWishlist(
		merchandise,
		quantities,
		totalPrice,
		currencyCode
	);

	const handleAddToWishlist = () => {
		addToWishlist();
		setItemAddedToWishlistOpen(true);
	};

	return (
		<MainWrapper
			currentRouteSlug={merchandise?.name && capitalizeWords(merchandise?.name)}
		>
			<StyledBodyWrapper>
				<MerchandiseDetailsContainer externalId={externalId} />
			</StyledBodyWrapper>
			{itemAddedToWishlistOpen && addedWishlistItem && (
				<MerchandiseAddedToWishlistRedirectModal
					item={addedWishlistItem}
					open={itemAddedToWishlistOpen}
					toggleOpen={setItemAddedToWishlistOpen}
				/>
			)}
			<Footer>
				<Button
					title={merchandiseDetailsTranslations('addToWishlistButtonTitle')}
					onClick={handleAddToWishlist}
				/>
			</Footer>
		</MainWrapper>
	);
};

export default MerchandiseDetails;
