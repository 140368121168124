import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import useToggle from 'hooks/useToggle';

import MinusIcon from 'assets/minus.svg';
import PlusIcon from 'assets/plus.svg';

import {
	CounterButton,
	CounterInputValue,
	CounterValue,
	CounterWrapper
} from './components/StyledComponents';

interface IProps {
	value: number;
	onChange: (number: number) => void;
	min?: number;
	max?: number;
	enableKeyboardInput?: boolean;
}

const Counter: FunctionalComponent<IProps> = function ({
	value,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChange = () => {},
	min,
	max,
	enableKeyboardInput = false
}) {
	const [inputValue, setInputValue] = useState(value + '');
	const [isEditing, setIsEditing] = useToggle();

	const onMinusClick = () => {
		if (min || min === 0) {
			min !== value && onChange(value - 1);
		} else {
			onChange(value - 1);
		}
	};

	const onPlusClick = () => {
		if (max || max === 0) {
			max !== value && onChange(value + 1);
		} else {
			onChange(value + 1);
		}
	};

	const onInputChange = newValue => setInputValue(newValue);

	const onFocus = () => {
		setIsEditing(true);
	};

	const onBlur = () => {
		setIsEditing(false);
		const number = Number(inputValue.replace(/[^0-9]/g, ''));

		if (Number.isNaN(number)) {
			setInputValue(value + '');
			return onChange(value);
		}

		if ((max || max === 0) && number > max) {
			setInputValue(max + '');
			onChange(max);
		} else if ((min || min === 0) && number < min) {
			setInputValue(min + '');
			onChange(min);
		} else {
			setInputValue(number + '');
			onChange(Number(number));
		}
	};

	return (
		<CounterWrapper>
			<CounterButton onClick={onMinusClick}>
				<MinusIcon></MinusIcon>
			</CounterButton>
			{enableKeyboardInput ? (
				<CounterInputValue
					value={isEditing ? undefined : value}
					onChange={event => onInputChange(event.target.value)}
					onFocus={onFocus}
					onBlur={onBlur}
					type="number"
					inputmode="numeric"
					min={min}
					max={max}
					step={1}
				/>
			) : (
				<CounterValue>{value}</CounterValue>
			)}
			<CounterButton onClick={onPlusClick}>
				<PlusIcon></PlusIcon>
			</CounterButton>
		</CounterWrapper>
	);
};

export default Counter;
