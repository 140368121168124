import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import { REDESIGN_SIGNUP } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useIntl from 'hooks/useIntl';
import Toast from 'redesigncomponents/Toast';
import { Paths } from 'router';
import { initialRegisterFormValues } from 'screens/Account/Register/cache';
import validateForm from 'utils/form/validateForm';
import convertToCamelCase from 'utils/string/convertToCamelCase';

import {
	SignUpFormValuesType,
	initialSignUpFormValues,
	signUpFormVar
} from './cache';
import useSignUpConstraints from './useSignUpConstraints';

const useSignUp = () => {
	const history = useHistory();
	const { state: { from = undefined } = {} } = useLocation();
	const { t } = useIntl('signUp');
	const [initialValues, setInitialValues] = useState({});
	const [register, { loading }] = useMyPagesMutation(REDESIGN_SIGNUP);

	const { constraints: registerConstraints } = useSignUpConstraints();
	const savedSignUpFormValues = useReactiveVar(signUpFormVar);

	useEffect(() => {
		// sets the initial values for the form to the values the user entered before leaving this screen (like to go to the Terms of Use screen)
		setInitialValues(savedSignUpFormValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validate = (values: Record<string, any>): any =>
		validateForm(values, registerConstraints);

	const signUp = async ({
		email,
		firstName,
		lastName,
		password,
		confirmPassword
	}: SignUpFormValuesType) => {
		const response = await register({
			variables: {
				email,
				firstName,
				lastName,
				password,
				confirmPassword
			}
		})
			.then(() => {
				Toast.success(t('successfulRegisterInfo'), { autoClose: false });
				signUpFormVar(initialSignUpFormValues);
				setInitialValues(initialRegisterFormValues);
				history.replace({
					pathname: Paths.SignIn,
					state: {
						from: from
					}
				});
			})
			.catch(error => ({ message: error.message }));

		if (response && response.message) {
			try {
				const errorObject = JSON.parse(response.message);
				const splitMessages = errorObject.message
					.replace('Error!', '')
					.split('\n');
				const responseObject = {};

				for (let i = 0; i < errorObject.invalidInputs.length; i++) {
					responseObject[convertToCamelCase(errorObject.invalidInputs[i])] =
						splitMessages[i];
				}
				return responseObject;
			} catch (e) {}
		}
	};

	return { loading, signUp, validate, initialValues };
};

export default useSignUp;
