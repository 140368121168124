import { useReactiveVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

import { merchandiseListCacheVar } from '.';

const useMerchandiseListCache = () => {
	const { initialized, sort } = useReactiveVar(merchandiseListCacheVar);

	const initializeMerchandiseListCache = sort => {
		merchandiseListCacheVar({
			...merchandiseListCacheVar(),
			sort,
			initialized: true
		});
	};

	const setSort = (sort: Option) => {
		merchandiseListCacheVar({
			...merchandiseListCacheVar(),
			sort
		});
	};

	return {
		initialized,
		sort,
		initializeMerchandiseListCache,
		setSort
	};
};

export default useMerchandiseListCache;
