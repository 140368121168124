import { gql } from '@apollo/client';

export const GET_LOGGED_IN_USER = gql`
	query GetLoggedInUser {
		user @client
	}
`;

export const GET_CART = gql`
	query GetCart {
		cart @client
	}
`;

export const GET_GENDER = gql`
	query {
		user_gender {
			value
		}
	}
`;

export const GET_USER_BY_PK = gql`
	query ($id: String!) {
		user: user_by_pk(id: $id) {
			id
			country_id
			created_at
			date_of_birth
			email
			first_name
			gender
			last_name
			connection
		}
	}
`;

export const GET_INVOICES = gql`
	query MyQuery {
		invoice_view {
			date
			description
			invoice_number
			product_owner_logo
			product_owner_name
			travel_itinerary_id
		}
	}
`;

export const GET_TRAVEL_ITINERARY = gql`
	query {
		travel_itinerary(order_by: { created_at: desc }) {
			id
			name
			created_at
			end_date
			start_date
			travel_itinerary_activities(where: { external_id: { _is_null: false } }) {
				id
				city
				price
				currency
				duration
				number_of_adults
				number_of_tickets
				number_of_children
				type_of_activity
				date_time_activity
				description
				external_id
				product_location {
					address
					city
					id
					country
					latitude
					longitude
					name
				}
				transaction_id
			}
			travel_itinerary_stays(where: { external_id: { _is_null: false } }) {
				id
				number_of_rooms
				price
				currency
				product_owner_name
				city
				check_out_date
				check_in_date
				check_in_time
				check_out_time
				transaction_id
				number_of_adults
				number_of_children
				external_id
				description
				product_location {
					address
					city
					country
					id
					latitude
					longitude
					name
				}
			}
			travel_itinerary_transports(where: { external_id: { _is_null: false } }) {
				id
				price
				currency
				city_to
				city_from
				duration
				product_owner_name
				type_of_transport
				transaction_id
				start_transport
				end_transport
				number_of_adults
				number_of_children
				external_id
				description
				product_location {
					address
					city
					country
					id
					latitude
					longitude
					name
				}
			}
		}
	}
`;

export const TRAVEL_ITINERARY_BY_ITEM_ID = gql`
	query ($id: uuid!) {
		travel_itinerary(
			where: {
				_or: [
					{
						travel_itinerary_activities: {
							id: { _eq: $id }
							transaction_id: { _neq: "" }
						}
					}
					{
						travel_itinerary_stays: {
							id: { _eq: $id }
							transaction_id: { _neq: "" }
						}
					}
					{
						travel_itinerary_transports: {
							id: { _eq: $id }
							transaction_id: { _neq: "" }
						}
					}
				]
			}
		) {
			id
		}
	}
`;
