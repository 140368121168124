import styled from 'styled-components';
import { Colors, FontSize, DeviceBreakpoints } from 'styles/defaultTheme';

export const AccordionContainer = styled.div`
	background-color: ${Colors.V0};
	color: ${Colors.V900};
	border-radius: 0.25rem;
`;

export const AccordionTitle = styled.h4`
	font-size: ${FontSize.base};
	@media ${DeviceBreakpoints.small} {
		text-transform: uppercase;
		font-size: ${FontSize.smSize};
	}
`;

export const AccordionBody = styled.div`
	padding: 0.75rem;
	font-size: ${FontSize.smSize};
	line-height: 1.25rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.625rem;
	svg {
		height: 1.25rem;
	}
`;

export const SvgWrapper = styled.div`
	transform: ${props => (props.collapsed ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const AccordionParagraph = styled.p`
	margin-bottom: 1.875rem;
	font-size: ${FontSize.smSize};
	line-height: 1.25rem;
	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const AccordionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.75rem;
	cursor: pointer;
`;
