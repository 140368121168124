import { makeVar } from '@apollo/client';

import { ActivityModel, Image, Session } from 'redesigntypes/ActivityModel';

type ActivityDetailsCache = {
	activity: ActivityModel;
	availableDates: Date[];
	sessionsByDate: {
		[key: string]: Session[];
	};
	lowestSessionTicketPrice: number;
	heroImage: Image;
	geographicaLocation: { lat: number; lng: number };
	selectedDate: Date;
	selectedSession: Session;
	selectedTickets: {
		[priceOptionKey: string]: number;
	};
};

export const initialActivityDetailsValue: ActivityDetailsCache = {
	activity: undefined,
	availableDates: undefined,
	sessionsByDate: {},
	lowestSessionTicketPrice: undefined,
	heroImage: undefined,
	geographicaLocation: undefined,
	selectedDate: undefined,
	selectedSession: undefined,
	selectedTickets: undefined
};

export const activityDetailsVar = makeVar<ActivityDetailsCache>(
	initialActivityDetailsValue
);
