import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'preact/hooks';

import dayjs from 'dayjs';

import { myPagesClient } from 'apollo/client';
import {
	DELETE_TRAVEL_PLAN,
	DELETE_TRAVEL_PLAN_ITEM,
	GET_TRAVEL_PLANS
} from 'apollo/myPages/mutations';
import { ITravelPlan } from 'redesigncache/checkout/types';
import { WishlistItemTypes } from 'redesigncache/wishlist/types';

const useTravelPlannerDetails = () => {
	const [travelPlans, setTravelPlans] = useState([]);
	const [getTravelPlan, { loading }] = useMutation(GET_TRAVEL_PLANS, {
		variables: {
			id: ''
		},
		client: myPagesClient,
		onCompleted: ({ getTravelPlans }) => {
			setTravelPlans(getTravelPlans?.travelPlans);
		}
	});

	useEffect(() => {
		getTravelPlan();
	}, [getTravelPlan]);

	const sortedTravelPlans = useMemo(() => {
		const sortedPlansObj = {};
		travelPlans.forEach(plan => {
			const travelPlanYear = Number(dayjs(plan.created_at).year());
			const previouslySavedPlansForYear = sortedPlansObj[travelPlanYear] || [];
			sortedPlansObj[travelPlanYear] = [...previouslySavedPlansForYear, plan];
		});

		return sortedPlansObj;
	}, [travelPlans]);

	const [removeTravelPlan] = useMutation(DELETE_TRAVEL_PLAN, {
		client: myPagesClient,
		onCompleted: () => {
			getTravelPlan();
		}
	});

	const [removeTravelPlanItem] = useMutation(DELETE_TRAVEL_PLAN_ITEM, {
		client: myPagesClient,
		onCompleted: () => {
			getTravelPlan();
		}
	});

	const handleItemRemove = (
		travelPlanId: string,
		itemId: string,
		itemType: WishlistItemTypes
	): void => {
		const travelPlan: ITravelPlan = travelPlans.find(
			plan => plan.id === travelPlanId
		);

		if (travelPlan.travelPlanItems.length === 1) {
			removeTravelPlan({
				variables: {
					travelPlanId: travelPlan.id
				}
			});
		} else {
			travelPlan.travelPlanItems.splice(
				travelPlan.travelPlanItems.findIndex(
					item => item.id === itemId && item.type === itemType
				),
				1
			);
			removeTravelPlanItem({
				variables: {
					travelPlanId: travelPlan.id,
					travelPlanItemId: itemId
				}
			});
		}
	};

	return {
		sortedTravelPlans,
		loading,
		handleItemRemove
	};
};

export default useTravelPlannerDetails;
