import { makeVar } from '@apollo/client';

import { OfferDetails } from 'redesignscreens/Main/Transport/TrainTransportList/types';
import { IBasePrice } from 'redesigntypes/Itinerary';

import {
	ITransportDetails,
	ITransportOptionSection
} from '../hooks/useTrainTransportDetails';

export enum TrainTransportDetailsStep {
	Departure = 'Departure',
	Return = 'Return',
	Summary = 'Summary'
}

type TrainTransportDetailsCache = {
	currentStep: TrainTransportDetailsStep;
	departureTransportDetails: ITransportDetails;
	returnTransportDetails: ITransportDetails;
	selectedDepartureOptions: any;
	selectedReturnOptions: any;
	openSection: ITransportOptionSection;
	departureDetails: {
		passengerString: string;
		price: IBasePrice;
		offerDetails: OfferDetails;
		transportDetails: any;
		selectedOptions: any;
	};
	returnDetails: {
		passengerString: string;
		price: IBasePrice;
		offerDetails: OfferDetails;
		transportDetails: any;
		selectedOptions: any;
	};
};

export const initialTrainTransportDetailsValue: TrainTransportDetailsCache = {
	currentStep: TrainTransportDetailsStep.Departure,
	departureTransportDetails: undefined,
	returnTransportDetails: undefined,
	selectedDepartureOptions: {},
	selectedReturnOptions: {},
	openSection: {
		itineraryIndex: 0,
		segmentIndex: undefined,
		type: 'ticketType'
	},
	departureDetails: undefined,
	returnDetails: undefined
};

export const trainTransportDetailsOptionsVar =
	makeVar<TrainTransportDetailsCache>(initialTrainTransportDetailsValue);
