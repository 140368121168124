import { useCallback, useEffect } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import {
	AffiliateLinkConfig,
	AffiliateLinkDetails,
	AffiliateLinkType
} from 'redesigncache/affiliateLink/cache';
import { TransportType } from 'types/enums';

const useProductTypeRedirect = (
	affiliateLinkConfig: AffiliateLinkConfig,
	affiliateLinkDetails: AffiliateLinkDetails
) => {
	const history = useHistory();

	useEffect(() => {
		if (affiliateLinkDetails?.type) {
			goToAffiliateLink();
		}
	}, [affiliateLinkDetails?.type]);

	const goToAffiliateLink = useCallback(() => {
		switch (affiliateLinkDetails?.type) {
			case AffiliateLinkType.Activity: {
				history.push(
					`/affiliate-link/activities/${affiliateLinkDetails?.externalId}`
				);
				break;
			}
			case AffiliateLinkType.Merchandise: {
				history.push(
					`/affiliate-link/merchandise/${affiliateLinkDetails?.externalId}`
				);
				break;
			}
			case AffiliateLinkType.Stay: {
				history.push(
					`/affiliate-link/accommodation/${affiliateLinkDetails?.externalId}`
				);
				break;
			}
			case AffiliateLinkType.Transportation: {
				if (
					affiliateLinkDetails.parameters.transportType ===
					TransportType.Airplane
				) {
					history.push(
						`/affiliate-link/transport-search/air?destination=${affiliateLinkConfig.parameters.destination}`
					);
				} else if (
					affiliateLinkDetails.parameters.transportType ===
					TransportType.TrainBus
				) {
					history.push(
						`/affiliate-link/transport-search/trainbus?destination=${affiliateLinkConfig.parameters.destination}`
					);
				}

				break;
			}
		}
	}, [affiliateLinkDetails?.type, affiliateLinkConfig]);

	return {
		goToAffiliateLink
	};
};

export default useProductTypeRedirect;
