import { FunctionalComponent } from 'preact';

import useAccommodationCover from 'hooks/useAccommodationCover';
import useIntl from 'hooks/useIntl';
import OfferItem from 'redesigncomponents/OfferItem';

type Props = {
	offer: any;
	onClick: () => void;
};

export const AccommodationOfferItem: FunctionalComponent<Props> = ({
	offer,
	onClick
}) => {
	const { t } = useIntl('accommodation');
	const { coverImage } = useAccommodationCover(offer.stayEstablishmentId);
	return (
		<OfferItem
			title={offer.name}
			infos={[
				offer.minimumPriceRate &&
					t('fromPrice', {
						price: offer.minimumPriceRate.price,
						currency: offer.minimumPriceRate.currency
					})
			]}
			image={coverImage}
			onClick={onClick}
		></OfferItem>
	);
};
