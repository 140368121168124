import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useParams } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import useAccommodationDetails from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAccommodationDetails';
import useAddAccommodationRoomToWishlist from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/useAddAccommodationRoomToWishlist';
import capitalizeWords from 'utils/string/capitalizeWords';

import useAccommodationListCache from '../AccommodationList/cache/useAccommodationListCache';
import AccommodationDetailsContainer from './AccommodationDetailsContainer';
import useAccommodationOffer from './AccommodationDetailsContainer/useAccommodationOffer';
import AccommodationAddedToWishlistRedirectModal from './components/AccommodationAddedToWishlistRedirectModal';
import { StyledBodyWrapper } from './components/StyledComponents';

interface IProps {}

const AccommodationDetails: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('accommodationDetails');
	const { id: externalId } = useParams();
	const [itemAddedToWishlistOpen, setItemAddedToWishlistOpen] = useToggle();
	const { travelPeriod: initialTravelPeriod } = useDestinationAndPeriod();

	const {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		selectedRoomOption,
		isSelectedRoomOptionValid,
		setNumberOfAdultsValue,
		setNumberOfChildrenValue,
		setChildrenAgesValue,
		setTravelPeriodValue
	} = useAccommodationOffer();
	const { accommodation, loadingAvailableRooms, getAvailableRoomByKey } =
		useAccommodationDetails();
	const { addToWishlist, addedWishlistItem } =
		useAddAccommodationRoomToWishlist();
	const {
		adultsCount,
		childrenCount,
		childrenAges: childrenAgesCount,
		numberOfRoomsCount
	} = useAccommodationListCache();

	useEffect(() => {
		setNumberOfAdultsValue(adultsCount);
		setNumberOfChildrenValue(childrenCount);
		setChildrenAgesValue(childrenAgesCount);
		setTravelPeriodValue([initialTravelPeriod[0], initialTravelPeriod[1]]);
	}, []);

	const handleAddToWishlist = () => {
		addToWishlist(
			numberOfRooms,
			numberOfAdults,
			numberOfChildren,
			childrenAges,
			[travelPeriod[0], travelPeriod[1]],
			getAvailableRoomByKey(selectedRoomOption.value as string),
			accommodation
		);

		setItemAddedToWishlistOpen(true);
	};

	return (
		<MainWrapper
			currentRouteSlug={
				accommodation?.name && capitalizeWords(accommodation?.name)
			}
		>
			<StyledBodyWrapper>
				<AccommodationDetailsContainer
					externalId={externalId}
					travelPeriod={travelPeriod as [Date, Date]}
					defaultNumberOfRooms={numberOfRoomsCount}
				/>
			</StyledBodyWrapper>
			{itemAddedToWishlistOpen && addedWishlistItem && (
				<AccommodationAddedToWishlistRedirectModal
					item={addedWishlistItem}
					open={itemAddedToWishlistOpen}
					toggleOpen={setItemAddedToWishlistOpen}
				/>
			)}
			<Footer>
				{isSelectedRoomOptionValid && !loadingAvailableRooms && (
					<Button
						title={t('addToWishlistButtonTitle')}
						onClick={handleAddToWishlist}
					/>
				)}
			</Footer>
		</MainWrapper>
	);
};

export default AccommodationDetails;
