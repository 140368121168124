import { useMemo } from 'preact/hooks';

import useToggle from 'hooks/useToggle';
import { Option } from 'redesigntypes/Option';
import useAccommodationDetailsCache from './cache/useAccommodationDetailsCache';

const useAccommodationOffer = () => {
	const [imageGalleryOpen, toggleImageGalleryOpen] = useToggle();

	const {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		selectedRoomOption,
		setNumberOfRooms,
		setNumberOfAdults,
		setNumberOfChildren,
		setChildrenAges,
		setTravelPeriod,
		setSelectedRoomOption
	} = useAccommodationDetailsCache();

	const setNumberOfRoomsValue = (rooms: number) => {
		setNumberOfRooms(rooms);
	};

	const setNumberOfAdultsValue = (adults: number) => {
		setNumberOfAdults(adults);
	};

	const setNumberOfChildrenValue = (children: number) => {
		setNumberOfChildren(children);
	};

	const setChildrenAgesValue = (childrenAges: number[]) => {
		setChildrenAges(childrenAges);
	};

	const setTravelPeriodValue = (travelPeriod: [Date, Date]) => {
		setTravelPeriod(travelPeriod);
	};

	const setSelectedRoomOptionValue = (selectedRoomOption: Option) => {
		setSelectedRoomOption(selectedRoomOption);
	};

	const isSelectedRoomOptionValid = useMemo(() => {
		return !!selectedRoomOption;
	}, [selectedRoomOption]);

	return {
		numberOfRooms,
		numberOfAdults,
		numberOfChildren,
		childrenAges,
		travelPeriod,
		imageGalleryOpen,
		selectedRoomOption,
		isSelectedRoomOptionValid,
		setNumberOfRoomsValue,
		setNumberOfAdultsValue,
		setNumberOfChildrenValue,
		setChildrenAgesValue,
		setTravelPeriodValue,
		toggleImageGalleryOpen,
		setSelectedRoomOptionValue
	};
};

export default useAccommodationOffer;
