import { FunctionalComponent } from 'preact';

import useIntl from 'hooks/useIntl';
import { AccommodationRoom } from 'redesigntypes/AccommodationRoom';

import {
	LeftContainer,
	PersonsInfo,
	PriceInfo,
	RightContainer,
	RoomContainer,
	RoomDescriptionHeader,
	RoomDescriptionText,
	RoomSubtitle,
	RoomTitle
} from './StyledComponents';

type Props = {
	selectedRoom: AccommodationRoom;
};

const SelectedRoom: FunctionalComponent<Props> = ({ selectedRoom }) => {
	const { t } = useIntl('accommodationDetails.selectedRoom');
	return (
		<RoomContainer>
			{selectedRoom ? (
				<>
					<RoomDescriptionHeader>
						<LeftContainer>
							<RoomTitle>{selectedRoom.roomDescription}</RoomTitle>
							<RoomSubtitle>{selectedRoom.rate.description}</RoomSubtitle>
						</LeftContainer>

						<RightContainer>
							<PriceInfo>
								{selectedRoom.rate.amount} {selectedRoom.rate.currency}
							</PriceInfo>
							<PersonsInfo>
								{t(
									'roomCapacityPersons',
									{ roomCapacity: selectedRoom.roomCapacity },
									selectedRoom.roomCapacity
								)}
							</PersonsInfo>
						</RightContainer>
					</RoomDescriptionHeader>
					<RoomDescriptionText>{selectedRoom.facilities}</RoomDescriptionText>
				</>
			) : (
				<RoomDescriptionHeader>
					<LeftContainer>
						{t('pleaseChooseARoomTypeEmptyMessage')}
					</LeftContainer>
				</RoomDescriptionHeader>
			)}
		</RoomContainer>
	);
};

export default SelectedRoom;
