import { useCheckout } from 'redesigncache/checkout/useCheckout';

const useUpdateTravelItineraryIdForTravelPlan = (
	travelPlanId: number | string
) => {
	const { checkout, updateCheckout } = useCheckout();

	const setTravelItineraryIdForTravelPlan = (travelItineraryId: string) => {
		const oldTravelPlans = [...checkout.travelPlans];
		const travelPlanIndex = oldTravelPlans.findIndex(
			tp => tp.id === travelPlanId
		);

		if (travelPlanIndex !== -1) {
			oldTravelPlans[travelPlanIndex].travelItineraryId = travelItineraryId;
			oldTravelPlans[travelPlanIndex].updatedAt = Date.now();
			updateCheckout([...oldTravelPlans]);
		}
	};

	return {
		setTravelItineraryIdForTravelPlan
	};
};

export default useUpdateTravelItineraryIdForTravelPlan;
