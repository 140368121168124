import { FunctionalComponent, ComponentChildren } from 'preact';

import {
    TabContentContainer
} from './StyledComponents';



interface IProps {
    children: ComponentChildren;
}

const TabContent: FunctionalComponent<IProps> = ({ children }) => {
    return (
        <TabContentContainer>
            {children}
        </TabContentContainer>
    );
};

export default TabContent;
