import { FunctionalComponent } from 'preact';
import { createPortal } from 'preact/compat';

import styled from 'styled-components';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import useAutofill from 'redesignscreens/Checkout/useAutofill';
import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

import CloseIcon from 'assets/close.svg';

const DialogWrapper = styled.div`
	color: ${Colors.GetGest};
	max-width: 20.5rem;
	position: relative;
`;

const DialogContent = styled.div`
	padding: 1rem;
	background-color: ${Colors.V0};
	box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
	border-radius: 0.125rem;
`;

const CloseIconContainer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 15px;
	right: 15px;
`;

const DialogHeading = styled.h6`
	font-weight: ${FontWeight.bold};
	font-size: ${FontSize.base};
	margin-bottom: 0.5rem;
	padding-right: 2rem;
`;

const DialogButtonContainer = styled.div`
	display: flex;
	gap: 0.25rem;
	margin-top: 0.5rem;
	button {
		flex: 1;
		&:first-child {
			border: 1px solid #243757;
			span {
				color: #243757;
			}
			&:hover {
				border: 1px solid #2f466e;
				background: #243757;
				span {
					color: #fff;
				}
			}
		}
		&:last-child {
			border: 1px solid #243757;
			background: #243757;
			&:hover {
				border: 1px solid #2f466e;
				background: #2f466e;
			}
		}
	}
`;

const DialogParagraph = styled.p`
	font-size: ${FontSize.smSize};
`;

interface IProps {
	isOpen?: boolean;
	onClose?: () => void;
	onAccept?: () => void;
}

const AutofillDialog: FunctionalComponent<IProps> = function ({
	isOpen,
	onClose,
	onAccept
}) {
	const { t } = useIntl('checkout.leadTravelerFormSection.autofillDialog');
	const { elementId } = useAutofill();
	return (
		isOpen &&
		createPortal(
			<DialogWrapper>
				<DialogContent>
					<CloseIconContainer>
						<CloseIcon onClick={onClose}></CloseIcon>
					</CloseIconContainer>
					<DialogHeading>{t('title')}</DialogHeading>
					<DialogParagraph>{t('paragraphText')}</DialogParagraph>
					<DialogButtonContainer>
						<Button
							title={t('no')}
							buttonStyleType={ButtonStyleTypes.secondary}
							onClick={onClose}
						></Button>
						<Button
							title={t('yes')}
							buttonStyleType={ButtonStyleTypes.primary}
							onClick={onAccept}
						></Button>
					</DialogButtonContainer>
				</DialogContent>
			</DialogWrapper>,
			document.getElementById(elementId)
		)
	);
};

export default AutofillDialog;
