import isEmpty from 'lodash.isempty';
import validate from 'validate.js';

export default function (values: any, constraints: any, field?: any): any {
	const errors = {};
	const data = { ...values };
	const validatedMessages =
		validate(data, constraints, { fullMessages: false }) || {};

	Object.keys(validatedMessages).map(
		key => (errors[key] = validatedMessages[key].join(' ') || '')
	);

	return field ? errors[field] : errors;
}

validate.validators.array = (arrayItems, itemConstraints) => {
	const arrayItemErrors = arrayItems?.reduce((errors, item, index) => {
		const error = validate(item, itemConstraints);

		if (error) errors[index.toString()] = { ...error };
		return errors;
	}, {});

	return isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors };
};
