import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Form } from 'react-final-form';

import arrayMutators from 'final-form-arrays';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import {
	IActivityWishlistItem,
	IQuantity,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import validateForm from 'utils/form/validateForm';

import TrashIcon from 'assets/trash.svg';

import { IComposedTravelPlanItem } from '../../../TravelPlan/useTravelPlan';
import useUpdateTravelItineraryIdForTravelPlan from '../../hooks/useUpdateTravelItineraryIdForTravelPlan';
import useUpdateTravelPlanItem from '../../hooks/useUpdateTravelPlanItem';
import AutofillDialog from '../AutofillDialog';
import { GetGestButton } from '../GetGestButton';
import { FormFooter } from '../StyledComponents';
import BillingDetailsFormSection from './components/BillingDetailsFormSection';
import BookingFieldsFormSection from './components/BookingFieldsFormSection';
import LeadTravelerFormSection from './components/LeadTravelerInfoFormSection';
import PaymentDetailsSectionForm from './components/PaymentDetailsSectionForm';
import TermsAndConditionsFormSection from './components/TermsAndConditionsFormSection';
import useCheckoutConstraints from './hooks/useCheckoutConstraints';
import usePayTravelPlanItem from './hooks/usePayTravelPlanItem';
import useTravelPlanItemFormSetup from './hooks/useTravelPlanItemFormSetup';

interface IProps {
	travelPlanItem: IComposedTravelPlanItem;
	travelItineraryId: string;
	travelPlanId: string | number;
	travelPlanName: string;
	travelPeriod: Date[];
	removeTravelPlanItem?: () => void;
}

const CheckoutForm: FunctionalComponent<IProps> = ({
	travelPlanItem,
	travelPeriod,
	travelPlanId,
	travelItineraryId,
	travelPlanName,
	removeTravelPlanItem
}) => {
	const { t } = useIntl('checkout');
	const { user, isLoggedIn } = useUserAuth();
	const [initialValues, setInitialValues] = useState({});
	const [isAutofillAccepted, setIsAutofillAccepted] = useToggle();
	const [showAutofillDialog, setShowAutofillDialog] = useToggle(isLoggedIn);
	const hiddenFields =
		travelPlanItem.type !== WishlistItemTypes.MERCHANDISE
			? ['dateOfBirth', 'gender', 'country']
			: [];
	const isMerchandise = travelPlanItem.type === WishlistItemTypes.MERCHANDISE;

	const acceptAutofill = () => {
		setShowAutofillDialog(false);
		setIsAutofillAccepted(true);
	};

	const { hasAdditionalBookingFieldsSection, hasLeadTravelerSection } =
		useTravelPlanItemFormSetup(travelPlanItem);
	const { constraints } = useCheckoutConstraints({
		hasLeadTravelerConstraints: hasLeadTravelerSection,
		hiddenFields
	});

	const { payTravelPlanItem, loading } = usePayTravelPlanItem(
		travelItineraryId,
		travelPlanItem,
		travelPeriod,
		travelPlanName
	);

	const { setPaidTravelPlanItem } = useUpdateTravelPlanItem(
		travelPlanItem,
		travelPlanId
	);
	const { setTravelItineraryIdForTravelPlan } =
		useUpdateTravelItineraryIdForTravelPlan(travelPlanId);

	const [isCardValid, setIsCardValid] = useState<boolean>(false);
	const onCardChange = async event =>
		setIsCardValid(Boolean(!event.error && !event.empty && event.complete));

	useEffect(() => {
		const initialValueObject = {};
		if (travelPlanItem.type === WishlistItemTypes.ACTIVITY) {
			const totalNumberOfSeats = (
				travelPlanItem.item as IActivityWishlistItem
			).quantity.reduce((previousValue: number, currentValue: IQuantity) => {
				return (
					previousValue + currentValue.numberOfSeats * currentValue.quantity
				);
			}, 0);

			initialValueObject['requiredPerParticipantFields'] = Array.from({
				length: totalNumberOfSeats
			}).map(_ => {
				return {};
			});
		}

		setInitialValues(initialValueObject);
	}, []);

	useEffect(() => {
		if (isAutofillAccepted) {
			setInitialValues({
				...initialValues,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				country: user.country,
				dateOfBirth: user.dateOfBirth,
				gender: hiddenFields.indexOf('gender') !== -1 ? user.gender : undefined
			});
		}
	}, [isAutofillAccepted]);

	const onSubmit = async values => {
		const payResult = await payTravelPlanItem(values);
		if (payResult.success) {
			setPaidTravelPlanItem();
			if (payResult.travelItineraryId) {
				setTravelItineraryIdForTravelPlan(payResult.travelItineraryId);
			}
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			keepDirtyOnReinitialize={true}
			mutators={{ ...arrayMutators }}
			initialValues={initialValues}
			validate={values => validateForm(values, constraints)}
		>
			{({ handleSubmit }) => (
				<form id="checkoutForm" onSubmit={handleSubmit}>
					{hasLeadTravelerSection && (
						<>
							{
								<AutofillDialog
									isOpen={showAutofillDialog}
									onClose={setShowAutofillDialog}
									onAccept={acceptAutofill}
								/>
							}
							<LeadTravelerFormSection
								isMerchandise={isMerchandise}
								hiddenFields={hiddenFields}
							/>
						</>
					)}
					{hasAdditionalBookingFieldsSection && (
						<BookingFieldsFormSection
							travelPlanItem={travelPlanItem}
							isMerchandise={isMerchandise}
						/>
					)}

					<BillingDetailsFormSection />
					<PaymentDetailsSectionForm onCardChange={onCardChange} />
					<TermsAndConditionsFormSection travelPlanItem={travelPlanItem} />

					<FormFooter>
						{removeTravelPlanItem && (
							<Button
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={TrashIcon}
								onlyIcon
								tightPadding
								disabled={loading}
								onClick={removeTravelPlanItem}
								type="button"
							></Button>
						)}
						<GetGestButton
							title={t('payButtonTitle')}
							loading={loading}
						></GetGestButton>
					</FormFooter>
				</form>
			)}
		</Form>
	);
};

export default CheckoutForm;
