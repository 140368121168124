import styled from 'styled-components';

import { Colors, FontWeight, FontSize } from 'styles/defaultTheme';

export const TooltipWrapper = styled.div`
	color: ${Colors.V900};
	max-width: 25rem;
	position: relative;
	border-radius: 0.125rem;
	z-index: 100;
`;

export const TooltipContent = styled.div`
	padding: 1rem;
	background-color: ${Colors.V0};
	box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
`;

export const TooltipHeading = styled.h6`
	font-weight: ${FontWeight.bold};
	font-size: ${FontSize.base};
	margin-bottom: 0.5rem;
	padding-right: 2rem;
`;

export const TooltipParagraph = styled.p`
	font-size: ${FontSize.smSize};
`;

export const CloseIconContainer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 15px;
	right: 15px;
`;

export const TrianglePointer = styled.div`
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 14px 9px 0 9px;
	border-color: #fff transparent transparent transparent;
	position: relative;
	left: 10px;
`;
