import { BookingField, PickupLocation } from 'redesigntypes/ActivityModel';
import { IProductLocation } from 'redesigntypes/ProductLocation';
import { Passenger } from 'redesigntypes/TransportDetails';

export interface IQuantity {
	label: string;
	quantity: number;
	optionId: string;
	numberOfSeats: number;
}

export interface IBasePrice {
	amount: number;
	currency: string;
}

export interface IPriceFlexibility {
	flexibility: string;
	flexibilityVariantNumber: number;
	price: IBasePrice;
}

interface IPassengerPrice {
	basePrice: IBasePrice;
	id: string;
	priceFlexibilities: IPriceFlexibility[];
}

export interface IPriceGroup {
	passengersPrices: IPassengerPrice[];
	title: string;
	totalBasePrice: IBasePrice;
	value: string;
}

interface ILocation {
	id: number;
	name: string;
	producerId: number;
}

export interface IProductCode {
	title: string;
	value: string;
}

interface ISegmentProducerCode extends IProductCode {}
interface ITransportInformation extends IProductCode {}
interface ITravelMethodCode extends IProductCode {}

export interface ISegmentPlacement {
	placementCharacteristics: IProductCode[];
	placementCompartments: IProductCode[];
	placementOrientation: IProductCode[];
	priceGroup: string;
}

export interface ISegment {
	arrivalDateTime: string;
	arrivalLocation: ILocation;
	bookingClass: null | any;
	bookingRule: number;
	departureDateTime: string;
	departureLocation: ILocation;
	id: string;
	productCode: IProductCode;
	segmentProducerCode: ISegmentProducerCode;
	segmentsPlacements: ISegmentPlacement[];
	transportId: string;
	transportInformation: ITransportInformation[];
	travelMethodCode: ITravelMethodCode;
}

export interface IItinerary {
	id: number;
	priceGroups: IPriceGroup[];
	segments: ISegment[];
}
export interface IActivityWishlistItem {
	activity_type: string;
	title: string;
	name: string;
	city: string;
	date: string;
	startTimeLocal: string;
	endTimeLocal: string;
	startTime: string;
	endTime: string;
	id: string;
	people: number;
	number_of_tickets: number;
	children: number;
	infants?: number | null;
	price: IBasePrice;
	quantity: IQuantity[];
	product_owner: string;
	fields: Array<BookingField>;
	booking_time_required: boolean;
	supplierName: string;
	terms: string;
	product_location: IProductLocation;
	duration: number;
	integration: string;
	pickupLocations: PickupLocation[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMerchandiseWishlistItem
	extends Omit<
		IActivityWishlistItem,
		'date' | 'startTimeLocal' | 'endTimeLocal' | 'startTime' | 'endTime'
	> {}

export interface IStayWishlistItem {
	accommodationName: string;
	title: string;
	stayEstablishmentId: string;
	children: number | null;
	infants?: number | null;
	childrenAges: number[] | null;
	city: string;
	people: number | null;
	price: IBasePrice;
	room_type: string;
	rooms: Array<{
		people: number | null;
		children: number | null;
		room_type: string;
	}>;
	number_of_rooms?: number;
	travelPeriod: [Date, Date];
	arrivalDate: Date;
	departureDate: Date;
	product_owner: string;
	priceRateCode: string;
	integration: string;
	roomDescription: string;
	roomCapacity: number;
	product_location: IProductLocation;
}

export interface ITransportWishlistItem {
	title: string;
	city_from: string;
	city_to: string;
	date: string;
	return_date?: string;
	price: IBasePrice;
	transport_type: string;
	type_of_transport?: string;
	getTransportationDetails?: {
		id: number;
		itineraries: IItinerary[];
		__typename: string;
	};
	people: number | null;
	children: number;
	infants: number;
	booking_url?: string;
	booking_token?: string;
	bags?: number;
	passengers: Passenger[];
	product_owner: string;
	trip_type: string;
	book_transportation?: any;
	operatorNames?: string;
	product_location: IProductLocation;
	departure_time: Date;
	return_departure_time: Date;
	duration: number;
	integration: string;
}

export type WishlistItemType =
	| IActivityWishlistItem
	| IMerchandiseWishlistItem
	| IStayWishlistItem
	| ITransportWishlistItem;

export enum TravelerTypes {
	Lead,
	Child,
	Traveler
}

export interface Traveler {
	firstName: string;
	lastName: string;
	gender: string;
	country: string;
	dateOfBirth: Date;
	title: string;
	type: TravelerTypes;
	nationality: string;
}

export interface WishlistItem {
	id: string;
	item?: WishlistItemType;
	type?: WishlistItemTypes;
	travelerList?: Traveler[];
	isTermsAndPrivacyChecked?: boolean;
	paid?: boolean;
	expired?: boolean;
	pickup_location?: PickupLocation;
	order_number?: string;
	leadTraveler?: {
		firstName: string;
		lastName: string;
		email: string;
		dateOfBirth: string;
		country: string;
		countryIsoCode2: string;
		nationality: string;
		gender: string;
		type: TravelerTypes;
	};
}

export interface Wishlist {
	wishlistItems: WishlistItem[];
	travelItineraryId: string;
}

export enum WishlistItemTypes {
	STAY = 'STAY',
	ACTIVITY = 'ACTIVITY',
	TRANSPORT = 'TRANSPORT',
	MERCHANDISE = 'MERCHANDISE',
	INSURANCE_OPTION = 'INSURANCE_OPTION',
	ENVIRONMENT_COMPENSATION = 'ENVIRONMENT_COMPENSATION'
}
