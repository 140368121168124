import { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import AccountMenu from 'redesigncomponents/AccountMenu';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import { Paths } from 'router';

import {
	BodyContent,
	BodyHeader,
	BodySubHeader,
	ButtonContainer,
	StyledBodyWrapper
} from './components/StyledComponents';

interface IProps {}

const AccountWelcome: FunctionalComponent<IProps> = () => {
	const history = useHistory();
	const { user } = useUserAuth();
	const { logout } = useUserAuth();
	const { t } = useIntl('account');

	const logOut = () => {
		logout();
		history.replace({
			pathname: Paths.Main
		});
	};

	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<BodyContent>
					<BodyHeader>
						{t('welcome')} {user.firstName} {user.lastName}
					</BodyHeader>
					<BodySubHeader>{t('toYourAccount')}</BodySubHeader>
					<AccountMenu></AccountMenu>
					<ButtonContainer>
						<Button
							title={t('logOut')}
							buttonStyleType={ButtonStyleTypes.secondary}
							onClick={logOut}
						></Button>
					</ButtonContainer>
				</BodyContent>
			</StyledBodyWrapper>
			<Footer />
		</MainWrapper>
	);
};

export default AccountWelcome;
