import { setContext } from '@apollo/client/link/context';

import storage from 'local-storage-fallback';

import { myPagesClient } from 'apollo/client';
import { REFRESH_TOKEN_LOGIN } from 'apollo/myPages/mutations';
import { parseJwt } from 'hooks/useToken';

let isFetching = false;

const refreshIdToken = (): Promise<any> => {
	const refreshToken = storage.getItem('DESTI1_REDESIGN:REFRESH:TOKEN');
	if (!refreshToken) {
		return Promise.reject();
	}

	return myPagesClient.mutate({
		mutation: REFRESH_TOKEN_LOGIN,
		variables: { refreshToken }
	});
};

const isTokenExpired = (): boolean => {
	const tokenString = storage.getItem('DESTI1_REDESIGN:TOKEN');
	const token = tokenString && parseJwt(tokenString);
	if (!token) {
		return false;
	}

	const expirationDate = new Date(token.exp * 1000);
	const now = new Date();
	const fiveMinutes = 1000 * 60 * 5;

	if (expirationDate.getTime() - now.getTime() < fiveMinutes) {
		return true;
	}

	return false;
};

export const refreshToken = setContext((_, { _headers }) => {
	if (isFetching) {
		return;
	}

	if (!isTokenExpired()) {
		return;
	}

	let headers = { ..._headers };

	isFetching = true;

	return refreshIdToken()
		.then(({ data }) => {
			const { idToken, refreshToken } = data.refreshTokenLogin;

			storage.setItem('DESTI1_REDESIGN:TOKEN', idToken);
			storage.setItem('DESTI1_REDESIGN:REFRESH:TOKEN', refreshToken);

			headers = {
				...headers,
				authorization: `Bearer ${idToken}`
			};

			isFetching = false;

			return headers;
		})
		.catch(() => {
			isFetching = false;

			storage.removeItem('DESTI1_REDESIGN:TOKEN');
			storage.removeItem('DESTI1_REDESIGN:REFRESH:TOKEN');

			// TODO: Add better redirect strategy
			window.location.reload();
		});
});
