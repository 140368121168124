import { FunctionalComponent } from 'preact';

import {
	OfferWrapper,
	SubTitle,
	Title,
	TitleWrapper
} from './components/StyledComponents';

interface IProps {
	title: string;
	infos: string[];
	image?: any;
	onClick?: () => void;
}

const OfferItem: FunctionalComponent<IProps> = ({
	title,
	infos,
	image,
	onClick
}) => {
	return (
		<OfferWrapper image={image} onClick={onClick}>
			<TitleWrapper>
				<Title>{title}</Title>
				{infos.map((info, index) => {
					return <SubTitle key={index}>{info}</SubTitle>;
				})}
			</TitleWrapper>
		</OfferWrapper>
	);
};

export default OfferItem;
