import { useState } from 'preact/hooks';

import {
	TravelPlanCardName as CardName,
	TravelPlanCardWrapper as CardWrapper,
	TravelPlanDetailsBox as DetailsBox,
	HeadWrapper,
	LeftWrapper,
	Location,
	Name,
	TravelPlanRightWrapper as RightWrapper
} from '../StyledComponents';
import DetailsButton from './DetailsButton';
import TravelItineneryDetails from './TravelItineraryDetails';
import useSingleTravelDetails from './useSingleTravelDetails';

type Props = {
	travelPlan: any;
};

const SingleTravelDetails = ({ travelPlan }) => {
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { travelPlanLocation, travelPlanItems } =
		useSingleTravelDetails(travelPlan);

	return (
		<CardWrapper collapsed={collapsed}>
			<HeadWrapper>
				<LeftWrapper>
					<CardName>
						<Name>{travelPlan.name}</Name>
						<Location>{travelPlanLocation}</Location>
					</CardName>
				</LeftWrapper>
				<RightWrapper>
					<DetailsButton collapsed={collapsed} onClick={toggleCollaped} />
				</RightWrapper>
			</HeadWrapper>
			<DetailsBox collapsed={collapsed}>
				{travelPlanItems.map(item => {
					return <TravelItineneryDetails travelPlanItem={item} />;
				})}
			</DetailsBox>
		</CardWrapper>
	);
};

export default SingleTravelDetails;
