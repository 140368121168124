import styled from 'styled-components';

import { Colors } from 'styles/defaultTheme';

export const Box = styled.div`
	display: inline-flex;
	border: 1px solid ${Colors.V40};
	border-radius: 0.25rem;
	color: ${Colors.V0};
	background-color: #fff;
`;

export const Value = styled.span`
	display: flex;
	align-items: center;
	font-size: 0.6rem;
	color: ${Colors.V900};
	line-height: 0.875rem;
	padding: 0.25rem 0.375rem;
	flex: 1;
	justify-content: center;
`;

export const IconContainer = styled.div`
	padding-inline: 0.25rem;
	background-color: ${Colors.V40};
`;
