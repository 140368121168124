import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import Accordion from 'redesigncomponents/Accordion';
import { AccordionParagraph } from 'redesigncomponents/Accordion/components/StyledComponents';
import CounterItem from 'redesigncomponents/CounterItem';
import HeroHeader from 'redesigncomponents/HeroHeader';
import ImageGallery from 'redesigncomponents/ImageGallery';
import ThreeDotLoader from 'redesigncomponents/ThreeDotLoader';
import utcDateWithLocalTimePlugin from 'utils/date/utcDateWithLocalTimePlugin';
import sanitizeContent from 'utils/rezdy/sanitizeContent';

import DestinationIcon from 'assets/icons/transport/destination.svg';

import {
	BoxHeader,
	BoxWrapper,
	HeaderTitle,
	ImageGalleryContainer,
	LeftSection,
	LoaderContainer,
	RightSection,
	SectionContainer,
	SectionHeader,
	TotalPrice
} from './components/StyledComponents';
import useMerchandiseDetails from './useMerchandiseDetails';
import useMerchandiseOffer from './useMerchandiseOffer';

dayjs.extend(utcDateWithLocalTimePlugin);

interface IProps {
	externalId: string;
}

const MerchandiseDetailsContainer: FunctionalComponent<IProps> = ({
	externalId = ''
}) => {
	const { t: merchandiseDetailsTranslations } = useIntl('merchandiseDetails');

	const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>();
	const {
		merchandise,
		heroImage,
		loading: loadingActivityDetails,
		called: calledActivityDetails,
		getMerchandise,
		clearMerchandiseDetailsCache
	} = useMerchandiseDetails(externalId);

	const {
		quantities,
		quantityRequiredMax,
		updateQuantity,
		prices,
		totalPrice,
		currencyCode
	} = useMerchandiseOffer();

	useEffect(() => {
		if (externalId) {
			clearMerchandiseDetailsCache();
		}
	}, [externalId]);

	useEffect(() => {
		if (externalId) {
			getMerchandise(externalId);
		}
	}, [externalId]);

	const toggleImageGalleryOpen = () =>
		setImageGalleryOpen(imageGalleryOpen => !imageGalleryOpen);

	return !merchandise || (!calledActivityDetails && loadingActivityDetails) ? (
		<LoaderContainer>
			<ThreeDotLoader style={{ zIndex: 101 }} color="#342245" size={80} />
		</LoaderContainer>
	) : imageGalleryOpen ? (
		<ImageGalleryContainer>
			<ImageGallery
				imageUrls={merchandise?.images.map(i => i.largeUrl)}
				onClose={toggleImageGalleryOpen}
			/>
		</ImageGalleryContainer>
	) : (
		<>
			<HeroHeader
				title={merchandise?.name}
				infos={[merchandise.supplierName]}
				backgroundImage={heroImage?.largeUrl}
				onClick={
					merchandise?.images?.length ? toggleImageGalleryOpen : undefined
				}
			/>
			<SectionContainer>
				<LeftSection>
					<SectionHeader>
						<HeaderTitle>
							{merchandiseDetailsTranslations('priceDetailsTitle')}
						</HeaderTitle>
					</SectionHeader>

					<BoxWrapper>
						{quantityRequiredMax !== 0 ? (
							<BoxHeader>
								{merchandiseDetailsTranslations('quantityAvailableTitle')}
								{': '} {quantityRequiredMax}
							</BoxHeader>
						) : null}
						{prices.map(price => (
							<CounterItem
								title={price.label}
								subtitle={merchandiseDetailsTranslations(
									'pricePerItemSubtitle',
									{
										price: price.price,
										currency: price.currencyCode
									}
								)}
								onChange={value => updateQuantity(price.id, value)}
								value={quantities[price.id]}
								min={price.minQuantity || 1}
								max={price.maxQuantity === 0 ? undefined : price.maxQuantity}
								enableKeyboardInput
							/>
						))}
					</BoxWrapper>
					<TotalPrice>
						{merchandiseDetailsTranslations('totalPrice')}: {totalPrice}{' '}
						{currencyCode}
					</TotalPrice>
				</LeftSection>
				<RightSection>
					<Accordion
						title={merchandiseDetailsTranslations('descriptionTitle')}
						defaultValue={true}
						Icon={DestinationIcon}
					>
						<AccordionParagraph
							dangerouslySetInnerHTML={{
								__html: sanitizeContent(merchandise?.description)
							}}
						>
							{merchandise?.description}
						</AccordionParagraph>
					</Accordion>
				</RightSection>
			</SectionContainer>
		</>
	);
};

export default MerchandiseDetailsContainer;
