import { FunctionalComponent } from 'preact';
import { MarkupText } from 'preact-i18n';
import { useEffect, useMemo } from 'preact/hooks';
import { useRouteMatch } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import StyledIcon from 'redesigncomponents/IconButton';
import { Paths } from 'router';

import CloseIcon from 'assets/close.svg';
import InfoIcon from 'assets/info.svg';

import { TooltipContainer } from '../StyledComponents';
import {
	CloseIconContainer,
	TooltipContent,
	TooltipHeading,
	TooltipParagraph,
	TooltipWrapper,
	TrianglePointer
} from './components/StyledComponents';
import { useTooltipViewed } from './components/TooltipViewProvider';

function getPathNameByRoute(value: string) {
	return Object.entries(Paths).find(([_, val]) => val === value)?.[0];
}
interface IProps {
	toolTipKey?: string;
}

const MainScreenTooltipKey = 'Main';

const TooltipInfo: FunctionalComponent<IProps> = ({ toolTipKey }) => {
	const { path: pathPattern } = useRouteMatch();
	const { t } = useIntl('tooltips');
	const pathName = getPathNameByRoute(pathPattern);

	const toolTipView = {
		pathName,
		tooltipKey: toolTipKey || MainScreenTooltipKey
	};

	const isTooltipViewed = useTooltipViewed(toolTipView);
	const toolTipContent = useMemo(() => {
		const title = t(`${toolTipView.pathName}.${toolTipView.tooltipKey}.title`);

		if (title) {
			return {
				title,
				content: (
					<MarkupText
						id={`tooltips.${toolTipView.pathName}.${toolTipView.tooltipKey}.paragraphText`}
					/>
				)
			};
		}

		return null;
	}, [toolTipKey]);

	const [tooltipActive, toggleTooltipActive] = useToggle(
		!isTooltipViewed && !!toolTipContent
	);

	const hasTooltip = !!toolTipContent;

	useEffect(() => {
		if (!isTooltipViewed) {
			toggleTooltipActive(true);
		}
	}, [toolTipKey, isTooltipViewed]);

	return (
		<>
			<StyledIcon
				Icon={InfoIcon}
				showAlert={hasTooltip}
				disabled={!hasTooltip}
				onClick={() => {
					hasTooltip && toggleTooltipActive();
				}}
			/>
			{hasTooltip && tooltipActive && (
				<TooltipContainer>
					<TooltipWrapper>
						<TooltipContent>
							<CloseIconContainer onClick={toggleTooltipActive}>
								<CloseIcon></CloseIcon>
							</CloseIconContainer>

							<TooltipHeading>{toolTipContent.title}</TooltipHeading>
							<TooltipParagraph>{toolTipContent.content}</TooltipParagraph>
						</TooltipContent>
						<TrianglePointer></TrianglePointer>
					</TooltipWrapper>
				</TooltipContainer>
			)}
		</>
	);
};

export default TooltipInfo;
