import styled from 'styled-components';
import { ToastContainer as OriginalToastContainer } from 'react-toastify';
import { colors } from 'styles';
import DismissButton from './DismissButton';

const ToastPositioner = styled.div`
  .Toastify {
    position: relative;
  }
`;
const StyledToastContainer = styled(OriginalToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity

  .Toastify__toast {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3),
      0 2px 15px 0 rgba(0, 0, 0, 0.05);
    font-size: 14px;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast-icon {
    width: auto;
  }

  .Toastify__toast--info {
    border: 2px solid ${colors.info};
    color: ${colors.info};
  }
  .Toastify__toast--success {
    border: 2px solid ${colors.success};
    color: ${colors.success};
  }
  .Toastify__toast--warning {
    border: 2px solid ${colors.warning};
    color: ${colors.warning};
  }
  .Toastify__toast--error {
    border: 2px solid ${colors.danger};
    color: ${colors.danger};
  }
  &&&.Toastify__toast-container {
    position: absolute;
  }
`;

const ToastContainer = () => (
  <ToastPositioner>
    <StyledToastContainer closeButton={DismissButton} />
  </ToastPositioner>
);

export default ToastContainer;
