import { FunctionalComponent } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';

import dayjs from 'dayjs';

import { UPDATE_USER } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import useCountryCodebookOptions from 'hooks/useCountryCodebookOptions';
import useGenderCodebookOptions from 'hooks/useGenderCodebookOptions';
import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import FieldDatepicker from 'redesigncomponents/FieldDatepicker';
import FieldSelect from 'redesigncomponents/FieldSelect';
import FieldTextInput from 'redesigncomponents/FieldTextInput';
import Toast from 'redesigncomponents/Toast';
import { useUserProfileConstraints } from 'shared/constraints';
import { IUser } from 'types/cache/User';
import validateForm from 'utils/form/validateForm';
import capitalizeWords from 'utils/string/capitalizeWords';

import CloseIcon from 'assets/close.svg';
import EditIcon from 'assets/edit.svg';
import SaveIcon from 'assets/save.svg';

import FormSectionHeader from '../../FormSectionHeader';
import {
	Divider,
	FormWrapper,
	InputWrapper,
	MainHeading,
	TwoInputWrapper
} from '../../StyledComponents';

const RESET_FORM_TIMEOUT = 100;

interface IProps {
	mobileLayout?: boolean;
}

const PersonalDetailsForm: FunctionalComponent<IProps> = mobileLayout => {
	const { t } = useIntl('account.accountDetails');
	const { user, refreshAuth } = useUserAuth();
	const { constraints: userConstraints } = useUserProfileConstraints();
	const [isUserEdit, setIsUserEdit] = useState<boolean>(false);

	const { countryOptions } = useCountryCodebookOptions();
	const { genderOptions } = useGenderCodebookOptions();

	const [updateUser, { loading: isUpdatingUser }] = useMyPagesMutation<{
		update_user_by_pk: IUser;
	}>(UPDATE_USER, {
		fetchPolicy: 'no-cache',
		onCompleted: () => refreshAuth()
	});

	const onUserFormSubmit = async (data: any) => {
		const { email, firstName, lastName, country, gender, dateOfBirth } = data;

		if (isUserEdit) {
			updateUser({
				variables: {
					id: user.id,
					email,
					firstName,
					lastName,
					countryId: country.value,
					gender: gender.value,
					dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
					connection: user.connection
				}
			})
				.then(() => {
					Toast.success(t('successfulUserEdit'), { autoClose: 5000 });
					setTimeout(() => setIsUserEdit(false), RESET_FORM_TIMEOUT);
				})
				.catch(error => ({ message: error.message }));
		}
	};

	const renderUserForm = ({
		handleSubmit,
		submitting,
		pristine,
		hasValidationErrors,
		form
	}) => (
		<form onSubmit={handleSubmit}>
			<Divider></Divider>
			<MainHeading>{t('accountDetails')}</MainHeading>
			<FormWrapper>
				<FormSectionHeader title={t('personalDetails')}>
					{isUserEdit ? (
						<>
							<Button
								title={t('cancel')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={CloseIcon}
								small
								tightPadding
								onClick={() => {
									form.reset();
									setIsUserEdit(false);
								}}
							/>
							<Button
								title={t('saveChanges')}
								buttonStyleType={ButtonStyleTypes.tertiary}
								Icon={SaveIcon}
								small
								tightPadding
								disabled={
									isUserEdit
										? submitting ||
										  hasValidationErrors ||
										  pristine ||
										  isUpdatingUser
										: false
								}
								loading={isUpdatingUser}
								onClick={handleSubmit}
							/>
						</>
					) : (
						<Button
							title={t('edit')}
							buttonStyleType={ButtonStyleTypes.tertiary}
							Icon={EditIcon}
							small
							tightPadding
							onClick={() => {
								setIsUserEdit(true);
							}}
						/>
					)}
				</FormSectionHeader>
				<TwoInputWrapper>
					<Field
						name="firstName"
						id="firstName"
						label={t('firstName')}
						type="text"
						component={FieldTextInput}
						disabled={!isUserEdit}
						required
						smallPadding
					/>
					<Field
						name="lastName"
						id="lastName"
						label={t('lastName')}
						component={FieldTextInput}
						required
						disabled={!isUserEdit}
						smallPadding
					/>
				</TwoInputWrapper>
				<TwoInputWrapper mobileLayout>
					<TwoInputWrapper>
						<Field
							name="dateOfBirth"
							id="dateOfBirth"
							label={t('dateOfBirth')}
							component={FieldDatepicker}
							showMonthYearDropdown
							maxDate={new Date(dayjs().subtract(18, 'year').toDate())}
							required
							disabled={!isUserEdit}
							smallPadding
							formatAsPlaceholder
						/>
						<InputWrapper>
							<Field id="gender" name="gender" required>
								{({ meta, input }) => (
									<FieldSelect
										meta={meta}
										input={input}
										options={genderOptions}
										label={t('gender')}
										smallPadding
										disabled={!isUserEdit}
									/>
								)}
							</Field>
						</InputWrapper>
					</TwoInputWrapper>
					<InputWrapper>
						<Field id="country" name="country" required>
							{({ meta, input }) => (
								<FieldSelect
									id="country"
									name="country"
									meta={meta}
									input={input}
									options={countryOptions}
									label={t('country')}
									smallPadding
									disabled={!isUserEdit}
									searchable
								/>
							)}
						</Field>
					</InputWrapper>
				</TwoInputWrapper>
			</FormWrapper>
		</form>
	);

	const initialValues = useMemo(() => {
		return {
			...user,
			gender: user.gender && {
				value: user.gender,
				label: capitalizeWords(user.gender)
			}
		};
	}, [user]);

	return (
		<Form
			onSubmit={values => onUserFormSubmit(values)}
			initialValues={initialValues}
			validate={values => validateForm(values, userConstraints)}
			render={renderUserForm}
		/>
	);
};

export default PersonalDetailsForm;
