const ELEMENTS_OPTIONS = {
	fonts: [
		{
			cssSrc:
				'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swa'
		}
	]
};

export default ELEMENTS_OPTIONS;
