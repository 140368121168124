type Config = {
	activityBookingTestingEnabled: boolean;
	enabledTransportTypes: {
		airplane: boolean;
		trainbus: boolean;
	};
	poweredByDestiOneLink: string;
	poweredByGetGestLink: string;
};

const developmentConfig: Config = {
	activityBookingTestingEnabled: true,
	enabledTransportTypes: {
		airplane: true,
		trainbus: true
	},
	poweredByDestiOneLink: 'https://d1techs.com/about/',
	poweredByGetGestLink: 'https://getgest.com/'
};

const testConfig: Config = {
	activityBookingTestingEnabled: true,
	enabledTransportTypes: {
		airplane: true,
		trainbus: true
	},
	poweredByDestiOneLink: 'https://d1techs.com/about/',
	poweredByGetGestLink: 'https://getgest.com/'
};

const uatConfig: Config = {
	activityBookingTestingEnabled: true,
	enabledTransportTypes: {
		airplane: true,
		trainbus: true
	},
	poweredByDestiOneLink: 'https://d1techs.com/about/',
	poweredByGetGestLink: 'https://getgest.com/'
};

const productionConfig: Config = {
	activityBookingTestingEnabled: false,
	enabledTransportTypes: {
		airplane: true,
		trainbus: false
	},
	poweredByDestiOneLink: 'https://d1techs.com/about/',
	poweredByGetGestLink: 'https://getgest.com/'
};

const configMap: { [key: string]: Config } = {
	debug: developmentConfig,
	development: developmentConfig,
	test: testConfig,
	uat: uatConfig,
	prod: productionConfig
};

export default configMap[process.env.PREACT_APP_ENV];
