import { makeVar } from '@apollo/client';

import { AccommodationModel } from 'redesigntypes/AccommodationModel';
import { AccommodationRoom } from 'redesigntypes/AccommodationRoom';
import { Option } from 'redesigntypes/Option';

type AccommodationDetailsCache = {
	numberOfRooms: number;
	numberOfAdults: number;
	numberOfChildren: number;
	childrenAges: number[];
	travelPeriod: [Date, Date];
	headerImage: string;
	galleryImages: string[];
	geographicaLocation: { lat: number; lng: number };
	accommodation: AccommodationModel;
	availableRoomsById: AccommodationRoom;
	selectedRoomOption: Option;
};

export const initialAccommodationDetailsValue: AccommodationDetailsCache = {
	numberOfRooms: 1,
	numberOfAdults: 2,
	numberOfChildren: 0,
	childrenAges: [],
	travelPeriod: undefined,
	headerImage: undefined,
	galleryImages: [],
	geographicaLocation: undefined,
	accommodation: undefined,
	availableRoomsById: undefined,
	selectedRoomOption: undefined
};

export const accommodationDetailsCacheVar = makeVar<AccommodationDetailsCache>(
	initialAccommodationDetailsValue
);
