import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'preact/hooks';

import { myDestiClient, myPagesClient } from 'apollo/client';
import {
	GET_ACCOMMODATION_CATEGORIES,
	GET_ALL_ACTIVITY_TYPES,
	GET_COUNTRIES,
	GET_TRANSPORT_SORT_OPTIONS_BY_TRANSPORT_TYPE,
	GET_TRANSPORT_TYPES
} from 'apollo/myDesti/queries';
import { GET_GENDER } from 'apollo/myPages/queries';
import { TransportType } from 'types/enums';

import { codebookVar } from './cache';

const useCodebook = (options: string[]) => {
	const {
		countries,
		genders,
		accommodationCategories,
		activityTypes,
		transportTypes,
		airplaneTransportSortOptions,
		trainbusTransportSortOptions
	} = useReactiveVar(codebookVar);

	useEffect(() => {
		if (options.includes('countries') && !countries?.length) {
			getCountries();
		}

		if (options.includes('genders') && !genders?.length) {
			getGenders();
		}

		if (
			options.includes('accommodationCategories') &&
			!accommodationCategories?.length
		) {
			getAccommodationCategories();
		}

		if (options.includes('transportTypes') && !transportTypes?.length) {
			getTransportTypes();
		}

		if (
			options.includes('airplaneTransportSortOptions') &&
			!airplaneTransportSortOptions?.length
		) {
			getAirplaneTransportSortOptions();
		}

		if (
			options.includes('trainbusTransportSortOptions') &&
			!trainbusTransportSortOptions?.length
		) {
			getTrainbusTransportSortOptions();
		}

		if (options.includes('activityTypes') && !activityTypes?.length) {
			getActivityTypes();
		}
	}, []);

	const [getCountries] = useLazyQuery(GET_COUNTRIES, {
		onCompleted: data => {
			if (data?.countries) {
				codebookVar({
					...codebookVar(),
					countries: data?.countries.map(c => ({
						...c,
						countryCode: c.country_code
					}))
				});
			}
		}
	});

	const [getTransportTypes] = useLazyQuery(GET_TRANSPORT_TYPES, {
		onCompleted: data => {
			if (data?.transport_type) {
				codebookVar({
					...codebookVar(),
					transportTypes: data?.transport_type.map(tt => ({
						...tt,
						keyName: tt.key_name
					}))
				});
			}
		}
	});
	const [getGenders] = useLazyQuery(GET_GENDER, {
		client: myPagesClient,
		onCompleted: data => {
			if (data?.user_gender) {
				codebookVar({
					...codebookVar(),
					genders: data?.user_gender.map(g => ({ value: g.value }))
				});
			}
		}
	});

	const [getAccommodationCategories] = useLazyQuery(
		GET_ACCOMMODATION_CATEGORIES,
		{
			client: myDestiClient,
			onCompleted: data => {
				if (data?.accommodation_category) {
					codebookVar({
						...codebookVar(),
						accommodationCategories: data?.accommodation_category.map(a => ({
							...a,
							priceFrom: a.price_from,
							priceTo: a.price_to
						}))
					});
				}
			}
		}
	);

	const [getActivityTypes] = useLazyQuery(GET_ALL_ACTIVITY_TYPES, {
		client: myDestiClient,
		onCompleted: data => {
			if (data?.activity_type) {
				codebookVar({
					...codebookVar(),
					activityTypes: data?.activity_type.map(g => ({
						value: g.value
					}))
				});
			}
		}
	});

	const [getAirplaneTransportSortOptions] = useLazyQuery(
		GET_TRANSPORT_SORT_OPTIONS_BY_TRANSPORT_TYPE,
		{
			variables: {
				transportType: TransportType.Airplane
			},
			onCompleted: data => {
				if (data?.getTransportSortOptions) {
					codebookVar({
						...codebookVar(),
						airplaneTransportSortOptions: data?.getTransportSortOptions.map(
							so => ({
								key: so.key,
								name: so.name
							})
						)
					});
				}
			}
		}
	);

	const [getTrainbusTransportSortOptions] = useLazyQuery(
		GET_TRANSPORT_SORT_OPTIONS_BY_TRANSPORT_TYPE,
		{
			variables: {
				transportType: TransportType.TrainBus
			},
			onCompleted: data => {
				if (data?.getTransportSortOptions) {
					codebookVar({
						...codebookVar(),
						trainbusTransportSortOptions: data?.getTransportSortOptions.map(
							so => ({
								key: so.key,
								name: so.name
							})
						)
					});
				}
			}
		}
	);

	return {
		countries,
		genders,
		accommodationCategories,
		airplaneTransportSortOptions,
		trainbusTransportSortOptions,
		transportTypes,
		activityTypes
	};
};

export default useCodebook;
