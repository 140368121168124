import { PluginFunc } from 'dayjs';

const utcDateWithLocalTimePlugin: PluginFunc = (_, dayjsClass, __) => {
	dayjsClass.prototype['toUTCDateWithLocalTime'] = function () {
		return this.utcOffset(0, true).toDate();
	};
};

export default utcDateWithLocalTimePlugin;

declare module 'dayjs' {
	interface Dayjs {
		toUTCDateWithLocalTime(): Date;
	}
}
