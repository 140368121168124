import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import Chip from 'redesigncomponents/Chip';

import AccordionIcon from 'assets/accordion-arrow.svg';

import DateChip from './components/DateChip';
import DestinationPath from './components/DestinationPath';
import {
	AccordionBody,
	AccordionContainer,
	AccordionHeader,
	ButtonContainer,
	ContentContainer,
	DepartureData,
	LeftContainer,
	PriceTitle,
	RightContainer,
	SectionContainer,
	SvgWrapper
} from './components/StyledComponents';
import useTrainTransportOfferItem from './useTrainTransportOfferItem';

interface IProps {
	offer: any; // TODO: add types
	selectTransportOffer?: any;
	clearSelectedTransportOffer?: any;
}

const TrainTransportOfferItem: FunctionalComponent<IProps> = ({
	offer,
	selectTransportOffer,
	clearSelectedTransportOffer
}) => {
	const { t } = useIntl('transportList.results');
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { headerDetails, expandedDetails } = useTrainTransportOfferItem(offer);
	return (
		<AccordionContainer>
			<AccordionHeader onClick={toggleCollaped}>
				<LeftContainer>
					<DateChip
						fromTitle={dayjs(headerDetails.fromDate).format('HH:mm')}
						toTitle={`${dayjs(headerDetails.toDate).format('HH:mm')}`}
						durationTitle={`(${headerDetails.duration})`}
					/>
					<Chip
						title={headerDetails.startingLine}
						Icon={headerDetails.travelMethodIcon}
					/>
					<Chip title={headerDetails.numberOfStops} />
				</LeftContainer>
				<RightContainer>
					<PriceTitle>{headerDetails.price}</PriceTitle>
					<SvgWrapper collapsed={collapsed}>
						<AccordionIcon></AccordionIcon>
					</SvgWrapper>
				</RightContainer>
			</AccordionHeader>
			{collapsed && (
				<AccordionBody>
					<div>
						{expandedDetails?.outgoingItineraries.map(itinierary => (
							<ContentContainer>
								<SectionContainer>
									<DepartureData>
										{dayjs(itinierary?.outgoingTime).format('ddd, DD MMM')}
									</DepartureData>
									<DestinationPath
										fromTitle={`${dayjs(itinierary?.outgoingTime).format(
											'HH:mm'
										)} ${itinierary?.outgoingStation}`}
										toTitle={`${dayjs(itinierary?.arrivalTime).format(
											'HH:mm'
										)} ${itinierary?.arrivalStation}`}
										itineraries={itinierary.segments}
									></DestinationPath>
								</SectionContainer>
							</ContentContainer>
						))}
					</div>
					{selectTransportOffer ? (
						<ButtonContainer>
							<Button
								title={t('selectButtonTitle')}
								buttonStyleType={ButtonStyleTypes.primary}
								onClick={selectTransportOffer}
							></Button>
						</ButtonContainer>
					) : (
						<ButtonContainer>
							<Button
								title={t('clearButtonTitle')}
								buttonStyleType={ButtonStyleTypes.primary}
								onClick={clearSelectedTransportOffer}
							></Button>
						</ButtonContainer>
					)}
				</AccordionBody>
			)}
		</AccordionContainer>
	);
};

export default TrainTransportOfferItem;
