import dayjs from 'dayjs';

import {
	GUEST_PREPARE_CHECKOUT_ITINERARY,
	PREPARE_CHECKOUT_ITINERARY
} from 'apollo/myBookings/mutations';
import { useBookingMutation } from 'apollo/myBookings/useBooking';
import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import {
	ITransportWishlistItem,
	WishlistItem
} from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../../../TravelPlan/useTravelPlan';

const usePrepareCheckoutItinerary = (travelItineraryId: string) => {
	const { t: commonTranslations } = useIntl('common');
	const { isLoggedIn } = useUserAuth();
	const getPassengersCountByCategory = (cartItem: WishlistItem) => {
		const { people, children, infants } = cartItem.item;

		return {
			people,
			children,
			infants
		};
	};

	const getTravelPlanObject = (
		travelPlanName: string,
		travelPlanItem: IComposedTravelPlanItem
	) => {
		const transportItem = travelPlanItem.item as ITransportWishlistItem;

		return JSON.stringify({
			id: travelItineraryId, //'travelItineraryId', // TODO: figure out if this a remnant of old plan/item logic
			start_date: transportItem.date,
			end_date:
				transportItem.return_date ||
				dayjs(transportItem.date).add(1, 'day').toDate(), // if the transport does not have a return, use the same date +1 day as the end date
			name: travelPlanName || commonTranslations('guestTravelPlan'),
			...getPassengersCountByCategory(travelPlanItem),
			travelPlan: [
				{
					type: travelPlanItem.type.toLowerCase(),
					id: travelPlanItem.id,
					item: {
						...travelPlanItem.item,
						order_number: travelPlanItem.order_number,
						product_owner: travelPlanItem.productOwner.name
					}
				}
			]
		});
	};

	const [callPrepareCheckoutItinerary, { loading: loadingSavingItinerary }] =
		useBookingMutation(PREPARE_CHECKOUT_ITINERARY, { fetchPolicy: 'no-cache' });
	const [
		callGuestPrepareCheckoutItinerary,
		{ loading: loadingSavingGuestItinerary }
	] = useBookingMutation(GUEST_PREPARE_CHECKOUT_ITINERARY, {
		fetchPolicy: 'no-cache'
	});

	const prepareCheckoutItinerary = async (
		travelPlanItem: IComposedTravelPlanItem,
		travelPlanName: string,
		contactEmail: string
	) => {
		const travelPlanItemString = getTravelPlanObject(
			travelPlanName,
			travelPlanItem
		);

		const response = isLoggedIn
			? await callPrepareCheckoutItinerary({
					variables: {
						travelPlan: travelPlanItemString, // one travel plan item is now saved as a whole travel plan
						contactEmail: contactEmail
					},
					fetchPolicy: 'no-cache'
			  })
			: await callGuestPrepareCheckoutItinerary({
					variables: {
						travelPlan: travelPlanItemString, // one travel plan item is now saved as a whole travel plan
						contactEmail: contactEmail
					},
					fetchPolicy: 'no-cache'
			  });

		if (isLoggedIn) {
			const {
				data: {
					prepareCheckoutItinerary: { travelItineraryId }
				}
			} = response;

			return travelItineraryId;
		} else {
			const {
				data: {
					guestPrepareCheckoutItinerary: { travelItineraryId }
				}
			} = response;

			return travelItineraryId;
		}
	};

	return {
		prepareCheckoutItinerary,
		loading: loadingSavingItinerary || loadingSavingGuestItinerary
	};
};

export default usePrepareCheckoutItinerary;
