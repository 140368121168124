import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

type MerchandiseListCache = {
	initialized: boolean;
	sort: Option;
};

export const initialMerchandiseListValue: MerchandiseListCache = {
	initialized: false,
	sort: undefined
};

export const merchandiseListCacheVar = makeVar<MerchandiseListCache>(
	initialMerchandiseListValue
);
