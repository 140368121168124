import styled from 'styled-components';

import { Colors, DeviceBreakpoints } from 'styles/defaultTheme';

export const HeaderWrapper = styled.div`
	width: 100%;
	background-color: ${Colors.V0};
	display: flex;
	justify-content: space-between;
	padding: 1.25rem;
	align-items: center;
	flex-wrap: wrap;
	@media ${DeviceBreakpoints.small} {
		padding: 0.75rem 0.5rem;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	img {
		max-height: 2.5rem;
		margin-left: 1rem;
	}
`;

export const RightWrapper = styled.div`
	display: flex;
	gap: 0.3125rem;
	margin-left: auto;
`;

export const LogoWrapper = styled.div`
	margin-right: 1.5rem;
	img {
		max-height: 2.5rem;
		margin-left: 1rem;
	}
`;
