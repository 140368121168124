import { useState } from 'preact/hooks';

import {
	IMerchandiseWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import { MerchandiseModel } from 'redesigntypes/ActivityModel';

const useAddMerchandiseToWishlist = (
	merchandise: MerchandiseModel,
	quantities: { [key: string]: number },
	totalPrice: number,
	currencyCode: string
) => {
	const { createWishlistItem, addItemToWishlist } = useWishlist();
	const [addedWishlistItem, setAddedWishlistItem] = useState<WishlistItem>();

	const addToWishlist = (): WishlistItem => {
		const newWishlistItem = createMerchandiseWishlistItem();
		addItemToWishlist(newWishlistItem);

		setAddedWishlistItem(newWishlistItem);
		return newWishlistItem;
	};

	const createMerchandiseWishlistItem = () => {
		const wishlistItem: Omit<WishlistItem, 'id'> = {
			type: WishlistItemTypes.MERCHANDISE,
			item: {
				quantity: merchandise.prices
					.filter(pc => quantities[pc.id])
					.map(pc => ({
						optionId: pc.id,
						quantity: quantities[pc.id],
						label: pc.label,
						numberOfSeats: pc.seatsUsed
					})),
				people: 0,
				children: 0,
				number_of_tickets: 0,
				id: merchandise.id,
				name: merchandise.name,
				title: merchandise.name,
				price: {
					amount: totalPrice,
					currency: currencyCode
				},
				activity_type: merchandise.productType,
				product_owner: merchandise.productOwner,
				supplierName: merchandise.supplierName,
				fields: merchandise.bookingFields,
				booking_time_required: merchandise.bookingTimeRequired,
				terms: merchandise.terms,
				integration: merchandise.integration
			} as IMerchandiseWishlistItem
		};

		return createWishlistItem(wishlistItem);
	};

	return {
		addToWishlist,
		createMerchandiseWishlistItem,
		addedWishlistItem
	};
};

export default useAddMerchandiseToWishlist;
