import { useMemo } from 'preact/hooks';
import { useLocation } from 'react-router-dom';

const useQueryParams = (names: Array<string>) => {
	const { search } = useLocation();

	return useMemo((): { [key: string]: string } => {
		const params = new URLSearchParams(search);
		return names.reduce((acc, curr) => {
			acc[curr] = params.get(curr);
			return acc;
		}, {});
	}, [search]);
};

export default useQueryParams;
