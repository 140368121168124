import dayjs from 'dayjs';
import styled from 'styled-components';

import Tab from 'redesigncomponents/TransportTabs/components/SingleTab';
import TabsContainer from 'redesigncomponents/TransportTabs/components/TabsContainer';
import { TransportTripType } from 'types/enums';

export enum TabsEnum {
	Departure = 'Depature',
	Return = 'Return'
}

const SelectedNumberCircle = styled.span`
	width: 15px;
	height: 15px;
	line-height: 15px;
	font-size: 10px;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: solid 1px #4a3a59;
	background-color: #4a3a59;
	color: white;
	text-align: center;
	display: block;
`;

const Tabs = ({
	selectedTab,
	setSelectedTab,
	departureDate,
	returnDate,
	tripType,
	hasSelectedDeparture,
	hasSelectedReturn
}) => {
	return (
		<TabsContainer>
			<Tab
				active={selectedTab === TabsEnum.Departure}
				tabTitle="Departure"
				dateTitle={dayjs(departureDate).format('DD.MM.YYYY')}
				onClick={() => setSelectedTab(TabsEnum.Departure)}
				Icon={
					hasSelectedDeparture && <SelectedNumberCircle>1</SelectedNumberCircle>
				}
			></Tab>
			{tripType.value === TransportTripType.RoundTrip && (
				<Tab
					active={selectedTab === TabsEnum.Return}
					tabTitle="Return"
					dateTitle={dayjs(returnDate).format('DD.MM.YYYY')}
					onClick={() => setSelectedTab(TabsEnum.Return)}
					Icon={
						hasSelectedReturn && <SelectedNumberCircle>1</SelectedNumberCircle>
					}
				></Tab>
			)}
		</TabsContainer>
	);
};

export default Tabs;
