import { PriceOption } from 'redesigntypes/ActivityModel';

const getNumberOfSelectedSeats = (
	selectedTickets: {
		[key: string]: number;
	},
	ticketsAvailable: PriceOption[]
) => {
	if (ticketsAvailable?.length) {
		const total: number = ticketsAvailable?.reduce(
			(previousValue: number, currentValue: PriceOption) => {
				const numberOfTickets = selectedTickets?.[currentValue.id] || 0;
				const seatsUsedByTicket = currentValue.seatsUsed;
				return previousValue + numberOfTickets * seatsUsedByTicket;
			},
			0
		);

		return total;
	}
	return 0;
};

export default getNumberOfSelectedSeats;
