import { useMemo } from 'preact/hooks';

import useIntl from 'hooks/useIntl';

export default function (
	options: { hasLeadTravelerConstraints: boolean; hiddenFields: string[] },
	dynamicValidationConstraints: any = undefined
) {
	const { t } = useIntl('common.validationMessages');

	const { leadTravelerConstraints } = useLeadTravelerConstraints(options);

	const constraints = useMemo(() => {
		return {
			firstNameBilling: {
				presence: {
					message: t('firstNameRequired')
				}
			},
			lastNameBilling: {
				presence: {
					message: t('lastNameRequired')
				}
			},
			countryBilling: {
				presence: {
					message: t('countryRequired')
				}
			},
			cityBilling: {
				presence: {
					message: t('cityRequired')
				}
			},
			zipCodeBilling: {
				presence: {
					message: t('zipCodeRequired')
				}
			},
			...leadTravelerConstraints,
			...dynamicValidationConstraints
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicValidationConstraints, leadTravelerConstraints]);

	return { constraints };
}

const useLeadTravelerConstraints = (options: {
	hasLeadTravelerConstraints: boolean;
	hiddenFields: string[];
}) => {
	const { t } = useIntl('common.validationMessages');
	const leadTravelerConstraints = useMemo(() => {
		if (options.hasLeadTravelerConstraints) {
			const constraints = {
				firstName: {
					presence: {
						message: t('firstNameRequired')
					}
				},
				lastName: {
					presence: {
						message: t('lastNameRequired')
					}
				},
				email: {
					presence: {
						message: t('emailRequired')
					}
				}
			};

			if (options.hiddenFields.indexOf('country') !== -1) {
				constraints['country'] = {
					presence: {
						message: t('countryRequired')
					}
				};
			}

			if (options.hiddenFields.indexOf('dateOfBirth') !== -1) {
				constraints['dateOfBirth'] = {
					presence: {
						message: t('dateOfBirthRequired')
					}
				};
			}

			if (options.hiddenFields.indexOf('gender') !== -1) {
				constraints['gender'] = {
					presence: {
						message: t('genderRequired')
					}
				};
			}

			return constraints;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	return { leadTravelerConstraints };
};
