import { TrainTransportDetailsStep } from '../../cache';
import useTrainTransportDetailsCache from '../../cache/useTrainTransportDetailsCache';
import PriceSection from '../PriceSection';
import { SectionsContainer } from '../StyledComponents';
import TrainTransportItemPreview from '../TrainTransportItemPreview';

const SummaryStep = ({}) => {
	const { departureDetails, returnDetails, setCurrentStep } =
		useTrainTransportDetailsCache();

	const goToDeparture = () =>
		setCurrentStep(TrainTransportDetailsStep.Departure);
	const goToReturn = () => setCurrentStep(TrainTransportDetailsStep.Departure);

	return (
		<SectionsContainer>
			<TrainTransportItemPreview
				offerDetails={departureDetails.offerDetails}
			></TrainTransportItemPreview>
			<PriceSection
				passengers={departureDetails.passengerString}
				price={departureDetails.price}
				onView={goToDeparture}
			/>
			{returnDetails?.offerDetails && (
				<>
					<TrainTransportItemPreview
						offerDetails={returnDetails.offerDetails}
					></TrainTransportItemPreview>
					<PriceSection
						passengers={returnDetails.passengerString}
						price={returnDetails.price}
						onView={goToReturn}
					/>
				</>
			)}
		</SectionsContainer>
	);
};

export default SummaryStep;
