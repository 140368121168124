import { useCallback, useState } from 'preact/hooks';

const useToggle = (initialState = false): [boolean, (newState?) => void] => {
	// Initialize the state
	const [state, setState] = useState<boolean>(initialState);

	// Define and memorize toggler function in case we pass down the component,
	// This function change the boolean value to it's opposite value
	const toggle = useCallback(
		(newState = undefined) =>
			setState(state => (typeof newState === 'boolean' ? newState : !state)),
		[]
	);

	return [state, toggle];
};

export default useToggle;
