import { useState } from 'preact/hooks';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useIntl from 'hooks/useIntl';
import useItemIcon from 'hooks/useItemIcon';
import { WishlistItemTypes } from 'redesigncache/wishlist/types';
import InfoBox from 'redesigncomponents/InfoBox';
import LocationMap from 'redesigncomponents/LocationMap';
import { MapDivider } from 'redesignscreens/Main/Accommodation/AccommodationDetails/AccommodationDetailsContainer/components/StyledComponents';

import {
	CardLogoWrapper,
	TravelItineraryCardName as CardName,
	TravelItineraryCardWrapper as CardWrapper,
	DetailsBox,
	FooterLeft,
	FooterRight,
	HeadWrapper,
	TravelItineraryLeftWrapper as LeftWrapper,
	Link,
	LocationMessage,
	Name,
	TravelItineraryRightWrapper as RightWrapper,
	RowName,
	RowValue,
	TableBody,
	TableFooter,
	TableLeft,
	TableRight,
	TableRow
} from '../StyledComponents';
import DetailsButton from './DetailsButton';

dayjs.extend(utc);

const TravelItineneryDetails = ({ travelPlanItem }) => {
	const { t } = useIntl('travels');
	const { t: commonTranslations } = useIntl('common.general');
	const [collapsed, setCollapsed] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);
	const { Icon } = useItemIcon(travelPlanItem.type, travelPlanItem);

	return (
		<CardWrapper>
			<HeadWrapper>
				<LeftWrapper>
					<CardName>
						<CardLogoWrapper>
							<Icon />
						</CardLogoWrapper>
						{travelPlanItem.type === WishlistItemTypes.TRANSPORT ? (
							<Name>{`${travelPlanItem.city_from} - ${travelPlanItem.city_to}`}</Name>
						) : (
							<Name>{travelPlanItem.description}</Name>
						)}
					</CardName>
				</LeftWrapper>
				<RightWrapper>
					<InfoBox
						title={t('noOfPeople')}
						subtitle={travelPlanItem.numberOfPeople}
					/>
					<DetailsButton collapsed={collapsed} onClick={toggleCollaped} />
				</RightWrapper>
			</HeadWrapper>
			<DetailsBox collapsed={collapsed}>
				<TableBody>
					<TableLeft>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{t('checkInTime')}</RowName>
									<RowValue>{travelPlanItem.checkInTime}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('startTime')}</RowName>
									<RowValue>{travelPlanItem.startTime}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{t('checkInDate')}</RowName>
									<RowValue>{travelPlanItem.startDate}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('startingDate')}</RowName>
									<RowValue>{travelPlanItem.startDate}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{t('checkOutTime')}</RowName>
									<RowValue>
										{travelPlanItem.checkOutTime ||
											commonTranslations('noInfoAvailable')}
									</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.ACTIVITY && (
								<>
									<RowName>{t('activityLength')}</RowName>
									<RowValue>{travelPlanItem.duration}</RowValue>
								</>
							)}
							{travelPlanItem.type === WishlistItemTypes.TRANSPORT && (
								<>
									<RowName>{t('tripDuration')}</RowName>
									<RowValue>{travelPlanItem.duration}</RowValue>
								</>
							)}
							{travelPlanItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{t('checkOutDate')}</RowName>
									<RowValue>{travelPlanItem.endDate}</RowValue>
								</>
							)}
						</TableRow>
					</TableLeft>
					<TableRight>
						<TableRow>
							<RowName>{t('location')}</RowName>
							<RowValue>
								<Link onCLick={() => setShowMap(true)}>
									{travelPlanItem.address || t('showOnMap')}
								</Link>
							</RowValue>
						</TableRow>
						<TableRow>
							{travelPlanItem.type === WishlistItemTypes.ACTIVITY ? (
								<>
									<RowName>{t('numberOfTickets')}</RowName>
									<RowValue>{travelPlanItem.numberOfTickets}</RowValue>
								</>
							) : (
								<>
									<RowName>{t('numberOfPeople')}</RowName>
									<RowValue>{travelPlanItem.numberOfPeople}</RowValue>
								</>
							)}
						</TableRow>
						{travelPlanItem.type === WishlistItemTypes.STAY && (
							<TableRow>
								<RowName>{t('numberOfRooms')}</RowName>
								<RowValue>{travelPlanItem.number_of_rooms}</RowValue>
							</TableRow>
						)}
						<TableRow>
							<RowName>{t('totalPrice')}</RowName>
							<RowValue>{travelPlanItem.totalPrice}</RowValue>
						</TableRow>
					</TableRight>
				</TableBody>
				<TableFooter>
					<FooterLeft>{t('bookingID')}</FooterLeft>
					<FooterRight>{travelPlanItem.external_id}</FooterRight>
				</TableFooter>
				{showMap && (
					<>
						<MapDivider />
						{travelPlanItem.longitude && travelPlanItem.latitude ? (
							<LocationMap
								markers={[
									{
										lng: travelPlanItem.longitude,
										lat: travelPlanItem.latitude
									}
								]}
							/>
						) : (
							<LocationMessage>
								{t('mapCoordinatesAreNotProvided')}
							</LocationMessage>
						)}
					</>
				)}
			</DetailsBox>
		</CardWrapper>
	);
};

export default TravelItineneryDetails;
