import IconValue from 'redesigncomponents/IconValue';

import Info from 'assets/icons/transport/info.svg';

import { IconValueContainer } from './StyledComponents';

interface Props {
	title: string;
}

const FormSectionTitle = ({ title }: Props) => {
	return (
		<IconValueContainer>
			<IconValue Icon={Info} value={title} />
		</IconValueContainer>
	);
};

export default FormSectionTitle;
