import { FunctionalComponent, VNode } from 'preact';

import DateIcon from 'assets/icons/transport/calendar.svg';

import {
	DateTitle,
	DateWrapper,
	SingleTab,
	TabTitle
} from './StyledComponents';

interface IProps {
	tabTitle?: string;
	dateTitle?: string;
	active?: boolean;
	Icon?: VNode;
	onClick?: () => void;
}

const TransportTab: FunctionalComponent<IProps> = ({
	tabTitle,
	dateTitle,
	active,
	Icon,
	onClick
}) => {
	return (
		<SingleTab active={active} onClick={onClick}>
			<TabTitle>{tabTitle}</TabTitle>
			<DateWrapper>
				<DateIcon></DateIcon>
				<DateTitle>{dateTitle}</DateTitle>
			</DateWrapper>
			{Icon && Icon}
		</SingleTab>
	);
};

export default TransportTab;
