import { useMutation, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_INTEGRATION_TRANSPORTATION_DETAILS } from 'apollo/myDesti/mutations';
import { IItinerary } from 'redesigntypes/Itinerary';
import { TransportType } from 'types/enums';

import { trainTransportSearchOptionsVar } from '../../../TrainTransportList/TrainTransportListContainer/cache';
import {
	getPassengersAndAddonsString,
	getPassengersForSearch
} from '../../../TrainTransportList/TrainTransportListContainer/hooks/useTransportationOffers';
import { OfferDetails } from '../../../TrainTransportList/types';
import {
	TrainTransportDetailsStep,
	trainTransportDetailsOptionsVar
} from '../cache';
import useTrainTransportDetailsCache from '../cache/useTrainTransportDetailsCache';

export interface ITransportDetails {
	id: string;
	itineraries: IItinerary[];
	productOwner: string;
}

export interface ITransportOptionSection {
	itineraryIndex: number;
	segmentIndex: number;
	type: string;
}

const useTrainTransportDetails = () => {
	const { currentStep } = useReactiveVar(trainTransportDetailsOptionsVar);
	const {
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	} = useReactiveVar(trainTransportSearchOptionsVar);

	const {
		departureTransportDetails,
		returnTransportDetails,
		selectedDepartureOptions,
		selectedReturnOptions,
		openSection,
		setDepartureTransportDetails,
		setReturnTransportDetails,
		setSelectedDepartureOptions,
		setSelectedReturnOptions,
		setOpenSection,
		clearTransportDetails
	} = useTrainTransportDetailsCache();

	const [getTransportationDetails, { loading }] = useMutation<{
		getTransportationDetails: ITransportDetails;
	}>(GET_INTEGRATION_TRANSPORTATION_DETAILS);

	const callGetTransportOfferDetails = async (
		offerDetails: OfferDetails
	): Promise<ITransportDetails> => {
		const { data } = await getTransportationDetails({
			variables: {
				journeyId: offerDetails.offer.timetable_id,
				passengers: getPassengersForSearch(offerDetails.optionCategories),
				hasPets: offerDetails.optionCategories.addons.pets.count > 0,
				originLocationCode: offerDetails.fromLocation.value,
				destinationLocationCode: offerDetails.toLocation.value,
				departureDateFrom: dayjs
					.utc(dayjs(offerDetails.offer.departure_time))
					.local()
					.format('YYYY-MM-DDTHH:mm:ssZ'),
				transportationType: [TransportType.TrainBus],
				uniqueTransportId: offerDetails.offer.unique_transport_id
			}
		});
		return data.getTransportationDetails;
	};

	useEffect(() => {
		const getTransportOffersDetails = async () => {
			try {
				const promises = [
					callGetTransportOfferDetails(selectedDepartureTransportOfferDetails)
				];

				if (selectedReturnTransportOfferDetails) {
					promises.push(
						callGetTransportOfferDetails(selectedReturnTransportOfferDetails)
					);
				}
				const [departureTransportDetails, returnDetails] = await Promise.all(
					promises
				);

				setDepartureTransportDetails(departureTransportDetails);
				setReturnTransportDetails(returnDetails);
			} catch {
				//ignored - toast handles the error
			}
		};
		getTransportOffersDetails();
	}, []);

	const offerDetails: OfferDetails = useMemo(() => {
		if (currentStep === TrainTransportDetailsStep.Departure) {
			return selectedDepartureTransportOfferDetails;
		} else if (currentStep === TrainTransportDetailsStep.Return) {
			return selectedReturnTransportOfferDetails;
		} else {
			return selectedDepartureTransportOfferDetails;
		}
	}, [
		currentStep,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	]);

	const setItineraryPriceOptions = (
		itineraryId,
		priceGroupValue,
		flexibilityIndex,
		flexibilityVariantNumber
	) => {
		currentStep === TrainTransportDetailsStep.Departure
			? setSelectedDepartureOptions({
					...selectedDepartureOptions,
					[itineraryId]: {
						...selectedDepartureOptions[itineraryId],
						priceGroupValue,
						flexibilityIndex,
						flexibilityVariantNumber,
						segments: {}
					}
			  })
			: setSelectedReturnOptions({
					...selectedReturnOptions,
					[itineraryId]: {
						...selectedReturnOptions[itineraryId],
						priceGroupValue,
						flexibilityIndex,
						flexibilityVariantNumber,
						segments: {}
					}
			  });
	};

	const setSegmentSeatPlacementOrientation = (
		itineraryIndex,
		segmentIndex,
		orientationValue
	) => {
		currentStep === TrainTransportDetailsStep.Departure
			? setSelectedDepartureOptions({
					...selectedDepartureOptions,
					[itineraryIndex]: {
						...selectedDepartureOptions[itineraryIndex],
						segments: {
							...selectedDepartureOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedDepartureOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								orientationValue
							}
						}
					}
			  })
			: setSelectedReturnOptions({
					...selectedReturnOptions,
					[itineraryIndex]: {
						...selectedReturnOptions[itineraryIndex],
						segments: {
							...selectedReturnOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedReturnOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								orientationValue
							}
						}
					}
			  });
	};

	const setSegmentSeatPlacementCompartment = (
		itineraryIndex,
		segmentIndex,
		compartmentValue
	) => {
		currentStep === TrainTransportDetailsStep.Departure
			? setSelectedDepartureOptions({
					...selectedDepartureOptions,
					[itineraryIndex]: {
						...selectedDepartureOptions[itineraryIndex],
						segments: {
							...selectedDepartureOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedDepartureOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								compartmentValue
							}
						}
					}
			  })
			: setSelectedReturnOptions({
					...selectedReturnOptions,
					[itineraryIndex]: {
						...selectedReturnOptions[itineraryIndex],
						segments: {
							...selectedReturnOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedReturnOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								compartmentValue
							}
						}
					}
			  });
	};

	const setSegmentSeatPlacementCharacteristic = (
		itineraryIndex,
		segmentIndex,
		characteristicValue
	) => {
		currentStep === TrainTransportDetailsStep.Departure
			? setSelectedDepartureOptions({
					...selectedDepartureOptions,
					[itineraryIndex]: {
						...selectedDepartureOptions[itineraryIndex],
						segments: {
							...selectedDepartureOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedDepartureOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								characteristicValue
							}
						}
					}
			  })
			: setSelectedReturnOptions({
					...selectedReturnOptions,
					[itineraryIndex]: {
						...selectedReturnOptions[itineraryIndex],
						segments: {
							...selectedReturnOptions[itineraryIndex]?.segments,
							[segmentIndex]: {
								...selectedReturnOptions[itineraryIndex]?.segments?.[
									segmentIndex
								],
								characteristicValue
							}
						}
					}
			  });
	};

	const openFormSection = (itineraryIndex, segmentIndex, type) => {
		setOpenSection({ itineraryIndex, segmentIndex, type });
	};

	const transportPrice = useMemo(() => {
		if (
			currentStep === TrainTransportDetailsStep.Departure &&
			selectedDepartureOptions &&
			departureTransportDetails
		) {
			return calculateTransportPrice(
				departureTransportDetails,
				selectedDepartureOptions
			);
		} else if (
			currentStep === TrainTransportDetailsStep.Return &&
			selectedReturnOptions &&
			returnTransportDetails
		) {
			return calculateTransportPrice(
				returnTransportDetails,
				selectedReturnOptions
			);
		}

		return { amount: 0, currency: 'SEK' };
	}, [
		currentStep,
		selectedDepartureOptions,
		selectedReturnOptions,
		departureTransportDetails,
		returnTransportDetails
	]);

	const transportPassengerString = useMemo(() => {
		if (currentStep === TrainTransportDetailsStep.Departure) {
			return getPassengersAndAddonsString(
				selectedDepartureTransportOfferDetails.optionCategories
			);
		} else if (currentStep === TrainTransportDetailsStep.Return) {
			return getPassengersAndAddonsString(
				selectedReturnTransportOfferDetails.optionCategories
			);
		}
	}, [
		currentStep,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	]);

	const isSectionValid = useCallback(() => {
		if (
			currentStep === TrainTransportDetailsStep.Departure &&
			selectedDepartureOptions &&
			departureTransportDetails
		) {
			return isTransportOptionSectionValid(
				openSection,
				departureTransportDetails,
				selectedDepartureOptions
			);
		} else if (
			currentStep === TrainTransportDetailsStep.Return &&
			selectedReturnOptions &&
			returnTransportDetails
		) {
			return isTransportOptionSectionValid(
				openSection,
				returnTransportDetails,
				selectedReturnOptions
			);
		}

		return false;
	}, [
		currentStep,
		openSection,
		selectedDepartureOptions,
		selectedReturnOptions,
		departureTransportDetails,
		returnTransportDetails
	]);

	const selectedOptionsValid = useMemo(() => {
		if (currentStep === TrainTransportDetailsStep.Departure) {
			return isTransportValid(
				departureTransportDetails,
				selectedDepartureOptions
			);
		} else if (currentStep === TrainTransportDetailsStep.Return) {
			return isTransportValid(returnTransportDetails, selectedReturnOptions);
		}

		return true;
	}, [
		currentStep,
		departureTransportDetails,
		returnTransportDetails,
		selectedDepartureOptions,
		selectedReturnOptions
	]);

	const transportDetails = useMemo(() => {
		if (currentStep === TrainTransportDetailsStep.Departure) {
			return departureTransportDetails;
		} else if (currentStep === TrainTransportDetailsStep.Return) {
			return returnTransportDetails;
		}

		return undefined;
	}, [currentStep, departureTransportDetails, returnTransportDetails]);

	const selectedOptions = useMemo(() => {
		if (currentStep === TrainTransportDetailsStep.Departure) {
			return selectedDepartureOptions;
		} else if (currentStep === TrainTransportDetailsStep.Return) {
			return selectedReturnOptions;
		}

		return undefined;
	}, [currentStep, selectedDepartureOptions, selectedReturnOptions]);

	const findNextSegmentWithAccommodationInItineraryIndex =
		currentSegmentIndex => {
			const selectedItineraryOptions =
				selectedOptions?.[openSection?.itineraryIndex];
			return transportDetails?.itineraries?.[
				openSection?.itineraryIndex
			]?.segments.findIndex((segment, segmentIndex) => {
				if (
					typeof currentSegmentIndex !== 'undefined' &&
					currentSegmentIndex >= segmentIndex
				) {
					return false;
				}
				const segmentPlacement = segment.segmentsPlacements?.find(
					placement =>
						placement.priceGroup === selectedItineraryOptions?.priceGroupValue
				);
				const segmentHasCompartments =
					segmentPlacement.placementCompartments.length;
				const segmentHasOrientations =
					segmentPlacement.placementOrientation.length;
				const segmentHasCharacteristics =
					segmentPlacement.placementCharacteristics.length;
				if (
					segmentHasCompartments ||
					segmentHasOrientations ||
					segmentHasCharacteristics
				) {
					return true;
				}
			});
		};
	return {
		currentStep,
		offerDetails,
		loading,
		transportDetails,
		transportPrice,
		transportPassengerString,
		selectedOptionsValid,
		setItineraryPriceOptions,
		setSegmentSeatPlacementOrientation,
		setSegmentSeatPlacementCompartment,
		setSegmentSeatPlacementCharacteristic,
		isSectionValid,
		findNextSegmentWithAccommodationInItineraryIndex,
		clearTransportDetails,
		selectedOptions,
		openSection,
		openFormSection
	};
};

export default useTrainTransportDetails;

const calculateTransportPrice = (
	transportDetails: ITransportDetails,
	selectedOptions
) => {
	let amount = 0;
	let currency = '';
	transportDetails.itineraries.forEach((itinerary, itineraryIndex) => {
		const selectedDepartureOptionsForItinerary =
			selectedOptions[itineraryIndex];

		if (!selectedDepartureOptionsForItinerary) {
			return;
		}
		const { priceGroupValue, flexibilityIndex } =
			selectedDepartureOptionsForItinerary;

		const priceGroup = itinerary.priceGroups.find(
			priceGroup => priceGroup.value === priceGroupValue
		);
		const basePrice = priceGroup.passengersPrices[0].basePrice.amount;
		currency = priceGroup.passengersPrices[0].basePrice.currency;

		amount += basePrice;

		const flexibilityPrice =
			priceGroup.passengersPrices[0].priceFlexibilities[flexibilityIndex].price
				.amount;

		amount += flexibilityPrice;
	});

	return { amount, currency };
};

const isTransportValid = (
	transportDetails: ITransportDetails,
	selectedOptions
) => {
	let departureOptionsValid = false;
	if (transportDetails && selectedOptions) {
		departureOptionsValid = true;
		transportDetails?.itineraries.forEach((itinerary, itineraryIndex) => {
			const selectedItineraryOptions = selectedOptions?.[itineraryIndex];

			if (
				!selectedItineraryOptions?.priceGroupValue ||
				typeof selectedItineraryOptions?.flexibilityIndex === undefined
			) {
				departureOptionsValid = false;
				return;
			}

			itinerary.segments.forEach((segment, segmentIndex) => {
				const selectedSegmentOptions =
					selectedItineraryOptions?.segments[segmentIndex];

				const segmentPlacement = segment.segmentsPlacements?.find(
					placement =>
						placement.priceGroup === selectedItineraryOptions.priceGroupValue
				);

				const segmentHasCompartments =
					segmentPlacement.placementCompartments.length;
				const segmentHasOrientations =
					segmentPlacement.placementOrientation.length;
				const segmentHasCharacteristics =
					segmentPlacement.placementCharacteristics.length;

				if (
					(segmentHasCompartments &&
						!selectedSegmentOptions?.compartmentValue) ||
					(segmentHasOrientations &&
						!selectedSegmentOptions?.orientationValue) ||
					(segmentHasCharacteristics &&
						!selectedSegmentOptions?.characteristicValue)
				) {
					departureOptionsValid = false;
					return;
				}
			});
		});
	}
	return departureOptionsValid;
};

const isTransportOptionSectionValid = (
	openSection,
	transportDetails: ITransportDetails,
	selectedOptions
) => {
	if (openSection) {
		if (openSection.type === 'ticketType') {
			const selectedItineraryOptions =
				selectedOptions?.[openSection?.itineraryIndex];

			if (
				selectedItineraryOptions?.priceGroupValue &&
				typeof selectedItineraryOptions?.flexibilityIndex !== undefined
			) {
				return true;
			}
		} else if (openSection.type === 'accommodation') {
			const selectedSegment =
				transportDetails?.itineraries?.[openSection?.itineraryIndex]?.segments[
					openSection?.segmentIndex
				];
			const selectedItineraryOptions =
				selectedOptions?.[openSection?.itineraryIndex];

			const selectedSegmentOptions =
				selectedItineraryOptions?.segments[openSection?.segmentIndex];

			if (!selectedSegmentOptions || !selectedItineraryOptions) {
				return false;
			}
			const segmentPlacement = selectedSegment.segmentsPlacements?.find(
				placement =>
					placement.priceGroup === selectedItineraryOptions.priceGroupValue
			);

			const segmentHasCompartments =
				segmentPlacement.placementCompartments.length;
			const segmentHasOrientations =
				segmentPlacement.placementOrientation.length;
			const segmentHasCharacteristics =
				segmentPlacement.placementCharacteristics.length;

			if (
				(segmentHasCompartments && !selectedSegmentOptions?.compartmentValue) ||
				(segmentHasOrientations && !selectedSegmentOptions?.orientationValue) ||
				(segmentHasCharacteristics &&
					!selectedSegmentOptions?.characteristicValue)
			) {
				return false;
			} else {
				return true;
			}
		}
	}

	return false;
};
