import { makeVar } from '@apollo/client';

export enum ProductType {
	Activity = 'Activity',
	Merchandise = 'Merchandise',
	Stay = 'Stay',
	Transportation = 'Transportation',
	Trainbus = 'Trainbus',
	Airplane = 'Airplane'
}

export type HostConfiguration = {
	hostId: string;
	hostUrl: string;
	name: string;
	logoUrl: string;
	productTypes: ProductType[];
};

export const initialValueHostConfiguration: HostConfiguration = undefined;

export const hostConfigurationVar = makeVar<HostConfiguration>(
	initialValueHostConfiguration
);
