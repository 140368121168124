import { useHistory } from 'react-router';

import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import { Paths } from 'router';

import {
	HeaderSubTitle,
	HeaderTitle,
	TravelPlannerLink
} from './StyledComponents';

const WishlistHeader = () => {
	const { t } = useIntl('wishlist');
	const { isLoggedIn } = useUserAuth();
	const history = useHistory();
	const goToTravelPlanner = () => history.push(Paths.TravelPlanner);

	return (
		<>
			<HeaderTitle>{t('yourWishlistHeader')}</HeaderTitle>
			<HeaderSubTitle>{t('wishlistSubtitle')} </HeaderSubTitle>
			{isLoggedIn && (
				<HeaderSubTitle>
					{t('wishlistSubtitleTravelPlannerLinkLabel')}{' '}
					<TravelPlannerLink onClick={goToTravelPlanner}>
						{t('wishlistSubtitleTravelPlannerLinkText')}
					</TravelPlannerLink>
					.
				</HeaderSubTitle>
			)}
		</>
	);
};

export default WishlistHeader;
