import { FunctionalComponent } from 'preact';
import { Field } from 'react-final-form';

import useCountryCodebookOptions from 'hooks/useCountryCodebookOptions';
import useIntl from 'hooks/useIntl';
import FieldSelect from 'redesigncomponents/FieldSelect';
import FieldTextInput from 'redesigncomponents/FieldTextInput';

import {
	TextInputSection,
	ThreeInputRow,
	TwoInputRow
} from '../../StyledComponents';
import FormSectionTitle from './FormSectionTitle';

interface IProps {
	rowLayout?: boolean;
}

const BillingDetailsFormSection: FunctionalComponent<IProps> = rowLayout => {
	const { t } = useIntl('checkout.billingDetailsFormSection');
	const { countryOptions } = useCountryCodebookOptions();

	return (
		<TextInputSection>
			<FormSectionTitle title={t('title')} />
			<TwoInputRow rowLayout>
				<Field
					label={t('firstNameLabel')}
					required
					name="firstNameBilling"
					id="firstNameBilling"
					component={FieldTextInput}
				/>
				<Field
					label={t('lastNameLabel')}
					required
					name="lastNameBilling"
					id="lastNameBilling"
					component={FieldTextInput}
				/>
			</TwoInputRow>
			<ThreeInputRow>
				<Field
					label={t('cityLabel')}
					required
					name="cityBilling"
					id="cityBilling"
					component={FieldTextInput}
				/>
				<Field
					label={t('zipCodeLabel')}
					required
					name="zipCodeBilling"
					id="zipCodeBilling"
					component={FieldTextInput}
				/>
				<Field
					label={t('countryLabel')}
					required
					name="countryBilling"
					id="countryBilling"
					component={FieldSelect}
					options={countryOptions}
					tertiary
					searchable
				/>
			</ThreeInputRow>
		</TextInputSection>
	);
};

export default BillingDetailsFormSection;
