import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Modal } from 'redesigncomponents/Modal';

const Spinner = styled.div`
	position: relative;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	box-shadow: inset 0 0 0 0.4rem;
	color: #243757;
	transform: rotate(90deg);
	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 1.95rem;
		height: 3.7rem;
		background: #fff;
	}
	&:before {
		border-radius: 3.7rem 0rem 0rem 3.7rem;
		top: -0.1rem;
		left: -0.1rem;
		transform-origin: 1.85rem 1.85rem;
		animation: spin 1.2s infinite ease 0.3s;
	}
	&:after {
		border-radius: 0rem 3.7rem 3.7rem 0rem;
		top: -0.1rem;
		left: 1.65rem;
		transform-origin: 0.1rem 1.85rem;
		animation: spin 1.2s infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

interface Props {
	isOpen: boolean;
}

const LoadingModal: FunctionalComponent<Props> = ({ isOpen }) => {
	return (
		<Modal
			isOpen={isOpen}
			dialogCustomStyle={{
				width: '100%',
				display: 'flex',
				'padding-block': '50px',
				'align-items': 'center',
				'justify-content': 'center'
			}}
		>
			<Spinner />
		</Modal>
	);
};

export default LoadingModal;
