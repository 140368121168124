import { makeVar } from '@apollo/client';

export type Destination = {
  label: string;
  value: string | number;
  selected: boolean;
  id: string | number;
  longitude: number;
  latitude: number;
};

export type DestinationAndPeriod = {
  destinations: Array<Destination>;
  travelPeriod: Array<Date>;
};

const destinationAndPeriod: DestinationAndPeriod = {
  destinations: undefined,
  travelPeriod: undefined,
};

export const destinationAndPeriodVar =
  makeVar<DestinationAndPeriod>(destinationAndPeriod);
