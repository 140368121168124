import { useMemo } from 'react';

import dayjs from 'dayjs';

import { ITravelPlan } from 'redesigncache/checkout/types';
import { WishlistItemTypes } from 'redesigncache/wishlist/types';
import {
	IActivityWishlistItem,
	IStayWishlistItem,
	ITransportWishlistItem
} from 'redesigncache/wishlist/types';

interface ItemLocationAndPeriod {
	dateFrom: string;
	dateTo?: string;
	location?: string | null;
}

const findObjectWithEarliestDate = (data: ItemLocationAndPeriod[]) => {
	return data.reduce((prev, current) =>
		dayjs(prev.dateFrom).isBefore(dayjs(current.dateFrom)) ? prev : current
	);
};

const prioritizedTransport = transportTravelPlans => {
	const transportDatesLocations = transportTravelPlans.map(plan => {
		const transportItem = plan.item as ITransportWishlistItem;

		return {
			dateFrom: dayjs(transportItem.date).format(),
			location: `${transportItem.city_from} - ${transportItem.city_to}`
		};
	});

	const prioritizedItem = findObjectWithEarliestDate(transportDatesLocations);

	return `${prioritizedItem.location}, ${dayjs(prioritizedItem.dateFrom).format(
		'DD.MM.'
	)}`;
};

const prioritizedAccommodation = accommodationTravelPlans => {
	const accommodationDatesLocations = accommodationTravelPlans.map(plan => {
		const accommodationItem = plan.item as IStayWishlistItem;

		return {
			dateFrom: dayjs(accommodationItem.travelPeriod[0]).format(),
			dateTo: dayjs(accommodationItem.travelPeriod[1]).format(),
			location: accommodationItem?.city
		};
	});

	const prioritizedItem = findObjectWithEarliestDate(
		accommodationDatesLocations
	);

	return `${prioritizedItem.location}, ${dayjs(prioritizedItem.dateFrom).format(
		'DD.MM.'
	)} - ${dayjs(prioritizedItem.dateTo).format('DD.MM.')}`;
};

const prioritizedActivity = activityTravelPlans => {
	const activityDatesLocations = activityTravelPlans.map(plan => {
		const activityItem = plan.item as IActivityWishlistItem;

		return {
			dateFrom: dayjs(activityItem.date).format(),
			location: activityItem?.city
		};
	});

	const prioritizedItem = findObjectWithEarliestDate(activityDatesLocations);

	return `${
		prioritizedItem.location ? `${prioritizedItem.location}, ` : ''
	}${dayjs(prioritizedItem.dateFrom).format('DD.MM.')}`;
};

const useTravelPlanLocationPeriod = (travelPlan: ITravelPlan) => {
	const travelPlanLocationAndPeriod = useMemo(() => {
		const transportTravelPlans = travelPlan?.travelPlanItems?.filter(
			plan => plan.type === WishlistItemTypes.TRANSPORT
		);
		const accommodationTravelPlans = travelPlan?.travelPlanItems?.filter(
			plan => plan.type === WishlistItemTypes.STAY
		);
		const activityTravelPlans = travelPlan?.travelPlanItems?.filter(
			plan => plan.type === WishlistItemTypes.ACTIVITY
		);

		if (transportTravelPlans.length) {
			return prioritizedTransport(transportTravelPlans);
		} else if (accommodationTravelPlans.length) {
			return prioritizedAccommodation(accommodationTravelPlans);
		} else if (activityTravelPlans.length) {
			return prioritizedActivity(activityTravelPlans);
		}
	}, [travelPlan]);

	return { travelPlanLocationAndPeriod };
};

export default useTravelPlanLocationPeriod;
