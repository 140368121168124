import DOMPurify from 'dompurify';

const sanitizeContent = (description: string) => {
	return DOMPurify.sanitize(description, {
		FORBID_TAGS: [
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'style',
			'a',
			'link',
			'iframe',
			'script'
		],
		FORBID_ATTR: ['style']
	});
};
export default sanitizeContent;
