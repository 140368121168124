import styled from 'styled-components';
import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

export const RadioContainer = styled.div`
  display: flex;
  background-color: ${Colors.V0};
  padding: 0.25rem;
  border-radius: 2rem;
  padding: 0.25rem 0.75rem 0.1rem 0.25rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Colors.V50};
`;

export const SingleRadio = styled.p`
  color: ${Colors.V900};
  display: flex;
  gap: 0.5rem;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  transform: translateY(-0.075em);
  border: 1px solid ${Colors.V900};
  background-color: ${Colors.V0};
  display: grid;
  place-content: center;
	&::before {
		content: "";
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background-color: ${Colors.V30};
	}
	&:checked::before {
    background-color: ${Colors.V900};
	}

`;

export const InputLabel = styled.label`
  font-size: ${FontSize.smSize};
  font-weight: ${FontWeight.semibold};
  white-space: nowrap;
  padding-right: 0.5rem;
`;

export const PriceContainer = styled.div`
  border-left: 1px solid ${Colors.V50};
`;

export const PriceValue = styled.span`
  font-size: ${FontSize.smSize};
  font-weight: ${FontWeight.normal};
  padding-left: 2rem;
  display: flex;
  white-space: nowrap;
`;