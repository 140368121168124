import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonFlexContainer,
	Modal,
	OptionModalText,
	OptionModalTitle
} from 'redesigncomponents/Modal';

import useRemoveTravelPlanItem from '../hooks/useRemoveTravelPlanItem';

const RemoveItemConfirmationModal = ({
	toggleOpen,
	travelPlanItem,
	travelPlanId
}) => {
	const { t } = useIntl('checkout.removeItemConfirmationModal');
	const { removeTravelPlanItem } = useRemoveTravelPlanItem(
		travelPlanItem,
		travelPlanId
	);

	return (
		<Modal isOpen={true} onClose={toggleOpen}>
			<OptionModalTitle>{t('title')}</OptionModalTitle>

			<OptionModalText>{t('content')}</OptionModalText>

			<ButtonFlexContainer>
				<Button
					title={t('cancelButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={toggleOpen}
				></Button>
				<Button
					title={t('confirmButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={() => {
						removeTravelPlanItem();
						toggleOpen();
					}}
				></Button>
			</ButtonFlexContainer>
		</Modal>
	);
};

export default RemoveItemConfirmationModal;
