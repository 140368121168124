import { FunctionalComponent } from 'preact';

import useItemIcon from 'hooks/useItemIcon';
import IconValue from 'redesigncomponents/IconValue';
import { IconValue as IIconValue } from 'redesigncomponents/IconValue/types';
import { IComposedTravelPlanItem } from 'redesignscreens/Checkout/components/TravelPlan/useTravelPlan';

import {
	FormHeader,
	FormHeaderLeft,
	FormHeaderRight,
	FormHeaderTitle,
	InfoSpacer,
	InfoWrapper,
	LogoBackground,
	Spacer
} from './StyledComponents';
import useTravelPlanItemHeader, {
	isInfoSpacer
} from './useTravelPlanItemHeader';

interface IProps {
	travelPlanItem: IComposedTravelPlanItem;
}
const TravelPlanItemHeader: FunctionalComponent<IProps> = ({
	travelPlanItem,
	children
}) => {
	const { title, infos } = useTravelPlanItemHeader(travelPlanItem);
	const { Icon } = useItemIcon(travelPlanItem.type, travelPlanItem.item);

	return (
		<FormHeader>
			<FormHeaderLeft>
				<LogoBackground>
					<Icon />
				</LogoBackground>
				<FormHeaderTitle>{title}</FormHeaderTitle>
			</FormHeaderLeft>
			<FormHeaderRight>
				<InfoWrapper>
					{infos.map(info => {
						return isInfoSpacer(info) ? (
							<InfoSpacer key={info.key} />
						) : (
							<IconValue key={info.key} Icon={info.icon} value={info.value} />
						);
					})}
				</InfoWrapper>
				<Spacer />
				{children}
			</FormHeaderRight>
		</FormHeader>
	);
};

export default TravelPlanItemHeader;
