import { useMutation } from '@apollo/client';
import { useReducer } from 'preact/hooks';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { GET_INTEGRATIONS_TRANSPORTATION_OFFER } from 'apollo/myDesti/mutations';
import { PAGE_DEFAULT, PAGE_LIMIT } from 'config/network';
import { Option } from 'redesigntypes/Option';
import { TransportType } from 'types/enums';

import {
	OptionCategoryTypes,
	TrainbusOptionCategoriesType
} from '../components/OptionCategories/useOptionCategories';

dayjs.extend(isToday);

const useLoadTransportationOffers = () => {
	const [state, dispatch] = useReducer(resultReducer, initialResultState);
	const { departureResults, returnResults } = state;

	const [
		getTransportationOffer,
		{ loading: loadingTransportOffer, called: calledLoadTransportOffer }
	] = useMutation(GET_INTEGRATIONS_TRANSPORTATION_OFFER, {});

	const loadTransportationOffer = async (
		from,
		to,
		departureDate,
		optionCategories,
		page,
		sort
	) => {
		const { data } = await getTransportationOffer({
			variables: {
				cabinBagCount: 0,
				checkedBagCount: 0,
				originLocationName: from.value,
				destinationLocationName: to.value,
				departureDate: departureDate,

				nonStop: false,
				hasPets: !!optionCategories.addons.pets.count,
				transportationType: [TransportType.TrainBus],
				passengers: getPassengersForSearch(optionCategories),
				travelClassMix: false,
				pageNumber: page,
				pageSize: PAGE_LIMIT,
				orderBy: sort?.value
			}
		});
		return data;
	};

	const loadDepartureTransportationOffer = async (
		departureDate,
		returnDate,
		fromLocation: Option,
		toLocation: Option,
		optionCategories: TrainbusOptionCategoriesType,
		page: number,
		transportSort: Option,
		tripType: Option
	) => {
		if (page === PAGE_DEFAULT) {
			// setting the values for the currently searched trip
			dispatch({
				type: 'SET_DEPARTURE_RESULTS',
				payload: {
					currentPage: 1,
					totalCount: undefined,
					startDate: departureDate,
					tripType: tripType
				}
			});
			dispatch({
				type: 'SET_RETURN_RESULTS',
				payload: {
					currentPage: 1,
					totalCount: undefined,
					startDate: returnDate,
					tripType: tripType
				}
			});
		}

		let startDate = dayjs(departureDate).isToday()
			? dayjs.utc(dayjs(departureDate)).local().add(10, 'minutes')
			: dayjs.utc(dayjs(departureDate)).local().startOf('day');
		if (dayjs().isAfter(startDate)) {
			startDate = dayjs.utc(dayjs(departureDate)).local().add(10, 'minutes');
		}

		try {
			const data = await loadTransportationOffer(
				fromLocation,
				toLocation,
				startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
				optionCategories,
				page,
				transportSort
			);

			handleDepartureResultList(data, page);
		} catch {}
	};

	const handleDepartureResultList = (resultList, page: number) => {
		const {
			railOffer: { data, totalCount, totalPages }
		} = resultList.getTransportation;

		const mappedTransports = mapRailResults(data);

		if (page === PAGE_DEFAULT) {
			dispatch({
				type: 'SET_DEPARTURE_RESULTS',
				payload: {
					transportOffer: mappedTransports,
					hasMore: page < totalPages,
					currentPage: page,
					totalCount: totalCount
				}
			});
		} else {
			dispatch({
				type: 'SET_DEPARTURE_RESULTS',
				payload: {
					transportOffer: [
						...departureResults.transportOffer,
						...mappedTransports
					],
					hasMore: page < totalPages,
					currentPage: page,
					totalCount: totalCount
				}
			});
		}
	};

	const loadReturnTransportationOffer = async (
		returnDate,
		fromLocation: Option,
		toLocation: Option,
		optionCategories: TrainbusOptionCategoriesType,
		page: number,
		transportSort: Option
	) => {
		const startDate = dayjs.utc(dayjs(returnDate)).local().startOf('date');

		const data = await loadTransportationOffer(
			fromLocation,
			toLocation,
			startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
			optionCategories,
			page,
			transportSort
		);

		handleReturnResultList(data, page);
	};

	const handleReturnResultList = (resultList, page: number) => {
		const {
			railOffer: { data, totalCount, totalPages }
		} = resultList.getTransportation;

		const mappedTransports = mapRailResults(data);

		if (page === PAGE_DEFAULT) {
			dispatch({
				type: 'SET_RETURN_RESULTS',
				payload: {
					transportOffer: mappedTransports,
					hasMore: page < totalPages,
					currentPage: page,
					totalCount: totalCount
				}
			});
		} else {
			dispatch({
				type: 'SET_RETURN_RESULTS',
				payload: {
					transportOffer: [
						...(returnResults.transportOffer || []),
						...mappedTransports
					],
					hasMore: page < totalPages,
					currentPage: page,
					totalCount: totalCount
				}
			});
		}
	};

	const mapRailResults = offers => {
		return offers.map(item => {
			const segments = item.itineraries.flatMap(
				itinerary => itinerary.segments
			);

			return {
				timetable_id: item.id,
				transport_line: `${segments[0]?.departureLocation.name}-${
					segments[segments.length - 1].arrivalLocation.name
				}`,
				transport_type: segments[0]?.travelMethodCode?.title,
				operator_names: segments.map(s => s.segmentProducerCode.title),
				departure_time: segments[0]?.departureDateTime,
				departure_station_code: segments[0]?.departureLocation.name,
				arrival_time: segments[segments.length - 1]?.arrivalDateTime,
				arrival_station_code:
					segments[segments.length - 1]?.arrivalLocation.name,
				price: item.fromPrice,
				transport_item: item,
				trip_info: segments[0]?.transportInformation?.join(),
				duration: item.duration,
				unique_transport_id: item.uniqueTransportId
			};
		});
	};

	return {
		calledLoadTransportOffer,
		loadingTransportOffer,

		loadDepartureTransportationOffer,
		loadReturnTransportationOffer,

		offersTripType: departureResults.tripType,
		departureTransportOffer: departureResults.transportOffer,
		departureCurrentPage: departureResults.currentPage,
		departureTotalCount: departureResults.totalCount,
		departureHasMore: departureResults.hasMore,
		departureStartDate: departureResults.startDate,

		returnTransportOffer: returnResults.transportOffer,
		returnCurrentPage: returnResults.currentPage,
		returnTotalCount: returnResults.totalCount,
		returnHasMore: returnResults.hasMore,
		returnStartDate: returnResults.startDate
	};
};

export default useLoadTransportationOffers;

export const getPassengersForSearch = (
	optionCategories: TrainbusOptionCategoriesType
) => {
	const passengers = [];
	let idIndex = 1;

	Object.entries(optionCategories[OptionCategoryTypes.passengers]).forEach(
		([key, value]) => {
			if (value.count > 0) {
				passengers.unshift(
					...Array.from({ length: value.count as number }).map((_, index) => ({
						id: `${idIndex++}`,
						age: value.ages?.[index] || 33,
						passengerCategoryId: key
					}))
				);
			}
		}
	);

	return passengers;
};

export const getPassengersAndAddonsString = (
	optionCategories: TrainbusOptionCategoriesType
) => {
	const stringSegments = [];
	Object.entries(optionCategories[OptionCategoryTypes.passengers]).map(
		([_, value]) => {
			if (value.count) {
				switch (value.keyName) {
					case 'VU': {
						stringSegments.push(`${value.count}x Adult`);
						break;
					}
					case 'BO': {
						stringSegments.push(`${value.count}x Child/Youth`);
						break;
					}
					case 'SU': {
						stringSegments.push(`${value.count}x Student`);
						break;
					}
					case 'SE': {
						stringSegments.push(`${value.count}x Senior`);
						break;
					}
					case 'PS': {
						stringSegments.push(`${value.count}x Retired`);
						break;
					}
				}
			}
		}
	);

	const petCount = optionCategories[OptionCategoryTypes.addons].pets?.count;
	if (petCount) {
		stringSegments.push(`${petCount}x Pet`);
	}

	return stringSegments.join(',');
};

export const getPassengerAdultCount = (
	optionCategories: TrainbusOptionCategoriesType
) => {
	let adultCount = 0;
	Object.entries(optionCategories[OptionCategoryTypes.passengers]).map(
		([_, value]) => {
			if (value.count && ['VU', 'SU', 'SE', 'PS'].includes(value.keyName)) {
				adultCount += value.count;
			}
		}
	);

	return adultCount;
};

export const getPassengerYouthCount = (
	optionCategories: TrainbusOptionCategoriesType
) => {
	let youthCount = 0;
	Object.entries(optionCategories[OptionCategoryTypes.passengers]).map(
		([_, value]) => {
			if (value.count && 'BO' === value.keyName) {
				youthCount += value.count;
			}
		}
	);

	return youthCount;
};

type TransportResult = {
	transportOffer: Array<any>;
	currentPage: number;
	totalCount: number;
	hasMore: boolean;
	tripType?: Option;
	startDate?: Date;
};

type TransportResultsType = {
	departureResults: TransportResult;
	returnResults: TransportResult;
};

const initialResultState: TransportResultsType = {
	departureResults: {
		transportOffer: undefined,
		currentPage: PAGE_DEFAULT,
		totalCount: undefined,
		hasMore: true,
		tripType: undefined,
		startDate: undefined
	},
	returnResults: {
		transportOffer: undefined,
		currentPage: PAGE_DEFAULT,
		totalCount: undefined,
		hasMore: true,
		tripType: undefined,
		startDate: undefined
	}
};

const resultReducer = (
	state: TransportResultsType,
	action
): TransportResultsType => {
	switch (action.type) {
		case 'SET_DEPARTURE_RESULTS':
			return {
				...state,
				departureResults: { ...state.departureResults, ...action.payload }
			};
		case 'SET_RETURN_RESULTS':
			return {
				...state,
				returnResults: { ...state.returnResults, ...action.payload }
			};

		default:
		// IGNORED
	}
};
