import { useEffect, useMemo } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import useQueryParams from 'hooks/useQueryParams';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import Button, {
	ButtonStyleTypes,
	LoadingStyleTypes
} from 'redesigncomponents/Button';
import SearchSelectComponent from 'redesigncomponents/FieldSearchSelect/StyledSearchSelect';
import { Option } from 'redesigntypes/Option';
import { TransportTripType, DetailTransportTypes } from 'types/enums';

import LocationIcon from 'assets/location.svg';
import TargetIcon from 'assets/target.svg';

import useTransportSearchOptionsCache from '../../cache/useTransportSearchCache';
import useLoadLocations from '../../hooks/useLoadLocations';
import OptionCategories from '../OptionCategories';
import {
	HalfRowWidth,
	InputOptionsContainer,
	InputOptionsRow
} from '../StyledComponents';
import TransportTypeSelect from '../TransportTypeSelect';
import TripDatePicker from '../TripDatepicker';
import TripTypeSelect from '../TripTypeSelect';

const AirplaneSearchOptions = ({
	searchTransportOffers,
	loadingTransportOffer
}) => {
	const { t: transportSearchTranslations } = useIntl('transportList');
	const history = useHistory();
	const { pathname } = useLocation();
	const { destination: queryParamsDestination } = useQueryParams([
		'destination'
	]);
	const { destinations } = useDestinationAndPeriod();
	const selectedDestination = destinations && destinations[0]?.label;
	const destination = selectedDestination || queryParamsDestination;

	const { loadLocationOptions } = useLoadLocations();
	const {
		transportType,
		fromLocation,
		toLocation,
		travelPeriod,
		tripType,
		airClass,
		optionCategories,
		setFromLocation,
		setToLocation,
		setTravelPeriod,
		setTripType,
		setOptionCategories
	} = useTransportSearchOptionsCache();

	useEffect(() => {
		//resetTransportSearchOptionsCache();
		if (destination) {
			const setInitialToLocation = async () => {
				try {
					const initialOptions = await loadLocationOptions(destination);
					const initialToLocation = initialOptions?.[0];

					setToLocation(initialToLocation);
				} catch {
					//ignored
				}
			};

			setInitialToLocation();
		}
	}, []);

	const searchParametersValid = useMemo(() => {
		const travelPeriodValid =
			tripType?.value === TransportTripType.OneWay
				? travelPeriod?.[0]
				: travelPeriod?.every(d => d);
		return (
			fromLocation &&
			toLocation &&
			transportType &&
			tripType &&
			travelPeriodValid
		);
	}, [
		fromLocation,
		toLocation,
		travelPeriod,
		transportType,
		tripType,
		airClass
	]);

	useEffect(() => {
		if (searchParametersValid) {
			searchTransportOffers();
		}
	}, []);

	const onTransportTypeChange = (transportType: Option) => {
		if (transportType.value === DetailTransportTypes.Airplane) {
			history.replace(
				pathname.includes('/affiliate-link/')
					? '/affiliate-link/transport-search/air'
					: '/transport/air'
			);
		}
	};

	return (
		<InputOptionsContainer>
			<InputOptionsRow>
				<HalfRowWidth>
					<TransportTypeSelect
						value={transportType}
						onChange={onTransportTypeChange}
					/>
					<TripTypeSelect value={tripType} onChange={setTripType} />
				</HalfRowWidth>
				<HalfRowWidth>
					<OptionCategories
						value={optionCategories}
						onChange={setOptionCategories}
					/>
				</HalfRowWidth>
			</InputOptionsRow>
			<InputOptionsRow>
				<HalfRowWidth>
					<SearchSelectComponent
						label={transportSearchTranslations('options.fromSelectLabel')}
						inputProps={{ value: fromLocation, onChange: setFromLocation }}
						loadOptions={loadLocationOptions}
						clearable
						DropdownIndicatorComponent={LocationIcon}
						autoFocus
					/>
					<SearchSelectComponent
						label={transportSearchTranslations('options.toSelectLabel')}
						inputProps={{ value: toLocation, onChange: setToLocation }}
						loadOptions={loadLocationOptions}
						clearable
						DropdownIndicatorComponent={TargetIcon}
					/>
				</HalfRowWidth>
				<HalfRowWidth>
					<TripDatePicker
						tripType={tripType?.value as TransportTripType}
						travelPeriod={travelPeriod}
						setTravelPeriod={setTravelPeriod}
					/>
					<Button
						title={transportSearchTranslations('options.searchButtonTitle')}
						buttonStyleType={ButtonStyleTypes.primary}
						loadingStyleType={LoadingStyleTypes.progress}
						onClick={searchTransportOffers}
						disabled={!searchParametersValid}
						loading={loadingTransportOffer}
						style={{ width: '100px' }}
					></Button>
				</HalfRowWidth>
			</InputOptionsRow>
		</InputOptionsContainer>
	);
};

export default AirplaneSearchOptions;
