import { useMemo, useState } from 'preact/hooks';

import useIntl from 'hooks/useIntl';

import BaggageIcon from 'assets/baggage.svg';
import AdultsIcon from 'assets/icons/counter/adults.svg';

export enum OptionCategoryTypes {
	passengers = 'passengers',
	addons = 'addons'
}

export type OptionCategoriesType = {
	[key: string]: { [key: string]: number };
};

const optionCategoryTypeIconMap = {
	[OptionCategoryTypes.passengers]: AdultsIcon,
	[OptionCategoryTypes.addons]: BaggageIcon
};

export const initialValuesOptionCategories = {
	[OptionCategoryTypes.passengers]: {
		adults: 2,
		children: 0,
		infants: 0
	},
	[OptionCategoryTypes.addons]: {
		cabinBaggage: 0,
		checkedBaggage: 0
	}
};

const minCountForCategory = {
	[OptionCategoryTypes.passengers]: {
		adults: 1,
		children: 0,
		infants: 0
	},
	[OptionCategoryTypes.addons]: {
		cabinBaggage: 0,
		checkedBaggage: 0
	}
};

const ageRangesForPassengerCategory = {
	adults: {
		min: 11,
		max: undefined
	},
	children: {
		min: 2,
		max: 11
	},
	infants: {
		min: undefined,
		max: 2
	}
};

const maxCountPerCategoryType = {
	[OptionCategoryTypes.passengers]: 9
}; // Tequila limitation

const useOptionCategories = () => {
	const { t } = useIntl('common.airplaneOptionCategories');

	const [optionCategories, setOptionCategories] = useState(
		initialValuesOptionCategories
	);

	const countPerOptionCategoryType = useMemo(() => {
		const count = {};
		Object.keys(optionCategories).forEach(categoryTypeKey => {
			const categoryType = optionCategories[categoryTypeKey];

			count[categoryTypeKey] = Object.values(categoryType).reduce(
				(accummulator: number, currentValue: number) =>
					accummulator + currentValue,
				0
			);
		});

		return count;
	}, [optionCategories]);

	const getCategorySubtitle = (passengerCategory: string) => {
		const ageRangeForCategory =
			ageRangesForPassengerCategory[passengerCategory];

		if (!ageRangeForCategory) return;

		const { min, max } = ageRangeForCategory;

		if (typeof min === 'undefined' && max)
			return t('ageRange.under', { years: max }, max);

		if (typeof max === 'undefined')
			return t('ageRange.over', { years: min }, min);

		return t('ageRange.between', { yearsMin: min, yearsMax: max });
	};

	const optionCategoriesArray = useMemo(() => {
		return Object.keys(optionCategories).map(categoryTypeKey => {
			const categoryType = optionCategories[categoryTypeKey];

			return {
				id: categoryTypeKey,
				title: t(`${categoryTypeKey}.title`),
				Icon: optionCategoryTypeIconMap[categoryTypeKey],
				categories: Object.keys(categoryType).map(categoryKey => {
					const numberOfSelectedCategoryTickets = categoryType[categoryKey];
					[categoryKey];

					return {
						id: categoryKey,
						title: t(`${categoryTypeKey}.categories.${categoryKey}`),
						subtitle: getCategorySubtitle(categoryKey),
						min: minCountForCategory[categoryTypeKey][categoryKey],
						max: maxCountForCategoryHandlers[categoryTypeKey]?.[categoryKey]?.(
							optionCategories
						), // every category has its own max count handler
						value: numberOfSelectedCategoryTickets
					};
				}),
				count: countPerOptionCategoryType[categoryTypeKey]
			};
		});
	}, [optionCategories, countPerOptionCategoryType]);

	const updateCategoryCount = (categoryTypeId, categoryId, value) => {
		setOptionCategories(
			updateCountForCategoryHandlers[categoryTypeId]?.[categoryId]?.(
				optionCategories,
				value
			)
		);
	};

	const resetOptionCategories = optionCategories => {
		setOptionCategories(optionCategories);
	};

	return {
		optionCategories,
		optionCategoriesArray,
		updateCategoryCount,
		resetOptionCategories
	};
};

export default useOptionCategories;

const updateCountForCategoryHandlers = {
	[OptionCategoryTypes.passengers]: {
		adults: (prevOptionCategories, newValue) => {
			const updatedOptionCategories = { ...prevOptionCategories };
			// update adults category value
			updatedOptionCategories[OptionCategoryTypes.passengers]['adults'] =
				newValue;

			// update infants category value - there can only be the same number of infants as the number of adults
			const infantsMaxCount = maxCountForCategoryHandlers[
				OptionCategoryTypes.passengers
			]['infants']?.(updatedOptionCategories);

			updatedOptionCategories[OptionCategoryTypes.passengers]['infants'] =
				Math.min(
					infantsMaxCount,
					updatedOptionCategories[OptionCategoryTypes.passengers]['infants']
				);

			// update cabinBaggage category value - there can only be cabin baggage as the total number of adults and children
			const cabinBaggageMaxCount = maxCountForCategoryHandlers[
				OptionCategoryTypes.addons
			]['cabinBaggage']?.(updatedOptionCategories);

			updatedOptionCategories[OptionCategoryTypes.addons]['cabinBaggage'] =
				Math.min(
					cabinBaggageMaxCount,
					updatedOptionCategories[OptionCategoryTypes.addons]['cabinBaggage']
				);

			// update checkedBaggage category value - there can only be cabin baggage as the total number of adults and children
			const checkedBaggageMaxCount = maxCountForCategoryHandlers[
				OptionCategoryTypes.addons
			]['checkedBaggage']?.(updatedOptionCategories);

			updatedOptionCategories[OptionCategoryTypes.addons]['checkedBaggage'] =
				Math.min(
					checkedBaggageMaxCount,
					updatedOptionCategories[OptionCategoryTypes.addons]['checkedBaggage']
				);

			return updatedOptionCategories;
		},
		children: (prevOptionCategories, newValue) => {
			const updatedOptionCategories = { ...prevOptionCategories };
			// update adults category value
			updatedOptionCategories[OptionCategoryTypes.passengers]['children'] =
				newValue;

			// update cabinBaggage category value - there can only be cabin baggage as the total number of adults and children
			const cabinBaggageMaxCount = maxCountForCategoryHandlers[
				OptionCategoryTypes.addons
			]['cabinBaggage']?.(updatedOptionCategories);

			updatedOptionCategories[OptionCategoryTypes.addons]['cabinBaggage'] =
				Math.min(
					cabinBaggageMaxCount,
					updatedOptionCategories[OptionCategoryTypes.addons]['cabinBaggage']
				);

			// update checkedBaggage category value - there can only be cabin baggage as the total number of adults and children
			const checkedBaggageMaxCount = maxCountForCategoryHandlers[
				OptionCategoryTypes.addons
			]['checkedBaggage']?.(updatedOptionCategories);

			updatedOptionCategories[OptionCategoryTypes.addons]['checkedBaggage'] =
				Math.min(
					checkedBaggageMaxCount,
					updatedOptionCategories[OptionCategoryTypes.addons]['checkedBaggage']
				);

			return updatedOptionCategories;
		},
		infants: (prevOptionCategories, newValue) => {
			const updatedOptionCategories = { ...prevOptionCategories };
			// update infants category value
			updatedOptionCategories[OptionCategoryTypes.passengers]['infants'] =
				newValue;

			return updatedOptionCategories;
		}
	},
	[OptionCategoryTypes.addons]: {
		cabinBaggage: (prevOptionCategories, newValue) => {
			const updatedOptionCategories = { ...prevOptionCategories };
			// update cabinBaggage category value
			updatedOptionCategories[OptionCategoryTypes.addons]['cabinBaggage'] =
				newValue;

			return updatedOptionCategories;
		},
		checkedBaggage: (prevOptionCategories, newValue) => {
			const updatedOptionCategories = { ...prevOptionCategories };
			// update checkedBaggage category value
			updatedOptionCategories[OptionCategoryTypes.addons]['checkedBaggage'] =
				newValue;

			return updatedOptionCategories;
		}
	}
};

const maxCountForCategoryHandlers = {
	[OptionCategoryTypes.passengers]: {
		adults: optionCategories => {
			const categoryTypeMaxCount: number =
				maxCountPerCategoryType[OptionCategoryTypes.passengers];
			const categoryTypeCount: number = getCountForCategoryType(
				optionCategories,
				OptionCategoryTypes.passengers
			);

			return (
				categoryTypeMaxCount -
				categoryTypeCount +
				optionCategories[OptionCategoryTypes.passengers]['adults']
			);
		},
		children: optionCategories => {
			const categoryTypeMaxCount =
				maxCountPerCategoryType[OptionCategoryTypes.passengers];
			const categoryTypeCount: number = getCountForCategoryType(
				optionCategories,
				OptionCategoryTypes.passengers
			);

			return (
				categoryTypeMaxCount -
				categoryTypeCount +
				optionCategories[OptionCategoryTypes.passengers]['children']
			);
		},
		infants: optionCategories => {
			const categoryTypeMaxCount =
				maxCountPerCategoryType[OptionCategoryTypes.passengers];
			const categoryTypeCount: number = getCountForCategoryType(
				optionCategories,
				OptionCategoryTypes.passengers
			);
			const adultsCount =
				optionCategories[OptionCategoryTypes.passengers]['adults'];

			return Math.min(
				categoryTypeMaxCount -
					categoryTypeCount +
					optionCategories[OptionCategoryTypes.passengers]['infants'],
				adultsCount
			);
		}
	},
	[OptionCategoryTypes.addons]: {
		cabinBaggage: optionCategories => {
			const adultsCount =
				optionCategories[OptionCategoryTypes.passengers]['adults'];
			const childrenCount =
				optionCategories[OptionCategoryTypes.passengers]['children'];

			return adultsCount + childrenCount;
		},
		checkedBaggage: optionCategories => {
			const adultsCount =
				optionCategories[OptionCategoryTypes.passengers]['adults'];
			const childrenCount =
				optionCategories[OptionCategoryTypes.passengers]['children'];

			return 2 * (adultsCount + childrenCount);
		}
	}
};

const getCountForCategoryType = (optionCategories, categoryType) =>
	Object.values(optionCategories[categoryType]).reduce(
		(accummulator: number, currentValue: number) =>
			accummulator + (currentValue || 0),
		0
	) as number;
