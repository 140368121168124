import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'preact/hooks';

import { CHECK_AVAILABILTY_INTEGRATIONS_TRANSPORT } from 'apollo/myDesti/mutations';
import { ITransportWishlistItem } from 'redesigncache/wishlist/types';

import { IComposedTravelPlanItem } from '../../../TravelPlan/useTravelPlan';
import useUpdateTravelPlanItem from '../../hooks/useUpdateTravelPlanItem';

const checkAvailabilityIntervalMs = 20000;

const useCheckFlightAvailabilityAndPrice = (
	travelPlanId,
	travelPlanItem: IComposedTravelPlanItem
) => {
	const [lastCheckTime, setLastCheckTime] = useState<number>();
	const [flightInvalid, setFlightInvalid] = useState<boolean>(false);
	const [flightPriceChanged, setFlightPriceChanged] = useState<boolean>(false);

	const [checkAvailability] = useMutation(
		CHECK_AVAILABILTY_INTEGRATIONS_TRANSPORT,
		{}
	);
	const { setTravelPlanItemExpired, setTravelPlanItemPrice } =
		useUpdateTravelPlanItem(travelPlanItem, travelPlanId);

	useEffect(() => {
		if ((travelPlanItem.item as ITransportWishlistItem).booking_token) {
			setLastCheckTime(Date.now());
		}
	}, []);

	useEffect(() => {
		let timeout = undefined;
		if ((travelPlanItem.item as ITransportWishlistItem).booking_token) {
			callRefreshFlightAvailabilityAndPrice();
			if (travelPlanItem.paid || flightInvalid) return;

			timeout = setTimeout(
				() => setLastCheckTime(Date.now()),
				checkAvailabilityIntervalMs
			);
		}

		return () => timeout && clearTimeout(timeout);
	}, [lastCheckTime]);

	const callRefreshFlightAvailabilityAndPrice = () => {
		const item = travelPlanItem.item as ITransportWishlistItem;

		checkAvailability({
			variables: {
				id: item.booking_token,
				bagCount: item.bags,
				passengers: item.passengers
			}
		})
			.then(({ data }) => {
				const check = data.checkTransportationAvailability;

				setFlightInvalid(check.flightsInvalid);
				if (check.flightsInvalid) {
					setTravelPlanItemExpired();
				}

				if (check.priceChange && item.price.amount !== check.price.amount) {
					setFlightPriceChanged(true);
					setTravelPlanItemPrice(check.price.amount);
				}
			})
			.catch(() => {
				setFlightInvalid(true);
			});
	};

	return {
		flightInvalid,
		flightPriceChanged,
		callRefreshFlightAvailabilityAndPrice
	};
};

export default useCheckFlightAvailabilityAndPrice;
