import { IntlContext, translate } from 'preact-i18n';
import { useCallback, useContext } from 'preact/hooks';

const useIntl = (scope: any) => {
	const {
		intl: { dictionary }
	} = useContext<any>(IntlContext);
	const t = useCallback((id, fields = undefined, plural = undefined) => {
		return translate(id, scope, dictionary, fields, plural);
	}, []);

	return { t };
};

export default useIntl;
