import { useMutation } from '@apollo/client';
import { useState } from 'preact/hooks';

import { GET_INTEGRATION_AVAILABLE_ACCOMMODATIONS } from 'apollo/myDesti/mutations';
import { PAGE_LIMIT } from 'config/network';
import { AccommodationListModel } from 'redesigntypes/AccommodationListModel';

const useAccommodationSearch = () => {
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [hasMore, setHasMore] = useState(true);
	const [accommodations, setAccommodations] = useState<
		Array<AccommodationListModel>
	>([]);

	const [loadAvailableAccommodations, { loading }] = useMutation<{
		getAvailableAccommodations;
	}>(GET_INTEGRATION_AVAILABLE_ACCOMMODATIONS, {
		onCompleted: ({ getAvailableAccommodations }) => {
			setAccommodations(getAvailableAccommodations.accommodations);
			setHasMore(totalPages > page);
			const result = [...getAvailableAccommodations.accommodations];
			if (page > 1) {
				setAccommodations([...accommodations, ...result]);
			} else {
				setAccommodations(result);
			}
			setTotalCount(getAvailableAccommodations.totalCount);
			setPage(getAvailableAccommodations.pageNumber);
			setTotalPages(getAvailableAccommodations.totalPages);
		}
	});

	const getAvailableAccommodations = (
		city: string,
		arrivalDate: string,
		departureDate: string,
		numOfAdults: number,
		childrenAges: number[],
		numOfRooms: number,
		sort: string | number
	) => {
		loadAvailableAccommodations({
			variables: {
				city: city,
				arrivalDate: arrivalDate,
				departureDate: departureDate,
				numOfAdults: numOfAdults,
				childrenAges: childrenAges,
				numOfRooms: numOfRooms,
				pageSize: PAGE_LIMIT,
				pageNumber: page,
				orderBy: sort
			}
		});
	};

	return {
		accommodations,
		totalCount,
		page,
		hasMore,
		loading,
		getAvailableAccommodations,
		setPage
	};
};

export default useAccommodationSearch;
