import { createContext } from 'preact';

export type TooltipView = {
	pathName: string;
	tooltipKey: string;
};

const tooltipViews: TooltipView[] = [];

export const TooltipViewContext = createContext(tooltipViews);
