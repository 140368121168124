import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { LoaderIcon } from 'redesigncomponents/Button';
import { FontSize, FontWeight } from 'styles/defaultTheme';

import PaidCheck from 'assets/paid-check.svg';

const ButtonWrapper = styled.button`
	background-color: ${({ disabled, paid }) =>
		disabled ? '#fff' : paid ? '#528b18' : '#243757'};
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: ${({ disabled, paid }) =>
		disabled
			? '1px solid #dfe2e6'
			: paid
			? '1px solid #528b18'
			: '1px solid #243757'};
	padding: 0.6rem 1rem;
	cursor: ${props => (props.disabled || props.paid ? 'default' : 'pointer')};
	&:hover {
		background-color: ${props => (props.disabled ? '#fff' : '##354e78')};
	}

	${({ loading }) =>
		loading &&
		`
    background: #647695;
    cursor: not-allowed;
    border: 1px solid #647695;

  `}
`;

const ButtonTitle = styled.span`
	font-weight: ${FontWeight.bold};
	font-size: ${FontSize.smSize};
	color: ${props => (props.disabled ? '#dfe2e6' : '#fff')};
`;

const PaidBox = styled.div`
	margin-left: 0.6rem;
`;

type Props = {
	title?: string;
	disabled?: boolean;
	paid?: boolean;
	onClick?: () => void;
	loading?: boolean;
	type?: string;
};

export const GetGestButton: FunctionalComponent<Props> = ({
	title,
	disabled,
	loading,
	paid,
	type = 'submit',
	onClick
}) => {
	return (
		<ButtonWrapper
			disabled={disabled}
			paid={paid}
			onClick={onClick}
			loading={loading}
			type={type}
		>
			{loading && <LoaderIcon />}
			<ButtonTitle disabled={disabled}>{title}</ButtonTitle>
			{paid && (
				<PaidBox>
					<PaidCheck />
				</PaidBox>
			)}
		</ButtonWrapper>
	);
};
