import { makeVar } from '@apollo/client';

import { Option } from 'redesigntypes/Option';

import { TrainbusOptionCategoriesType } from '../components/OptionCategories/useOptionCategories';
import { OfferDetails } from '../../types';

type TrainTransportSearchCache = {
	fromLocation?: Option;
	toLocation?: Option;
	transportType?: Option;
	tripType?: Option;
	airClass?: Option;
	transportSort?: Option;
	travelPeriod?: [Date, Date];
	optionCategories?: TrainbusOptionCategoriesType;
	selectedDepartureTransportOfferDetails?: OfferDetails;
	selectedReturnTransportOfferDetails?: OfferDetails;
};

export const initialTrainTransportSearchValue: TrainTransportSearchCache = {};

export const trainTransportSearchOptionsVar =
	makeVar<TrainTransportSearchCache>(initialTrainTransportSearchValue);
