import { FunctionalComponent } from 'preact';

import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import { IBasePrice } from 'redesigntypes/Itinerary';

import {
	ButtonWrapper,
	PassengersNumber,
	PriceInfo,
	PriceSectionWrapper,
	PriceValue
} from './StyledComponents';

interface IProps {
	price: IBasePrice;
	passengers: string;
	onConfirmDisabled?: boolean;
	onConfirm?: () => void;
	onView?: () => void;
}

const PriceSection: FunctionalComponent<IProps> = ({
	price,
	passengers,
	onConfirmDisabled,
	onConfirm,
	onView
}) => {
	return (
		<PriceSectionWrapper>
			<PriceInfo>
				<PassengersNumber>{passengers}</PassengersNumber>
				<PriceValue>
					Total price:{' '}
					{price?.amount
						? `${price.amount} ${price.currency}`
						: 'Select the ticket options'}{' '}
				</PriceValue>
			</PriceInfo>
			<ButtonWrapper>
				{onView && (
					<Button
						title="View"
						buttonStyleType={ButtonStyleTypes.secondary}
						onClick={onView}
					></Button>
				)}
				{onConfirm && (
					<Button
						title="Confirm"
						buttonStyleType={ButtonStyleTypes.primary}
						disabled={onConfirmDisabled}
						onClick={onConfirm}
					></Button>
				)}
			</ButtonWrapper>
		</PriceSectionWrapper>
	);
};

export default PriceSection;
