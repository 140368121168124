import { useMemo, useState } from 'preact/hooks';
import Select from 'react-select';

import styled from 'styled-components';
import { useTheme } from 'styled-components';

import { commonLabelStyles } from 'redesigncomponents/FieldTextInput/StyledTextInput';
import { Option } from 'redesigntypes/Option';
import { fonts } from 'styles';

const StyledSelect = styled.div`
	position: relative;
	opacity: ${props => (props.isDisabled ? '0.4' : '1')};
	pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
	border: none;
	font-size: 16px;
	outline: none;
	color: #2c2c2c;
	width: 100%;
`;

const customStyles = {
	option: (provided, state) => {
		const { secondary, tertiary } = state.selectProps;
		return {
			padding: '8px 12px',
			width: '100%',
			fontSize: '14px',
			fontFamily: fonts.default,
			cursor: 'pointer',
			color: '#857a8f',
			backgroundColor: secondary ? '#f7f6f8' : tertiary ? '#fafbfb' : '#fff',
			'&:hover': {
				backgroundColor: '#CCC8D1'
			},
			'&:active': {
				backgroundColor: '#CCC8D1'
			},
			'&--is-selected:': {
				backgroundColor: '#CCC8D1'
			}
		};
	},
	container: provided => ({
		...provided
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	control: (provided, state) => {
		const { isDisabled } = state.selectProps;
		const { smallPadding, secondary } = state.selectProps;
		return {
			...provided,
			padding: smallPadding ? '13px' : '15px',
			fontSize: '14px',
			borderRadius: '4px',
			fontFamily: fonts.default,
			borderColor: state?.selectProps?.error ? '#b3261e' : '#91889b',
			borderWidth: state?.selectProps?.error ? '2px' : '1px',

			boxShadow: state?.isFocused
				? state?.selectProps?.error
					? '0px 0px 0px 1px #b3261e inset'
					: '0px 0px 0px 1px #91889b inset'
				: 'none',

			// Style types
			// backgroundColor: isDisabled ? '#CCC8D1' : '#fff',
			backgroundColor: state.selectProps.secondary
				? '#f7f6f8'
				: state.selectProps.tertiary
				? '#fafbfb'
				: '#fff',
			color: isDisabled ? '#79747E' : '#857a8f',
			height: '3.5rem'
		};
	},
	menu: provided => ({
		...provided,
		paddingBottom: '10px',
		backgroundColor: 'transparent',
		boxShadow: 'none'
	}),
	menuList: (provided, state) => ({
		...provided,
		maxHeight: '200px',
		width: '100%',
		backgroundColor: state.selectProps.secondary
			? '#f7f6f8'
			: state.selectProps.tertiary
			? '#fafbfb'
			: '#fff',
		boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1)'
	}),
	placeholder: provided => ({
		...provided,
		fontFamily: fonts.default,
		fontSize: '16px',

		// Style types
		color: '#CCC8D1'
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: state?.selectProps?.isDisabled ? '#79747E' : '#000',

		fontFamily: fonts.default,
		fontSize: '16px',
		width: '100%'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		// Style types
		color: '#79747E'
	}),
	valueContainer: (provided, state) => ({
		...provided,
		overflow: 'visible'
	})
};

const TextLabel = styled.label`
	${commonLabelStyles};
	top: ${({ smallPadding }) => (smallPadding ? '13px' : '15px')};
	transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
	${({ required, minified }) =>
		required &&
		!minified &&
		`
			:after {
				content: ' *';
				color: #857a8f;
			}
	`}

	${props =>
		props.minified &&
		`
		top: -8px;
		font-size: 11px;
		left: 10px;
		color: ${props => (props.error ? '#b3261e' : '#857a8f')};
	`}

	${({ secondary }) =>
		secondary &&
		`
		background-color: #f7f6f8;

	`}

    ${({ tertiary }) =>
		tertiary &&
		`
		background-color: #fafbfb;

	`}
`;

const InfoText = styled.span`
	font-size: 11px;
	line-height: 14px;
	padding: 5px 5px 0 5px;
	color: ${props => (props.error ? '#b3261e' : '#2c2c2c')};
	display: inline-block;
`;

const SelectComponent = ({
	inputProps,
	error,
	label,
	options = [],
	searchable = false,
	placeholder = null,
	disabled = false,
	required = false,
	smallPadding = false,
	secondary = false,
	tertiary = false,
	CustomOptionComponent = undefined,
	loading = false,
	...rest
}) => {
	const theme = useTheme();
	const [focused, setFocused] = useState<boolean>();
	const value = useMemo(() => {
		return options
			? options.find(
					(option: Option) =>
						option.value === inputProps?.value ||
						option.value === inputProps?.value?.value
			  )
			: '';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputProps, inputProps?.value, options]);

	const customComponents = useMemo(() => {
		return CustomOptionComponent
			? { Option: CustomOptionComponent }
			: undefined;
	}, [CustomOptionComponent]);

	return (
		<StyledSelect error={error} isDisabled={disabled}>
			<Select
				theme={theme}
				{...rest}
				{...inputProps}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				required={required}
				styles={customStyles}
				options={options}
				searchable={searchable}
				value={value || ''}
				placeholder={placeholder}
				isDisabled={disabled}
				error={error}
				smallPadding={smallPadding}
				secondary={secondary}
				tertiary={tertiary}
				menuShouldScrollIntoView={false}
				components={customComponents}
				isLoading={loading}
				menuPlacement="auto"
			/>
			<TextLabel
				required={required}
				minified={value || (searchable && focused)}
				smallPadding={smallPadding}
				secondary={secondary}
			>
				{label}
			</TextLabel>
			{error && <InfoText error={error}>{error}</InfoText>}
		</StyledSelect>
	);
};

export default SelectComponent;
