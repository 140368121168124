import { FunctionalComponent } from 'preact';

import AccountMenu from 'redesigncomponents/AccountMenu';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';

import { ContentWrapper } from './components/StyledComponents';
import EmailForm from './components/forms/EmailForm/EmailForm';
import PasswordForm from './components/forms/PasswordForm/PasswordForm';
import PersonalDetailsForm from './components/forms/PersonalDetails/PersonalDetailsForm';

interface IProps {}

const AccountDetails: FunctionalComponent<IProps> = () => {
	return (
		<MainWrapper>
			<BodyWrapper topAlign>
				<ContentWrapper>
					<AccountMenu />
					<PersonalDetailsForm />
					<EmailForm />
					<PasswordForm />
				</ContentWrapper>
			</BodyWrapper>
			<Footer />
		</MainWrapper>
	);
};

export default AccountDetails;
