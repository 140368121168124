import styled from 'styled-components';

import { Colors, DeviceBreakpoints, FontSize } from 'styles/defaultTheme';

export const FormWrapper = styled.div`
	background: #fafbfb;
	border-radius: 0.25rem;
	border: 1px solid #7a8699;
	width: 100%;
	color: #091e42;
	padding: 0.75rem;
	margin-bottom: 1.25rem;
	@media ${DeviceBreakpoints.small} {
		margin-bottom: 0.75rem;
	}
`;

export const LogoWrapper = styled.div`
	cursor: pointer;
	display: flex;
`;

export const TextInputSection = styled.div``;

export const TextInputHalfWidth = styled.div`
	width: 40%;
	margin-left: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	@media ${DeviceBreakpoints.medium} {
		width: 100%;
		margin-left: 0;
	}
`;

export const TwoInputRow = styled.div`
	display: flex;
	gap: 0.5rem;
	margin-bottom: 0.75rem;
	> div {
		flex: 1;
		input,
		label {
			background: #fafbfb;
		}
	}
	@media ${DeviceBreakpoints.small} {
		flex-direction: ${({ rowLayout }) => (rowLayout ? 'row' : 'column')};
	}
`;

export const LeadTravelSection = styled.div`
	border-top: 1px solid ${Colors.V50};
	margin-top: 1.5rem;
`;

export const ThreeInputRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr 1fr;
	margin-bottom: 0.75rem;
	gap: 0.5rem;
	> div {
		flex: 1;
		input,
		label {
			background: #fafbfb;
		}
	}
	@media ${DeviceBreakpoints.small} {
		grid-template-columns: 1fr;
	}
`;

export const ButtonContainer = styled.div`
	margin-top: 1.25rem;
	button {
		border: 1px solid #243757;
		padding: 0.375rem 1.5rem;
		span {
			color: #243757;
		}
		&:hover {
			background: #505f79;
		}
	}
`;

export const FormFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
	gap: 0.75rem;
	button {
		&:first-child {
			padding: 0.45rem 0.75rem;
		}
	}
`;
