import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import secondsToDMH from 'utils/time/secondsToDMH';

import BusIcon from 'assets/icons/transport/bus.svg';
import TrainIcon from 'assets/icons/transport/train.svg';

const travelMethodIconMap = {
	Train: TrainIcon,
	Bus: BusIcon
};

const getLine = segment =>
	`${segment.segmentProducerCode.title} ${segment.transportId}`;

const useTrainTransportOfferItem = offerItem => {
	const headerDetails = useMemo(() => {
		if (offerItem) {
			const itineraries = offerItem.transport_item.itineraries;

			const allSegments = itineraries.flatMap(itinerary => itinerary.segments);
			const firstSegment = allSegments[0];
			const lastSegment = allSegments[allSegments.length - 1];

			return {
				transportLine: offerItem.transport_line,
				fromDate: firstSegment.departureDateTime,
				toDate: lastSegment.arrivalDateTime,
				duration: secondsToDMH(offerItem.transport_item.duration),
				startingLine: getLine(firstSegment),
				numberOfStops:
					allSegments.length === 1 ? 'Direct' : `${allSegments.length} stops`,
				price: `${offerItem.price.amount} ${offerItem.price.currency}`,
				travelMethodIcon:
					travelMethodIconMap[firstSegment.travelMethodCode.title]
			};
		}
	}, [offerItem]);

	const expandedDetails = useMemo(() => {
		if (offerItem) {
			const itineraries = offerItem.transport_item.itineraries;

			return {
				outgoingItineraries: itineraries.map(i => {
					const firstSegmentInItinerary = i.segments[0];
					const lastSegmentInItinerary = i.segments[i.segments.length - 1];
					return {
						outgoingTime: firstSegmentInItinerary.departureDateTime,
						arrivalTime: lastSegmentInItinerary.arrivalDateTime,
						outgoingStation: firstSegmentInItinerary.departureLocation.name,
						arrivalStation: lastSegmentInItinerary.arrivalLocation.name,
						segments: i.segments.map(segment => {
							return {
								outgoingTime: dayjs(segment.departureDateTime).format('HH:mm'),
								outgoingStation: segment.departureLocation.name,
								line: getLine(segment),
								duration: secondsToDMH(segment.duration),
								travelMethodIcon:
									travelMethodIconMap[segment.travelMethodCode.title]
							};
						})
					};
				})
			};
		}
	}, [offerItem]);

	return { headerDetails, expandedDetails };
};

export default useTrainTransportOfferItem;
