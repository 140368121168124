import { FunctionalComponent } from 'preact';

import useIntl from 'hooks/useIntl';
import Footer from 'redesigncomponents/Footer';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import {
	ParagraphHeading,
	ParagraphItem,
	StyledBodyWrapper,
	TermsContentWrapper,
	TermsParagraph,
	TermsSubtitle,
	TermsTitle
} from 'redesignscreens/Auth/TermsOfUse/components/StyledComponents';

interface IProps {}

const TermsOfUse: FunctionalComponent<IProps> = () => {
	const { t } = useIntl('termsOfUse');
	return (
		<MainWrapper>
			<StyledBodyWrapper>
				<TermsContentWrapper>
					<TermsTitle>{t('termsTitle')}</TermsTitle>
					<TermsSubtitle>{t('termsSubtitle')}</TermsSubtitle>
					<TermsParagraph>
						<ParagraphHeading>{t('paragraphOneHeading')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphOneTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphOneTextTwo')}</ParagraphItem>
					</TermsParagraph>
					<TermsParagraph>
						<ParagraphHeading>{t('paragraphTwoHeading')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphTwoTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextTwo')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextThree')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextFour')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextFive')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextFiveA')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextFiveB')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextFiveC')}</ParagraphItem>
						<ParagraphItem>{t('paragraphTwoTextSix')}</ParagraphItem>
						<ParagraphHeading>{t('paragraphThreeHeadding')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphThreeTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphThreeTextTwo')}</ParagraphItem>
						<ParagraphItem>{t('paragraphThreeTextThree')}</ParagraphItem>
						<ParagraphHeading>{t('paragraphFourHeading')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphFourTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphFourTextTwo')}</ParagraphItem>
						<ParagraphHeading>{t('paragraphFiveHeading')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphFiveTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphFiveTextTwo')}</ParagraphItem>
						<ParagraphItem>{t('paragraphFiveTextThree')}</ParagraphItem>
						<ParagraphItem>{t('paragraphFiveTextFour')}</ParagraphItem>
						<ParagraphHeading>{t('paragraphSixHeading')}</ParagraphHeading>
						<ParagraphItem>{t('paragraphSixTextOne')}</ParagraphItem>
						<ParagraphItem>{t('paragraphSixTextTwo')}</ParagraphItem>
						<ParagraphItem>{t('paragraphSixTextThree')}</ParagraphItem>
						<ParagraphItem>{t('paragraphSixTextFour')}</ParagraphItem>
						<ParagraphItem>{t('paragraphSixTextFive')}</ParagraphItem>
					</TermsParagraph>
				</TermsContentWrapper>
			</StyledBodyWrapper>
			<Footer />
		</MainWrapper>
	);
};

export default TermsOfUse;
