import { useCallback } from 'preact/hooks';

import { OfferDetails } from '../../types';

const useItemCanBeAddedToWishlist = (
	selectedDepartureTransportOfferDetails: OfferDetails,
	selectedReturnTransportOfferDetails: OfferDetails
) => {
	const itemCanBeAddedToWishlist = useCallback(
		(id: string) =>
			selectedDepartureTransportOfferDetails?.offer.unique_transport_id !==
				id &&
			selectedReturnTransportOfferDetails?.offer.unique_transport_id !== id,
		[
			selectedDepartureTransportOfferDetails,
			selectedReturnTransportOfferDetails
		]
	);

	return { itemCanBeAddedToWishlist };
};

export default useItemCanBeAddedToWishlist;
