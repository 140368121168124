import { FunctionalComponent } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useLocation } from 'react-router-dom';

import { PAGE_DEFAULT } from 'config/network';
import useToggle from 'hooks/useToggle';
import useTrainbusTransportSortCodebookOptions from 'hooks/useTrainbusTransportSortOptions';
import useTransportTripTypeCodebookOptions from 'hooks/useTransportTripTypeOptions';
import Loading from 'redesigncomponents/Loading';
import SortBy from 'redesigncomponents/SortBy';
import TabContent from 'redesigncomponents/TransportTabs/components/TabContent';
import { Option } from 'redesigntypes/Option';
import { InfiniteScrollWithRef } from 'shared/components';
import { TransportTripType } from 'types/enums';

import useTransportSearchOptionsCache from './cache/useTransportSearchCache';
import DefaultStateTransportList from './components/DefaultStateTransportList';
import NoResultsTransportList from './components/NoResultsTransportList';
import { initialValuesOptionCategories } from './components/OptionCategories/useOptionCategories';
import SelectedItems from './components/SelectedItems';
import {
	ResultsContainer,
	ResultsTitle,
	ResultsValue,
	SortingContainer,
	TransportListContainer
} from './components/StyledComponents';
import Tabs, { TabsEnum } from './components/Tabs';
import TrainTransportOfferItem from './components/TrainTransportOfferItem';
import TrainbusSearchOptions from './components/TrainbusSearchOptions';
import useItemCanBeAddedToWishlist from './hooks/useItemCanBeAddedToWishlist';
import useLoadTransportationOffers from './hooks/useTransportationOffers';

interface IProps {
	travelPeriod?: [Date, Date];
}

const TrainTransportListContainer: FunctionalComponent<IProps> = ({
	travelPeriod
}) => {
	const [initialized, toggleInitialized] = useToggle();

	const location = useLocation();
	const { trainbusTransportSortOptions } =
		useTrainbusTransportSortCodebookOptions();
	const { transportTripTypeOptions } = useTransportTripTypeCodebookOptions();
	const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Departure);
	const {
		transportType,
		fromLocation,
		toLocation,
		tripType,
		transportSort,
		optionCategories,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails,
		setTransportSort,
		setSelectedDepartureTransportOfferDetails,
		setSelectedReturnTransportOfferDetails,
		initializeTrainTransportSearchCache
	} = useTransportSearchOptionsCache();

	const {
		loadDepartureTransportationOffer,
		loadReturnTransportationOffer,
		loadingTransportOffer,
		calledLoadTransportOffer,
		departureTransportOffer,
		departureCurrentPage,
		departureHasMore,
		departureTotalCount,
		departureStartDate,
		returnTransportOffer,
		returnCurrentPage,
		returnHasMore,
		returnTotalCount,
		returnStartDate,
		offersTripType
	} = useLoadTransportationOffers();

	const { itemCanBeAddedToWishlist } = useItemCanBeAddedToWishlist(
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	);

	useEffect(() => {
		if (
			!initialized &&
			!location?.state?.breadcrumbNavigation &&
			!location?.state?.backNavigation &&
			transportTripTypeOptions?.length
		) {
			// breadcrumbNavigation added to state in Breadcrumbs component
			// initialize the activity list cache only if we got here from a regular link and if the screen has not been initialized before
			// if we got here by breadcrumb navigation, we expect to have the same state as we are going back in history
			initializeTrainTransportSearchCache(
				transportType,
				transportTripTypeOptions[1],
				trainbusTransportSortOptions,
				initialValuesOptionCategories,
				toLocation,
				travelPeriod
			);
		}
		if (!initialized) {
			toggleInitialized();
		}
	}, [
		initialized,
		transportTripTypeOptions,
		location?.state?.breadcrumbNavigation
	]);

	useEffect(() => {
		if (
			selectedTab === TabsEnum.Return &&
			tripType.value === TransportTripType.RoundTrip
		) {
			loadTransportationOfferPage(PAGE_DEFAULT, transportSort);
		}
	}, [selectedTab]);

	useEffect(() => {
		// clear returning date if the trip type switches to one way
		if (tripType?.value === TransportTripType.OneWay) {
			travelPeriod[1] = undefined;
			setSelectedTab(TabsEnum.Departure);
			setSelectedDepartureTransportOfferDetails(undefined);
			setSelectedReturnTransportOfferDetails(undefined);
		}
	}, [tripType]);

	useEffect(() => {
		// initial sort
		if (trainbusTransportSortOptions?.length && !transportSort) {
			setTransportSort(trainbusTransportSortOptions[0]);
		}
	}, [trainbusTransportSortOptions]);

	const searchTransportOffers = () => {
		setSelectedTab(TabsEnum.Departure);
		loadTransportationOfferPage(PAGE_DEFAULT, transportSort, true);
	};

	const updateSortOption = (sort: Option) => {
		setTransportSort(sort);
		loadTransportationOfferPage(PAGE_DEFAULT, sort);
	};

	const loadNextTransportationOfferPage = () => {
		const page =
			selectedTab === TabsEnum.Departure
				? departureCurrentPage
				: returnCurrentPage;
		loadTransportationOfferPage(page + 1, transportSort);
	};

	const loadTransportationOfferPage = (
		pageNumber: number,
		sort: Option,
		newSearch = false
	) => {
		newSearch || selectedTab === TabsEnum.Departure
			? loadDepartureTransportationOffer(
					travelPeriod[0],
					travelPeriod[1],
					fromLocation,
					toLocation,
					optionCategories,
					pageNumber,
					sort,
					tripType
			  )
			: loadReturnTransportationOffer(
					travelPeriod[1],
					toLocation,
					fromLocation,
					optionCategories,
					pageNumber,
					sort
			  );
	};

	const selectDepartureTransportOffer = offer => {
		if (offer) {
			setSelectedDepartureTransportOfferDetails({
				offer,
				fromLocation,
				toLocation,
				travelPeriod,
				optionCategories,
				transportType,
				tripType
			});
		} else {
			setSelectedDepartureTransportOfferDetails(undefined);
		}
	};

	const selectReturnTransportOffer = offer => {
		if (offer) {
			setSelectedReturnTransportOfferDetails({
				offer,
				fromLocation: toLocation,
				toLocation: fromLocation,
				travelPeriod,
				optionCategories,
				transportType,
				tripType
			});
		} else {
			setSelectedReturnTransportOfferDetails(undefined);
		}
	};

	const transportOffer = useMemo(() => {
		return selectedTab === TabsEnum.Departure
			? departureTransportOffer?.filter(to =>
					itemCanBeAddedToWishlist(to.unique_transport_id)
			  )
			: returnTransportOffer?.filter(to =>
					itemCanBeAddedToWishlist(to.unique_transport_id)
			  );
	}, [
		selectedTab,
		departureTransportOffer,
		returnTransportOffer,
		itemCanBeAddedToWishlist
	]);

	const hasMore = useMemo(() => {
		return selectedTab === TabsEnum.Departure
			? departureHasMore
			: returnHasMore;
	}, [selectedTab, departureTransportOffer, returnTransportOffer]);

	const currentPage = useMemo(() => {
		return selectedTab === TabsEnum.Departure
			? departureCurrentPage
			: returnCurrentPage;
	}, [selectedTab, departureCurrentPage, returnCurrentPage]);

	return initialized ? (
		<>
			<TrainbusSearchOptions
				searchTransportOffers={searchTransportOffers}
				loadingTransportOffer={loadingTransportOffer}
			/>
			<TransportListContainer>
				{!calledLoadTransportOffer ? (
					<DefaultStateTransportList />
				) : (
					<>
						<Tabs
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
							departureDate={departureStartDate || travelPeriod[0]}
							returnDate={returnStartDate || travelPeriod[1]}
							tripType={offersTripType || tripType}
							hasSelectedDeparture={selectedDepartureTransportOfferDetails}
							hasSelectedReturn={selectedReturnTransportOfferDetails}
						/>
						{(selectedTab === TabsEnum.Departure
							? selectedDepartureTransportOfferDetails
							: selectedReturnTransportOfferDetails) && (
							<SelectedItems selectedValue={1}>
								<TrainTransportOfferItem
									offer={
										selectedTab === TabsEnum.Departure
											? selectedDepartureTransportOfferDetails?.offer
											: selectedReturnTransportOfferDetails.offer
									}
									clearSelectedTransportOffer={() =>
										selectedTab === TabsEnum.Departure
											? selectDepartureTransportOffer(undefined)
											: selectReturnTransportOffer(undefined)
									}
								></TrainTransportOfferItem>
							</SelectedItems>
						)}
						{loadingTransportOffer && currentPage === 1 ? (
							<Loading />
						) : (
							<>
								{transportOffer?.length ? (
									<InfiniteScrollWithRef
										height={450}
										dataLength={transportOffer?.length}
										next={loadNextTransportationOfferPage}
										hasMore={hasMore}
										loader={<div />}
									>
										<TabContent>
											{transportOffer?.map(offer => {
												return (
													<TrainTransportOfferItem
														key={offer.timetable_id}
														offer={offer}
														selectTransportOffer={() =>
															selectedTab === TabsEnum.Departure
																? selectDepartureTransportOffer(offer)
																: selectReturnTransportOffer(offer)
														}
													/>
												);
											})}
										</TabContent>
									</InfiniteScrollWithRef>
								) : (
									<NoResultsTransportList />
								)}
								<SortingContainer>
									<ResultsContainer>
										<ResultsTitle>Results</ResultsTitle>
										<ResultsValue>
											{selectedTab === TabsEnum.Departure
												? departureTotalCount
												: returnTotalCount}
										</ResultsValue>
									</ResultsContainer>
									<SortBy
										value={transportSort}
										onSortChange={updateSortOption}
										options={trainbusTransportSortOptions}
									></SortBy>
								</SortingContainer>
							</>
						)}
					</>
				)}
			</TransportListContainer>
		</>
	) : (
		<Loading />
	);
};

export default TrainTransportListContainer;
