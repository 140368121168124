import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const AccordionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	cursor: pointer;
`;

export const AccordionContainer = styled.div`
	background-color: ${Colors.V0};
	color: ${Colors.V900};
	border-radius: 0.25rem;
	border: 1px solid ${Colors.V50};
`;

export const LeftContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		> div {
			display: none;
		}
		> div:first-of-type {
			display: inline-flex;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 3rem;
	@media ${DeviceBreakpoints.small} {
		gap: 0.75rem;
	}
`;

export const DepartureData = styled.h4`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
	margin-bottom: 0.45rem;
`;

export const NightStayWrapper = styled.div`
	padding: 0.125rem 0;
	display: flex;
	align-items: center;
`;

export const HorizontalLine = styled.div`
	height: 1px;
	background-color: ${Colors.V50};
	flex: 1;
`;

export const StayDurationTitle = styled.span`
	color: ${Colors.V700};
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
	padding: 0 0.625rem;
`;

export const PriceTitle = styled.span`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.semibold};
	white-space: nowrap;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.base};
	}
`;

export const AccordionBody = styled.div`
	display: grid;
	grid-template-columns: 4fr 1fr;
	border-top: 1px solid ${Colors.V50};
	@media ${DeviceBreakpoints.medium} {
		grid-template-columns: 1fr;
	}
`;

export const ContentContainer = styled.div`
	border-right: 1px solid ${Colors.V50};
	@media ${DeviceBreakpoints.small} {
		border-right: none;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 0.5rem;
	@media ${DeviceBreakpoints.medium} {
		padding-right: 0.5rem;
		justify-content: end;
		button {
			padding: 0.6rem 1.5rem;
		}
	}
`;

export const SectionContainer = styled.div`
	padding: 0.5rem;
`;

export const SvgWrapper = styled.div`
	transform: ${props => (props.collapsed ? 'rotate(180deg)' : 'rotate(0)')};
`;
