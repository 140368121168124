const flattenRoutes = (arr: Array<any>): Array<any> => {
  return arr.reduce((acc, cur) => {
    let nacc = acc.concat(cur);
    if (Array.isArray(cur.children) && cur.children.length) {
      nacc = nacc.concat(flattenRoutes(cur.children));
    }

    return nacc;
  }, []);
};
export default flattenRoutes;
