import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import SortBy from 'redesigncomponents/SortBy';
import { Option } from 'redesigntypes/Option';
import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

const OptionsTitleWrapper = styled.div`
	background: ${Colors.V10};
	padding: 0.625rem;
	border-radius: 0.25rem;
	border: 1px solid ${Colors.V50};
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2);
	margin-bottom: 1.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media ${DeviceBreakpoints.small} {
    	margin-bottom: 0.75rem;
	}
`;

export const ResultsContainer = styled.div`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.smSize};
	@media ${DeviceBreakpoints.small} {
		border: 1px solid ${Colors.V100};
		border-radius: 0.25rem;
		padding: 0.25rem;
	}
`;

export const ResultsTitle = styled.span``;
export const ResultsValue = styled.span`
	padding: 0.25rem;
	border: 1px solid ${Colors.V100};
	border-radius: 0.25rem;
	margin-left: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		border: 1px solid transparent;
	}
`;

type Props = {
	count?: number;
	sortOptions: Option[];
	sort: Option;
	onSortChange: (option: Option) => void;
};

export const OptionsNumberTitle: FunctionalComponent<Props> = ({
	count,
	sort,
	onSortChange,
	sortOptions
}) => {
	return (
		<OptionsTitleWrapper>
			<ResultsContainer>
				<ResultsTitle>Results</ResultsTitle>
				<ResultsValue>{count}</ResultsValue>
			</ResultsContainer>
			<SortBy options={sortOptions} value={sort} onSortChange={onSortChange} />
		</OptionsTitleWrapper>
	);
};

export default OptionsNumberTitle;
