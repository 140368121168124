import { useEffect, useState } from 'preact/hooks';

import storage from 'local-storage-fallback';

export function parseJwt(token: string) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export default function useToken() {
	const [subject, setSubject] = useState<string>();

	useEffect(() => {
		loadToken();
	}, []);

	const loadToken = () => {
		const token = storage.getItem('DESTI1_REDESIGN:TOKEN');
		const tokenParsed = token && parseJwt(token);
		const subject = tokenParsed?.sub || null;

		setSubject(subject);

		return { subject };
	};

	const saveToken = (idToken: string, refreshToken: string) => {
		storage.setItem('DESTI1_REDESIGN:TOKEN', idToken);
		storage.setItem('DESTI1_REDESIGN:REFRESH:TOKEN', refreshToken);

		const subject = getSubjectFromToken(idToken);
		setSubject(subject);
	};

	const getSubjectFromToken = idToken => {
		const tokenParsed = idToken && parseJwt(idToken);
		return tokenParsed?.sub;
	};

	const removeToken = () => {
		storage.removeItem('DESTI1_REDESIGN:TOKEN');
		storage.removeItem('DESTI1_REDESIGN:REFRESH:TOKEN');
		setSubject(null);
	};

	return {
		subject,
		saveToken,
		loadToken,
		removeToken,
		getSubjectFromToken
	};
}
