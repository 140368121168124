import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { BodyWrapperHeight, Colors, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	@media ${DeviceBreakpoints.small} {
		padding: 0rem;
		height: ${BodyWrapperHeight.mobile};
	}
`;
