import { FunctionalComponent, ComponentChildren } from 'preact';

import {
    RadioGroupContainer,
    GroupHeader,
    AdditionalInfo,
    RadioContainer
} from './StyledComponents';



interface IProps {
    children: ComponentChildren;
    title?: string;
    additionalTitle?: string;
}

const RadioGroup: FunctionalComponent<IProps> = ({ children, title, additionalTitle }) => {
    return (
        <RadioGroupContainer>
            <GroupHeader>{title}</GroupHeader>
            <AdditionalInfo>{additionalTitle}</AdditionalInfo>
            <RadioContainer>
                {children}
            </RadioContainer>
        </RadioGroupContainer>
    );
};

export default RadioGroup;
