import styled from 'styled-components';

import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

export const SortByContainer = styled.div`
	color: ${Colors.V900};
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const DropdownHeader = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${Colors.V0};
	padding: 0.25rem 0.75rem;

	border: 1px solid ${Colors.V100};
	border-radius: 0.25rem;
	height: 1.79rem;
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.semibold};
	cursor: pointer;
`;

export const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0.25rem 0.5rem;
	border-bottom: 1px solid ${Colors.V30};
	font-size: ${FontSize.smSize};
	cursor: pointer;
	&:last-child {
		border-bottom: 0;
	}
	&:hover {
		background-color: ${Colors.V20};
	}
`;

export const DropdownContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
	position: relative;
`;

export const CheckSvgContainer = styled.div`
	width: 1.5rem;
	height: 1.5rem;
`;

export const ItemTitle = styled.span`
	margin-left: 0.5rem;
	font-weight: ${({ active }) =>
		active ? `${FontWeight.semibold}` : `${FontWeight.normal}`};
`;

export const SelectedTitle = styled.span`
	margin-left: 0.375rem;
`;

export const SortByTitle = styled.span``;

export const DropDownBody = styled.div`
	background-color: ${Colors.V10};
	box-shadow: 0px 4px 4px rgba(158, 149, 166, 0.3);
	border-radius: 0.25rem;
	position: absolute;
	width: 100%;
	top: 1.8rem;
	z-index: 1;
`;

export const SvgWrapper = styled.div`
	margin-left: 0.5rem;
`;
