import { FunctionalComponent } from 'preact';
import { MarkupText } from 'preact-i18n';
import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonContainer,
	LogoContainer,
	Modal,
	ModalInfoText
} from 'redesigncomponents/Modal';
import { Paths } from 'router';

import GetGestLogoBig from 'assets/get-gest-logo-big.svg';

type ModalProps = {
	open: boolean;
	toggleOpen: () => void;
	affiliateLink?: boolean;
	handleCheckoutActions?: () => void;
};

const GetGestRedirectModal: FunctionalComponent<ModalProps> = ({
	open,
	toggleOpen,
	affiliateLink = false,
	handleCheckoutActions
}) => {
	const { t } = useIntl('checkout.getGestRedirectModal');
	const history = useHistory();

	const goToCheckout = () => {
		history.push(affiliateLink ? '/affiliate-link/checkout' : Paths.Checkout);

		handleCheckoutActions?.();
	};

	return (
		<Modal isOpen={open} onClose={toggleOpen}>
			<LogoContainer>
				<GetGestLogoBig></GetGestLogoBig>
			</LogoContainer>
			<ModalInfoText>
				<MarkupText id="checkout.getGestRedirectModal.youWillBeRedirectedToGetGestInfoText" />
			</ModalInfoText>
			<ButtonContainer>
				<Button
					title={t('continueToGetGestButtonTitle')}
					buttonStyleType={ButtonStyleTypes.primary}
					onClick={goToCheckout}
				></Button>
			</ButtonContainer>
		</Modal>
	);
};

export default GetGestRedirectModal;
