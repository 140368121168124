import throttle from 'lodash.throttle';

const asyncThrottle = (func, wait) => {
	const throttled = throttle((resolve, reject, args) => {
		func(...args)
			.then(resolve)
			.catch(reject);
	}, wait);
	return (...args) =>
		new Promise((resolve, reject) => {
			throttled(resolve, reject, args);
		});
};

export default asyncThrottle;
