import { components } from 'react-select';
import styled from 'styled-components';

const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 6px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Text = styled.span``;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span:after {
    display: block;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => (props.small ? '17px' : '20px')};
  width: ${props => (props.small ? '17px' : '20px')};
  background-color: #e5e2e7;
  border: 1px solid #91889b;
  border-radius: 2px;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: ${props => (props.small ? '5px' : '6px')};
    top: ${props => (props.small ? '2px' : '2px')};
    width: ${props => (props.small ? '3px' : '4px')};
    height: ${props => (props.small ? '7px' : '9px')};
    border: solid #2e1f3b;
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover {
    border: 1px solid #796d84;
  }
`;

const Option = props => {
  const onLabelClick = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    props.selectOption({ ...props.data });
    event.preventDefault();
  };

  const onCheckboxClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    props.innerProps.onClick(event);
    event.stopPropagation();
  };

  return (
    <>
      <components.Option {...props}>
        <CheckboxContainer onClick={onLabelClick}>
          <Input
            type="checkbox"
            checked={props.isSelected}
            onChange={onCheckboxClick}
            onClick={onCheckboxClick}
          ></Input>
          <Text>{props.label}</Text>
          <Checkmark small></Checkmark>
        </CheckboxContainer>
      </components.Option>
    </>
  );
};

export default Option;
