import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';

import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import { affiliateLinkVar } from 'redesigncache/affiliateLink/cache';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import MerchandiseDetailsContainer from 'redesignscreens/Main/Merchandise/MerchandiseDetails/MerchandiseDetailsContainer';
import useAddMerchandiseToWishlist from 'redesignscreens/Main/Merchandise/MerchandiseDetails/MerchandiseDetailsContainer/useAddMerchandiseToWishlist';
import useMerchandiseDetails from 'redesignscreens/Main/Merchandise/MerchandiseDetails/MerchandiseDetailsContainer/useMerchandiseDetails';
import useMerchandiseOffer from 'redesignscreens/Main/Merchandise/MerchandiseDetails/MerchandiseDetailsContainer/useMerchandiseOffer';
import capitalizeWords from 'utils/string/capitalizeWords';

import { StyledBodyWrapper } from './components/StyledComponents';

interface IProps {}

const MerchandiseDetails: FunctionalComponent<IProps> = () => {
	const history = useHistory();
	const defaultTravelPlanName = useDefaultTravelPlanName();
	const { t: merchandiseDetailsTranslations } = useIntl('merchandiseDetails');
	const { details: { externalId } = { externalId: '' } } =
		useReactiveVar(affiliateLinkVar);
	const { merchandise } = useMerchandiseDetails(externalId);

	const { quantities, totalPrice, currencyCode } = useMerchandiseOffer();

	const { createMerchandiseWishlistItem } = useAddMerchandiseToWishlist(
		merchandise,
		quantities,
		totalPrice,
		currencyCode
	);

	const { addToCheckout } = useCheckout();

	const bookNow = () => {
		const wishlistItem = createMerchandiseWishlistItem();
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPlanItems: [wishlistItem],
			travelPlanName: defaultTravelPlanName,
			travelItineraryId: null
		});
		goToCheckout();
	};

	const goToCheckout = () => {
		history.push('/affiliate-link/checkout');
	};

	return (
		<AffiliateLinkWrapper
			currentRouteSlug={merchandise?.name && capitalizeWords(merchandise?.name)}
		>
			<StyledBodyWrapper>
				<MerchandiseDetailsContainer externalId={externalId} />
			</StyledBodyWrapper>
			<Footer>
				<Button
					title={merchandiseDetailsTranslations('buyNowButtonTitle')}
					onClick={bookNow}
				/>
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default MerchandiseDetails;
