import { useEffect, useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import { ITravelPlan } from 'redesigncache/checkout/types';
import {
	IActivityWishlistItem,
	IStayWishlistItem,
	ITransportWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';

import { IProductOwnerExtended } from '../../types';
import useStripeProductOwners from './useProductOwners';

export interface IComposedTravelPlanItem extends WishlistItem {
	productOwner: IProductOwnerExtended;
}

const useTravelPlan = (travelPlan: ITravelPlan) => {
	const { productOwnersMap, productOwnerCodes, loadProductOwners } =
		useStripeProductOwners();

	useEffect(() => {
		loadProductOwners(
			travelPlan.travelPlanItems.map(
				(ci: WishlistItem) => ci.item.product_owner
			)
		);
	}, []);

	const composedTravelPlanItems = useMemo((): IComposedTravelPlanItem[] => {
		return travelPlan.travelPlanItems
			.filter((ci: WishlistItem) =>
				productOwnerCodes.includes(ci.item.product_owner)
			)
			.map(
				(ci: WishlistItem) =>
					({
						...ci,
						productOwner: productOwnersMap[ci.item.product_owner]
					} as IComposedTravelPlanItem)
			);
	}, [productOwnerCodes, travelPlan?.travelPlanItems, travelPlan.updatedAt]);

	const travelPlanLocationAndPeriod = useMemo(() => {
		const accommodationTravelPlan = travelPlan?.travelPlanItems?.find(
			plan => plan.type === WishlistItemTypes.STAY
		);
		const transportTravelPlan = travelPlan?.travelPlanItems?.find(
			plan => plan.type === WishlistItemTypes.TRANSPORT
		);
		const activityTravelPlan = travelPlan?.travelPlanItems?.find(
			plan => plan.type === WishlistItemTypes.ACTIVITY
		);

		if (accommodationTravelPlan) {
			const accommodationItem =
				accommodationTravelPlan.item as IStayWishlistItem;
			return `${accommodationItem.city}, ${dayjs(
				accommodationItem.travelPeriod[0]
			).format('DD.MM.YYYY')} - ${dayjs(
				accommodationItem.travelPeriod[1]
			).format('DD.MM.YYYY')}`;
		} else if (transportTravelPlan) {
			const transportItem = transportTravelPlan.item as ITransportWishlistItem;
			return `${transportItem.city_from} - ${transportItem.city_to}, ${dayjs(
				transportItem.date
			).format('DD.MM.YYYY')}`;
		} else if (activityTravelPlan) {
			const activityItem = activityTravelPlan.item as IActivityWishlistItem;
			const city = activityItem.city;
			return `${city ? `${city}, ` : ''}${dayjs(activityItem.date).format(
				'DD.MM.YYYY'
			)}`;
		}
	}, [travelPlan]);

	return {
		composedTravelPlanItems,
		travelPlanLocationAndPeriod
	};
};

export default useTravelPlan;
