import { OfferDetails } from '../../types';
import useTransportSearchOptionsCache from '../cache/useTransportSearchOptionsCache';

const useTransportationDetails = () => {
	const { selectedTransportOffer, setSelectedTransportOffer } =
		useTransportSearchOptionsCache();

	const setSelectedTransportOfferDetailsValue = (
		selectedTransportOffer: OfferDetails
	) => {
		setSelectedTransportOffer(selectedTransportOffer);
	};

	return {
		selectedTransportOffer,
		setSelectedTransportOfferDetailsValue
	};
};

export default useTransportationDetails;
