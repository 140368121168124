import { FunctionalComponent } from 'preact';

import AffiliateCheckoutHeader from 'redesigncomponents/Header/AffiliateCheckoutHeader';
import StyledToastContainer from 'redesigncomponents/Toast/ToastContainer';

interface IProps {}

const AffiliateCheckoutWrapper: FunctionalComponent<IProps> = ({
	children
}) => {
	return (
		<>
			<AffiliateCheckoutHeader />
			<StyledToastContainer />
			{children}
		</>
	);
};

export default AffiliateCheckoutWrapper;
