import { setContext } from '@apollo/client/link/context';

import storage from 'local-storage-fallback';
import { DestOneConfig } from '../../types/global';

export const hostLink = setContext((_, { _headers }) => {
	const hostId =
		storage.getItem('DESTI1_REDESIGN:HOSTID') ??
		window.DEST1_REDESIGN_CONFIG.hostId;
	const headers = {
		..._headers,
		cookie: document.cookie
	};

	headers['host-id'] = hostId;
	headers['x-hasura-host-id'] = hostId;

	return {
		headers: {
			...headers,
			'widget-language': window.DEST1_REDESIGN_CONFIG.lang
		}
	};
});
