import { useReactiveVar } from '@apollo/client';
import { FunctionalComponent } from 'preact';

import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import { affiliateLinkVar } from 'redesigncache/affiliateLink/cache';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import AffiliateLinkWrapper from 'redesigncomponents/WidgetContentWrappers/AffiliateLinkWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';
import ActivityDetailsContainer from 'redesignscreens/Main/Activities/ActivityDetails/ActivityDetailsContainer';
import useActivityDetails from 'redesignscreens/Main/Activities/ActivityDetails/ActivityDetailsContainer/useActivityDetails';
import useActivityOffer from 'redesignscreens/Main/Activities/ActivityDetails/ActivityDetailsContainer/useActivityOffer';
import useAddActivityToWishlist from 'redesignscreens/Main/Activities/ActivityDetails/ActivityDetailsContainer/useAddActivityToWishlist';
import capitalizeWords from 'utils/string/capitalizeWords';

import { StyledBodyWrapper } from './components/StyledComponents';

interface IProps {}

const ActivityDetails: FunctionalComponent<IProps> = () => {
	const defaultTravelPlanName = useDefaultTravelPlanName();
	const { t: activityDetailsTranslations } = useIntl('activityDetails');
	const [redirectToGetGestOpen, setRedirectToGetGestOpen] = useToggle();
	const { details: { externalId } = { externalId: '' } } =
		useReactiveVar(affiliateLinkVar);
	const { activity } = useActivityDetails(externalId);

	const { selectedSession, selectedTickets, selectedActivityOfferValid } =
		useActivityOffer();
	const { createActivityWishlistItem } = useAddActivityToWishlist(
		activity,
		selectedSession,
		selectedTickets
	);

	const { addToCheckout } = useCheckout();

	const bookNow = () => {
		const wishlistItem = createActivityWishlistItem();
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPeriod: [
				selectedSession.startTimeLocal,
				selectedSession.endTimeLocal
			],
			travelPlanItems: [wishlistItem],
			travelPlanName: defaultTravelPlanName,
			travelItineraryId: null
		});

		setRedirectToGetGestOpen(true);
	};

	return (
		<AffiliateLinkWrapper
			currentRouteSlug={activity?.name && capitalizeWords(activity?.name)}
		>
			<StyledBodyWrapper>
				<ActivityDetailsContainer externalId={externalId} />
			</StyledBodyWrapper>
			{redirectToGetGestOpen && (
				<GetGestRedirectModal
					affiliateLink
					open={redirectToGetGestOpen}
					toggleOpen={setRedirectToGetGestOpen}
				/>
			)}
			<Footer>
				{selectedActivityOfferValid && (
					<Button
						title={activityDetailsTranslations('bookNowButtonTitle')}
						onClick={bookNow}
					/>
				)}
			</Footer>
		</AffiliateLinkWrapper>
	);
};

export default ActivityDetails;
