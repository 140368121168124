import { FunctionalComponent } from 'preact';
import { CSSProperties } from 'preact/compat';
import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

import styled from 'styled-components';

export enum ButtonStyleTypes {
	primary = 'primary',
	secondary = 'secondary',
	tertiary = 'tertiary'
}

export enum LoadingStyleTypes {
	spinner = 'spinner',
	progress = 'progress'
}

const StyledButton = styled.button`
	background: ${Colors.V700};
	border: 1px solid ${Colors.V700};
	border-radius: 0.25rem;
	cursor: pointer;
	padding: ${({ tightPadding }) => (tightPadding ? '0.45rem' : '0.5rem 1.5rem;')};
	justify-content: center;
	align-items: center;
	position: relative;
	pointer-events: auto;
	svg {
		path {
			fill: ${Colors.V0};
		}
	}
	&:hover {
		background:  ${Colors.V400};
		border-color:  ${Colors.V400};
		span {
			color:  ${Colors.V0};
		}
		svg {
			path {
				fill:  ${Colors.V0};
			}
		}
	}

	&:active {
		background: ${Colors.V800};
		span {
			color: ${Colors.V0};
		}
		svg {
			path {
				fill: ${Colors.V0};
			}
		}
	}

	${({ buttonStyleType }) =>
		buttonStyleType === ButtonStyleTypes.secondary &&
		`
    background: ${Colors.V0};
    svg {
      path {
        fill: ${Colors.V700};
      }
    }
    
  `}

	${({ buttonStyleType }) =>
		buttonStyleType === ButtonStyleTypes.tertiary &&
		`
    background: ${Colors.V0};
    border-color: ${Colors.V200};
	min-height: 36px;
    svg {
      path {
        fill: ${Colors.V600};
      }
    }
	span {
		font-size: 13px;
	}
    &:hover {
      background: ${Colors.V30};
      span {
          color: ${Colors.V900};
        }
        svg path {
          fill: ${Colors.V600};
        }
    }
  `}

  ${({ disabled }) =>
		disabled &&
		`
    background: ${Colors.V0};
    color: ${Colors.V40};
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid ${Colors.V40};
    svg path {
          fill: ${Colors.V40};
        }
  `}

  

	${({ loadingStyleType }) =>
		loadingStyleType === LoadingStyleTypes.progress &&
		`
		::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background: linear-gradient(90deg, #342245, #857a8f, #342245);
		background-size: 400%;
		border-radius: 0.25rem;
		opacity: 0;
		transition: 0.5s;

	}
	`};

	@keyframes animate {
		0% {
			background-position: 0%;
		}
		100% {
			background-position: -400%;
		}
	}

	${({ loading, loadingStyleType }) =>
		loading &&
		loadingStyleType === LoadingStyleTypes.progress &&
		`
			background: linear-gradient(90deg, #342245, #857a8f, #342245);
			background-size: 400%;
			animation: animate 4s linear infinite;
			&:hover {
				background: linear-gradient(90deg, #342245, #857a8f, #342245);
				background-size: 400%;
				animation: animate 4s linear infinite;
			}
	`};

	${({ loading, loadingStyleType }) =>
		loading &&
		loadingStyleType === LoadingStyleTypes.spinner &&
		`
			display: flex;
			background: ${Colors.V200};
			cursor: not-allowed;
			border: 1px solid ${Colors.V400};
			&:hover {
				background: ${Colors.V200};
			}
	`};
	@media ${DeviceBreakpoints.small} {
		padding: ${({ tightPadding }) => (tightPadding ? '0.45rem' : '1rem 1.5rem;')};
	}
`;

const ButtonContent = styled.span`
	font-size: 1rem;
	font-weight: ${FontWeight.bold};
	font-family: 'Open Sans';
	color:  ${Colors.V0};
	display: flex;
	gap: ${({ column, onlyIcon }) =>
		column ? '0.25rem' : onlyIcon ? '0rem' : '0.625rem'};
	width: ${({ onlyIcon }) => (onlyIcon ? '1.5rem' : 'unset')};
	align-items: center;
	justify-content: center;
	flex-direction: ${({ column }) => (column ? 'column' : 'row')};
	svg {
		order: ${({ column }) => (column ? '1' : '2')};
	}

	${({ buttonStyleType }) =>
		buttonStyleType === ButtonStyleTypes.secondary &&
		`
    color: ${Colors.V700};
  `}

	${({ buttonStyleType }) =>
		buttonStyleType === ButtonStyleTypes.tertiary &&
		`
    color: ${Colors.V600};
    font-weight: ${FontWeight.normal};
  `}

  ${({ small }) =>
		small &&
		`
    font-size: ${FontSize.smSize};
  `}
  
  ${({ disabled }) =>
		disabled &&
		`
    color: ${Colors.V40};
  `}
`;

const Title = styled.span`
	order: ${props => (props.column ? '2' : '1')};
`;
export const LoaderIcon = styled.div`
	border-radius: 100%;
	left: 10px;
	top: 12px;
	width: 16px;
	height: 16px;
	border: 3px solid rgba(255, 255, 255, 0.5);
	border-left-color:  ${Colors.V0};
	border-top-color:  ${Colors.V0};
	animation: spin 0.8s infinite linear, grow 0.3s forwards ease-out;
	margin-right: 10px;
	@keyframes spin {
		to {
			transform: rotate(359deg);
		}
	}
`;
export interface IButtonProps {
	type?: string;
	disabled?: boolean;
	loading?: boolean;
	buttonStyleType?: ButtonStyleTypes;
	loadingStyleType?: LoadingStyleTypes;
	title?: string;
	onClick?: () => void;
	Icon?: FunctionalComponent;
	column?: boolean;
	onlyIcon?: boolean;
	tightPadding?: boolean;
	small?: boolean;
	style?: CSSProperties;
}

/**
 * Primary UI component for user interaction
 */
const Button: FunctionalComponent<IButtonProps> = ({
	buttonStyleType = ButtonStyleTypes.primary,
	disabled,
	loading,
	loadingStyleType = LoadingStyleTypes.spinner,
	onlyIcon,
	title,
	type = 'button',
	onClick,
	Icon,
	column,
	tightPadding,
	small,
	style
}) => {
	return (
		<StyledButton
			type={type}
			disabled={disabled}
			buttonStyleType={buttonStyleType}
			loadingStyleType={loadingStyleType}
			onClick={disabled ? () => {} : onClick}
			loading={loading}
			tightPadding={tightPadding}
			style={style}
		>
			{loading && loadingStyleType === LoadingStyleTypes.spinner && (
				<LoaderIcon />
			)}
			<ButtonContent
				buttonStyleType={buttonStyleType}
				disabled={disabled}
				column={column}
				onlyIcon={onlyIcon}
				small={small}
				loading={loading}
			>
				<Title column={column}>{title}</Title>
				{Icon && <Icon />}
			</ButtonContent>
		</StyledButton>
	);
};

export default Button;
