import styled from 'styled-components';

import { Colors, DeviceBreakpoints } from 'styles/defaultTheme';

import GoogleIcon from 'assets/google.svg';

const ButtonWrapper = styled.button`
	padding: 1.25rem;
	background: ${Colors.V0};
	border: 1px solid ${Colors.V400};
	cursor: pointer;
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Roboto';
	font-weight: 500;
	&:hover {
		background: ${Colors.V10};
	}
	@media ${DeviceBreakpoints.small} {
		padding: 1rem;
	}
`;

const ButtonLabel = styled.span`
	margin-left: 1.125rem;
	font-size: 1rem;
	color: ${Colors.V900};
`;

const ContentWrapper = styled.span`
	display: flex;
	align-items: center;
`;

const LogoWrapper = styled.span`
	display: flex;
`;

const GoogleButton = ({ title, onClick }) => {
	return (
		<ButtonWrapper onClick={onClick} type="button">
			<ContentWrapper>
				<LogoWrapper>
					<GoogleIcon></GoogleIcon>
				</LogoWrapper>
				<ButtonLabel>{title}</ButtonLabel>
			</ContentWrapper>
		</ButtonWrapper>
	);
};

export default GoogleButton;
