import { useEffect, useState } from 'preact/hooks';

import useDefaultTravelPlanName from 'hooks/useDefaultTravelPlanName';
import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import useDestinationAndPeriod from 'redesigncache/destinationAndPeriod/useDestinationAndPeriod';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import StyledTextInput from 'redesigncomponents/FieldTextInput/StyledTextInput';
import Footer from 'redesigncomponents/Footer';
import StyledToast from 'redesigncomponents/Toast';
import MainWrapper from 'redesigncomponents/WidgetContentWrappers/MainWrapper';
import GetGestRedirectModal from 'redesignscreens/Checkout/components/TravelPlanItem/components/GetGestRedirectModal';

import EmptyWishlistInfo from './components/EmptyWishlist';
import GuestUserModal from './components/GuestUserModal';
import SavedTravelPlanToast from './components/SavedTravelPlanToast';
import {
	FooterButtonsWrapper,
	InputContainer,
	SelectedCards,
	StyledBodyWrapper,
	WishlistContent
} from './components/StyledComponents';
import WishlistHeader from './components/WishlistHeader';
import WishlistItemCard from './components/WishlistItemCard';
import useSaveTravelPlan from './useSaveTravelPlan';
import useWishlistItems from './useWishlistItems';

const Wishlist = () => {
	const { t } = useIntl('wishlist');

	const { isLoggedIn } = useUserAuth();
	const { travelPeriod } = useDestinationAndPeriod();
	const defaultTravelPlanName = useDefaultTravelPlanName();

	const [guestUserModalOpen, toggleGuestUserModalOpen] = useToggle();
	const [redirectToGetGestOpen, toggleRedirectToGetGestOpen] = useToggle();
	const [travelPlanName, setTravelPlanName] = useState<string>(
		defaultTravelPlanName
	);

	const { wishlistItemsCount, removeWishlistItem, removeWishlistItems } =
		useWishlist();

	const {
		selectedItems,
		notSelectedItems,
		selectedWishlistItemIds,
		setSelectedWishlistItemIds,
		toggleWishlistItemSelected
	} = useWishlistItems();

	const { saveTravelPlan, loading } = useSaveTravelPlan();
	const { addToCheckout } = useCheckout();

	useEffect(() => {
		// reset travel plan name when nothing is selected
		if (selectedWishlistItemIds?.length === 0) {
			setTravelPlanName(defaultTravelPlanName);
		}
	}, [selectedWishlistItemIds]);

	const handleSaveTravelPlan = async () => {
		try {
			await saveTravelPlan(travelPlanName, travelPeriod, selectedItems);
			removeWishlistItems(selectedWishlistItemIds);
			setSelectedWishlistItemIds([]);
			StyledToast.success(<SavedTravelPlanToast />, {
				autoClose: false
			});
		} catch {
			//ignored
		}
	};

	const handleCheckoutActions = () => {
		addToCheckout({
			id: Date.now(),
			updatedAt: Date.now(),
			travelPeriod: travelPeriod,
			travelPlanItems: selectedItems,
			travelPlanName: travelPlanName,
			travelItineraryId: null
		});
		removeWishlistItems(selectedWishlistItemIds);
		setSelectedWishlistItemIds([]);
	};

	const handleBookTravelPlan = () => {
		toggleRedirectToGetGestOpen(true);
	};

	const handleAuthenticatedAction = action => {
		if (isLoggedIn) {
			action();
		} else {
			toggleGuestUserModalOpen();
		}
	};

	const actionsAvailable = selectedItems?.length > 0 && travelPlanName?.trim();
	return (
		<MainWrapper>
			{wishlistItemsCount > 0 ? (
				<>
					<StyledBodyWrapper>
						<WishlistHeader />
						<WishlistContent>
							{selectedItems?.length > 0 && (
								<SelectedCards>
									<InputContainer>
										<StyledTextInput
											label={t('travelPlanNameInputLabel')}
											inputProps={{
												value: travelPlanName,
												onChange: event => setTravelPlanName(event.target.value)
											}}
										/>
									</InputContainer>
									{selectedItems.map(wi => (
										<WishlistItemCard
											wishlistItem={wi}
											toggleWishlistItemSelected={() =>
												toggleWishlistItemSelected(wi.id)
											}
											selected={true}
											removeWishListItem={() => removeWishlistItem(wi.id)}
										/>
									))}
								</SelectedCards>
							)}
							{notSelectedItems.map(wi => (
								<WishlistItemCard
									wishlistItem={wi}
									toggleWishlistItemSelected={() =>
										toggleWishlistItemSelected(wi.id)
									}
									selected={false}
									removeWishListItem={() => removeWishlistItem(wi.id)}
								/>
							))}
						</WishlistContent>
					</StyledBodyWrapper>
					<Footer>
						<FooterButtonsWrapper>
							<Button
								buttonStyleType={ButtonStyleTypes.secondary}
								title={t('bookNowButtonTitle')}
								disabled={!actionsAvailable}
								onClick={() => handleAuthenticatedAction(handleBookTravelPlan)}
							/>
							<Button
								title={t('saveTravelPlanButtonTitle')}
								disabled={!actionsAvailable || loading}
								onClick={() => handleAuthenticatedAction(handleSaveTravelPlan)}
							/>
						</FooterButtonsWrapper>
					</Footer>
				</>
			) : (
				<EmptyWishlistInfo />
			)}

			{guestUserModalOpen && (
				<GuestUserModal onClose={toggleGuestUserModalOpen} />
			)}
			{redirectToGetGestOpen && (
				<GetGestRedirectModal
					open={redirectToGetGestOpen}
					toggleOpen={toggleRedirectToGetGestOpen}
					handleCheckoutActions={handleCheckoutActions}
				/>
			)}
		</MainWrapper>
	);
};

export default Wishlist;
