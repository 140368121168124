import { ModalBody, ModalHeader } from 'redesigncomponents/Modal';
import { FunctionalComponent } from 'preact';
import useIntl from 'hooks/useIntl';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: #342245;
`;

type Props = {};

const Covid19InfoTip: FunctionalComponent<Props> = () => {
  const { t } = useIntl('common.covid19Info');

  return (
    <>
      <ModalHeader>{t('header')}</ModalHeader>
      <ModalBody>
        {t('infoStart')}{' '}
        <StyledLink
          href={process.env.PREACT_COVID_19_INFORMATION_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t('infoLinkLabel')}
        </StyledLink>{' '}
        {t('infoEnd')}
      </ModalBody>
    </>
  );
};

export default Covid19InfoTip;
