import { VNode } from 'preact';

import { IProductLocation } from './ProductLocation';

interface Price {
	amount: number;
	currency: string;
}

export interface PriceOption {
	id: string;
	label: string;
	name: string;
	maxQuantity: number;
	minQuantity: number;
	price: number;
	priceGroupType: string;
	productCode: string;
	seatsUsed: number;
	currencyCode: string;
}

interface ActivityLocation {
	latitude: number;
	longitude: number;
	address: string;
	countryCode: number;
}

export interface Session {
	allDay: boolean;
	startTime: string;
	startTimeLocal: string;
	endTime: string;
	endTimeLocal: string;
	seatsAvailable: number;
	prices: Array<PriceOption>;
}

export interface BookingField {
	fieldType: BookingFieldTypes;
	label: string;
	fullWidth: boolean;
	name: string;
	LabelComponent: VNode | undefined;
	listOptions: string;
	listOptionsParsed: Array<{ value: string | number; label: string }>;
	requiredPerBooking: boolean;
	requiredPerParticipant: boolean;
	value: string; // all values are converted to string
	visiblePerBooking: boolean;
	visiblePerParticipant: boolean;
	required: boolean;
	maxDate: Date;
	validate: (value: any) => void;
	updatedAt?: number;
}

export enum BookingFieldTypes {
	String = 'String',
	List = 'List',
	Boolean = 'Boolean',
	Phone = 'Phone',
	Date = 'Date',
	Hidden = 'Hidden'
}

export interface Image {
	largeUrl: string;
	mediumUrl: string;
	thumbnailUrl: string;
}

export interface ActivityOverviewModel {
	id: string;
	name: string;
	displayPrice: Price;
	image: Image;
	location: ActivityLocation;
	shortDescription: string;
	supplierName: string;
}

export interface PickupLocation {
	additionalInstructions?: string;
	address: string;
	latitude?: number;
	longitude?: number;
	locationName: string;
	minutesPrior: number;
}

export interface ActivityModel {
	id: string;
	name: string;
	images: Array<Image>;
	description: string;
	displayPrice: Price;
	bookingFields: Array<BookingField>;
	bookingTimeRequired: boolean;
	productLocation: IProductLocation;
	quantityRequiredMax: number;
	quantityRequiredMin: number;
	sessions: Array<Session>;
	unitLabel: string;
	productType: string;
	productOwner: string;
	supplierName: string;
	terms: string;
	duration: number;
	integration: string;
	pickupLocations?: PickupLocation[];
}

export interface MerchandiseOverviewModel {
	id: string;
	name: string;
	displayPrice: Price;
	image: Image;
	shortDescription: string;
	supplierName: string;
}

export interface MerchandiseModel {
	id: string;
	name: string;
	images: Array<Image>;
	description: string;
	displayPrice: Price;
	prices: Array<PriceOption>;
	bookingFields: Array<BookingField>;
	bookingTimeRequired: boolean;
	quantityRequiredMax: number;
	quantityRequiredMin: number;
	unitLabel: string;
	productType: string;
	productOwner: string;
	supplierName: string;
	terms: string;
	duration: number;
	integration: string;
	pickupLocations?: PickupLocation[];
}
