import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	BodyWrapperHeight,
	Colors,
	DeviceBreakpoints,
	FontSize,
	FontWeight
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background-color: #fff;
	color: #342245;
	border-top: 1px solid #efedf0;
	border-bottom: 1px solid #efedf0;
	height: ${BodyWrapperHeight.base};
	display: block;
	padding: 1.25rem 1.5rem;
	overflow-y: auto;
	position: relative;
	@media ${DeviceBreakpoints.small} {
		padding: 1.25rem 0.75rem;
	}
`;

export const ContentWrapper = styled.div`
	flex: 1;
`;

export const MainHeading = styled.h4`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
	margin-bottom: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const Divider = styled.div`
	width: calc(100% + 3rem);
	height: 1px;
	background: ${Colors.V50};
	margin: 1.25rem 0 1.25rem -1.875rem;
	@media ${DeviceBreakpoints.small} {
		margin: 0.5rem 0 0.5rem -0.75rem;
		width: calc(100% + 1.5rem);
		visibility: hidden;
	}
`;

export const YearInfo = styled.div`
	background: ${Colors.V400};
	display: inline-flex;
	color: ${Colors.V0};
	border-radius: 0.25rem;
	font-weight: ${FontWeight.bold};
	padding: 0.75rem 4rem;
	margin-bottom: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
		padding: 0.5rem 1.25rem;
	}
`;

export const InstructionText = styled.p`
	font-size: ${FontSize.smSize};
	margin-bottom: 1.5rem;
`;

export const CardsWrapper = styled.div`
	> div {
		margin-bottom: 0.75rem;
		/* &:last-child {
      margin-bottom: 0;
    } */
	}
`;

export const LoaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CardWrapper = styled.div`
	width: 100%;
	background-color: ${Colors.V20};
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding: 0.75rem 1.25rem;
	color: ${Colors.V900};
	border-radius: 0.25rem;
	border: ${props =>
		props.collapsed ? `1px solid ${Colors.V400}` : `1px solid transparent`};
	&:hover {
		background-color: ${props => (props.collapsed ? Colors.V20 : Colors.V30)};
		box-shadow: ${props =>
			props.collapsed ? `none` : `0px 0px 0px 1px ${Colors.V400} inset`};
	}
	@media ${DeviceBreakpoints.small} {
		padding: 0.625rem 0.5rem;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const CheckboxWrapper = styled.div`
	width: 20px;
	height: 20px;
	margin-right: 12px;
`;

export const RightWrapper = styled.div`
	display: flex;
	align-items: center;
	div:first-child {
		margin-right: 0.625rem;
	}
`;

export const CardName = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Name = styled.h4`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.lgSize};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.base};
		font-weight: ${FontWeight.bold};
	}
`;

export const Location = styled.span`
	color: ${Colors.V300};
	font-size: ${FontSize.xsSize};
	line-height: ${FontSize.xsSize};
`;

export const HeadWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

export const DetailsBox = styled.div`
	display: ${props => (props.collapsed ? 'block' : 'none')};
	> div {
		margin-top: 0.75rem;
		@media ${DeviceBreakpoints.small} {
			margin-top: 0.5rem;
		}
	}
`;
