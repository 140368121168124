import { FunctionalComponent } from 'preact';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import SelectComponent from './StyledSelect';
import Menu from './components/SelectMenu';
import Option from './components/SelectOption';

export type OptionTypes = {
	id?: string;
	label: string;
	value: number | string;
	selected?: boolean;
	key?: any;
};

export type SelectProps = {
	input?: FieldInputProps<any>;
	meta?: FieldMetaState<any>;
	options?: OptionTypes[];
	isSearchable?: boolean;
	placeholder?: string;
	isDisabled?: boolean;
	required?: boolean;
	[otherProp: string]: any;
};

const FieldMultiselect: FunctionalComponent<SelectProps> = ({
	input,
	meta = {},
	label,
	options = [],
	isSearchable = false,
	placeholder = null,
	disabled = false,
	required = false,
	...rest
}) => {
	const error = meta?.touched && (meta.error || meta.submitError);

	const handleOnChange = selected => {
		input.onChange(selected);
	};

	const handleSelectAll = () => {
		input.onChange(options);
	};

	const handleClearAll = () => {
		input.onChange([]);
	};

	return (
		<SelectComponent
			value={input.value}
			label={label}
			placeholder={
				input?.value?.length > 0
					? `${input?.value?.length} 
					${input?.value?.length === 1 ? 'destination' : 'destinations'} 
					selected`
					: placeholder
			}
			options={options}
			isMulti
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			components={{
				Option,
				Menu
			}}
			allowSelectAll={true}
			inputProps={{ ...input, ...rest }}
			controlShouldRenderValue={false}
			error={error}
			onChange={handleOnChange}
			selectAll={handleSelectAll}
			clearAll={handleClearAll}
		/>
	);
};

export default FieldMultiselect;
