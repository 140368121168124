import dayjs from 'dayjs';

const generateTravelPeriodString = (travelPeriod: [Date, Date]) => {
	const departureDate = travelPeriod?.[0];
	const returnDate = travelPeriod?.[1];
	if (!returnDate && !departureDate) return;
	if (departureDate && !returnDate) {
		return dayjs(departureDate).format('DD.MM.YYYY');
	} else if (departureDate && returnDate) {
		const departureDateConverted = dayjs(departureDate);
		const returnDateConverted = dayjs(returnDate);

		if (returnDateConverted.year() === departureDateConverted.year()) {
			return `${departureDateConverted.format(
				'DD.MM'
			)} - ${returnDateConverted.format('DD.MM')}`;
		} else {
			return `${departureDateConverted.format(
				'DD.MM.YYYY'
			)} - ${returnDateConverted.format('DD.MM.YYYY')}`;
		}
	}
};

export default generateTravelPeriodString;
