import styled from 'styled-components';

import { Colors, FontSize, FontWeight,  DeviceBreakpoints } from 'styles/defaultTheme';

const HeaderWrapper = styled.div`
	align-items: center;
	display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
	padding: 1.25rem 0;
	@media ${DeviceBreakpoints.small} {
		display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
		padding: 0;
	}
`;

const HeaderTitle = styled.h4`
	font-size: ${({ mobile }) => (mobile ? `${FontSize.lgSize}` : `${FontSize.xlSize}`)};
	font-weight: ${({ mobile }) => (mobile ? '600' : '700')};
	margin-left: ${({ Icon }) => (Icon ? '0.625rem' : 0)};
	color: ${Colors.V900};
`;

const IconWrapper = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6.875rem;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0;
	box-shadow: 0px 2px 3px rgba(158, 149, 166, 0.2); 
`;

type IProps = {
	label: string;
	Icon?: string;
	style?: any;
	mobile?: boolean;
};

export const InputHeader = ({
	label,
	Icon,
	mobile,
	style = {}
}: IProps) => {
	return (
		<HeaderWrapper style={style} mobile={mobile}>
			{Icon && (
				<IconWrapper>
					<Icon />
				</IconWrapper>
			)}
			<HeaderTitle Icon={Icon} mobile={mobile}>{label}</HeaderTitle>
		</HeaderWrapper>
	);
};
