import { useState, useEffect, useRef } from 'preact/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import { Paths } from 'router';
import Toast from 'redesigncomponents/Toast';
import useIntl from 'hooks/useIntl';

const authTokenResponseKey = 'authTokenResponse';
type SignInStatus = 'SUCCESS' | 'FAILURE';

type SignInEventMessage = {
	message: string;
	data: {
		status: SignInStatus;
		data: {
			message: string;
			status: SignInStatus;
			authToken?: string;
			idToken?: string;
			refreshToken?: string;
			state?: string;
		};
	};
};

const useSocialSignIn = () => {
	const history = useHistory();
	const { state: { from = undefined } = {} } = useLocation();
	const { t } = useIntl('signIn');

	const [stateValue] = useState<string>(uuidv4());
	const popup = useRef<Window>();
	const { tokenLogin } = useUserAuth();

	const startGoogleSignIn = () => {
		const url = `${process.env.PREACT_APP_AUTH0_SOCIAL_AUTHORIZE_URL}&state=${stateValue}`;

		popup.current = window.open(
			url,
			'popup',
			'width=600,height=600,scrollbars=no,resizable=no'
		);
		return false;
	};

	const closePopup = () => {
		try {
			popup.current.close();
		} catch (error) {
			console.error(error);
			// ignored
		} finally {
			popup.current = undefined;
		}
	};

	const onMessageReceivedFromPopUp = async event => {
		const signInEventMessage: SignInEventMessage = event.data;

		if (signInEventMessage.message === authTokenResponseKey) {
			const data = signInEventMessage.data;
			if (data.status === 'SUCCESS' && stateValue === data.data.state) {
				await tokenLogin(data.data.idToken, data.data.refreshToken);
				closePopup();
				history.replace({
					pathname: from ? from : Paths.AccountMain
				});
			} else if (stateValue !== data.data.state) {
				Toast.error(t('invalidStateReturnedFromCallback'));
				closePopup();
			} else {
				Toast.error(data.data.message);
				closePopup();
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', onMessageReceivedFromPopUp);
		return () =>
			window.removeEventListener('message', onMessageReceivedFromPopUp);
	}, []);

	return {
		startGoogleSignIn
	};
};

export default useSocialSignIn;
