import styled from 'styled-components';

import BodyWrapper from 'redesigncomponents/BodyWrapper';
import {
	Colors,
	FontSize,
	FontWeight,
	BodyWrapperHeight,
	DeviceBreakpoints
} from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
`;

export const SectionContainer = styled.div`
	margin-top: 1.25rem;
	@media ${DeviceBreakpoints.small} {
		margin-top: 0;
		padding: 0.5rem 0.75rem;
	}
`;

export const DetailsContainer = styled.div`
	margin-top: 0.75rem;
	background: ${Colors.V0};
	border-radius: 0.25rem;
	padding: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		margin-top: 0;
		background: transparent;
		border-radius: 0;
		padding: 0;
	}
`;

export const OptionsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	@media (max-width: 680px) {
		grid-template-columns: 1fr;
	}
`;

export const SectionHeader = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.875rem;
	@media ${DeviceBreakpoints.small} {
		margin-bottom: 0.5rem;
	}
`;

export const HeaderTitle = styled.h4`
	font-size: ${FontSize.base};
	padding-left: 0.25rem;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const PriceTitle = styled.p``;

export const PriceValue = styled.p`
	font-size: ${FontSize.xlSize};
	font-weight: ${FontWeight.bold};
`;

export const TotalPrice = styled.div`
	background: ${Colors.V200};
	border-radius: 0.25rem;
	color: ${Colors.V0};
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	@media ${DeviceBreakpoints.small} {
		order: 3;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`;

export const RoomContainer = styled.div`
	margin-top: 0.75rem;
	background: ${Colors.V0};
	border-radius: 0.25rem;
	padding: 0.75rem;
	@media ${DeviceBreakpoints.small} {
		background: transparent;
		border-radius: 0;
		padding: 0;
	}
`;

export const RoomDescriptionHeader = styled.div`
	background: ${Colors.V30};
	padding: 0.25rem 0.5rem;
	margin-bottom: 0.625rem;
	font-size: ${FontSize.base};
	font-weight: ${FontWeight.bold};
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media ${DeviceBreakpoints.small} {
		background: ${Colors.V0};
	}
`;

export const RoomDescriptionText = styled.div`
	font-size: ${FontSize.smSize};
`;

export const LeftContainer = styled.div``;

export const RoomTitle = styled.h4``;

export const RoomSubtitle = styled.h6``;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const PriceInfo = styled.span``;

export const PersonsInfo = styled.span`
	font-size: ${FontSize.smSize};
	font-weight: ${FontWeight.normal};
`;

export const LoaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const BoxHeader = styled.h6`
	font-size: ${FontSize.base};
	margin: 0;
	margin-bottom: 0.5rem;
`;

export const ImageGalleryContainer = styled.div`
	height: 100%;
	width: 100%;
`;

export const MapDivider = styled.div`
	border-bottom: 2px solid #e5e2e7;
	position: relative;
	margin: 1rem 0;

	:before,
	:after {
		position: absolute;
		bottom: -6px;
		left: 0;
		height: 10px;
		width: 10px;
		background: #e5e2e7;
		content: '';
		border-radius: 5px;
	}

	:after {
		right: 0;
		left: auto;
	}
`;

export const ChildAgeWrapper = styled.div`
	padding: 0.5rem 0;
`;

export const InfoMessage = styled.div`
	font-size: ${FontSize.smSize};
	padding: 0.5rem;
`;
