import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import cloneDeep from 'lodash.clonedeep';

import useIntl from 'hooks/useIntl';
import useToggle from 'hooks/useToggle';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import CounterItem from 'redesigncomponents/CounterItem';
import StyledCheckbox from 'redesigncomponents/FieldCheckbox/StyledCheckbox';
import StyledTextInput from 'redesigncomponents/FieldTextInput/StyledTextInput';

import AccordionIcon from 'assets/accordion-arrow.svg';
import BaggageIcon from 'assets/baggage.svg';
import AdultsIcon from 'assets/icons/counter/adults.svg';

import {
	DropDownContainer,
	DropDownHeader,
	DropDownHeaderCell,
	ErrorText,
	HeaderTitle,
	InputRow,
	MenuBody,
	MenuBodySection,
	MenuContainer,
	MenuFooter,
	MenuHeader,
	MenuRow,
	SelectedValue
} from './components/StyledComponents';
import useOptionCategories, {
	TrainbusOptionCategoriesType,
	TrainbusOptionCategoriesWithKeyNamesType,
	initialValuesOptionCategories
} from './useOptionCategories';

interface IProps {
	value: TrainbusOptionCategoriesType;
	onChange: (value: TrainbusOptionCategoriesType) => void;
}

const OptionCategories: FunctionalComponent<IProps> = ({ onChange }) => {
	const { t } = useIntl('transportList.options.optionCategories');
	const [collapsed, toggleCollapsed] = useToggle();
	const [savedOptionCategories, setSavedOptionCategories] =
		useState<TrainbusOptionCategoriesWithKeyNamesType>();
	const {
		updateCategoryCount,
		updatePassengerAge,
		updateSeniorIsRetired,
		resetOptionCategories,
		getPassengerCategoryValue,
		getAddonCategoryValue,
		getPassengerAge,
		getSeniorIsRetired,
		getOptionCategoriesWithRealIds,
		ageRangeByPassengerCategory,
		countPerOptionCategoryType,
		validationErrors,
		optionCategories,
		areOptionCategoriesValid,
		optionCategoryParameters
	} = useOptionCategories();

	useEffect(() => {
		resetOptionCategories(cloneDeep(initialValuesOptionCategories));
		onChange(cloneDeep(initialValuesOptionCategories));
	}, []);

	useEffect(() => {
		if (Object.keys(optionCategoryParameters).length) {
			onChange(getOptionCategoriesWithRealIds());
			setSavedOptionCategories(cloneDeep(optionCategories));
		}
	}, [optionCategoryParameters]);

	const onSave = () => {
		onChange(getOptionCategoriesWithRealIds());
		setSavedOptionCategories(cloneDeep(optionCategories));
		toggleCollapsed();
	};

	const onCancel = () => {
		if (collapsed) {
			resetOptionCategories(cloneDeep(savedOptionCategories));
			toggleCollapsed(false);
		}
	};

	const onDropdownClick = () => {
		if (areOptionCategoriesValid && collapsed) {
			onSave();
		} else if (!collapsed) {
			toggleCollapsed(true);
		}
	};

	return (
		<DropDownContainer>
			<DropDownHeader onClick={onDropdownClick}>
				<DropDownHeaderCell>
					<AdultsIcon />
					<SelectedValue>
						{countPerOptionCategoryType['passengers']}
					</SelectedValue>
				</DropDownHeaderCell>
				<DropDownHeaderCell>
					<BaggageIcon />
					<SelectedValue>{countPerOptionCategoryType['addons']}</SelectedValue>
				</DropDownHeaderCell>
				<DropDownHeaderCell>
					<AccordionIcon></AccordionIcon>
				</DropDownHeaderCell>
			</DropDownHeader>
			{collapsed && (
				<MenuContainer>
					<MenuBody>
						<MenuBodySection>
							<MenuHeader>
								<AdultsIcon />
								<HeaderTitle>{t('passengersTitle')}</HeaderTitle>
							</MenuHeader>
							{validationErrors['passengers'] && (
								<ErrorText>{validationErrors['passengers']}</ErrorText>
							)}
							<MenuRow>
								<CounterItem
									value={getPassengerCategoryValue('VU')}
									onChange={value =>
										updateCategoryCount('passengers', 'VU', value)
									}
									min={0}
									title={t('adults')}
								></CounterItem>
							</MenuRow>
							<MenuRow>
								<CounterItem
									value={getPassengerCategoryValue('BO')}
									onChange={value =>
										updateCategoryCount('passengers', 'BO', value)
									}
									min={0}
									title={t('child')}
									subtitle={t('passengerCategoryAgeRange', {
										min: ageRangeByPassengerCategory['BO']?.min,
										max: ageRangeByPassengerCategory['BO']?.max
									})}
								></CounterItem>
								{Array.from({ length: getPassengerCategoryValue('BO') }).map(
									(_, index) => {
										return (
											<InputRow>
												<StyledTextInput
													inputProps={{
														pattern: '[0-9]',
														min: ageRangeByPassengerCategory['BO']?.min,
														max: ageRangeByPassengerCategory['BO']?.max,
														value: getPassengerAge('BO', index),
														onChange: e =>
															updatePassengerAge('BO', index, e.target.value)
													}}
													type="number"
													label={t('childIndex', { index: index + 1 })}
													error={
														getPassengerAge('BO', index) &&
														validationErrors['BO']?.[index]
													}
												/>
											</InputRow>
										);
									}
								)}
							</MenuRow>
							<MenuRow>
								<CounterItem
									value={getPassengerCategoryValue('SU')}
									onChange={value =>
										updateCategoryCount('passengers', 'SU', value)
									}
									min={0}
									title={t('student')}
									subtitle={t('passengerCategoryAgeRange', {
										min: ageRangeByPassengerCategory['SU']?.min,
										max: ageRangeByPassengerCategory['SU']?.max
									})}
								></CounterItem>
								{Array.from({ length: getPassengerCategoryValue('SU') }).map(
									(_, index) => {
										return (
											<InputRow>
												<StyledTextInput
													inputProps={{
														pattern: '[0-9]',
														min: ageRangeByPassengerCategory['SU']?.min,
														max: ageRangeByPassengerCategory['SU']?.max,
														value: getPassengerAge('SU', index),
														onChange: e =>
															updatePassengerAge('SU', index, e.target.value)
													}}
													type="number"
													label={t('studentIndex', { index: index + 1 })}
													error={
														getPassengerAge('SU', index) &&
														validationErrors['SU']?.[index]
													}
												/>
											</InputRow>
										);
									}
								)}
							</MenuRow>
							<MenuRow>
								<CounterItem
									value={getPassengerCategoryValue('SE')}
									onChange={value =>
										updateCategoryCount('passengers', 'SE', value)
									}
									min={0}
									title={t('senior')}
									subtitle={t('passengerCategoryAgeRange', {
										min: ageRangeByPassengerCategory['SE']?.min,
										max: ageRangeByPassengerCategory['SE']?.max
									})}
								></CounterItem>
								{Array.from({ length: getPassengerCategoryValue('SE') }).map(
									(_, index) => {
										return (
											<InputRow>
												<StyledTextInput
													inputProps={{
														pattern: '[0-9]',
														min: getSeniorIsRetired(index)
															? ageRangeByPassengerCategory['PS']?.min
															: ageRangeByPassengerCategory['SE']?.min,
														max: ageRangeByPassengerCategory['SE']?.max,
														value: getPassengerAge('SE', index),
														onChange: e =>
															updatePassengerAge('SE', index, e.target.value)
													}}
													type="number"
													label={t('seniorIndex', { index: index + 1 })}
													error={
														getPassengerAge('SE', index) &&
														validationErrors['SE']?.[index]
													}
												/>
												<StyledCheckbox
													label={t('retired')}
													inputProps={{
														checked: getSeniorIsRetired(index),
														onChange: e =>
															updateSeniorIsRetired(index, e.target.checked)
													}}
												/>
											</InputRow>
										);
									}
								)}
							</MenuRow>
						</MenuBodySection>
						<MenuBodySection>
							<MenuHeader>
								<BaggageIcon />
								<HeaderTitle>{t('addonsTitle')}</HeaderTitle>
							</MenuHeader>
							<MenuRow>
								{validationErrors['pets'] && (
									<ErrorText>{validationErrors['pets']}</ErrorText>
								)}
								<CounterItem
									value={getAddonCategoryValue('pets')}
									onChange={value =>
										updateCategoryCount('addons', 'pets', value)
									}
									min={0}
									title={t('pets')}
								></CounterItem>
							</MenuRow>
						</MenuBodySection>
					</MenuBody>
					<MenuFooter>
						<Button
							title={t('cancelButtonTitle')}
							buttonStyleType={ButtonStyleTypes.secondary}
							onClick={onCancel}
						></Button>
						<Button
							title={t('saveButtonTitle')}
							buttonStyleType={ButtonStyleTypes.primary}
							onClick={onSave}
							disabled={!areOptionCategoriesValid}
						></Button>
					</MenuFooter>
				</MenuContainer>
			)}
		</DropDownContainer>
	);
};

export default OptionCategories;
