import { useMutation } from '@apollo/client';

import { myPagesClient } from 'apollo/client';
import {
	DELETE_TRAVEL_PLAN,
	UPDATE_TRAVEL_PLAN_ITEM
} from 'apollo/myPages/mutations';
import { checkoutVar } from 'redesigncache/checkout/cache';
import { useCheckout } from 'redesigncache/checkout/useCheckout';
import Toast from 'redesigncomponents/Toast';

import { IComposedTravelPlanItem } from '../../TravelPlan/useTravelPlan';

const useUpdateTravelPlanItem = (
	travelPlanItem: IComposedTravelPlanItem,
	travelPlanId: number | string
) => {
	const { updateCheckout } = useCheckout();

	const [updatePaidTravelPlanItem] = useMutation(UPDATE_TRAVEL_PLAN_ITEM, {
		client: myPagesClient
	});

	const [removeTravelPlan] = useMutation(DELETE_TRAVEL_PLAN, {
		client: myPagesClient
	});

	const setPaidTravelPlanItem = async () => {
		const oldTravelPlans = [...checkoutVar().travelPlans];
		const travelPlanIndex = oldTravelPlans.findIndex(
			tp => tp.id === travelPlanId
		);

		if (travelPlanIndex !== -1) {
			const cacheTravelPlanItemIndex = oldTravelPlans[
				travelPlanIndex
			].travelPlanItems.findIndex(tpi => tpi.id === travelPlanItem.id);
			oldTravelPlans[travelPlanIndex].travelPlanItems.splice(
				cacheTravelPlanItemIndex,
				1,
				{ ...travelPlanItem, paid: true }
			);
			oldTravelPlans[travelPlanIndex].updatedAt = Date.now();
			updateCheckout([...oldTravelPlans]);
			if (oldTravelPlans[travelPlanIndex].savedTravelPlan) {
				// check if all travel plan items are paid. If so, delete travel plan
				if (
					oldTravelPlans[travelPlanIndex].travelPlanItems.every(i => i.paid)
				) {
					try {
						await removeTravelPlan({
							variables: {
								travelPlanId: travelPlanId
							}
						});
					} catch (error) {
						Toast.error(error);
					}
				}

				// update paid travel plan item with paid: true
				else {
					try {
						await updatePaidTravelPlanItem({
							variables: {
								travelPlanId: travelPlanId,
								travelPlanItem: {
									...travelPlanItem,
									paid: true
								}
							}
						});
					} catch (error) {
						Toast.error(error);
					}
				}
			}
		}
	};

	const setTravelPlanItemExpired = () => {
		const oldTravelPlans = [...checkoutVar().travelPlans];

		const travelPlanIndex = oldTravelPlans.findIndex(
			tp => tp.id === travelPlanId
		);

		if (travelPlanIndex !== -1) {
			const cacheTravelPlanItemIndex = oldTravelPlans[
				travelPlanIndex
			].travelPlanItems.findIndex(tpi => tpi.id === travelPlanItem.id);

			oldTravelPlans[travelPlanIndex].travelPlanItems.splice(
				cacheTravelPlanItemIndex,
				1,
				{ ...travelPlanItem, expired: true }
			);
			oldTravelPlans[travelPlanIndex].updatedAt = Date.now();
			updateCheckout([...oldTravelPlans]);
		}
	};

	const setTravelPlanItemPrice = (amount: number) => {
		const oldTravelPlans = [...checkoutVar().travelPlans];
		const travelPlanIndex = oldTravelPlans.findIndex(
			tp => tp.id === travelPlanId
		);

		if (travelPlanIndex !== -1) {
			const cacheTravelPlanItemIndex = oldTravelPlans[
				travelPlanIndex
			].travelPlanItems.findIndex(tpi => tpi.id === travelPlanItem.id);
			const item = travelPlanItem.item;
			oldTravelPlans[travelPlanIndex].travelPlanItems.splice(
				cacheTravelPlanItemIndex,
				1,
				{
					...travelPlanItem,
					item: {
						...item,
						price: { ...item.price, amount }
					}
				}
			);
			oldTravelPlans[travelPlanIndex].updatedAt = Date.now();
			updateCheckout([...oldTravelPlans]);
		}
	};

	return {
		setTravelPlanItemPrice,
		setPaidTravelPlanItem,
		setTravelPlanItemExpired
	};
};

export default useUpdateTravelPlanItem;
