import { FunctionalComponent, VNode } from 'preact';

import ImageGalleryIcon from 'assets/image-gallery.svg';

import {
	BoxContainer,
	BoxSubtitle,
	BoxTitle,
	ButtonText,
	GalleryButton,
	HeroContainer,
	PositionContainer
} from './components/StyledComponents';

interface IProps {
	title: string;
	infos: string[] | VNode[];
	backgroundImage: string;
	onClick?: () => void;
}

const HeroHeader: FunctionalComponent<IProps> = ({
	title,
	infos,
	backgroundImage,
	onClick
}) => {
	return (
		<HeroContainer backgroundImage={backgroundImage}>
			{onClick && (
				<GalleryButton onClick={onClick}>
					<ImageGalleryIcon></ImageGalleryIcon>
					<ButtonText>Photos</ButtonText>
				</GalleryButton>
			)}
			<PositionContainer>
				<BoxContainer>
					<BoxTitle>{title}</BoxTitle>
					{infos.map((info, index) => {
						return <BoxSubtitle key={index}>{info}</BoxSubtitle>;
					})}
				</BoxContainer>
			</PositionContainer>
		</HeroContainer>
	);
};

export default HeroHeader;
