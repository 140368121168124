import { useMemo } from 'preact/hooks';

const useAccommodationCover = code => {
	const coverImage = useMemo(() => {
		switch (code) {
			case 'GB008566': {
				return 'assets/images/hotels/GB008566/hotel-cover.png';
			}
			case 'SE001455': {
				return 'assets/images/hotels/SE001455/hotel-cover.png';
			}
		}
	}, [code]);

	return { coverImage };
};

export default useAccommodationCover;
