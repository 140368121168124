import styled from 'styled-components';

import { Colors, FontSize, FontWeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const DropDownContainer = styled.div`
	position: relative;
	font-size: ${FontSize.base};
	color: ${Colors.V900};
`;

export const DropDownHeader = styled.div`
	display: flex;
	background-color: ${Colors.V10};
	border: 1px solid ${Colors.V30};
	border-radius: 0.25rem;
	justify-content: space-between;
	padding: 1rem;
	height: 3.5rem;
	min-width: 11.25rem;
	@media ${DeviceBreakpoints.small} {
		justify-content: center;
		gap: 2rem;
	}
`;

export const DropDownHeaderCell = styled.div`
	align-items: center;
	display: flex;
`;

export const SelectedValue = styled.span`
	margin-left: 0.625rem;
`;

export const MenuContainer = styled.div`
	padding: 0.5rem 0.25rem;
	background-color: ${Colors.V0};
	box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
	border-radius: 0.25rem;
	min-width: 27.3rem;
	position: absolute;
	width: 100%;
	right: 0;
	z-index: 102;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	@media ${DeviceBreakpoints.small} {
		min-width: 20rem;
	}
`;

export const MenuHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 0.25rem;
`;

export const MenuFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.75rem 0.5rem;
	border-top: 1px solid ${Colors.V50};
`;

export const MenuBody = styled.div`
	display: flex;
	gap: 1rem;
	@media ${DeviceBreakpoints.small} {
		flex-direction: column;
	}
`;

export const MenuBodySection = styled.div`
	flex: 1;
`;

export const HeaderTitle = styled.h5`
	font-weight: ${FontWeight.semibold};
	margin-left: 0.625rem;
`;

export const MenuRow = styled.div``;

export const InputRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 0.5rem;
	margin-top: 0.4rem;
	margin-bottom: 0.2rem;
`;

export const ErrorText = styled.span`
	font-size: ${FontSize.smSize};
	line-height: 14px;
	padding: 5px 5px 0 5px;
	color: #b3261e;
	display: inline-block;
`;
