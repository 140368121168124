import { FunctionalComponent } from 'preact';

import config from 'config/config';
import AccountDetails from 'redesignscreens/Account/AccountDetails';
import TravelHistory from 'redesignscreens/Account/TravelHistory';
import TravelPlanner from 'redesignscreens/Account/TravelPlanner';
import AccountMain from 'redesignscreens/Account/Welcome/index';
import AffiliateLinkAccommodationDetails from 'redesignscreens/AffiliateLink/AccommodationDetails';
import AffiliateLinkActivityDetails from 'redesignscreens/AffiliateLink/ActivityDetails';
import AffiliateLinkAirTransportList from 'redesignscreens/AffiliateLink/AirTransportList';
import AffiliateCheckout from 'redesignscreens/AffiliateLink/Checkout';
import AffiliateLinkMerchandiseDetails from 'redesignscreens/AffiliateLink/MerchandiseDetails';
import AffiliateProductTypeRedirect from 'redesignscreens/AffiliateLink/ProductTypeRedirect';
import AffiliateLinkTrainTransportDetails from 'redesignscreens/AffiliateLink/TrainTransportDetails';
import AffiliateLinkTrainTransportList from 'redesignscreens/AffiliateLink/TrainTransportList';
import ForgotPassword from 'redesignscreens/Auth/ForgotPassword';
import SignIn from 'redesignscreens/Auth/SignIn';
import SignUp from 'redesignscreens/Auth/SignUp';
import Checkout from 'redesignscreens/Checkout';
import AccommodationDetails from 'redesignscreens/Main/Accommodation/AccommodationDetails';
import Accommodation from 'redesignscreens/Main/Accommodation/AccommodationList';
import ActivityDetails from 'redesignscreens/Main/Activities/ActivityDetails';
import Activities from 'redesignscreens/Main/Activities/ActivityList';
import Main from 'redesignscreens/Main/Main/index';
import MerchandiseDetails from 'redesignscreens/Main/Merchandise/MerchandiseDetails';
import MerchandiseList from 'redesignscreens/Main/Merchandise/MerchandiseList';
import AirTransportList from 'redesignscreens/Main/Transport/AirTransportList';
import TrainTransportDetails from 'redesignscreens/Main/Transport/TrainTransportDetails';
import TrainTransportList from 'redesignscreens/Main/Transport/TrainTransportList';
import TermsOfUse from 'redesignscreens/TermsOfUse';
import Wishlist from 'redesignscreens/Wishlist';
import { TransportType } from 'types/enums';

import flattenRoutes from './flattenRoutes';

export enum Paths {
	Main = '/',
	Wishlist = '/wishlist',
	TermsOfUse = '/account/terms-of-use',
	Activities = '/activities',
	ActivityDetails = '/activities/:id',
	Merchandise = '/merchandise',
	MerchandiseDetails = '/merchandise/:id',
	Accommodations = '/accommodations',
	AccommodationDetails = '/accommodations/:id',
	AirTransport = '/transport/air',
	TrainTransport = '/transport/trainbus',
	TrainTransportDetails = '/transport/trainbus/details',
	SignIn = '/account/login',
	SignUp = '/account/register',
	ForgotPassword = '/account/forgot-password',
	AccountMain = '/account',
	AccountDetails = '/account/details',
	Invoices = '/account/invoices',
	TravelPlanner = '/account/travel-planner',
	TravelHistory = '/account/travel-history',
	Checkout = '/checkout',
	AffiliateProductTypeRedirect = '/affiliate-link/:id/product-type-redirect',
	AffiliateActivityDetails = '/affiliate-link/activity/:id/',
	AffiliateMerchandiseDetails = '/affiliate-link/merchandise/:id/',
	AffiliateAccommodationDetails = '/affiliate-link/accommodation/:id/',
	AffiliateTransportListAir = '/affiliate-link/transport-search/air',
	AffiliateTransportListTrainbus = '/affiliate-link/transport-search/trainbus',
	AffiliateTransportDetailsTrainbus = '/affiliate-link/transport-details/trainbus',
	AffiliateCheckout = '/affiliate-link/checkout'
}

export type TRoute = {
	path: string;
	private?: boolean;
	nameKey: string;
	component: FunctionalComponent;
	exact: boolean;
	children?: Array<TRoute>;
};

const MainFlowRoutesHierarchy: Array<TRoute> = [
	{
		path: Paths.Main,
		nameKey: 'Main',
		component: Main,
		exact: true,
		children: [
			{
				path: Paths.Activities,
				nameKey: 'Activities',
				component: Activities,
				exact: true,
				children: [
					{
						path: Paths.ActivityDetails,
						nameKey: undefined,
						component: ActivityDetails,
						exact: true
					}
				]
			},
			{
				path: Paths.Merchandise,
				nameKey: 'Merchandise',
				component: MerchandiseList,
				exact: true,
				children: [
					{
						path: Paths.MerchandiseDetails,
						nameKey: undefined,
						component: MerchandiseDetails,
						exact: true
					}
				]
			},
			{
				path: Paths.Accommodations,
				nameKey: 'Accommodation',
				component: Accommodation,
				exact: true,
				children: [
					{
						path: Paths.AccommodationDetails,
						nameKey: undefined,
						component: AccommodationDetails,
						exact: true
					}
				]
			},
			{
				path: Paths.AirTransport,
				nameKey: 'Transport',
				component: AirTransportList,
				exact: true
			},
			{
				path: Paths.TrainTransport,
				nameKey: 'Transport',
				component: TrainTransportList,
				exact: true,
				children: [
					{
						path: Paths.TrainTransportDetails,
						nameKey: undefined,
						component: TrainTransportDetails,
						exact: true
					}
				]
			}
		]
	},
	{
		path: Paths.Wishlist,
		nameKey: 'Wishlist',
		component: Wishlist,
		exact: true,
		private: false
	},
	{
		path: Paths.Checkout,
		nameKey: 'Checkout',
		component: Checkout,
		exact: true
	},
	{
		path: Paths.AccountMain,
		nameKey: 'AccountMain',
		component: AccountMain,
		exact: true,
		private: true,
		children: [
			{
				path: Paths.SignIn,
				nameKey: undefined,
				component: SignIn,
				exact: true
			},
			{
				path: Paths.SignUp,
				nameKey: undefined,
				component: SignUp,
				exact: true
			},
			{
				path: Paths.ForgotPassword,
				nameKey: undefined,
				component: ForgotPassword,
				exact: true
			},
			{
				path: Paths.AccountDetails,
				nameKey: 'AccountDetails',
				component: AccountDetails,
				exact: true,
				private: true
			},
			{
				path: Paths.TravelPlanner,
				nameKey: 'TravelPlanner',
				component: TravelPlanner,
				exact: true,
				private: true
			},
			{
				path: Paths.TravelHistory,
				nameKey: 'TravelHistory',
				component: TravelHistory,
				exact: true,
				private: true
			}
		]
	},
	{
		path: Paths.TermsOfUse,
		nameKey: 'TermsOfUse',
		component: TermsOfUse,
		exact: true,
		children: []
	}
];

export const MainFlowRoutes: Array<TRoute> = flattenRoutes(
	MainFlowRoutesHierarchy
);

const AffiliateLinkRoutesHierarchy: Array<TRoute> = [
	{
		path: '/affiliate-link/:id/product-type-redirect',
		nameKey: undefined,
		component: AffiliateProductTypeRedirect,
		exact: true
	},
	{
		path: '/affiliate-link/activities/:id/',
		nameKey: undefined,
		component: AffiliateLinkActivityDetails,
		exact: true
	},
	{
		path: '/affiliate-link/merchandise/:id/',
		nameKey: undefined,
		component: AffiliateLinkMerchandiseDetails,
		exact: true
	},
	{
		path: '/affiliate-link/accommodation/:id/',
		nameKey: undefined,
		component: AffiliateLinkAccommodationDetails,
		exact: true
	},
	{
		path: '/affiliate-link/transport-search/air',
		nameKey: undefined,
		component: AffiliateLinkAirTransportList,
		exact: true
	},
	{
		path: config.enabledTransportTypes[TransportType.TrainBus]
			? '/affiliate-link/transport-search/trainbus'
			: 'disabled',
		nameKey: undefined,
		component: AffiliateLinkTrainTransportList,
		exact: true
	},
	{
		path: '/affiliate-link/transport-details/trainbus',
		nameKey: undefined,
		component: AffiliateLinkTrainTransportDetails,
		exact: true
	},
	{
		path: '/affiliate-link/checkout',
		nameKey: 'Checkout',
		component: AffiliateCheckout,
		exact: true
	}
];

export const AffiliateLinkFlowRoutes: Array<TRoute> = flattenRoutes(
	AffiliateLinkRoutesHierarchy
);
