import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';

import AccordionIcon from 'assets/accordion-arrow.svg';

import {
	AccordionBody,
	AccordionContainer,
	AccordionHeader,
	AccordionTitle,
	SvgWrapper,
	TitleWrapper
} from './components/StyledComponents';

interface IProps {
	title?: string;
	defaultValue?: boolean;
	Icon?: FunctionalComponent;
}

const Accordion: FunctionalComponent<IProps> = ({
	title,
	children,
	defaultValue,
	Icon
}) => {
	const [collapsed, setCollapsed] = useState(defaultValue);
	const toggleCollaped = () => setCollapsed(collapsed => !collapsed);

	return (
		<AccordionContainer>
			<AccordionHeader onClick={toggleCollaped}>
				<TitleWrapper>
					{Icon && <Icon />}
					<AccordionTitle>{title}</AccordionTitle>
				</TitleWrapper>
				<SvgWrapper collapsed={collapsed}>
					<AccordionIcon></AccordionIcon>
				</SvgWrapper>
			</AccordionHeader>
			{collapsed && <AccordionBody>{children}</AccordionBody>}
		</AccordionContainer>
	);
};

export default Accordion;
