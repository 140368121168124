import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import { PriceOption, Session } from 'redesigntypes/ActivityModel';

import useActivityDetailsCache from './cache/useActivityDetailsCache';
import getNumberOfSelectedSeats from './getNumberOfSelectedSeats';
import { getActivityDetailsMapDateKey } from './useActivityDetails';

const useActivityOffer = () => {
	const { t: activityDetailsTranslations } = useIntl('activityDetails');
	const {
		activity,
		selectedDate,
		selectedSession,
		selectedTickets,
		availableDates,
		sessionsByDate,
		setSelectedDate,
		setSelectedSession,
		setSelectedTickets
	} = useActivityDetailsCache();

	const isDateAvailable = (date: Date) => {
		return availableDates?.some(
			d =>
				getActivityDetailsMapDateKey(d) === getActivityDetailsMapDateKey(date)
		);
	};

	const getSessionsForDate = (date: Date) => {
		return sessionsByDate[getActivityDetailsMapDateKey(date)] || [];
	};

	const sessionOptions = useMemo(() => {
		if (sessionsByDate && selectedDate) {
			const sessionsForDate = getSessionsForDate(selectedDate);

			return sessionsForDate?.map((s: Session) => ({
				value: s.startTimeLocal,
				label: s.allDay
					? activityDetailsTranslations('allDay')
					: dayjs(s.startTimeLocal, { utc: true }).format('HH:mm')
			}));
		}
		return [];
	}, [sessionsByDate, selectedDate]);

	const setSelectedDateValue = (date: Date) => {
		setSelectedDate(date);
		const sessionsForDate = getSessionsForDate(date);
		if (sessionsForDate.length) {
			const newSelectedSession = sessionsForDate[0];
			setSelectedSessionValue(newSelectedSession);
		} else {
			setSelectedSession(undefined);
		}
	};

	const setSelectedSessionValue = (newSelectedSession: Session) => {
		setSelectedSession(newSelectedSession);
		const firstTicketInSession: PriceOption = newSelectedSession.prices?.[0];

		if (
			firstTicketInSession &&
			(!selectedTickets?.[firstTicketInSession.id] ||
				selectedTickets?.[firstTicketInSession.id] >
					newSelectedSession.seatsAvailable)
		) {
			setSelectedTickets({
				[firstTicketInSession.id]:
					newSelectedSession.seatsAvailable > 0
						? activity.quantityRequiredMin > newSelectedSession.seatsAvailable
							? newSelectedSession.seatsAvailable
							: activity.quantityRequiredMin
						: 0
			});
		}
	};

	const selectedActivityOfferValid = useMemo(() => {
		const numberOfSelectedSeats = getNumberOfSelectedSeats(
			selectedTickets,
			selectedSession?.prices
		);
		if (
			selectedTickets &&
			Object.values(selectedTickets).some(st => st) &&
			numberOfSelectedSeats >= activity.quantityRequiredMin &&
			numberOfSelectedSeats <= activity.quantityRequiredMax &&
			selectedSession &&
			selectedDate
		) {
			return true;
		}
		return false;
	}, [selectedTickets, selectedSession, selectedDate]);

	return {
		firstAvailableDate: availableDates?.[0],
		sessionOptions,
		selectedDate,
		selectedSession,
		selectedTickets,
		selectedActivityOfferValid,
		isDateAvailable,
		getSessionsForDate,
		setSelectedDateValue,
		setSelectedSessionValue,
		setSelectedTicketsValue: setSelectedTickets
	};
};

export default useActivityOffer;
