import { useApolloClient } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo, useState } from 'preact/hooks';

import { myBookingsClient as myBookingsClientImport } from 'apollo/client';
import { GET_MERCHANTS_BY_GLOBAL_ID } from 'apollo/myBookings/queries';
import { GET_PRODUCT_OWNERS_BY_CODE } from 'apollo/myDesti/queries';

import { IProductOwnerExtended, ProductOwnersMap } from '../../types';

const useStripeProductOwners = () => {
	const myTravelPlanClient = useApolloClient();
	const myBookingsClient = useApolloClient(myBookingsClientImport);

	const [productOwnersMap, setProductOwnersMap] = useState<ProductOwnersMap>(
		{}
	);

	const productOwnerCodes: string[] = useMemo(
		() => Object.values(productOwnersMap)?.map(po => po.code),
		[productOwnersMap]
	);

	const loadProductOwners = async (codes: string[]) => {
		// load product owners from MyTravelPlan
		const productOwnersResponse = await myTravelPlanClient.query({
			query: GET_PRODUCT_OWNERS_BY_CODE,
			variables: { codes: codes }
		});
		const productOwnerData = productOwnersResponse.data.product_owners;

		// load product owners/merchants from MyBookings
		const productOwnerGlobalIds = productOwnerData.map(po => po.global_id);

		const merchantsResponse = await myBookingsClient.query({
			query: GET_MERCHANTS_BY_GLOBAL_ID,
			variables: { globalIds: productOwnerGlobalIds }
		});

		const merchantData = merchantsResponse.data.merchant;

		const productOwners: { [key: string]: IProductOwnerExtended } = {};

		productOwnerData.forEach(po => {
			const merchantExternalId = merchantData.find(
				m => m.global_id === po.global_id
			)?.external_id;

			const extendedPO = {
				...po,
				globalId: po.global_id,
				external_id: merchantExternalId,
				externalId: merchantExternalId
			};
			if (merchantExternalId) {
				const stripeOptions = merchantExternalId
					? { stripeAccount: merchantExternalId }
					: undefined;

				const loader = loadStripe(
					process.env.PREACT_APP_STRIPE_PUBLIC_KEY,
					stripeOptions
				);
				extendedPO.loader = loader;
			}
			productOwners[po.code] = extendedPO;
		});

		setProductOwnersMap(productOwners);
	};

	return {
		productOwnersMap,
		productOwnerCodes,
		loadProductOwners
	};
};

export default useStripeProductOwners;
