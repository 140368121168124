import styled from 'styled-components';
import { FunctionalComponent } from 'preact';
import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

const TicketChipContainer = styled.div`
    background-color: ${Colors.V30};
    padding: 0.25rem 0.5rem;
    border-radius: 3.25rem;
    display: flex;
    align-items: center;
`;

const TicketChipTitle = styled.span`
	font-size: ${FontSize.smSize};
    font-weight: ${FontWeight.semibold};
    color: ${Colors.V900};
	white-space: nowrap;
`;

interface IProps {
	title?: string;
}

const TicketChip: FunctionalComponent<IProps> = ({ title }) => {

	return (
		<TicketChipContainer>
			<TicketChipTitle>{title}</TicketChipTitle>
		</TicketChipContainer>
	);
};

export default TicketChip;
