import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontSize, FontWeight } from 'styles/defaultTheme';

const BoxWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	background: ${Colors.V400};
	padding: 0.2rem 0.5rem;
	border-radius: 0.25rem;
	flex-direction: column;
	color: ${Colors.V0};
	font-size: ${FontSize.xsSize};
`;

const BoxTitle = styled.span`
	font-size: ${FontSize.xxsSize};
`;

const BoxSubtitle = styled.span`
	display: flex;
	align-items: center;
	font-weight: ${FontWeight.bold};
`;

type Props = {
	title: string;
	subtitle?: string;
};

const InfoBox: FunctionalComponent<Props> = ({ title, subtitle }) => {
	return (
		<BoxWrapper>
			<BoxTitle>{title}</BoxTitle>
			{subtitle && <BoxSubtitle>{subtitle}</BoxSubtitle>}
		</BoxWrapper>
	);
};

export default InfoBox;
