import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'preact/hooks';

import { GET_PASSENGER_CATEGORIES_INTEGRATION } from 'apollo/myDesti/mutations';
import { TransportType } from 'types/enums';

export enum OptionCategoryTypes {
	passengers = 'passengers',
	addons = 'addons'
}

export type TrainbusOptionCategoriesWithKeyNamesType = {
	[OptionCategoryTypes.passengers]: {
		VU: { count: number }; // Adult
		BO: { count: number; ages?: number[] }; // Child/Youth
		SE: { count: number; ages?: number[]; retired: boolean[] }; // Senior
		PS: { count: number; ages?: number[]; retired: boolean[] }; // Retired
		SU: { count: number; ages?: number[] }; // Student
	};
	[OptionCategoryTypes.addons]: {
		pets: { count: number };
	};
};

export type TrainbusOptionCategoriesType = {
	[OptionCategoryTypes.passengers]: {
		[key: string]: {
			count: number;
			ages?: number[];
			retired: boolean[];
			keyName: string;
		};
	};
	[OptionCategoryTypes.addons]: {
		pets: { count: number };
	};
};
export const initialValuesOptionCategories = {
	[OptionCategoryTypes.passengers]: {
		VU: { count: 1 }, // Adult
		BO: { count: 0, ages: [] }, // Child/Youth
		SE: { count: 0, ages: [], retired: [] }, // Senior
		PS: { count: 0, ages: [], retired: [] }, // Senior
		SU: { count: 0, ages: [] } // Student
	},
	[OptionCategoryTypes.addons]: {
		pets: { count: 0 }
	}
};

/**
 * TODO: rewrite with arrays - objects created a complicated setup
 * @returns
 *
 */

const useOptionCategories = () => {
	const [optionCategories, setOptionCategories] =
		useState<TrainbusOptionCategoriesWithKeyNamesType>(
			initialValuesOptionCategories
		);

	const [optionCategoryParameters, setOptionCategoryParameters] = useState({});

	const [loadPassengerCategories] = useMutation(
		GET_PASSENGER_CATEGORIES_INTEGRATION,
		{
			variables: {
				transportType: TransportType.TrainBus
			}
		}
	);

	useEffect(() => {
		const getPassengerCategoryParameters = async () => {
			try {
				const { data } = await loadPassengerCategories();

				if (data.getPassengerCategories) {
					const optionCategoryParameters = data.getPassengerCategories.reduce(
						(accummulator, currentValue) => {
							accummulator[currentValue.value] = currentValue;
							return accummulator;
						},
						{}
					);
					setOptionCategoryParameters(optionCategoryParameters);
				}
			} catch {
				setOptionCategories(undefined);
			}
		};

		getPassengerCategoryParameters();
	}, []);

	const minCountForCategory = {
		[OptionCategoryTypes.passengers]: {
			VU: 1, // Adult
			BO: 0, // Child/Youth
			SE: 0, // Senior
			SU: 0, // Student
			PS: 0 // Student
		},
		[OptionCategoryTypes.addons]: {
			pets: 0
		}
	};

	const countPerOptionCategoryType = useMemo(() => {
		const count = {};

		if (optionCategories) {
			Object.keys(optionCategories).forEach(categoryTypeKey => {
				const categoryType = optionCategories[categoryTypeKey];

				count[categoryTypeKey] = Object.values(categoryType).reduce(
					(accummulator: number, currentValue: any) =>
						accummulator + currentValue.count,
					0
				);
			});
		}

		return count;
	}, [optionCategories]);

	const updateCategoryCount = (categoryTypeId, categoryId, value) => {
		const updatedOptionCategories = {
			...optionCategories
		};
		updatedOptionCategories[categoryTypeId][categoryId].count = value;

		if (
			value < updatedOptionCategories[categoryTypeId][categoryId].ages?.length
		) {
			const updatedPassengerAges = [
				...updatedOptionCategories[categoryTypeId][categoryId].ages
			];
			updatedPassengerAges.length = value;
			updatedOptionCategories[categoryTypeId][categoryId].ages =
				updatedPassengerAges;
			if (categoryId === 'SE') {
				const updatedPassengerRetired = [
					...updatedOptionCategories[categoryTypeId][categoryId].retired
				];
				updatedPassengerRetired.length = value;
				updatedOptionCategories[categoryTypeId][categoryId].retired =
					updatedPassengerRetired;
			}
		} else {
			const updatedPassengerAges = [
				...(updatedOptionCategories[categoryTypeId][categoryId].ages || [])
			];
			updatedPassengerAges.push(
				...Array.from({ length: value - updatedPassengerAges.length }).map(
					_ => undefined
				)
			);
			updatedOptionCategories[categoryTypeId][categoryId].ages =
				updatedPassengerAges;

			if (categoryId === 'SE') {
				const updatedPassengerRetired = [
					...updatedOptionCategories[categoryTypeId][categoryId].retired
				];
				updatedPassengerRetired.push(
					...Array.from({ length: value - updatedPassengerRetired.length }).map(
						_ => false
					)
				);
				updatedOptionCategories[categoryTypeId][categoryId].retired =
					updatedPassengerRetired;
			}
		}
		setOptionCategories(updatedOptionCategories);
	};

	const updatePassengerAge = (categoryId, passengerIndex, value) => {
		const updatedOptionCategories = {
			...optionCategories
		};
		updatedOptionCategories['passengers'][categoryId].ages[passengerIndex] =
			isNaN(value) ? value : Number(value);
		setOptionCategories(updatedOptionCategories);
	};

	const updateSeniorIsRetired = (passengerIndex, value) => {
		const updatedOptionCategories = {
			...optionCategories
		};
		updatedOptionCategories['passengers']['SE'].retired[passengerIndex] = value;

		setOptionCategories(updatedOptionCategories);
	};

	const resetOptionCategories = optionCategories => {
		setOptionCategories(optionCategories);
	};

	const getPassengerCategoryValue = category => {
		return optionCategories['passengers'][category].count;
	};

	const getPassengerAge = (categoryId, passengerIndex) => {
		return optionCategories['passengers'][categoryId]?.ages[passengerIndex];
	};

	const getSeniorIsRetired = passengerIndex => {
		return optionCategories['passengers']['SE']?.retired[passengerIndex];
	};

	const getAddonCategoryValue = category => {
		return optionCategories['addons'][category].count;
	};

	const ageRangeByPassengerCategory = useMemo(() => {
		if (optionCategoryParameters) {
			return Object.keys(optionCategoryParameters).reduce((acc, curr) => {
				const passengerAgeRangeForCategory =
					optionCategoryParameters[curr]?.passengerAgeRange;
				if (passengerAgeRangeForCategory) {
					acc[curr] = {
						min: passengerAgeRangeForCategory.passengerAgeFrom,
						max: passengerAgeRangeForCategory.passengerAgeTo
					};
				}
				return acc;
			}, {});
		}

		return undefined;
	}, [optionCategoryParameters]);

	const validationErrors = useMemo(() => {
		const errors = {};

		if (!optionCategories) {
			errors['passengers'] = 'At least one traveler must be added.';
			return errors;
		}

		// one traveler must be selected
		const totalCountOfPassengers =
			optionCategories.passengers.VU?.count +
			optionCategories.passengers.SE?.count +
			optionCategories.passengers.SU?.count +
			optionCategories.passengers.BO?.count;
		if (totalCountOfPassengers === 0) {
			errors['passengers'] = 'At least one traveler must be added.';
		}

		// children under 6 can not travel without an adults
		const children = optionCategories.passengers.BO;
		const countOfAdults =
			optionCategories.passengers.VU.count +
			optionCategories.passengers.SE.count +
			optionCategories.passengers.SU.count;

		if (children.count && countOfAdults === 0) {
			const childrenYoungerThan6Indices = children.ages
				.map((childAge, i) => (childAge <= 6 ? `${i}` : undefined))
				.filter(i => i);

			errors['BO'] = childrenYoungerThan6Indices.reduce((acc, curr) => {
				acc[curr] = 'Children under the age of 6 can not travel alone.';
				return acc;
			}, {});
		}

		// age range validation for every passenger category
		Object.keys(ageRangeByPassengerCategory).forEach(categoryId => {
			const ageRange = ageRangeByPassengerCategory[categoryId];
			const retiredAgeRange = ageRangeByPassengerCategory['PS']; // retired, but not a senior

			if (ageRange) {
				optionCategories.passengers[categoryId].ages.forEach((age, index) => {
					let { min, max } = ageRange;
					if (categoryId === 'SE') {
						const isRetired =
							optionCategories.passengers[categoryId].retired[index];
						if (isRetired) {
							({ min, max } = retiredAgeRange); // use min/max from the retired passenger parameters
						}
					}

					if (age > max || age < min) {
						if (!errors[categoryId]) {
							errors[categoryId] = {};
						}
						errors[categoryId][index] = `Age must be between ${min} and ${max}`;
					}

					if (!age || !/^[0-9\b]+$/.test(age)) {
						if (!errors[categoryId]) {
							errors[categoryId] = {};
						}
						errors[categoryId][
							index
						] = `Enter a valid age between ${min} and ${max}`;
					}
				});
			}
		});

		// only one addon of same type can be added per traveler
		const pets = optionCategories.addons.pets;
		if (pets.count > countOfAdults) {
			errors['pets'] = 'You can bring max one pet per traveller.';
		}

		return errors;
	}, [optionCategories, ageRangeByPassengerCategory]);

	const areOptionCategoriesValid = useMemo(() => {
		return Object.keys(validationErrors).length === 0;
	}, [validationErrors]);

	const getOptionCategoriesWithRealIds = (): TrainbusOptionCategoriesType => {
		const passengers = {};
		Object.entries(optionCategories.passengers).forEach(([key, value]) => {
			if (key === 'SE') {
				const { retired = [], ages = [] } = value as any;
				const retiredIndices = retired
					.map((retired, i) => (retired ? i : ''))
					.filter(String);

				if (retiredIndices.length) {
					passengers[optionCategoryParameters['PS'].id] = {
						count: retiredIndices.length,
						ages: ages.filter((_, index) => {
							return retiredIndices.indexOf(index) !== -1;
						}),
						keyName: 'PS'
					};
					passengers[optionCategoryParameters['SE'].id] = {
						count: retired.length - retiredIndices.length,
						ages: ages.filter((age, index) => {
							return retiredIndices.indexOf(index) === -1;
						}),
						keyName: 'SE'
					};
				} else {
					passengers[optionCategoryParameters['SE'].id] = {
						...value,
						ages: [...ages], // to avoid deep clone issues
						keyName: 'SE'
					};
				}
			} else if (key !== 'PS') {
				// PS case is handled as part of SE
				passengers[optionCategoryParameters[key].id] = {
					...value,
					ages: 'ages' in value ? [...value['ages']] : undefined, // to avoid deep clone issues
					keyName: key
				};
			}
		});

		return {
			passengers,
			addons: { ...optionCategories.addons }
		};
	};

	return {
		minCountForCategory,
		optionCategories,
		countPerOptionCategoryType,
		validationErrors,
		areOptionCategoriesValid,
		ageRangeByPassengerCategory,
		optionCategoryParameters,
		updateCategoryCount,
		updatePassengerAge,
		updateSeniorIsRetired,
		resetOptionCategories,
		getPassengerCategoryValue,
		getPassengerAge,
		getAddonCategoryValue,
		getSeniorIsRetired,
		getOptionCategoriesWithRealIds
	};
};

export default useOptionCategories;
