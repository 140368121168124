import { useReactiveVar } from '@apollo/client';
import { useContext } from 'preact/hooks';
import { useHistory } from 'react-router-dom';

import useIntl from 'hooks/useIntl';
import { hostConfigurationVar } from 'redesigncache/hostConfiguration/cache';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonContainer,
	GetGestModalWrapper,
	Modal,
	ModalHeader,
	ModalInfoText,
	ModalParagraph,
	ProminentMessage
} from 'redesigncomponents/Modal';
import { ModalContext } from 'redesigncomponents/WidgetModal';
import { Paths } from 'router';

const GetGestPaymentConfirmationModal = ({
	open,
	affiliateLink = false,
	showExploreMoreTravel = true
}) => {
	const history = useHistory();
	const { t } = useIntl('checkout.getGestPaymentConfirmationModal');
	const { onModalClose } = useContext(ModalContext);
	const hostConfiguration = useReactiveVar(hostConfigurationVar);

	const siteName = hostConfiguration.name;

	const goToHome = () => {
		history.replace(Paths.Main);
	};

	const closeWidget = () => {
		onModalClose();
	};

	return (
		<Modal isOpen={open}>
			<GetGestModalWrapper>
				<ModalHeader>{t('congratulationsHeader')}</ModalHeader>
				<ModalInfoText>
					<ModalParagraph>
						{t('youHaveSuccessfullyCompletedThePayment')}
					</ModalParagraph>
					<ModalParagraph>
						{t('yourBookingAndPaymentConfirmationWillBeEmailedToYou')}
					</ModalParagraph>
					{showExploreMoreTravel && (
						<ProminentMessage>
							{t('exploreMoreOfDestination', { destination: siteName })}
						</ProminentMessage>
					)}
				</ModalInfoText>
				<ButtonContainer>
					<Button
						title={
							affiliateLink
								? t('closeButtonTitle')
								: t('exploreMoreButtonTitle')
						}
						buttonStyleType={ButtonStyleTypes.primary}
						onClick={affiliateLink ? closeWidget : goToHome}
					></Button>
				</ButtonContainer>
			</GetGestModalWrapper>
		</Modal>
	);
};

export default GetGestPaymentConfirmationModal;
