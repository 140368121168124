import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import { CartTypes } from 'types/shared/Cart';
import { formatListsPrice } from 'utils/price/priceFormatting';
import secondsToDMH from 'utils/time/secondsToDMH';

const useSingleTravelDetails = (travelPlan: any) => {
	const { t } = useIntl('travels');

	const getTravelPlanLocationString = (travelPlan, type) => {
		switch (type) {
			case CartTypes.STAY:
				return `${travelPlan?.travel_itinerary_stays[0]?.city}, ${dayjs(
					travelPlan?.travel_itinerary_stays[0]?.check_in_date
				).format('DD.MM.')} - ${dayjs(
					travelPlan?.travel_itinerary_stays[0]?.check_out_date
				).format('DD.MM.')}`;
			case CartTypes.TRANSPORT:
				return `${travelPlan?.travel_itinerary_transports[0]?.city_from} - ${
					travelPlan?.travel_itinerary_transports[0]?.city_to
				}, ${dayjs(
					travelPlan?.travel_itinerary_transports[0]?.start_transport
				).format('DD.MM.')}`;
			case CartTypes.ACTIVITY:
				return `${
					travelPlan?.travel_itinerary_activities[0]?.product_location?.city
				}, ${dayjs(
					travelPlan?.travel_itinerary_activities[0]?.date_time_activity
				).format('DD.MM.')}`;
		}
	};

	const travelPlanLocation = useMemo(() => {
		if (travelPlan?.travel_itinerary_stays?.length > 0) {
			return getTravelPlanLocationString(travelPlan, CartTypes.STAY);
		} else if (travelPlan?.travel_itinerary_transports?.length > 0) {
			return getTravelPlanLocationString(travelPlan, CartTypes.TRANSPORT);
		} else if (travelPlan?.travel_itinerary_activities?.length > 0) {
			return getTravelPlanLocationString(travelPlan, CartTypes.ACTIVITY);
		}
	}, [travelPlan]);

	const getNumberOfPeopleString = item => {
		const adults = t(
			'adults',
			{ count: item?.number_of_adults },
			item?.number_of_adults || 0
		);
		const children = t(
			'children',
			{ count: item?.number_of_children },
			item?.number_of_children || 0
		);

		if (item.number_of_children > 0) {
			return `${adults} + ${children}`;
		}
		if (item.number_of_children < 1) {
			return adults;
		}
	};

	const getTravelPlanItemObj = (item: any, type: CartTypes) => {
		return {
			...item,
			type: type,
			startTime: dayjs
				.utc(
					item.check_in_date || item.start_transport || item.date_time_activity
				)
				.format('HH:mm A'),
			startDate: dayjs(
				item.check_in_date || item.start_transport || item.date_time_activity
			).format('DD.MM.YYYY'),
			endDate: dayjs(item.check_out_date).format('DD.MM.YYYY'),
			numberOfPeople: getNumberOfPeopleString(item),
			numberOfTickets: item?.number_of_tickets || t('noInfoAvailable'),
			checkInTime: item.check_in_time,
			checkOutTime: item.check_out_time,
			duration: item.duration && secondsToDMH(item.duration),
			totalPrice: `${item.currency} ${formatListsPrice(item.price)}`,
			address: item?.product_location?.address,
			longitude: item?.product_location?.longitude,
			latitude: item?.product_location?.latitude
		};
	};

	const travelPlanItems = useMemo(() => {
		const travelPlanItems = [];
		if (travelPlan?.travel_itinerary_activities?.length > 0) {
			travelPlan?.travel_itinerary_activities.map(travelPlanItem => {
				travelPlanItems.push(
					getTravelPlanItemObj(travelPlanItem, CartTypes.ACTIVITY)
				);
			});
		}
		if (travelPlan?.travel_itinerary_stays?.length > 0) {
			travelPlan?.travel_itinerary_stays.map(travelPlanItem => {
				travelPlanItems.push(
					getTravelPlanItemObj(travelPlanItem, CartTypes.STAY)
				);
			});
		}
		if (travelPlan?.travel_itinerary_transports?.length > 0) {
			travelPlan?.travel_itinerary_transports.map(travelPlanItem => {
				travelPlanItems.push(
					getTravelPlanItemObj(travelPlanItem, CartTypes.TRANSPORT)
				);
			});
		}
		return travelPlanItems;
	}, [travelPlan]);

	return {
		travelPlanLocation,
		travelPlanItems
	};
};

export default useSingleTravelDetails;
