import { useCallback, useMemo } from 'preact/hooks';

import { useCheckout } from 'redesigncache/checkout/useCheckout';
import {
	ITransportWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';

import { OfferDetails } from '../../types';

const filterBookingTokens = (wishlistItems: WishlistItem[]) =>
	wishlistItems
		.filter(
			wi =>
				wi.type === WishlistItemTypes.TRANSPORT &&
				(wi.item as ITransportWishlistItem).booking_token
		)
		.map(wi => (wi.item as ITransportWishlistItem).booking_token);

const useItemCanBeAddedToWishlist = (
	selectedTransportOfferDetails: OfferDetails
) => {
	const { wishlist } = useWishlist();
	const { checkout } = useCheckout();

	const bookingTokensInBookingProcess = useMemo((): string[] => {
		const bookingTokensInWishlist = filterBookingTokens(wishlist.wishlistItems);

		const bookingTokensInCheckout = filterBookingTokens(
			checkout.travelPlans.flatMap(tp => tp.travelPlanItems)
		);

		return Array.from(
			new Set([...bookingTokensInWishlist, ...bookingTokensInCheckout])
		);
	}, [checkout.travelPlans, wishlist.wishlistItems]);

	const itemCanBeAddedToWishlist = useCallback(
		(bookingToken: string) =>
			!bookingTokensInBookingProcess.includes(bookingToken) &&
			selectedTransportOfferDetails?.offer.transport_item.bookingToken !==
				bookingToken,
		[bookingTokensInBookingProcess, selectedTransportOfferDetails]
	);

	return { itemCanBeAddedToWishlist };
};

export default useItemCanBeAddedToWishlist;
