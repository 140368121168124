import styled from 'styled-components';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import { Colors, BodyWrapperHeight, DeviceBreakpoints } from 'styles/defaultTheme';

export const StyledBodyWrapper = styled(BodyWrapper).attrs({
	as: 'div'
})`
	background: ${Colors.V0};
	border-top: 1px solid ${Colors.V30};
    border-bottom: 1px solid ${Colors.V30};
	padding: 1.25rem;
	color: ${Colors.V900};
	height: ${BodyWrapperHeight.base};
	overflow-y: auto;
	position: relative;
	display: grid;
	@media ${DeviceBreakpoints.small} {
		height: ${BodyWrapperHeight.mobile};
	}
`;

export const DividerWrapper = styled.div`
	position: relative;
`;

export const DividerText = styled.p`
    text-align: center;
    position: absolute;
    background: ${Colors.V0};
    left: calc(50% - 1.625rem);
    top: -0.6875rem;
    width: 3.25rem;
`;

export const DividerLine = styled.div`
	height: 1px;
	background: ${Colors.V200};
	margin-top: 25px;
	margin-bottom: 25px;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	margin-top: 20px;
`;

export const InputRow = styled.div`
	display: flex;
	gap: 15px;
	> div {
		flex: 1;
	}
`;

export const CheckboxLabelText = styled.span`
	font-size: 14px;
`;

export const TermsLink = styled.a`
	color: #6f627b;
	font-weight: 700;
	text-decoration: underline;
	margin-left: 5px;
	cursor: pointer;
`;
