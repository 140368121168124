import { useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'preact/hooks';

import useTrainbusTransportSortCodebookOptions from 'hooks/useTrainbusTransportSortOptions';
import useTransportTypeCodebookOptions from 'hooks/useTransportTypeOptions';
import { Option } from 'redesigntypes/Option';
import { TransportTripType, TransportType } from 'types/enums';

import {
	initialTrainTransportSearchValue,
	trainTransportSearchOptionsVar
} from '.';
import { OfferDetails } from '../../types';
import { TrainbusOptionCategoriesType } from '../components/OptionCategories/useOptionCategories';

const useTransportSearchCache = () => {
	const { trainbusTransportSortOptions } =
		useTrainbusTransportSortCodebookOptions();
	const { transportTypeOptions } = useTransportTypeCodebookOptions();
	const {
		fromLocation,
		toLocation,
		transportType,
		transportSort,
		tripType,
		airClass,
		travelPeriod,
		optionCategories,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	} = useReactiveVar(trainTransportSearchOptionsVar);

	// TODO: Move all the useeffects to a separate initialization hook
	useEffect(() => {
		if (!travelPeriod?.every(d => d)) {
			setTravelPeriod([
				new Date(Date.now() + 5 * 60 * 1000),
				new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			]);
		}
	}, []);

	useEffect(() => {
		if (transportTypeOptions?.length && !transportType) {
			setTransportType(
				transportTypeOptions.find(tt => tt.value === TransportType.TrainBus)
			);
		}
	}, [transportTypeOptions]);

	useEffect(() => {
		if (trainbusTransportSortOptions?.length && !transportSort) {
			setTransportSort(trainbusTransportSortOptions?.[0]);
		}
	}, [trainbusTransportSortOptions]);

	const initializeTrainTransportSearchCache = (
		transportType,
		tripType,
		transportSort,
		optionCategories,
		toLocation,
		travelPeriod
	) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			transportType,
			tripType,
			transportSort,
			optionCategories,
			fromLocation: undefined,
			toLocation,
			travelPeriod,
			selectedDepartureTransportOfferDetails: undefined,
			selectedReturnTransportOfferDetails: undefined
		});
	};

	const setTransportType = (transportType: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			transportType
		});
	};

	const setFromLocation = (fromLocation: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			fromLocation
		});
	};

	const setToLocation = (toLocation: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			toLocation
		});
	};

	const setTravelPeriod = (travelPeriod: [Date, Date]) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			travelPeriod
		});
	};

	const setTripType = (tripType: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			tripType
		});
	};

	const setTransportSort = (transportSort: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			transportSort
		});
	};

	const setAirClass = (airClass: Option) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			airClass
		});
	};

	const setSelectedDepartureTransportOfferDetails = (
		selectedDepartureTransportOfferDetails: OfferDetails
	) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			selectedDepartureTransportOfferDetails
		});
	};

	const setSelectedReturnTransportOfferDetails = (
		selectedReturnTransportOfferDetails: OfferDetails
	) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			selectedReturnTransportOfferDetails
		});
	};

	const setOptionCategories = (
		optionCategories: TrainbusOptionCategoriesType
	) => {
		trainTransportSearchOptionsVar({
			...trainTransportSearchOptionsVar(),
			optionCategories
		});
	};

	const resetTransportSearchOptionsCache = () => {
		trainTransportSearchOptionsVar(initialTrainTransportSearchValue);
	};

	const isSelectedOfferValid = useMemo(() => {
		const usedTripType =
			selectedDepartureTransportOfferDetails?.tripType || tripType;
		if (usedTripType?.value === TransportTripType.OneWay) {
			return !!selectedDepartureTransportOfferDetails;
		} else if (usedTripType?.value === TransportTripType.RoundTrip) {
			return (
				!!selectedDepartureTransportOfferDetails &&
				!!selectedReturnTransportOfferDetails
			);
		}
	}, [
		tripType,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails
	]);

	return {
		transportType,
		fromLocation,
		toLocation,
		travelPeriod,
		tripType,
		transportSort,
		airClass,
		optionCategories,
		isSelectedOfferValid,
		selectedDepartureTransportOfferDetails,
		selectedReturnTransportOfferDetails,
		setTransportType,
		setFromLocation,
		setToLocation,
		setTravelPeriod,
		setTripType,
		setTransportSort,
		setAirClass,
		setOptionCategories,
		setSelectedDepartureTransportOfferDetails,
		setSelectedReturnTransportOfferDetails,
		initializeTrainTransportSearchCache,
		resetTransportSearchOptionsCache
	};
};

export default useTransportSearchCache;
