import { useHistory } from 'react-router';

import useIntl from 'hooks/useIntl';
import useUserAuth from 'redesigncache/auth/useUserAuth';
import BodyWrapper from 'redesigncomponents/BodyWrapper';
import Button from 'redesigncomponents/Button';
import Footer from 'redesigncomponents/Footer';
import { Paths } from 'router';

import PlannerIcon from 'assets/icons/account/account-planner.svg';

import {
	EmptyWishlistContainer,
	IconContent,
	IconTitle,
	IconWrapper,
	WishlistHeaderSubTitle,
	WishlistHeaderTitle
} from './StyledComponents';

const EmptyWishlistInfo = () => {
	const { t } = useIntl('wishlist.emptyWishlistInfo');
	const history = useHistory();
	const { isLoggedIn } = useUserAuth();

	const goToTravelPlanner = () => history.push(Paths.TravelPlanner);
	const goToSignIn = () => history.push(Paths.SignIn);

	return (
		<>
			<BodyWrapper>
				<EmptyWishlistContainer>
					<WishlistHeaderTitle>{t('yourWishlistTitle')}</WishlistHeaderTitle>
					<WishlistHeaderSubTitle>
						{isLoggedIn
							? t('checkoutTheTravelPlannerInfo')
							: t('checkoutTheTravelPlannerInfoGuestUser')}
					</WishlistHeaderSubTitle>
					{isLoggedIn && (
						<IconContent>
							<IconWrapper onClick={goToTravelPlanner}>
								<PlannerIcon></PlannerIcon>
							</IconWrapper>
							<IconTitle>{t('travelPlannerLinkTitle')}</IconTitle>
						</IconContent>
					)}
				</EmptyWishlistContainer>
			</BodyWrapper>
			<Footer>
				{isLoggedIn ? (
					<Button
						title={t('travelPlannerButtonTitle')}
						onClick={goToTravelPlanner}
					/>
				) : (
					<Button title={t('signInButtonTitle')} onClick={goToSignIn} />
				)}
			</Footer>
		</>
	);
};

export default EmptyWishlistInfo;
