import { useMemo } from 'preact/hooks';

import dayjs from 'dayjs';

import useIntl from 'hooks/useIntl';
import {
	IMerchandiseWishlistItem,
	WishlistItem
} from 'redesigncache/wishlist/types';

type MerchandiseWishlistItemDetails = {
	title: string;
	city: string;
	total: string;
};

const useMerchandiseWishlistItemRedirectModal = (
	wishlistItem: WishlistItem
) => {
	const { t } = useIntl('activityDetails.activityWishlistItemDetails');

	const merchandiseWishlistItemDetails =
		useMemo((): MerchandiseWishlistItemDetails => {
			const merchandiseItem = wishlistItem.item as IMerchandiseWishlistItem;
			const details: MerchandiseWishlistItemDetails = {
				title: `${merchandiseItem.supplierName}: ${merchandiseItem.name}`,
				city: merchandiseItem.product_location?.city,
				total: t('total', {
					amount: merchandiseItem.price.amount,
					currency: merchandiseItem.price.currency
				})
			};

			return details;
		}, [wishlistItem]);

	return {
		merchandiseWishlistItemDetails
	};
};

export default useMerchandiseWishlistItemRedirectModal;
