import { useEffect } from 'preact/hooks';

import AccommodationTypeSectionForm from './components/AccommodationTypeSectionForm';
import PriceSection from './components/PriceSection';
import { SectionsContainer } from './components/StyledComponents';
import TicketTypeSectionForm from './components/TicketTypeSectionForm';
import TrainTransportItemPreview from './components/TrainTransportItemPreview';
import useTrainTransportDetails from './hooks/useTrainTransportDetails';

const TrainTransportDetailsContainer = () => {
	const {
		offerDetails,
		transportDetails,
		transportPrice,
		transportPassengerString,
		selectedOptions,
		openSection,
		setItineraryPriceOptions,
		setSegmentSeatPlacementCompartment,
		setSegmentSeatPlacementOrientation,
		setSegmentSeatPlacementCharacteristic,
		openFormSection,
		isSectionValid,
		findNextSegmentWithAccommodationInItineraryIndex,
		clearTransportDetails
	} = useTrainTransportDetails();

	useEffect(() => {
		clearTransportDetails();
	}, []);

	const onConfirm = () => {
		if (openSection) {
			const nextSegmentWithAccommodationInItineraryIndex =
				findNextSegmentWithAccommodationInItineraryIndex(
					openSection.segmentIndex
				);
			if (nextSegmentWithAccommodationInItineraryIndex !== -1) {
				openFormSection(
					openSection.itineraryIndex,
					nextSegmentWithAccommodationInItineraryIndex,
					'accommodation'
				);
			} else {
				openFormSection(
					openSection.itineraryIndex + 1,
					undefined,
					'ticketType'
				);
			}
		}
	};

	return (
		<SectionsContainer>
			<TrainTransportItemPreview
				offerDetails={offerDetails}
			></TrainTransportItemPreview>
			{transportDetails?.itineraries?.map((itinerary, itineraryIndex) => {
				const selectedItineraryOptions = selectedOptions?.[itineraryIndex];

				return (
					<>
						{(openSection?.itineraryIndex >= itineraryIndex ||
							(selectedItineraryOptions &&
								Object.keys(selectedItineraryOptions).length !== 0)) && (
							<TicketTypeSectionForm
								itinerary={itinerary}
								selectedItineraryOptions={selectedItineraryOptions}
								setItineraryPriceOptions={(
									priceGroupValue,
									flexibilityIndex,
									flexibilityVariantNumber
								) =>
									setItineraryPriceOptions(
										itineraryIndex,
										priceGroupValue,
										flexibilityIndex,
										flexibilityVariantNumber
									)
								}
								open={
									openSection?.itineraryIndex === itineraryIndex &&
									typeof openSection?.segmentIndex === 'undefined' &&
									openSection?.type === 'ticketType'
								}
								setOpenSection={() =>
									openFormSection(itineraryIndex, undefined, 'ticketType')
								}
							/>
						)}
						{itinerary.segments.map((segment, segmentIndex) => {
							const selectedSegmentOptions =
								selectedOptions?.[itineraryIndex]?.segments[segmentIndex] || {};
							const segmentHasAccommodationsChoices =
								segment.segmentsPlacements[0].placementCompartments?.length !==
									0 ||
								segment.segmentsPlacements[0].placementOrientation?.length !==
									0 ||
								segment.segmentsPlacements[0].placementCharacteristics
									?.length !== 0;

							return (
								<>
									{((openSection?.itineraryIndex > itineraryIndex ||
										(openSection?.itineraryIndex === itineraryIndex &&
											openSection?.segmentIndex > segmentIndex)) &&
										segmentHasAccommodationsChoices) ||
									(openSection?.itineraryIndex === itineraryIndex &&
										openSection?.segmentIndex === segmentIndex &&
										openSection?.type === 'accommodation') ||
									(selectedSegmentOptions &&
										Object.keys(selectedSegmentOptions).length !== 0) ? (
										<AccommodationTypeSectionForm
											priceGroupValue={
												selectedItineraryOptions?.priceGroupValue
											}
											segment={segment}
											selectedSegmentOptions={selectedSegmentOptions}
											setSegmentSeatPlacementCompartment={compartmentValue =>
												setSegmentSeatPlacementCompartment(
													itineraryIndex,
													segmentIndex,
													compartmentValue
												)
											}
											setSegmentSeatPlacementOrientation={orientationValue =>
												setSegmentSeatPlacementOrientation(
													itineraryIndex,
													segmentIndex,
													orientationValue
												)
											}
											setSegmentSeatPlacementCharacteristic={characteristicValue =>
												setSegmentSeatPlacementCharacteristic(
													itineraryIndex,
													segmentIndex,
													characteristicValue
												)
											}
											open={
												openSection?.itineraryIndex === itineraryIndex &&
												openSection?.segmentIndex === segmentIndex &&
												openSection?.type === 'accommodation'
											}
											setOpenSection={() =>
												openFormSection(
													itineraryIndex,
													segmentIndex,
													'accommodation'
												)
											}
										/>
									) : undefined}
								</>
							);
						})}
					</>
				);
			})}
			<PriceSection
				passengers={transportPassengerString}
				price={transportPrice}
				onConfirmDisabled={!isSectionValid()}
				onConfirm={
					openSection?.itineraryIndex < transportDetails?.itineraries.length &&
					onConfirm
				}
			/>
		</SectionsContainer>
	);
};

export default TrainTransportDetailsContainer;
