import { FunctionalComponent } from 'preact';

import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';

import MinusIcon from 'assets/minus.svg';
import PlusIcon from 'assets/plus.svg';

type Props = {
	collapsed: boolean;
	onClick: () => void;
};

const DetailsButton: FunctionalComponent<Props> = ({ collapsed, onClick }) => {
	return (
		<Button
			title="Details"
			buttonStyleType={ButtonStyleTypes.tertiary}
			tightPadding
			onClick={onClick}
			Icon={collapsed ? MinusIcon : PlusIcon}
		/>
	);
};

export default DetailsButton;
