import { useMemo, useState } from 'preact/hooks';

import dayjs from 'dayjs';

import { GET_TRAVEL_ITINERARY } from 'apollo/myPages/queries';
import { useMyPagesQuery } from 'apollo/myPages/useMyPages';

const useTravelHistoryDetails = () => {
	const [travelPlans, setTravelPlans] = useState([]);
	const { loading } = useMyPagesQuery(GET_TRAVEL_ITINERARY, {
		fetchPolicy: 'no-cache',
		onCompleted: ({ travel_itinerary }) => {
			setTravelPlans(travel_itinerary);
		}
	});

	const sortedTravelPlans = useMemo(() => {
		const sortedPlansObj = {};
		travelPlans.forEach(plan => {
			if (
				plan.travel_itinerary_activities.length > 0 ||
				plan.travel_itinerary_stays.length > 0 ||
				plan.travel_itinerary_transports.length > 0
			) {
				const travelPlanYear = Number(dayjs(plan.created_at).year());
				const previouslySavedPlansForYear =
					sortedPlansObj[travelPlanYear] || [];
				sortedPlansObj[travelPlanYear] = [...previouslySavedPlansForYear, plan];
			}
		});

		return sortedPlansObj;
	}, [travelPlans]);

	return {
		sortedTravelPlans,
		loading
	};
};

export default useTravelHistoryDetails;
