import Select from 'react-select';

import styled from 'styled-components';
import { useTheme } from 'styled-components';

import { commonLabelStyles } from 'redesigncomponents/FieldTextInput/StyledTextInput';
import { fonts } from 'styles';

const StyledSelect = styled.div`
	position: relative;
	border: none;
	font-size: 16px;
	outline: none;
	color: #2c2c2c;

	width: 100%;
`;

const customStyles = {
	option: (provided, state) => ({
		padding: '8px 12px',
		width: '100%',
		fontSize: '14px',
		fontFamily: fonts.default,
		cursor: 'pointer',
		color: '#342245',
		backgroundColor: '#efedf0',
		'&:hover': {
			backgroundColor: '#CCC8D1'
		},
		'&:active': {
			backgroundColor: '#CCC8D1'
		},
		'&--is-selected:': {
			backgroundColor: '#CCC8D1'
		}
	}),
	container: provided => ({
		...provided
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	control: (provided, state) => {
		const { isDisabled } = state.selectProps;

		return {
			...provided,
			padding: '15px',
			fontSize: '14px',
			borderRadius: '4px',
			fontFamily: fonts.default,
			borderColor: state?.selectProps?.error ? '#b3261e' : '#91889b',
			borderWidth: state?.selectProps?.error ? '2px' : '1px',

			boxShadow: state?.isFocused
				? state?.selectProps?.error
					? '0px 0px 0px 1px #b3261e inset'
					: '0px 0px 0px 1px #91889b inset'
				: 'none',

			backgroundColor: isDisabled ? '#CCC8D1' : '#fff',
			color: isDisabled ? '#79747E' : '#857a8f'
		};
	},
	placeholder: (provided, state) => ({
		...provided,
		fontFamily: fonts.default,
		fontSize: '16px',
		fontWeight: 400,

		color: '#2C2C2C'
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: state?.selectProps?.isDisabled ? '#79747E' : '#000',

		fontFamily: fonts.default,
		fontSize: '16px',
		width: '100%'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		color: '#79747E'
	}),
	valueContainer: (provided, state) => ({
		...provided,
		overflow: 'visible'
	})
};

const TextLabel = styled.label`
	${commonLabelStyles};
	top: 15px;
	${props =>
		props.minified &&
		`
		top: -8px;
		font-size: 11px;
		left: 10px;
		color: ${props => (props.error ? '#b3261e' : '#857a8f')};
  	`}
	&:hover,
  	&:focus {
		transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
	}
`;

const InfoText = styled.span`
	font-size: 11px;
	line-height: 14px;
	padding: 5px 5px 0 5px;
	color: ${props => (props.error ? '#b3261e' : '#2c2c2c')};
	display: inline-block;
`;

const SelectComponent = ({
	inputProps,
	error,
	label,
	options = [],
	isSearchable = false,
	placeholder = null,
	disabled = false,
	required = false,
	value,
	onChange,
	selectAll,
	clearAll,
	...rest
}) => {
	const theme = useTheme();

	return (
		<StyledSelect error={error}>
			<Select
				theme={theme}
				{...rest}
				{...inputProps}
				value={value}
				required={required}
				styles={customStyles}
				options={options}
				onChange={onChange}
				selectAll={selectAll}
				clearAll={clearAll}
				isSearchable={true}
				isClearable={false}
				placeholder={placeholder}
				isDisabled={disabled}
				error={error}
			/>
			<TextLabel minified={label}>{label}</TextLabel>
			{error && <InfoText error={error}>{error}</InfoText>}
		</StyledSelect>
	);
};

export default SelectComponent;
