import styled from 'styled-components';

import { DeviceBreakpoints, FontSize, FontWeight } from 'styles/defaultTheme';

export const FormHeader = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	flex-direction: column;
	align-items: flex-start;
`;

export const FormHeaderLeft = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

export const FormHeaderTitle = styled.h6`
	font-weight: ${FontWeight.semibold};
	font-size: ${FontSize.base};
	@media ${DeviceBreakpoints.small} {
		font-size: ${FontSize.smSize};
	}
`;

export const FormHeaderRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.1rem;
	width: 100%;
	max-height: unset;
	> div {
		background-color: transparent;
	}
	> button {
		height: 2.5rem;
		@media ${DeviceBreakpoints.small} {
			height: 2.25rem;
		}
	}

	@media ${DeviceBreakpoints.small} {
		align-items: flex-end;
	}
`;

export const LogoBackground = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
	background-color: #f7f6f8;
	margin-right: 0.625rem;
	svg {
		width: 1.25rem;
	}
`;

export const DeleteIconContainer = styled.div`
	margin: 0 0.75rem;
	display: flex;
`;

export const Spacer = styled.span`
	flex: 1;
	background-color: transparent;
`;

export const InfoWrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	gap: 0.25rem;
	justify-items: flex-start;
	font-weight: ${FontWeight.normal};
	@media (max-width: 385px) {
		grid-template-columns: 1fr;
	}
`;

export const InfoSpacer = styled.div`
	@media (max-width: 385px) {
		display: none;
	}
`;
