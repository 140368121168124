import { FunctionalComponent } from 'preact';
import { FieldMetaState } from 'react-final-form';

import { Option } from 'redesigntypes/Option';

import SelectComponent from './StyledSelect';

export type SelectProps = {
	input?: any; // TODO FieldInputProps<any>;
	meta?: FieldMetaState<any>;

	options?: Option[];
	searchable?: boolean;
	isErrorVisible?: boolean;
	placeholder?: string;
	disabled?: boolean;
	required?: boolean;
	smallPadding?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	loading?: boolean;
	[otherProp: string]: any;
};

const FieldSelect: FunctionalComponent<SelectProps> = ({
	input,
	meta = {},
	label,
	options = [],
	placeholder = null,
	disabled = false,
	smallPadding = false,
	secondary = false,
	tertiary = false,
	searchable = false,
	required = false,
	CustomOptionComponent = undefined,
	loading = false,
	...rest
}) => {
	const error = meta?.touched && (meta.error || meta.submitError);

	return (
		<SelectComponent
			label={label}
			options={options}
			disabled={disabled}
			inputProps={{ ...input, ...rest }}
			error={error}
			smallPadding={smallPadding}
			searchable={searchable}
			secondary={secondary}
			tertiary={tertiary}
			required={required}
			loading={loading}
			CustomOptionComponent={CustomOptionComponent}
		/>
	);
};

export default FieldSelect;
