export const getPhoneConstraints = t => {
	const constraints = {
		length: {
			minimum: 7,
			tooShort: t('phoneNumberTooShort'),
			maximum: 15,
			tooLong: t('phoneNumberTooLong')
		}
	};
	return constraints;
};
