const capitalizeWords = (text: string): string => {
	return (
		text &&
		text
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
	);
};

export default capitalizeWords;
