import { FunctionalComponent } from 'preact';

import styled from 'styled-components';

import { Colors, FontWeight, FontSize } from 'styles/defaultTheme';

import ClockIcon from 'assets/icons/transport/clock.svg';
import SwapIcon from 'assets/icons/transport/swap.svg';

const DateChipContainer = styled.div`
	color: ${Colors.V900};
	background-color: ${Colors.V30};
	padding: 0.5rem;
	display: inline-flex;
	align-items: center;
	border-radius: 0.25rem;
	gap: 0.5rem;
	font-weight: ${FontWeight.bold};
	height: 2.25rem;
`;

const DateFromTitle = styled.span``;

const DurationTitle = styled.span`
	font-weight: ${FontWeight.normal};
	font-size: ${FontSize.smSize};
	white-space: nowrap;
`;

const DateToTitle = styled.span``;

interface IProps {
	fromTitle?: string;
	toTitle?: string;
	durationTitle?: string;
	roundTrip?: boolean;
}

const DateChip: FunctionalComponent<IProps> = ({
	fromTitle,
	toTitle,
	roundTrip,
	durationTitle
}) => {
	return (
		<DateChipContainer>
			<ClockIcon></ClockIcon>
			<DateFromTitle>{fromTitle}</DateFromTitle>
			{toTitle && (
				<>
					{roundTrip ? <SwapIcon></SwapIcon> : ' - '}
					<DateToTitle>{toTitle}</DateToTitle>
					<DurationTitle>{durationTitle}</DurationTitle>
				</>
			)}
		</DateChipContainer>
	);
};

export default DateChip;
