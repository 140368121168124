import { FunctionalComponent } from 'preact';

import useIntl from 'hooks/useIntl';
import useItemIcon from 'hooks/useItemIcon';
import useToggle from 'hooks/useToggle';
import { WishlistItem, WishlistItemTypes } from 'redesigncache/wishlist/types';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import StyledCheckbox from 'redesigncomponents/FieldCheckbox/StyledCheckbox';
import InfoBox from 'redesigncomponents/InfoBox';
import LocationMap from 'redesigncomponents/LocationMap';
import { LocationMessage } from 'redesignscreens/Account/TravelHistory/components/StyledComponents';
import DetailsButton from 'redesignscreens/Account/TravelPlanner/components/SingleTravelDetails/DetailsButton';
import { MapDivider } from 'redesignscreens/Main/Activities/ActivityDetails/ActivityDetailsContainer/components/StyledComponents';

import TrashIcon from 'assets/trash.svg';

import {
	CardLogoWrapper,
	CardName,
	CardWrapper,
	CheckboxWrapper,
	DetailsBox,
	HeadWrapper,
	InfoBoxWrapper,
	LeftWrapper,
	Link,
	Name,
	RightWrapper,
	RowName,
	RowValue,
	TableBody,
	TableLeft,
	TableRight,
	TableRow
} from './components/StyledComponents';
import useWishlistItemCard from './useWishlistItemCard';

type Props = {
	wishlistItem: WishlistItem;
	removeWishListItem: () => void;
	selected: boolean;
	toggleWishlistItemSelected: () => void;
};

const WishlistItemCard: FunctionalComponent<Props> = ({
	wishlistItem,
	removeWishListItem,
	selected,
	toggleWishlistItemSelected
}) => {
	const { t: commonTranslations } = useIntl('common.general');
	const { t: detailsTranslations } = useIntl('wishlist.travelPlanItem.details');
	const [collapsed, toggleCollaped] = useToggle();
	const [showMap, toggleShowMap] = useToggle();
	const { title, infos, details } = useWishlistItemCard(wishlistItem);
	const { Icon } = useItemIcon(wishlistItem.type, wishlistItem.item);

	const hasDetails = Object.keys(details).length > 0;

	return (
		<CardWrapper>
			<HeadWrapper>
				<LeftWrapper>
					<CardName>
						<CheckboxWrapper>
							<StyledCheckbox
								inputProps={{
									onChange: toggleWishlistItemSelected,
									checked: selected
								}}
							/>
						</CheckboxWrapper>
						<CardLogoWrapper>
							<Icon />
						</CardLogoWrapper>

						<Name>{title}</Name>
					</CardName>
				</LeftWrapper>
				<RightWrapper>
					<InfoBoxWrapper>
						{infos.map(info => (
							<InfoBox title={info.title} subtitle={info.subtitle} />
						))}
					</InfoBoxWrapper>
					<Button
						buttonStyleType={ButtonStyleTypes.tertiary}
						tightPadding
						onlyIcon
						Icon={TrashIcon}
						onClick={removeWishListItem}
					/>
					{hasDetails && (
						<DetailsButton collapsed={collapsed} onClick={toggleCollaped} />
					)}
				</RightWrapper>
			</HeadWrapper>

			<DetailsBox collapsed={collapsed}>
				<TableBody>
					<TableLeft>
						<TableRow>
							{wishlistItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{detailsTranslations('checkInTime')}</RowName>
									<RowValue>{details.checkInTime}</RowValue>
								</>
							) : (
								<>
									<RowName>{detailsTranslations('startTime')}</RowName>
									<RowValue>{details.startTime}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{wishlistItem.type === WishlistItemTypes.STAY ? (
								<>
									<RowName>{detailsTranslations('checkInDate')}</RowName>
									<RowValue>{details.startDate}</RowValue>
								</>
							) : (
								<>
									<RowName>{detailsTranslations('startingDate')}</RowName>
									<RowValue>{details.startDate}</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{wishlistItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{detailsTranslations('checkOutTime')}</RowName>
									<RowValue>
										{details.checkOutTime ||
											commonTranslations('noInfoAvailable')}
									</RowValue>
								</>
							)}
						</TableRow>
						<TableRow>
							{wishlistItem.type === WishlistItemTypes.ACTIVITY && (
								<>
									<RowName>{detailsTranslations('activityLength')}</RowName>
									<RowValue>{details.duration}</RowValue>
								</>
							)}
							{wishlistItem.type === WishlistItemTypes.TRANSPORT && (
								<>
									<RowName>{detailsTranslations('tripDuration')}</RowName>
									<RowValue>{details.duration}</RowValue>
								</>
							)}
							{wishlistItem.type === WishlistItemTypes.STAY && (
								<>
									<RowName>{detailsTranslations('checkOutDate')}</RowName>
									<RowValue>{details.endDate}</RowValue>
								</>
							)}
						</TableRow>
					</TableLeft>
					<TableRight>
						<TableRow>
							<RowName>{detailsTranslations('location')}</RowName>
							<RowValue>
								<Link onCLick={() => toggleShowMap(true)}>
									{details.address || detailsTranslations('showOnMap')}
								</Link>
							</RowValue>
						</TableRow>
						<TableRow>
							{wishlistItem.type === WishlistItemTypes.ACTIVITY ? (
								<>
									<RowName>{detailsTranslations('numberOfTickets')}</RowName>
									<RowValue>{details.numberOfTickets}</RowValue>
								</>
							) : (
								<>
									<RowName>{detailsTranslations('numberOfPeople')}</RowName>
									<RowValue>{details.numberOfPeople}</RowValue>
								</>
							)}
						</TableRow>
						{wishlistItem.type === WishlistItemTypes.STAY && (
							<TableRow>
								<RowName>{detailsTranslations('numberOfRooms')}</RowName>
								<RowValue>{details.item.number_of_rooms}</RowValue>
							</TableRow>
						)}
						<TableRow>
							<RowName>{detailsTranslations('totalPrice')}</RowName>
							<RowValue>{details.totalPrice}</RowValue>
						</TableRow>
					</TableRight>
				</TableBody>

				{showMap && (
					<>
						<MapDivider />
						{details.longitude && details.latitude ? (
							<LocationMap
								markers={[
									{
										lng: details.longitude,
										lat: details.latitude
									}
								]}
							/>
						) : (
							<LocationMessage>
								{detailsTranslations('mapCoordinatesAreNotProvided')}
							</LocationMessage>
						)}
					</>
				)}
			</DetailsBox>
		</CardWrapper>
	);
};

export default WishlistItemCard;
