import { FunctionalComponent, h } from 'preact';
import { FieldInputProps } from 'react-final-form';

import styled from 'styled-components';

const Wrapper = styled.div``;

const CheckboxContainer = styled.label`
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 20px;
`;

const Text = styled.span`
	${({ required }) =>
		required &&
		`
			:after {
				content: ' *';
				color: #091e42;
			}
	`}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	&:checked ~ span {
		border: 1px solid #6f627b;
	}
	&:checked ~ span:after {
		display: block;
	}
`;

const Checkmark = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	height: ${props => (props.small ? '17px' : '20px')};
	width: ${props => (props.small ? '17px' : '20px')};
	background-color: #e5e2e7;
	border: 1px solid #91889b;
	border-radius: 2px;
	&:after {
		content: '';
		position: absolute;
		display: none;
		left: ${props => (props.small ? '5px' : '6px')};
		top: ${props => (props.small ? '2px' : '2px')};
		width: ${props => (props.small ? '3px' : '4px')};
		height: ${props => (props.small ? '7px' : '9px')};
		border: solid #2e1f3b;
		border-radius: 1px;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	&:hover {
		border: 1px solid #796d84;
	}
`;

const InfoText = styled.span`
	font-size: 11px;
	line-height: 14px;
	padding: 5px 5px 0 5px;
	color: #b3261e;
	display: inline-block;
`;

type Props = {
	id?: string;
	inputProps?: any;
	label?: string | any;
	small?: boolean;
	error?: string;
	required?: boolean;
};
const StyledCheckbox: FunctionalComponent<Props> = ({
	id = undefined,
	inputProps,
	label,
	small,
	error,
	required
}) => {
	return (
		<Wrapper>
			<CheckboxContainer>
				<Text required={required}>{label}</Text>
				<Input
					id={id}
					type="checkbox"
					checked="checked"
					required={required}
					{...inputProps}
				></Input>
				<Checkmark small={small}></Checkmark>
			</CheckboxContainer>
			{error && <InfoText error={error}>{error}</InfoText>}
		</Wrapper>
	);
};

export default StyledCheckbox;
