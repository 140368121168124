import { FunctionalComponent } from 'preact';

import useIntl from 'hooks/useIntl';
import useTransportTypeCodebookOptions from 'hooks/useTransportTypeOptions';
import FieldSelect from 'redesigncomponents/FieldSelect';
import { Option } from 'redesigntypes/Option';

type Props = {
	disabled?: boolean;
	value: Option;
	onChange: (Option) => void;
};

const TransportTypeSelect: FunctionalComponent<Props> = ({
	value,
	onChange,
	disabled
}) => {
	const { t } = useIntl('transportList.options');
	const { transportTypeOptions } = useTransportTypeCodebookOptions();

	return (
		<FieldSelect
			options={transportTypeOptions}
			onChange={onChange}
			value={value}
			disabled={disabled}
			label={t('typeOfTransportSelectLabel')}
		/>
	);
};

export default TransportTypeSelect;
