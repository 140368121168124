import useIntl from 'hooks/useIntl';

export default function () {
	const { t: messages } = useIntl('common.validationMessages');

	const constraints = {
		contactEmail: {
			presence: {
				message: messages('emailRequired')
			},
			email: {
				message: messages('emailInvalid')
			}
		}
	};

	return { constraints };
}
