import styled from 'styled-components';

import { Colors, DeviceBreakpoints, FontWeight } from 'styles/defaultTheme';

export const FooterWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
`;

export const FooterContent = styled.div`
	width: 100%;
	background-color: ${Colors.V0};
	display: flex;
	justify-content: space-between;
	padding: 20px;
	align-items: center;
	position: relative;
`;

export const LeftWrapper = styled.div`
	display: flex;
	& div:first-child {
		margin-right: 10px;
	}
`;

export const RightWrapper = styled.div``;

export const PoweredBy = styled.div`
	position: relative;
	background-color: ${Colors.V0};
	border-radius: 0 0 3.75rem 3.75rem;
	margin-top: 0.1rem;
	padding: 0.5rem 2rem;
	border-top: 1px solid ${Colors.V50};
	line-height: 14px;
	@media ${DeviceBreakpoints.small} {
		display: none;
	}
`;

export const PoweredByLink = styled.a`
	font-family: 'Open Sans';
	font-size: 10px;
	font-weight: ${FontWeight.light};
	cursor: pointer;
	color: #342245;
`;

export const TooltipContainer = styled.div`
	position: absolute;
	bottom: 3.438rem; // 55px
`;
