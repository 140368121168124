import { ApolloError, useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'preact/hooks';

import { GET_AFFILIATE_LINK_BY_PK } from 'apollo/myDesti/queries';

import { affiliateLinkVar } from './cache';

const useAffiliateLink = (id: string) => {
	const [error, setError] = useState<string>();
	const { startedAt } = useReactiveVar(affiliateLinkVar);

	useEffect(() => {
		setError(undefined);
		if (id) {
			getAffiliateLink({ variables: { id } });
		}
	}, [id, startedAt]);

	const [getAffiliateLink, { loading }] = useLazyQuery(
		GET_AFFILIATE_LINK_BY_PK,
		{
			onCompleted: data => {
				const affiliateLinkResponse = data.affiliate_link_by_pk;
				if (affiliateLinkResponse && affiliateLinkResponse.is_active) {
					affiliateLinkVar({
						...affiliateLinkVar(),
						details: {
							externalId: affiliateLinkResponse.external_id,
							isActive: affiliateLinkResponse.is_active,
							productOwner: affiliateLinkResponse.product_owner && {
								code: affiliateLinkResponse.product_owner.code,
								globalId: affiliateLinkResponse.product_owner.global_id,
								enabled: affiliateLinkResponse.product_owner.enabled,
								integrations: affiliateLinkResponse.product_owner.integrations
							},
							type: affiliateLinkResponse.type,
							parameters: JSON.parse(affiliateLinkResponse.parameters)
						}
					});
				} else {
					setError('No booking link found.');
				}
			},
			onError: (error: ApolloError) => {
				setError(error.message);
			}
		}
	);

	return {
		loading,
		error: error,
		affiliateLink: affiliateLinkVar()
	};
};

export default useAffiliateLink;
