import { FunctionalComponent } from 'preact';

import EditIcon from 'assets/edit.svg';
import WizardArrow from 'assets/wizard-arrow.svg';
import CheckIcon from 'assets/wizard-checked.svg';

import { TrainTransportDetailsStep } from '../../cache';
import {
	ArrowIconWrapper,
	ItemIconWrapper,
	Link,
	StepsContainer,
	WizardItem
} from './components/StyledComponents';
import useTransportDetailsWizard from './useTransportDetailsWizard';

interface IProps {
	active?: boolean;
}

const WizardSteps: FunctionalComponent<IProps> = () => {
	const { steps, currentStep, goToStep, currentStepIndex } =
		useTransportDetailsWizard();
	return (
		<StepsContainer>
			{steps.map((step, index) => {
				return (
					<>
						<WizardItem active={step.value === currentStep}>
							<ItemIconWrapper>
								{step.value === currentStep ? <EditIcon /> : <CheckIcon />}
							</ItemIconWrapper>
							<Link
								active={step.value === currentStep}
								onClick={
									index < currentStepIndex
										? () => goToStep(step.value as TrainTransportDetailsStep)
										: undefined
								}
							>
								{step.label}
							</Link>
						</WizardItem>
						{index !== steps.length - 1 && (
							<ArrowIconWrapper>
								<WizardArrow></WizardArrow>
							</ArrowIconWrapper>
						)}
					</>
				);
			})}
		</StepsContainer>
	);
};

export default WizardSteps;
