import { useHistory } from 'react-router';

import useIntl from 'hooks/useIntl';
import Button, { ButtonStyleTypes } from 'redesigncomponents/Button';
import {
	ButtonFlexContainer,
	Modal,
	OptionModalSubtitle,
	OptionModalText,
	OptionModalTitle
} from 'redesigncomponents/Modal';
import { Paths } from 'router';

const GuestUserModal = ({ onClose }) => {
	const { t } = useIntl('wishlist.guestUserModal');
	const history = useHistory();

	const goToLogin = () => {
		history.push(Paths.SignIn, { from: Paths.Wishlist });
	};

	const goToSignUp = () => {
		history.push(Paths.SignUp, { from: Paths.Wishlist });
	};

	return (
		<Modal isOpen={true} onClose={onClose}>
			<OptionModalTitle>{t('youAreAlmostDoneHeader')}</OptionModalTitle>

			<OptionModalSubtitle>
				{t('oneMoreStepToCompleteBeforeText')}
			</OptionModalSubtitle>
			<OptionModalText>{t('signInOrRegisterText')}</OptionModalText>

			<ButtonFlexContainer>
				<Button
					title={t('logInButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goToLogin}
				></Button>
				<Button
					title={t('signUpButtonTitle')}
					buttonStyleType={ButtonStyleTypes.secondary}
					onClick={goToSignUp}
				></Button>
			</ButtonFlexContainer>
		</Modal>
	);
};

export default GuestUserModal;
