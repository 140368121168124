import { FunctionalComponent, ComponentChildren } from 'preact';

import {
    TabsNavigation
} from './StyledComponents';



interface IProps {
    children: ComponentChildren;
}

const TabsContainer: FunctionalComponent<IProps> = ({ children }) => {
    return (
        <TabsNavigation>
            {children}
        </TabsNavigation>
    );
};

export default TabsContainer;
