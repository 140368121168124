import { useMemo } from 'preact/hooks';

import { Option } from 'redesigntypes/Option';

import useIntl from './useIntl';

const useChildrenAgesOptions = () => {
	const { t } = useIntl('common.childrenAges');

	const childrenAgesOptions: Array<Option> = useMemo(() => {
		return Array.from({ length: 4 }).map((_, index) => ({
			value: index,
			label: t('years', { count: index }, index)
		}));
	}, []);

	const defaultChildAge: Option = useMemo(() => {
		return {
			value: 3,
			label: t('years', { count: 3 }, 3)
		};
	}, []);

	return { childrenAgesOptions, defaultChildAge };
};

export default useChildrenAgesOptions;
