import { useMutation } from '@apollo/client';
import { useCallback } from 'preact/hooks';

import { BOOK_TRANSPORTATION } from 'apollo/myDesti/mutations';
import { ITransportCartItem } from 'types/cache/Cart';

const useBookTransportation = item => {
	const [bookTransportation] = useMutation(BOOK_TRANSPORTATION);

	const createTransportationBooking = useCallback(
		async (
			formValues: any,
			productOwnerGlobalId: string,
			paymentIntentId: string
		) => {
			const transportationItem = item.item as ITransportCartItem;
			const passengers = transportationItem.book_transportation.passengers;

			const { firstName, lastName, email } = formValues;

			const updatedPassengers = passengers.map((passenger, index) => ({
				...passenger,
				firstName:
					index === 0 ? firstName : formValues['firstNameTraveler' + index],
				lastName:
					index === 0 ? lastName : formValues['lastNameTraveler' + index],
				email: index === 0 ? email : formValues['emailTraveler' + index]
			}));

			try {
				return await bookTransportation({
					variables: {
						journeys: transportationItem.book_transportation.journeys,
						passengers: updatedPassengers,
						integration: transportationItem.integration,
						globalId: productOwnerGlobalId,
						paymentIntentId
					}
				});
			} catch (error) {
				return error;
			}
		},
		[item]
	);

	return { createTransportationBooking };
};

export default useBookTransportation;
