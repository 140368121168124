import { useState } from 'preact/hooks';

import {
	IActivityWishlistItem,
	WishlistItem,
	WishlistItemTypes
} from 'redesigncache/wishlist/types';
import useWishlist from 'redesigncache/wishlist/useWishlist';
import {
	ActivityModel,
	PriceOption,
	Session
} from 'redesigntypes/ActivityModel';

const useAddActivityToWishlist = (
	activity: ActivityModel,
	session: Session,
	tickets: {
		[key: string]: number;
	}
) => {
	const { createWishlistItem, addItemToWishlist } = useWishlist();
	const [addedWishlistItem, setAddedWishlistItem] = useState<WishlistItem>();

	const getTotalAmount = (): number => {
		const total: number = session.prices?.reduce(
			(previousValue: number, currentValue: PriceOption) => {
				const numberOfTickets = tickets?.[currentValue.id] || 0;
				const ticketPrice = currentValue.price;
				return previousValue + numberOfTickets * ticketPrice;
			},
			0
		);

		return total;
	};

	const getNumberOfTickets = (): number => {
		const total: number = Object.values(tickets).reduce(
			(total, numberOfTickets) => numberOfTickets + total,
			0
		);

		return total;
	};

	const addToWishlist = (): WishlistItem => {
		const newWishlistItem = createActivityWishlistItem();
		addItemToWishlist(newWishlistItem);

		setAddedWishlistItem(newWishlistItem);
		return newWishlistItem;
	};

	const createActivityWishlistItem = () => {
		const totalPrice = getTotalAmount();
		const wishlistItem: Omit<WishlistItem, 'id'> = {
			type: WishlistItemTypes.ACTIVITY,
			item: {
				quantity: session.prices
					.filter(pc => tickets[pc.id])
					.map(pc => ({
						optionId: pc.id,
						quantity: tickets[pc.id],
						label: pc.label,
						numberOfSeats: pc.seatsUsed
					})),
				people: getNumberOfTickets(),
				number_of_tickets: getNumberOfTickets(),
				id: activity.id,
				name: activity.name,
				title: activity.name,
				price: {
					amount: totalPrice,
					currency: activity.displayPrice.currency
				},
				city: activity.productLocation.address,
				date: session.startTimeLocal,
				activity_type: activity.productType,
				product_owner: activity.productOwner,
				supplierName: activity.supplierName,
				fields: activity.bookingFields,
				booking_time_required: activity.bookingTimeRequired,
				startTimeLocal: session.startTimeLocal,
				endTimeLocal: session.endTimeLocal,
				startTime: session.startTime,
				endTime: session.endTime,
				terms: activity.terms,
				product_location: activity.productLocation,
				duration: activity.duration,
				integration: activity.integration,
				pickupLocations: activity.pickupLocations
			} as IActivityWishlistItem
		};

		return createWishlistItem(wishlistItem);
	};

	return { addToWishlist, createActivityWishlistItem, addedWishlistItem };
};

export default useAddActivityToWishlist;
